import { readAuthTokenCookie } from 'Utils/auth/authUtils';
import JwtDecode from 'jwt-decode';
export default class CIAMApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    this.authenticationApiUrl = `${API.CIAM.HOST}/cim/${COUNTRY}/${LANGUAGE}/v1`;
    this.country = COUNTRY;
    this.language = LANGUAGE;
  }

  getRedirectURLForUpdateMobile(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'CHANGE_USERNAME');
  }

  getRedirectURLForUpdateEmail(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'CHANGE_EMAIL');
  }

  getRedirectURLForDeleteProfile(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'DELETE_USER');
  }

  getRedirectURLForUpdatePassword(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'CHANGE_PASSWORD');
  }

  getRedirectURLForPhoneVerification(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'PHONE_VERIFY');
  }

  getRedirectURLForEmailVerification(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'EMAIL_VERIFY');
  }

  getRedirectURL(partyUid, bearerToken, flow) {
    const userId = JwtDecode(readAuthTokenCookie()).sub;

    if (!partyUid || !bearerToken) {
      throw Error();
    }
    try {
      return window.ikea.cie.reauthenticate({
        country: this.country,
        lang: this.language,
        userId: userId,
        flow: flow,
        consumer: 'OWF',
        accessToken: bearerToken
      });
    } catch (error) {
      return false;
    }
  }

  sendOTP(phone, partyUid) {
    if (!partyUid || !phone) {
      throw new Error('User ID  and phone number required to send OTP');
    }
    let authTokenCookie = readAuthTokenCookie();
    return fetch(`${this.authenticationApiUrl}/passwordless/start`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokenCookie}`
      },
      body: JSON.stringify({
        phoneNumber: phone,
        flow: 'PHONE_VERIFY',
        partyUID: partyUid
      })
    })
      .then(response => {
        if (response.status == 200) {
          return { otp_sent: true };
        } else if (response.status == 400) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (body.violations) {
          return {
            otpSentError: {
              response: {
                errors: [{ code: body.violations[0].errorCode }]
              }
            },
            otpSentStatus: false
          };
        }
        return { otpSentStatus: body.otp_sent };
      })
      .catch(error => {
        throw error; //Need to catch the error and write it to the console later!!
      });
  }

  sendEmail(loginId, partyUid) {
    if (!loginId || !partyUid) {
      throw new Error(
        'Email address and partyUID required to send verification email'
      );
    }
    let authTokenCookie = readAuthTokenCookie();
    return fetch(`${this.authenticationApiUrl}/${partyUid}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokenCookie}`
      },
      body: JSON.stringify({
        email: loginId
      })
    })
      .then(response => {
        if (response.status == 200) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        return body.email_sent;
      })
      .catch(error => {
        throw error; //Need to catch the error and write it to the console later!!
      });
  }
}
