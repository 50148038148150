// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__row-center{justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/_common/Grid/Row.scss"],"names":[],"mappings":"AACE,qBACE,sBAAA","sourcesContent":[".row {\n  &-center {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row-center": "profile__row-center"
};
export default ___CSS_LOADER_EXPORT___;
