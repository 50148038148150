import React from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

export const Swipeable = ({ children, ...props }) => {
  const handlers = useSwipeable(props);
  return <div {...handlers}>{children}</div>;
};

Swipeable.propTypes = {
  children: PropTypes.array
};
