export const ErrorTypes = {
  VERIFICATION: 20,
  ACCOUNT: 30,
  NETWORK: 40,
  SERVICE: 50,
  ACCESS: 60,
  INTERNAL_DATA: 70,
  APPLICATION: 80,
  UNKNOWN: 99
};
