import { getNumOfActiveOrders } from './transformers';
import { readAuthTokenCookie } from 'Utils/auth/authUtils';

export default class OWShoppingListApiClient {
  constructor() {
    this.apiHost = CONFIG.API.SHOPPING_LIST.HOST;
  }

  getNumOfShoppingLists() {
    const authTokenCookie = readAuthTokenCookie();
    const postData = { query: '{lists{listId}}' };

    return fetch(`${this.apiHost}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },
      body: JSON.stringify(postData)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error();
      })
      .then(json => {
        return json.data && json.data.lists && json.data.lists.length;
      })
      .catch(() => {
        throw Error();
      });
  }
}
