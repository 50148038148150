// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__divider{width:100%;border-top:1px solid rgb(var(--colour-neutral-2, 245, 245, 245)) !important}.profile__divider-dashboard{margin:3rem 0 2.5rem 0 !important}.profile__divider-dashed{border-top:1px dashed rgb(var(--colour-neutral-2, 245, 245, 245)) !important}.profile__divider-margin-xs{margin:.5rem 0}.profile__divider-margin-s{margin:1rem 0}.profile__divider-margin-m{margin:1.25rem 0}.profile__divider-margin-l{margin:2rem 0}.profile__divider-margin-xl{margin:2.5rem 0}", "",{"version":3,"sources":["webpack://./src/components/_common/Divider.scss"],"names":[],"mappings":"AAEA,kBACE,UAAA,CACA,2EAAA,CAEA,4BACE,iCAAA,CAGF,yBACE,4EAAA,CAIA,4BACE,cAAA,CAEF,2BACE,aAAA,CAEF,2BACE,gBAAA,CAEF,2BACE,aAAA,CAEF,4BACE,eAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.divider {\n  width: 100%;\n  border-top: 1px solid $colour-neutral-2 !important;\n\n  &-dashboard {\n    margin: $space-300 0 $space-250 0 !important;\n  }\n\n  &-dashed {\n    border-top: 1px dashed $colour-neutral-2 !important;\n  }\n\n  &-margin {\n    &-xs {\n      margin: $space-50 0;\n    }\n    &-s {\n      margin: $space-100 0;\n    }\n    &-m {\n      margin: $space-125 0;\n    }\n    &-l {\n      margin: $space-200 0;\n    }\n    &-xl {\n      margin: $space-250 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "profile__divider",
	"divider-dashboard": "profile__divider-dashboard",
	"divider-dashed": "profile__divider-dashed",
	"divider-margin-xs": "profile__divider-margin-xs",
	"divider-margin-s": "profile__divider-margin-s",
	"divider-margin-m": "profile__divider-margin-m",
	"divider-margin-l": "profile__divider-margin-l",
	"divider-margin-xl": "profile__divider-margin-xl"
};
export default ___CSS_LOADER_EXPORT___;
