import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, Toast } from 'Common';
import { t, exists } from 'Utils/localization/i18next';
import {
  updateProfile,
  updateProfileSettings
} from 'Redux/profile/profileThunks';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import DropdownControlled from 'BaseForm/DropdownControlled';
import PreferredStoreDropdownContainer from './PreferredStoreDropdownContainer';
// This component can be used for triggering updateProfile API call
// on every onChange. Default value for the field is read from the
// profile data in Redux store (depending on the field's name prop).
// This component can be used by the DynamicFormContainer.

const Components = {
  Dropdown: DropdownControlled,
  PreferredStoreDropdown: PreferredStoreDropdownContainer
};

class ProfileUpdateOnFieldChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showSuccessMessage: false };
    this.updateActions = {
      PreferredStoreDropdown: this.props.updateProfileSettings
    };
  }

  onChange = async e => {
    const { name, value } = e.target;
    const newProfile = { ...this.props.profile, [name]: value };
    const updateAction =
      this.updateActions[this.props.component] || this.props.updateProfile;
    const success = await updateAction(
      trimAllObjPropertiesSkipPasswords(newProfile),
      this.props.formId
    );
    if (success) {
      this.setState({ showSuccessMessage: true });
    }
  };

  render() {
    const Component = Components[this.props.component];
    if (!Component) {
      return null;
    }
    return (
      <React.Fragment>
        {this.props.enableUpdateConfirmation &&
          this.state.showSuccessMessage &&
          exists(this.props.label) && (
            <Toast
              autoCloseMs={5000}
              closeText={t('common.dismiss')}
              onClose={() => this.setState({ showSuccessMessage: false })}
            >
              <Text bold>
                {t('dashboard.confirmation.genericFieldUpdateTitle', {
                  field: t(this.props.label)
                })}
              </Text>
              <Text>
                {t('dashboard.confirmation.genericFieldUpdateText', {
                  field: t(this.props.label).toLowerCase()
                })}
              </Text>
            </Toast>
          )}
        <Component
          {...this.props}
          value={this.props.profile[this.props.name]}
          onChange={this.onChange}
          hideKeys={this.props.hideKeys}
        />
      </React.Fragment>
    );
  }
}

ProfileUpdateOnFieldChange.propTypes = {
  formId: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  profile: PropTypes.object,
  updateProfile: PropTypes.func.isRequired,
  updateProfileSettings: PropTypes.func.isRequired,
  userLogin: PropTypes.string,
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  enableUpdateConfirmation: PropTypes.bool
};

ProfileUpdateOnFieldChange.defaultProps = {
  profile: {},
  enableUpdateConfirmation: true
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    userLogin: state.auth.userLogin
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateProfile,
      updateProfileSettings
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileUpdateOnFieldChange);
