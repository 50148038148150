import { transform } from './StoresTransformers';

export default class StoresApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    if (CONFIG.FEATURE.ENABLE_NAVIGATION_STORES_API) {
      this.apiUrl = `${API.STORES.HOST}/${COUNTRY}/${LANGUAGE}/meta-data/navigation/stores-suggested-detailed.json`;
    } else {
      this.apiUrl = `${API.IRW.HOST}/retail/irms/${COUNTRY}/${LANGUAGE}/stores`;
      this.headers = {
        Accept: 'application/vnd.ikea.api+json;version=2',
        Consumer: 'OWPRLO',
        Contract: '42427'
      };
    }
  }

  getStores() {
    return fetch(this.apiUrl, {
      method: 'GET',
      headers: {
        ...this.headers
      }
    })
      .then(response => {
        return response.status === 200 ? response.json() : [];
      })
      .then(data => {
        return transform(data);
      })
      .catch(() => {
        return [];
      });
  }
}
