import React from 'react';
import PropTypes from 'prop-types';
import IkeaRadioButtonGroup from '@ingka/radio-button-group';
import classNames from 'classnames';
import { t } from 'Utils/localization/i18next';
import styles from './RadioButtonGroup.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

const RadioButtonGroup = props => (
  <IkeaRadioButtonGroup
    id={`${props.formId}-${props.name}`}
    name={t(props.label)}
    list={props.options.map((item, index) => {
      return {
        label: t(item.label),
        id: `${props.formId}-${props.name}-${index}`,
        name: props.name,
        text: t(item.label),
        value: item.value,
        disabled: item.disabled,
        checked: item.value === props.value
      };
    })}
    prefix={CONFIG.APP.STYLES_PREFIX}
    describedById={`${props.formId}-${props.name}-error`}
    className={classNames(styles['radio-btn-group'], {
      [props.className]: props.className
    })}
    onBlur={props.onBlur}
    onChange={props.onChange}
  />
);

RadioButtonGroup.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  // Array with { "label: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  showOptional: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default RadioButtonGroup;
