import React from 'react';
import PropTypes from 'prop-types';
import IkeaFormField from '@ingka/form-field';

const FormField = props => (
  <IkeaFormField
    id={props.id}
    className={props.className}
    prefix={CONFIG.APP.STYLES_PREFIX}
    validation={props.validation}
    fieldHelper={props.fieldHelper}
    shouldValidate={props.shouldValidate}
    valid={props.valid}
  >
    {props.children}
  </IkeaFormField>
);

FormField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  validation: PropTypes.object,
  fieldHelper: PropTypes.object,
  shouldValidate: PropTypes.bool,
  valid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default FormField;
