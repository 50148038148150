import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import { createProfile } from 'Redux/profile/profileThunks';
import { setPageTitle } from 'Utils/document/documentUtils';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import Routes from 'Routes/profileRoutes';
import {
  PageHeader,
  InlineAlert,
  TranslateToInnerHTML,
  WithoutHeaderAndFooter,
  Grid,
  Row,
  Col,
  Loading
} from 'Common';

class SignupFamilyKioskContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showSuccessMessage: false };
  }

  componentDidMount() {
    this.setShowSuccessMessage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setShowSuccessMessage();
    }
  }

  setShowSuccessMessage = () => {
    const showSuccessMessage =
      getQueryStringValue(this.props.location.search, 'success') === 'true';

    if (this.state.showSuccessMessage === showSuccessMessage) {
      return;
    }
    this.setState({ showSuccessMessage });
    if (showSuccessMessage) {
      setTimeout(this.refreshPage, 5000);
    }
  };

  refreshPage = () => {
    this.props.history.push(Routes.SIGNUP_KIOSK);
  };

  getSignupConfirmationPageURL = () => {
    return `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/${
      CONFIG.APP.BASE_NAME
    }${Routes.SIGNUP_CONFIRMATION_BASIC}${
      this.from ? `?from=${this.from}` : ''
    }`;
  };

  onSubmit = data => {
    const confirmationPageURL = `${window.location}?success=true`;
    const disableAutoLogin = true;

    this.props.createProfile(
      {
        ...trimAllObjPropertiesSkipPasswords(data),
        profileType: 'family'
      },
      'family-signup-form',
      null,
      confirmationPageURL,
      disableAutoLogin
    );
  };

  render() {
    setPageTitle(
      t(['metaData.sigupFamilyPageTitle', 'metaData.defaultPageTitle'])
    );

    if (this.props.isLoading) {
      return <Loading isLoading text={t('loaders.signupFamily')} />;
    }

    return (
      <WithoutHeaderAndFooter>
        <Grid
          style={{
            paddingTop: '2rem',
            paddingBottom: '2rem',
            minHeight: '75vh'
          }}
        >
          <Row center>
            <Col xs={12} sm={10} lg={7}>
              {' '}
              <PageHeader>{t('forms.signup.pageTitleFamily')}</PageHeader>
              {this.state.showSuccessMessage && (
                <InlineAlert
                  title="forms.signup.successTitle"
                  text="forms.signup.successText"
                  icon="check"
                />
              )}
              <DynamicFormContainer
                formId="family-signup-form"
                textSubmit={t('forms.signup.btnSubmit')}
                onSubmit={this.onSubmit}
              >
                <TranslateToInnerHTML translationKey="forms.signup.extraHtmlSectionFamily" />
              </DynamicFormContainer>
            </Col>
          </Row>
        </Grid>
      </WithoutHeaderAndFooter>
    );
  }
}

SignupFamilyKioskContainer.propTypes = {
  isLoading: PropTypes.bool,
  createProfile: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createProfile
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.PROFILE_CREATE]
  };
};

const SignupFamilyWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFamilyKioskContainer);

export default withRouter(SignupFamilyWithConnect);
