import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';
import mask from 'Utils/mask/mask';
import styles from './DashboardFieldReadContainer.scss';

const DashboardFieldReadContainer = props => {
  const placeholder =
    props.names.length === 1 &&
    !props.profile[props.names[0]] &&
    t(`dashboard.placeholders.${props.names[0]}`);

  const fieldTitle = props.title && t(`${props.title}`);

  return (
    <FlexPanel>
      {props.hidden ? (
        <Text larger bold colour="grey-500">
          ••••••••
        </Text>
      ) : placeholder ? (
        <Text colour="grey-500">{placeholder}</Text>
      ) : (
        <div className={styles['specifier-value-pair']}>
          {fieldTitle && (
            <div className={styles.specifier}>
              <Text>{fieldTitle}</Text>
            </div>
          )}
          <div className={styles.value}>
            <Text ltr>
              {props.names.reduce((concatenated, name) => {
                const value = props.profile[name]
                  ? `${mask(props.profile[name], props.maskRules)} `
                  : '';
                return `${concatenated}${value}`;
              }, '')}
            </Text>
          </div>
        </div>
      )}
    </FlexPanel>
  );
};

DashboardFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  hidden: PropTypes.bool,
  profile: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  maskRules: PropTypes.arrayOf(PropTypes.object)
};

DashboardFieldReadContainer.defaultProps = {
  names: []
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardFieldReadContainer);
