import {
  transformFields,
  transformValues,
  transformOptions
} from './ExperianTransformers';

export default class ExperianApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.ADDRESS_AUTOCOMPLETE.HOST;
    this.apiKey = CONFIG.API.ADDRESS_AUTOCOMPLETE.CLIENT_ID;
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
  }

  async complete(
    searchQuery,
    containerId = '',
    maxResults,
    successCallback,
    failureCallback
  ) {
    const requestBody = {
      country: this.countryCode,
      language: this.languageCode,
      freeText: searchQuery
    };

    const result = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': `${this.apiKey}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!result.ok) {
      failureCallback();
      return;
    }

    const json = await result.json();

    const transformed = transformOptions(json);

    if (!transformed) {
      failureCallback();
      return;
    }
    successCallback(transformed);
  }

  async capture(searchQuery, captureId, successCallback, failureCallback) {
    const requestBody = {
      country: this.countryCode,
      language: this.languageCode,
      captureId
    };
    const result = await fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': `${this.apiKey}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!result.ok) {
      failureCallback();
      return;
    }
    const json = await result.json();
    const transformed1 = transformFields(json, this.addressFieldMap);
    const transformed2 = transformValues(transformed1, this.addressValueMap);

    if (!transformed2) {
      failureCallback();
      return;
    }
    successCallback(transformed2);
  }
}
