import { readAuthTokenCookie } from 'Utils/auth/authUtils';

export default class LoyaltyApiClient {
  constructor() {
    this.apiHost = CONFIG.API.LOYALTY.HOST;
    this.country = CONFIG.COUNTRY;
    this.language = CONFIG.LANGUAGE;
  }

  isRewardsMember() {
    let authTokenCookie = readAuthTokenCookie() || '';
    let apiUrl = `${this.apiHost}/rewards/${this.country}/${this.language}`;

    if (CONFIG.API.LOYALTY.ENABLE_LIGHTWEIGHT_CALL) {
      apiUrl = `${this.apiHost}/${this.country}/isMember`;
    }

    return fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: authTokenCookie
      }
    })
      .then(response => {
        return response.status === 200 ? response.json() : { isMember: false };
      })
      .then(data => {
        return data.isMember;
      })
      .catch(() => {
        return false;
      });
  }
}
