import maskers from './maskers';

const { matchAndInsert, matchAndReplace, removeSpaces, toNumeric } = maskers;

export default {
  JP: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /^([0-9]{3})([0-9]{4})$/, insert: '-' })
  ],
  PL: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /^([0-9]{2})-?([0-9]{3})$/, insert: '-' })
  ],
  PT: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /([0-9]{4})-?([0-9]{3})/, insert: '-' })
  ],
  FO: [
    value => toNumeric(value),
    value => matchAndReplace(value, { match: /(\d{3})/, replace: 'FO-$1' })
  ]
};

// There can be one or more masking rules for each key defined in
// "defaultCountryCodeMap" in countryCodes.js
