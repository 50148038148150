export class Response {
  errors = [];
  hasErrors() {
    return this.errors.length && true;
  }
}

export class ResponseError {
  constructor(type, code, additionalPayload) {
    this.type = type;
    this.code = code;
    this.payload = additionalPayload || null;
  }
}
