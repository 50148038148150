import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from 'Utils/localization/i18next';
import {
  Button,
  Grid,
  Row,
  Col,
  PageHeader,
  Text,
  Loading,
  WithoutHeaderAndFooter
} from 'Common';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { resetPassword } from 'Redux/auth/authThunks';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import Routes from 'Routes/profileRoutes';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import styles from './ResetPasswordContainer.scss';

class ResetPasswordContainer extends React.PureComponent {
  onSubmit = data => {
    const formId = 'reset-password-form';
    this.props.resetPassword(data, formId);
  };

  onSubmitBasic = data => {
    const formId = 'reset-password-form';
    const { search, hash } = this.props.location;
    const from = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.FROM
    );
    this.props.resetPassword(data, formId, from, true);
  };

  render() {
    const { pathname } = this.props.location;
    const isBasicResetPassword = new RegExp(Routes.RESET_PASSWORD_BASIC).test(
      pathname
    );
    if (this.props.isLoading && isBasicResetPassword) {
      return (
        <WithoutHeaderAndFooter>
          <Loading
            isLoading={this.props.isLoading}
            text={t('loaders.default')}
          />
        </WithoutHeaderAndFooter>
      );
    }
    if (this.props.isLoading) {
      return (
        <Loading isLoading={this.props.isLoading} text={t('loaders.default')} />
      );
    }
    if (isBasicResetPassword) {
      const from = getQueryStringValue(
        `${this.props.location.search}${this.props.location.hash}`,
        Routes.QUERY_PARAMS.FROM
      );
      const loginUrl = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN_BASIC}?from=${from}`;
      return (
        <WithoutHeaderAndFooter>
          <Grid className={styles['reset-password']}>
            <Row>
              <Col
                sm={5}
                lg={5}
                xl={4}
                className={styles['reset-password-left']}
              >
                <PageHeader center={false} margin={false}>
                  {t('password.pageTitle')}
                </PageHeader>
                <Text marginBottom="m" marginTop="m">
                  {t('password.text')}{' '}
                </Text>
                <Button
                  marginTop="xl"
                  type="secondary"
                  text={t('common.backToLogin')}
                  className={styles['reset-password-login-btn-desktop']}
                  onClick={() => {
                    window.location = loginUrl;
                  }}
                />
              </Col>
              <Col sm={1} lg={2} xl={3} />
              <Col sm={6} lg={5} xl={5}>
                <DynamicFormContainer
                  formId="reset-password-form"
                  textSubmit={t('password.submitButton')}
                  onSubmit={this.onSubmitBasic}
                />
                <Button
                  mobileFullWidth
                  marginTop="m"
                  type="secondary"
                  text={t('common.backToLogin')}
                  className={styles['reset-password-login-btn-mobile']}
                  onClick={() => {
                    window.location = loginUrl;
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </WithoutHeaderAndFooter>
      );
    }
    return (
      <Grid className={styles['reset-password']}>
        <Row>
          <Col sm={5} lg={5} xl={4} className={styles['reset-password-left']}>
            <PageHeader center={false} margin={false}>
              {t('password.pageTitle')}
            </PageHeader>
            <Text marginBottom="m" marginTop="m">
              {t('password.text')}{' '}
            </Text>
            <Button
              marginTop="xl"
              type="secondary"
              text={t('common.backToLogin')}
              className={styles['reset-password-login-btn-desktop']}
              onClick={() => {
                window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`;
              }}
            />
          </Col>
          <Col sm={1} lg={2} xl={3} />
          <Col sm={6} lg={5} xl={5}>
            <DynamicFormContainer
              formId="reset-password-form"
              textSubmit={t('password.submitButton')}
              onSubmit={this.onSubmit}
            />
            <Button
              mobileFullWidth
              marginTop="m"
              type="secondary"
              text={t('common.backToLogin')}
              className={styles['reset-password-login-btn-mobile']}
              onClick={() => {
                window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`;
              }}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

ResetPasswordContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  resetPassword: PropTypes.func,
  isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetPassword
    },
    dispatch
  );
};
const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE]
  };
};
const ResetPasswordWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
export default withRouter(ResetPasswordWithConnect);
