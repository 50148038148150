import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, Link, Row, Col, Grid } from 'Common';
import styles from './MissingDataNotification.scss';

const MissingDataNotificationBanner = props => (
  <Grid>
    <Row center className={styles['row-padding']}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <div className={styles['missing-data-notification']}>
          <div className={styles['missing-data-notification-content']}>
            <Text bold>{t(props.text)} </Text>
            <Text className={styles['missing-data-notification-action-links']}>
              {props.action && (
                <React.Fragment>
                  <Link
                    bold
                    onClick={() => {
                      const tabButton = document.querySelector(
                        '[aria-controls="tab-button-preferences"]'
                      );
                      const editButton = document.getElementById(
                        'family-dashboard-communication-preferences-link'
                      );
                      tabButton.click();
                      editButton.click();
                    }}
                  >
                    {t('missingDataNotification.button')}
                  </Link>
                </React.Fragment>
              )}
            </Text>
          </div>
        </div>
      </Col>
    </Row>
  </Grid>
);

MissingDataNotificationBanner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.string,
  tabButtonId: PropTypes.string,
  editButtonId: PropTypes.string,
  dismissable: PropTypes.bool
};

MissingDataNotificationBanner.defaultProps = {
  dismissable: true
};

export default MissingDataNotificationBanner;
