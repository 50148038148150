import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './SignupSection.scss';

const SignupSection = props => (
  <div className={styles['signup-section']}>
    {props.title && <H2>{t(props.title)}</H2>}
    {props.children}
  </div>
);

SignupSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default SignupSection;
