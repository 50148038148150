export function updatePreferencesRequest(uncheckedPreferences) {
  return {
    patchPreference: {
      preferenceMeta: {
        consumer: 'OWPL',
        sourceInternalId: '',
        externalUserId: ''
      },
      preferencePackage: uncheckedPreferences.map(preference => {
        return {
          type: preference.type,
          decisionCode: 'NO',
          communicationChannel: preference.channel.toUpperCase()
        };
      })
    }
  };
}
