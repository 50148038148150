import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';
import mask from 'Utils/mask/mask';

class DashboardMobileFieldReadContainer extends React.PureComponent {
  render() {
    const placeholder =
      !this.props.profile[this.props.names[0]] &&
      t(`dashboard.placeholders.${this.props.names[0]}`);
    const maskedValue = this.props.profile[this.props.names[0]]
      ? `${mask(this.props.profile[this.props.names[0]], this.props.maskRules)}`
      : '';

    return (
      <FlexPanel spread>
        {placeholder ? (
          <Text colour="grey-500">{placeholder}</Text>
        ) : (
          <Text>{maskedValue}</Text>
        )}
        {CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION && (
          <Text
            bold
            colour={
              this.props.profile.phoneVerified
                ? 'feedback-confirmation'
                : 'grey-300'
            }
          >
            {this.props.profile.phoneVerified
              ? t('common.verified')
              : t('common.unverified')}
          </Text>
        )}
      </FlexPanel>
    );
  }
}

DashboardMobileFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  profile: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  maskRules: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardMobileFieldReadContainer);
