// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__contentPane{width:100%}.profile__tabs__tab--active::after{z-index:0}", "",{"version":3,"sources":["webpack://./src/components/DashboardRegular/DashboardRegularContainer.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,mCACE,SAAA","sourcesContent":[".contentPane {\n  width: 100%; \n}\n\n.tabs__tab--active::after {\n  z-index: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentPane": "profile__contentPane",
	"tabs__tab--active": "profile__tabs__tab--active"
};
export default ___CSS_LOADER_EXPORT___;
