import { t } from 'Utils/localization/i18next';

export const divideString = (rawString = '', groupLength, divider) =>
  rawString.replace(new RegExp(`(.{${groupLength}})`, 'g'), `$1${divider}`);

/**
 * Returns the reverse of a string based on a defined separator.
 * @param {string} string - The string to be reversed.
 * @param {string} stringSeparator - The string separator.
 * @returns {string} The reversed string.
 */
export const reverseString = (string, stringSeparator) => {
  if (string && string.length > 1 && stringSeparator) {
    return string
      .split(stringSeparator)
      .reverse()
      .join(stringSeparator);
  }
  if (string && string.length > 1) {
    return string
      .split('')
      .reverse()
      .join('');
  }
  return string;
};

export const getShortString = (string = '', maxLength, stringSuffix) => {
  return string.length > maxLength
    ? string
        .substring(0, maxLength)
        .trim()
        .concat(stringSuffix)
    : string;
};

export const getOrdinalNumberCount = count => {
  const rules = new Intl.PluralRules(
    `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
    {
      type: 'ordinal'
    }
  );
  const suffixes = new Map([
    ['zero', t('common.ordinalNumberCount_zero')],
    ['one', t('common.ordinalNumberCount_one')],
    ['two', t('common.ordinalNumberCount_two')],
    ['few', t('common.ordinalNumberCount_few')],
    ['many', t('common.ordinalNumberCount_many')],
    ['other', t('common.ordinalNumberCount_other')]
  ]);
  const formatOrdinals = n => {
    const rule = rules.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix}`;
  };
  return formatOrdinals(count);
};

export const decodeBase64 = string => {
  return window.atob(string);
};

export const capitalize = string => {
  if (typeof string !== 'string') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
