import { writeCookie, deleteCookie } from '../cookies/cookiesUtils';

const DMP_ICM_ID_COOKIE_NAME = 'ICMid_DMP';
const DMP_EMAIL_ID_COOKIE_NAME = 'email_DMP';
const DMP_COOKIE_PATH = `/${CONFIG.COUNTRY.toLowerCase()}/${CONFIG.LANGUAGE.toLowerCase()}`;

export const writeDMPUserIdCookies = (cookiesContent = [], expiryTimeMs) => {
  const expiryDate = new Date();
  expiryDate.setMilliseconds(expiryDate.getMilliseconds() + expiryTimeMs);

  cookiesContent.forEach(cookieContent => {
    const { cookieName, cookieValue, secure } = cookieContent;
    if (
      cookieName === DMP_ICM_ID_COOKIE_NAME ||
      cookieName === DMP_EMAIL_ID_COOKIE_NAME
    ) {
      writeCookie(cookieName, cookieValue, expiryDate, DMP_COOKIE_PATH, secure);
    }
  });
};

export const removeDMPUserIdCookies = () => {
  deleteCookie(DMP_ICM_ID_COOKIE_NAME, DMP_COOKIE_PATH);
  deleteCookie(DMP_EMAIL_ID_COOKIE_NAME, DMP_COOKIE_PATH);
};
