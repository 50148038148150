import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getStores } from 'Redux/stores/storesThunks';
import DropdownControlled from 'BaseForm/DropdownControlled';

class PreferredStoreDropdownContainer extends React.PureComponent {
  componentDidMount() {
    this.props.getStores();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.title && (
          <Text bold marginBottom="xs" colour="grey-900">
            {t(this.props.title)}
          </Text>
        )}
        <DropdownControlled
          formId={this.props.formId}
          name={this.props.name}
          value={this.props.value}
          label={this.props.label}
          options={this.props.stores
            .map(store => {
              return {
                name: store.name,
                value: store.number
              };
            })
            .filter(store => !this.props.hideKeys.includes(store.value))}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

PreferredStoreDropdownContainer.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // From Redux/Thunks
  getStores: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.object)
};

PreferredStoreDropdownContainer.defaultProps = {
  hideKeys: []
};

const mapStateToProps = state => {
  return {
    stores: state.stores.stores
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStores
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferredStoreDropdownContainer);
