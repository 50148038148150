import LoqateAddressyApiClient from './LoqateAddressyApiClient';
import LoqateInternalApiClient from './LoqateInternalApiClient';
import LoqateEverythingLocationApiClient from './LoqateEverythingLocationApiClient';
import GoogleMapsGeocoderClient from './GoogleMapsGeocoderClient';
import GoogleMapsAutocompleteClient from './GoogleMapsAutocompleteClient';
import DaumPostalServiceClient from './DaumPostalServiceClient';
import DaDataApiClient from './DaDataApiClient';
import PostCodeNLApiClient from './PostCodeNLApiClient';
import ExperianApiClient from './ExperianApiClient';
import DeutschePostApiClient from './DeutschePostApiClient';

export const ADDRESS_SERVICE_PROVIDERS = {
  LOQATE_EVERYTHING_LOCATION: 'LOQATE_EVERYTHING_LOCATION',
  LOQATE_ADDRESSY: 'LOQATE_ADDRESSY',
  LOQATE_INTERNAL: 'LOQATE_INTERNAL',
  DAWA: 'DAWA',
  GOOGLE_MAPS_GEOCODER: 'GOOGLE_MAPS_GEOCODER',
  GOOGLE_MAPS_AUTOCOMPLETE: 'GOOGLE_MAPS_AUTOCOMPLETE',
  DAUM_POSTAL_SERVICE: 'DAUM_POSTAL_SERVICE',
  DA_DATA: 'DA_DATA',
  POSTCODE_NL: 'POSTCODE_NL',
  EXPERIAN: 'EXPERIAN',
  DEUTSCHE_POST: 'DEUTSCHE_POST'
};

export const AddressApiClients = {
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_ADDRESSY]: LoqateAddressyApiClient,
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_INTERNAL]: LoqateInternalApiClient,
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_EVERYTHING_LOCATION]: LoqateEverythingLocationApiClient,
  [ADDRESS_SERVICE_PROVIDERS.DAWA]: LoqateInternalApiClient,
  [ADDRESS_SERVICE_PROVIDERS.GOOGLE_MAPS_GEOCODER]: GoogleMapsGeocoderClient,
  [ADDRESS_SERVICE_PROVIDERS.GOOGLE_MAPS_AUTOCOMPLETE]: GoogleMapsAutocompleteClient,
  [ADDRESS_SERVICE_PROVIDERS.DAUM_POSTAL_SERVICE]: DaumPostalServiceClient,
  [ADDRESS_SERVICE_PROVIDERS.DA_DATA]: DaDataApiClient,
  [ADDRESS_SERVICE_PROVIDERS.POSTCODE_NL]: PostCodeNLApiClient,
  [ADDRESS_SERVICE_PROVIDERS.EXPERIAN]: ExperianApiClient,
  [ADDRESS_SERVICE_PROVIDERS.DEUTSCHE_POST]: DeutschePostApiClient
};
