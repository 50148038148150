export const PLANNERS_URL_PATTERNS = {
  NEW_KITCHEN_PLANNER_DESKTOP:
    CONFIG.ENV === 'production'
      ? [/^https:\/\/kitchen.planner.ikea.com\/planner\/#\/ciam$/]
      : [
          /^https:\/\/kitchen.planner.ikeadt.com\/planner\/#\/ciam$/,
          /^https:\/\/\*.ihp3-systems.red.cdtapps.com\/planner\/#\/ciam$/,
          /^http:\/\/ihp3.red.cdtapps.com:3000\/#\/ciam$/,
          /^http:\/\/kiosk.ihp3.red.cdtapps.com:30000\/#\/ciam$/
        ],
  NEW_KITCHEN_PLANNER_KIOSK:
    CONFIG.ENV === 'production'
      ? [/^https:\/\/kiosk.kitchen.planner.ikea.com\/planner\/#\/ciam$/]
      : [
          /^https:\/\/kiosk.develop.ihp3.red.cdtapps.com\/planner\/#\/ciam$/,
          /^https:\/\/kiosk.kitchen.planner.ikeadt.com\/planner\/#\/ciam$/,
          /^https:\/\/develop.ihp3.red.cdtapps.com\/planner\/#\/ciam$/
        ],
  IHP_KITCHEN_PLANNER:
    CONFIG.ENV === 'production'
      ? [/^https:\/\/kitchenplanner.ikea.com\/[a-z]{2}\/UI\/Pages\/VPUI.htm/]
      : [
          /^https:\/\/kitchenplanner.(cf01|ppe).ikeadt.com\/[a-z]{2}\/UI\/Pages\/VPUI.htm/
        ]
};

export const validIKEAUrlPatternsStricter =
  CONFIG.ENV === 'production'
    ? [
        // Finance integration
        /^https:\/\/ww4.ikea.com\/[a-z]{2}\/[a-z]{2}\/apply-for-finance[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/[a-z]{2}\/[a-z]{2}\/apply-for-finance\/\?selectedOffer=\d{4}$/,
        // Finance integration (BE)
        /^https:\/\/ikeabe.cf.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        /^https:\/\/ikeabe-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/be\/nl\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/be\/fr\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a[/]{0,1}$/,
        // Finance integration (PT)
        /^https:\/\/ikeapt-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        // Finance integration (FR)
        /^https:\/\/ikeafr-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        // Kitchen Planners integration
        ...PLANNERS_URL_PATTERNS.NEW_KITCHEN_PLANNER_DESKTOP,
        ...PLANNERS_URL_PATTERNS.NEW_KITCHEN_PLANNER_KIOSK,
        ...PLANNERS_URL_PATTERNS.IHP_KITCHEN_PLANNER,
        // IKEA Home Smart
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net\/apple-app-site-association$/,
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net\/.well-known\/assetlinks.json$/,
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net$/,
        // Other
        /^https:\/\/info.ikea-usa.com\/giftregistry$/,
        /^GeomagicalLabsInc\.Sugarcube:\/\/[a-z]{2}\.accounts\.ikea\.com\/ios\/GeomagicalLabsInc\.Sugarcube\/callback$/,
        /^https:\/\/ikea-family.ru\/api\/v1\/ciam\/callback\/login$/,
        /^https:\/\/intest\.prod\.ibes\.cdtapps\.com/,
        /^https:\/\/ww8\.ikea\.com\/clickandcollect\/[a-z]{2}\/receive\/auth/,
        /^https:\/\/ww8\.ikea\.com\/clickandcollect\/[a-z]{2}\//,
        /^https:\/\/lucl\.prod\.ibes\.cdtapps\.com\/clickandcollect\/[a-z]{2}\/receive\/auth/
      ]
    : [
        // Finance integration
        /^https:\/\/localhost:5000((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/[a-z]{2}\/[a-z]{2}\/apply-for-finance[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/[a-z]{2}\/[a-z]{2}\/apply-for-finance\/\?selectedOffer=\d{4}$/,
        // Finance integration (BE)
        /^https:\/\/ikeabe.cf.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        /^https:\/\/ikeabe-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/be\/nl\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/be\/fr\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a[/]{0,1}$/,
        /^https:\/\/ww4.ikea.com\/be\/nl\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a\/\?selectedOffer=\d{4}$/,
        /^https:\/\/ww4.ikea.com\/be\/fr\/test-apply-for-finance-64d5dcdf-e5d3-4180-8afb-019cfa308a3a\/\?selectedOffer=\d{4}$/,
        // Finance integration (PT)
        /^https:\/\/ikeapt-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        // Finance integration (FR)
        /^https:\/\/ikeafr-dev.jifiti.com((\/apply)|(\/ikea-family)|())[/]{0,1}$/,
        // Kitchen Planners integration
        ...PLANNERS_URL_PATTERNS.NEW_KITCHEN_PLANNER_DESKTOP,
        ...PLANNERS_URL_PATTERNS.NEW_KITCHEN_PLANNER_KIOSK,
        ...PLANNERS_URL_PATTERNS.IHP_KITCHEN_PLANNER,
        // IKEA Home Smart
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net\/apple-app-site-association$/,
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net\/.well-known\/assetlinks.json$/,
        /^https:\/\/ihsaccounts.alpha.config.homesmart.ikea.net$/,
        // IBES
        /^https:\/\/ibes2022-in-cte03.cte.test.ibes.cdtapps.com\/clickandcollect\/[a-z]{2}\//,
        // Other
        /^https:\/\/info.ikea-usa.com\/giftregistry$/,
        /^http:\/\/ppseelm-nt3310\.ikeadt\.com\/nl\/UI\/Pages\/VPUI\.htm/,
        /^http:\/\/ikeabuilds.sandbox3.2020.net\/ihp_4_9_20_sektion\/UI\/Pages\/VPUI.htm/,
        /^GeomagicalLabsInc\.Sugarcube:\/\/dev[a-z]{2}\.accounts\.ikea\.com\/ios\/GeomagicalLabsInc\.Sugarcube\/callback$/,
        /^https:\/\/ciam-ikea-family-frontend.n-ikea.kodix.dev\/api\/v1\/ciam\/callback\/login$/,
        /^https:\/\/.*.returns.ingka.com\/[a-z]{2}\/[a-z]{2}\/returns\/index.html$/,
        /^http:\/\/localhost:3013\/[a-z]{2}\/[a-z]{2}\/returns$/,
        /^https:\/\/ww8\.cte\.ikeadt\.com\/clickandcollect\/[a-z]{2}\//
      ];

export const validIKEAUrlPatterns =
  CONFIG.ENV === 'production'
    ? [/^https:\/\/.+.ikea.com/, ...validIKEAUrlPatternsStricter]
    : [/^https:\/\/.+.ikea(dt)?.com/, ...validIKEAUrlPatternsStricter];

export const validRedirectWithoutTokenPatterns =
  CONFIG.ENV === 'production'
    ? [/^https:\/\/kitchen\.planner\.ikea\.com/]
    : [/^https:\/\/kitchen(dev)?\.planner\.ikeadt\.com/];
