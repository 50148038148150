import CheckboxControlled from 'BaseForm/CheckboxControlled';
import SwitchControlled from 'BaseForm/SwitchControlled';
import SwitchComponent from 'BaseForm/Switch';
import CheckboxWithModal from 'ExtendedForm/CheckboxWithModal';
import ConsentInfo from 'ExtendedForm/ConsentInfo';
import DoubleConsentCheckbox from 'ExtendedForm/DoubleConsentCheckbox';
import MultiLinkConsent from 'ExtendedForm/MultiLinkConsent';
import MultiLinkConsentText from 'ExtendedForm/MultiLinkConsentText';
import CountryDropdown from 'ExtendedForm/CountryDropdown';
import Dropdown from 'BaseForm/Dropdown';
import IkeaCatalogueCheckboxContainer from 'ExtendedForm/IkeaCatalogueCheckboxContainer';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import PhoneInput from 'ExtendedForm/PhoneInput/PhoneInput';
import PillGroup from 'BaseForm/PillGroup';
import PreferredLanguageDropdown from 'ExtendedForm/PreferredLanguageDropdown';
import PreferredStoreDropdownContainer from 'ExtendedForm/PreferredStoreDropdownContainer';
import ProfileTypeRadioButtonGroup from 'ExtendedForm/ProfileTypeRadioButtonGroup';
import InterestCodeRadioButton from 'ExtendedForm/InterestCodeRadioButton';
import ProfileUpdateOnFieldChange from 'ExtendedForm/ProfileUpdateOnFieldChange';
import RadioButtonGroup from 'BaseForm/RadioButtonGroup';
import ResidenceTypeSelector from 'ExtendedForm/ResidenceTypeSelector/ResidenceTypeSelector';
import QuantityField from 'BaseForm/QuantityField';
import MultipleConsentCheckbox from 'ExtendedForm/MultipleConsentCheckbox';
import ZipCodeInput from 'ExtendedForm/ZipCodeInput';

import AddressContainer from 'ExtendedForm/Address/AddressContainer';
import ContactMethodsGroupContainer from 'ExtendedForm/ContactMethodsGroupContainer';
import CheckboxArrayContainer from 'ExtendedForm/CheckboxArrayContainer';
import DynamicChildBirthdateContainer from 'ExtendedForm/DynamicChildBirthdateContainer';
import PasswordContainer from 'ExtendedForm/PasswordContainer';
import UserLoginContainer from 'ExtendedForm/UserLoginContainer';
import {
  Divider,
  TextWithModal,
  TextWithLink,
  ResetPasswordLink,
  ResetPasswordBasicLink,
  TranslateToInnerHTML,
  LegalText
} from 'Common';
import PasswordInfo from 'ExtendedForm/PasswordInfo';

import DashboardSectionForm from 'Components/Dashboard/DashboardSectionForm';
import SignupSection from 'Components/Signup/SignupSection';
import AuthSection from 'Components/Login/AuthSection';
import HouseholdSection from 'Components/Household/HouseholdSection';
import UpgradeSectionForm from '../../Dashboard/UpgradeSectionForm';
import PreferredStoreDropdownOnSubmit from '../PreferredStoreDropdownOnSubmit';
import PaidMediaConsentCheckbox from '../PaidMediaConsentCheckbox';

import PreferredCommunicationFrequency from '../PreferredCommunicationFrequencyDropdown';

// Components that handle one form field only. In yml form configurations;
// field name, label, defaultValues and other component props are found
// at first level (fields[*].componentProps).
export const SimpleInputComponents = {
  Checkbox: CheckboxControlled,
  CheckboxWithModal,
  Switch: SwitchControlled,
  SwitchComponent,
  ConsentInfo,
  CountryDropdown,
  DoubleConsentCheckbox,
  MultiLinkConsent,
  MultiLinkConsentText,
  Dropdown,
  IkeaCatalogueCheckbox: IkeaCatalogueCheckboxContainer,
  InputField: InputFieldHandler,
  PhoneInput,
  PillGroup,
  PreferredLanguageDropdown,
  PreferredStoreDropdownOnSubmit,
  PaidMediaConsentCheckbox,
  PreferredCommunicationFrequency,
  InterestCodeRadioButton,
  PreferredStoreDropdown: PreferredStoreDropdownContainer,
  ProfileTypeRadioButtonGroup,
  ProfileUpdateOnFieldChange,
  RadioButtonGroup,
  ResidenceTypeSelector,
  QuantityField,
  MultipleConsentCheckbox,
  ZipCodeInput
};

// Components that handle more than one form field. In yml form configurations;
// field names, labels, defaultValues and other component props are found
// at second level (fields[*].componentsProps.nestedFields[*].componentProps).
export const NestedInputComponents = {
  Address: AddressContainer,
  ContactMethodsGroup: ContactMethodsGroupContainer,
  CheckboxArray: CheckboxArrayContainer,
  DynamicChildBirthdate: DynamicChildBirthdateContainer,
  Password: PasswordContainer,
  UserLogin: UserLoginContainer
};

// Components that don't handle any input and are not connected to Redux.
export const StaticComponents = {
  Divider,
  PasswordInfo,
  TextWithModal,
  TranslateToInnerHTML,
  TextWithLink,
  LegalText,
  ResetPasswordLink,
  ResetPasswordBasicLink
};

// Components used for sectionizing the forms.
export const SectionComponents = {
  DashboardSectionForm,
  UpgradeSectionForm,
  HouseholdSection,
  SignupSection,
  AuthSection
};

export const Components = {
  ...SimpleInputComponents,
  ...NestedInputComponents,
  ...StaticComponents,
  ...SectionComponents
};
