import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import mask from 'Utils/mask/mask';
import { zipCode } from 'Utils/mask/zipCodeMasker';

const DashboardPrimaryAddressFieldReadContainer = props =>
  props.profile.address1 || props.profile.zipCode || props.profile.cityName ? (
    <Text>
      {props.rows.map((row, index) => (
        <div key={index}>
          {row.map(
            item =>
              props.profile[item] &&
              (item === 'addressCountryCode'
                ? `${t(
                    `countries.${mask(
                      props.profile[item],
                      props.maskRules[item]
                    )}`
                  )} `
                : item === 'zipCode'
                ? `${zipCode(
                    props.profile[item],
                    props.profile.addressCountryCode,
                    props.maskRules[item]
                  )} `
                : `${mask(props.profile[item], props.maskRules[item])} `)
          )}
        </div>
      ))}
    </Text>
  ) : (
    <p>{t(`dashboard.address.primaryMissing`)}</p>
  );

DashboardPrimaryAddressFieldReadContainer.propTypes = {
  profile: PropTypes.object,
  icon: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  maskRules: PropTypes.object
};

DashboardPrimaryAddressFieldReadContainer.defaultProps = {
  profile: {},
  maskRules: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardPrimaryAddressFieldReadContainer);
