import { ACTIONS } from './addressesConstants';

const initialState = {
  deliveryAddresses: [],
  deliveryAddressesFailed: false
};

const addresses = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DELIVERY_ADDRESSES:
      return {
        ...state,
        deliveryAddresses: action.deliveryAddresses,
        deliveryAddressesFailed: false
      };

    case ACTIONS.DELETE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddresses: state.deliveryAddresses.filter(
          address => address.addressId !== action.deliveryAddressId
        )
      };

    case ACTIONS.SET_DELIVERY_ADDRESSES_FAILED:
      return {
        ...state,
        deliveryAddressesFailed: true
      };

    default:
      return state;
  }
};

export default addresses;

// Actions should be mapped in the same order
// as they are defined in the actions file.
