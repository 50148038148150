import transformers from './transformers';

const transform = (value, rules) => {
  if (!rules || !rules.length) {
    return value;
  }

  return rules.reduce((newValue, rule) => {
    const { name, ...args } = rule;
    const transformedValue = transformers[name]
      ? transformers[name](newValue, args)
      : newValue;
    return transformedValue;
  }, value);
};

export default transform;
