import { ReduxStore } from 'Redux';
import { getFormValues } from 'Redux/forms/formsHelper';
import zipCodeRules from './zipCodeRules';

export const zipCode = (value = '', args, formId) => {
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);
  const country = formValues.addressCountryCode || CONFIG.COUNTRY.toUpperCase();
  if (!value.length || !zipCodeRules[country]) {
    return value;
  }

  return zipCodeRules[country].reduce(
    (currentValue, currentRule) => currentRule(currentValue),
    value
  );
};
