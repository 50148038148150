// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__deleted-profile h1{margin-top:6rem}.profile__deleted-profile img{margin-top:3rem;margin-bottom:6rem;width:100%}@media(max-width: 48rem){.profile__deleted-profile h1{margin-top:1rem}.profile__deleted-profile img{margin-top:3rem;margin-bottom:3rem}}", "",{"version":3,"sources":["webpack://./src/components/DeletedProfile/DeletedProfileContainer.scss"],"names":[],"mappings":"AACE,6BACE,eAAA,CAEF,8BACE,eAAA,CACA,kBAAA,CACA,UAAA,CAIJ,yBAEI,6BACE,eAAA,CAEF,8BACE,eAAA,CACA,kBAAA,CAAA","sourcesContent":[".deleted-profile {\n  h1 {\n    margin-top: 6rem;\n  }\n  img {\n    margin-top: 3rem;\n    margin-bottom: 6rem;\n    width: 100%;\n  }\n}\n\n@media (max-width: 48rem) {\n  .deleted-profile {\n    h1 {\n      margin-top: 1rem;\n    }\n    img {\n      margin-top: 3rem;\n      margin-bottom: 3rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleted-profile": "profile__deleted-profile"
};
export default ___CSS_LOADER_EXPORT___;
