// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__loading-wrap{height:75vh;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/_common/Loading.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".loading-wrap {\n  height: 75vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-wrap": "profile__loading-wrap"
};
export default ___CSS_LOADER_EXPORT___;
