import { ACTIONS } from './profileConstants';

const initialState = {
  // firstName: 'Paola',
  // lastName: 'Spring',
  // birthDate: '10-10-1990',
  // cardNumber: '6275980365041709422',
  // profileType: PROFILE_TYPES.FAMILY,
  // mobile: '+46707232386',
  // email: 'paola@gmail.com',
  // userLogin: 'paola@gmail.com',
  // noOfRooms: 0,
  // noOfChildren: 0,
  // preferredStore: '422',
  // preferredLanguage: 'EN',
  // address1: 'Block No 6 Room 301.86 Gullin E St',
  // address2: 'Gumei Residential District,Shanghai',
  // cityName: 'Jiangsu',
  // country: 'ZZ',
  // zipCode: '200235',
  // addressId: '123456789',
  // allowEmail: '0',
  // allowSMS: '1',
  // allowDirectMail: '0',
  // allowPhone: '1',
  // participateInMemberPanel: true,
  // deliveryAddresses: [
  //   {
  //     lastName: 'Smith',
  //     mobile: '03594561925',
  //     phone: '05965503377',
  //     countryCode: 'ZZ',
  //     zipCode: '200235',
  //     addressId: '123456780',
  //     cityName: 'Jiangsu',
  //     firstName: 'Jane',
  //     address1: 'Apt No 6 Room 678',
  //     address2: 'Gumei Residential District,Shanghai'
  //   },
  //   {
  //     lastName: 'Smith',
  //     mobile: '03594561925',
  //     phone: '05965503377',
  //     countryCode: 'ZZ',
  //     zipCode: '200235',
  //     addressId: '123456781',
  //     cityName: 'Jiangsu',
  //     firstName: 'Jane',
  //     address1: 'Apt No 6 Room 678',
  //     address2: 'Gumei Residential District,Shanghai'
  //   }
  // ]
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_PROFILE:
      return {
        ...action.profile
      };

    case ACTIONS.SET_USER_ID:
      return {
        ...state,
        userId: action.userId
      };

    case ACTIONS.SET_COWORKER_LINKING_STATUS:
      return {
        ...state,
        coworkerLinkingStatus: action.coworkerLinkingStatus
      };

    case ACTIONS.CLEAR_PROFILE:
      return initialState;

    default:
      return state;
  }
};

export default profile;

// Actions should be mapped in the same order
// as they are defined in the actions file.
