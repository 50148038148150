import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal, { Sheets, ModalHeader } from '@ingka/modal';
import checkmark from '@ingka/ssr-icon/paths/checkmark-small';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import cross from '@ingka/ssr-icon/paths/cross-small';
import SSRIcon from '@ingka/ssr-icon';
import { t } from 'Utils/localization/i18next';
import { Text } from 'Common';
import styles from './CommunicationPreferences.scss';

class PersonalisedAds extends React.PureComponent {
  state = { modalOpen: false };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      showSuccessMessage: false,
      isConfirming: false
    };
    this.myRef = React.createRef();
  }

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div ref={this.setRef}>
          <Text
            marginBottom="s"
            className={styles['communication-preferences-description']}
            prefix={CONFIG.APP.STYLES_PREFIX}
          >
            {t('forms.personalisedAdsLabel')}{' '}
            <SSRIcon
              size="100"
              paths={informationCircle}
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            />
          </Text>
          <Text marginBottom="s">
            {this.props.profile.allowSharingToSocialMedia ? (
              <Text className={styles['sub-category-value']}>
                <span className={styles['subscribed-indicator']}>
                  <SSRIcon size="100" paths={checkmark} />
                </span>
                {t(`forms.personalisedAdsAllow`)}
              </Text>
            ) : (
              <Text className={styles['sub-category-value']}>
                <span className={styles['unsubscribed-indicator']}>
                  <SSRIcon size="100" paths={cross} />
                </span>
                {t(`forms.personalisedAdsDontAllow`)}
              </Text>
            )}
          </Text>
          <div className={styles['section-read-unsubscribe-box']}>
            <Modal
              visible={this.state.modalOpen}
              escapable={true}
              handleCloseBtn={() => {
                this.setState({ modalOpen: false });
              }}
              prefix={CONFIG.APP.STYLES_PREFIX}
            >
              <Sheets
                header={<ModalHeader ariaCloseTxt="Close prompt" />}
                prefix={CONFIG.APP.STYLES_PREFIX}
              >
                <div className={styles['personalised-ads']}>
                  <Text className={styles['info-header']} bold marginBottom="m">
                    {t('forms.personalisedModalHeader')}
                  </Text>
                  <Text marginBottom="s">
                    {t('forms.personalisedAdsInfo1')}
                  </Text>
                  <Text marginBottom="s">
                    {t('forms.personalisedAdsInfo2')}
                  </Text>
                  <Text marginBottom="s">
                    {t('forms.personalisedAdsInfo3')}
                  </Text>
                  <Text marginBottom="s">
                    {t('forms.personalisedAdsInfo4')}
                  </Text>
                </div>
              </Sheets>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PersonalisedAds.propTypes = {
  profile: PropTypes.object
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(mapStateToProps)(PersonalisedAds);
