import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@ingka/switch';
import { t } from 'Utils/localization/i18next';
import '@ingka/focus/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/forms/style.scss';

export const SwitchComponent = props => (
  <Switch
    id={`${props.formId}-${props.name}`}
    name={props.name}
    value={props.value}
    label={t(props.label)}
    checked={props.value}
    disabled={props.disabled}
    describedById={`${props.formId}-${props.name}-error`}
    prefix={CONFIG.APP.STYLES_PREFIX}
    onChange={props.onChange}
  />
);

SwitchComponent.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  describedById: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func
};

SwitchComponent.defaultProps = {
  formId: 'formId',
  name: 'switch',
  value: false,
  label: 'switchLabel',
  checked: false,
  disabled: false,
  describedById: 'switchId',
  prefix: ''
};

export default SwitchComponent;
