const FormData = require('form-data');

export default class ProfanityFilterApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    this.apiUrl = `${API.PROFANITY_FILTER_SERVICE.HOST}`;
    this.x_client_id = `${API.PROFANITY_FILTER_SERVICE.CLIENT_ID}`;
    this.retailUnit = `${COUNTRY}`;
    this.language = `${LANGUAGE}`;
  }

  async doProfanityCheck({ firstName, lastName, address1, address2 }) {
    const form = new FormData();
    const ERROR_CODE_DEFAULT = 'DEFAULT_PROFANITY_ERROR';
    form.append('retailUnit', this.retailUnit);
    form.append('language', this.language);
    form.append(
      'jsonForValidation',
      JSON.stringify({
        firstName,
        lastName,
        address1,
        address2
      })
    );

    return new Promise((resolve, reject) => {
      let result = [];
      fetch(this.apiUrl, {
        method: 'POST',
        headers: {
          'x-client-id': this.x_client_id
        },
        body: form
      })
        .then(response => {
          if (response.status === 403) {
            return response.json();
          }
          return resolve(result);
        })
        .then(body => {
          result = new Response();
          result = body.map(reasonCode => {
            // "textForValidation"  is the text input by the user , "fieldName" is the name of field where profanity was input
            // When required the profanity that matched user input can be found in the field matchedProfanity
            const error = {
              fieldName: reasonCode[0].fieldName,
              textForValidation: reasonCode[0].textForValidation
            };
            return { response: { errors: [error] }, profanityError: true };
          });

          return reject(result[0]);
        })
        .catch(error => {
          error = {
            code: ERROR_CODE_DEFAULT
          };
          return reject(error);
        });
    });
  }
}
