import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import analytics from 'Utils/analytics/analyticsWrapper';
import { getProfile } from 'Redux/profile/profileThunks';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import {
  getOrderHistoryCount,
  getShoppingListCount
} from 'Redux/commerce/commerceThunks';
import { getDeliveryAddresses } from 'Redux/addresses/addressesThunks';
import DashboardRegularContainer from 'Components/DashboardRegular/DashboardRegularContainer';
import DashboardFamilyContainer from 'Components/DashboardFamily/DashboardFamilyContainer';
import { setPageTitle } from 'Utils/document/documentUtils';
import { Loading } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getPayments } from 'Redux/payment/paymentThunks';

const DashboardComponents = {
  [PROFILE_TYPES.REGULAR]: DashboardRegularContainer,
  [PROFILE_TYPES.FAMILY]: DashboardFamilyContainer
};
class DashboardContainer extends React.PureComponent {
  async componentDidMount() {
    await this.getDashboardData();
    await analytics.userDisplayedDashboard();
    if (this.props.isEmailUndefined && CONFIG.FEATURE.ENABLE_EMAIL_POPUP) {
      const updateEmailLink = document.getElementById('update-email-link');
      updateEmailLink.click();
    }
  }

  async componentDidUpdate() {
    await this.getDashboardData();
    await analytics.userDisplayedDashboard();
    if (this.props.isEmailUndefined && CONFIG.FEATURE.ENABLE_EMAIL_POPUP) {
      const updateEmailLink = document.getElementById('update-email-link');
      updateEmailLink.click();
    }
  }

  getDashboardData = async () => {
    if (!this.props.profileFetched) {
      await this.props.getProfile();
      if (!CONFIG.FEATURE.ENABLE_ICM_CMS) {
        this.props.getDeliveryAddresses();
      }

      const isRegular = this.props.profileType === PROFILE_TYPES.REGULAR;
      const isFamily = this.props.profileType === PROFILE_TYPES.FAMILY;

      if (
        (CONFIG.FEATURE.REGULAR_LINKBOXES.ENABLE_ORDER_HISTORY && isRegular) ||
        (CONFIG.FEATURE.FAMILY_LINKBOXES.ENABLE_ORDER_HISTORY && isFamily)
      ) {
        this.props.getOrderHistoryCount();
      }

      if (
        (CONFIG.FEATURE.REGULAR_LINKBOXES.ENABLE_SHOPPING_LISTS && isRegular) ||
        (CONFIG.FEATURE.FAMILY_LINKBOXES.ENABLE_SHOPPING_LISTS && isFamily)
      ) {
        this.props.getShoppingListCount();
      }

      if (
        CONFIG.FEATURE.REGULAR_DASHBOARD.ENABLE_PAYMENTS_TAB ||
        CONFIG.FEATURE.FAMILY_DASHBOARD.ENABLE_PAYMENTS_TAB
      )
        await this.props.getPayments();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    setPageTitle(
      t(['metaData.dashboardPageTitle', 'metaData.defaultPageTitle'])
    );
    if (
      ((CONFIG.FEATURE.REGULAR_DASHBOARD.ENABLE_PAYMENTS_TAB ||
        CONFIG.FEATURE.FAMILY_DASHBOARD.ENABLE_PAYMENTS_TAB) &&
        !this.props.isPaymentFetched) ||
      !this.props.profileFetched
    ) {
      return (
        <Loading
          isLoading
          text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
        />
      );
    }
    const DashboardComponent = DashboardComponents[this.props.profileType];
    return (
      <DashboardComponent
        isSettingsTabDefault={/^#settings$/.test(window.location.hash)}
        allowDirectMail={this.props.allowDirectMail}
        allowSMS={this.props.allowSMS}
        allowEmail={this.props.allowEmail}
        allowPhone={this.props.allowPhone}
      />
    );
  }
}

DashboardContainer.propTypes = {
  profileFetched: PropTypes.bool.isRequired,
  profileType: PropTypes.string,
  getProfile: PropTypes.func.isRequired,
  getDeliveryAddresses: PropTypes.func.isRequired,
  getOrderHistoryCount: PropTypes.func.isRequired,
  getShoppingListCount: PropTypes.func.isRequired,
  location: PropTypes.object,
  getPayments: PropTypes.func,
  isPaymentFetched: PropTypes.bool,
  isEmailUndefined: PropTypes.bool,
  allowEmail: PropTypes.bool,
  allowSMS: PropTypes.bool,
  allowDirectMail: PropTypes.bool,
  allowPhone: PropTypes.bool
};

DashboardContainer.defaultProps = {
  isPaymentFetched: false
};

const mapStateToProps = state => {
  return {
    profileFetched: !!state.profile.profileType,
    profileType: state.profile.profileType,
    isEmailUndefined: !state.profile.email,
    isPaymentFetched: state.payment.isPaymentFetched,
    allowEmail: state.profile.allowEmail,
    allowSMS: state.profile.allowSMS,
    allowDirectMail: state.profile.allowDirectMail,
    allowPhone: state.profile.allowPhone
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProfile,
      getDeliveryAddresses,
      getOrderHistoryCount,
      getShoppingListCount,
      getPayments
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
