import { parseDomainFromUrl } from 'Utils/url/urlUtils';

export const getCookies = () => {
  const cookies = {};
  if (document.cookie && document.cookie !== '') {
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i += 1) {
      const nameValue = cookieArray[i].split('=');
      nameValue[0] = nameValue[0].trim();
      cookies[decodeURIComponent(nameValue[0])] = decodeURIComponent(
        nameValue[1]
      );
    }
  }
  return cookies;
};

export const readCookie = name => {
  const cookie = document.cookie.match(new RegExp(`\\b${name}=([^;]+)`));
  return cookie && cookie[1];
};

export const writeCookie = (name, value, expiryDate, path = '/', secure) => {
  const cookieData = `${name}=${value}`;
  const cookieDomain = `;domain=${parseDomainFromUrl(window.location.href)}`;
  const cookieExpires = expiryDate ? `;expires=${expiryDate}` : '';
  const cookiePath = `;path=${path}`;
  const cookieSecure = secure === true ? ';secure' : '';
  document.cookie = `${cookieData}${cookieDomain}${cookiePath}${cookieExpires}${cookieSecure}`;
};

export const deleteCookie = (name, path = '/', url = window.location.href) => {
  const domain = parseDomainFromUrl(url);
  // We add a dot before the domain to make it valid for subdomains as well
  document.cookie = `${name}=; domain=.${domain};path=${path};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const deleteWcCookies = url => {
  const cookies = getCookies();
  Object.keys(cookies).forEach(cookieName => {
    if (cookieName.startsWith('WC_')) {
      deleteCookie(cookieName, undefined, url);
    }
  });
};
