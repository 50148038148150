import React from 'react';
import PropTypes from 'prop-types';
import { Components } from './components';

const DynamicFormRead = props => {
  const model = FORM_CONFIG[props.formId];

  if (!model) {
    return null;
  }

  return (
    <div>
      {model.sections.map((section, index) => {
        const SectionComponent = Components[section.component];
        return (
          <SectionComponent
            {...section.componentProps}
            key={`${model.id}-section-${index}`}
          >
            {section.fields &&
              section.fields.map((field, i) => {
                const FieldComponent = Components[field.component];
                return (
                  <FieldComponent
                    {...field.componentProps}
                    key={`${model.id}-field-${i}`}
                    formId={props.formId}
                  />
                );
              })}
          </SectionComponent>
        );
      })}
    </div>
  );
};

DynamicFormRead.propTypes = {
  formId: PropTypes.string.isRequired
};

export default DynamicFormRead;
