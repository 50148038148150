import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'Utils/localization/i18next';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import { availableCountryCodeMap, defaultCountryCodeMap } from './countryCodes';
import CountryCodeDropdown from './CountryCodeDropdown';
import styles from './PhoneInput.scss';

class PhoneInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputFieldPhoneNumber: '',
      dropdownCountry: this.props.defaultCountry || CONFIG.COUNTRY.toUpperCase()
    };
    this.countryCodeMap = this.getCountryCodeMap();
  }

  componentDidMount() {
    this.setPhoneFieldData(this.props.value, () => {
      // Trigger autocorrect of initial value.
      if (this.state.inputFieldPhoneNumber) {
        this.onInputFieldBlur({
          target: { value: this.state.inputFieldPhoneNumber }
        });
      }
    });
  }

  // Returns a map of country codes to be included in the country code dropdown.
  // It has country names (e.g. "SE") as keys and country codes (e.g. "+46") as
  // values. If "acceptedCountries" is not defined, the "defaultCountryCodeMap"
  // will be used. This includes all international country codes (within IKEA).
  getCountryCodeMap = () => {
    const { acceptedCountries } = this.props;
    if (!acceptedCountries.length) {
      return defaultCountryCodeMap;
    }
    const countries = {};
    acceptedCountries.forEach(country => {
      if (availableCountryCodeMap[country]) {
        countries[country] = availableCountryCodeMap[country];
      }
    });
    return countries;
  };

  // Takes a phone number, e.g. "00467072", "+467072" or "7072". If the
  // number starts with a valid country code, this is set as dropdown value
  // ("dropdownCountry") and the free text field ("inputFieldPhoneNumber")
  // is set to the remaining number.
  setPhoneFieldData = (phoneNumber = '', callback) => {
    const { dropdownCountry } = this.state;
    const matchedCountryName = Object.keys(this.countryCodeMap).find(
      countryName =>
        phoneNumber.startsWith(this.countryCodeMap[countryName]) ||
        phoneNumber.startsWith(
          this.countryCodeMap[countryName].replace('+', '00')
        )
    );
    const newDropdownCountry = matchedCountryName || dropdownCountry;

    const newInputFieldPhoneNumber = this.stripCountryCode(
      phoneNumber,
      this.countryCodeMap[newDropdownCountry]
    );

    if (this.state.dropdownCountry !== newDropdownCountry) {
      // Selected country is saved in Redux so that the phone validator
      // can access market specific regex. In Redux selected country will
      // be set as "{name}Country": 'SE'.
      this.props.onChange({
        target: {
          name: `${this.props.name}Country`,
          value: newDropdownCountry
        }
      });
    }

    this.setState(
      {
        dropdownCountry: newDropdownCountry,
        inputFieldPhoneNumber: newInputFieldPhoneNumber
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  getCompletePhoneNumber = (dropdownCountry, inputFieldPhoneNumber) => {
    const completePhoneNumber =
      !inputFieldPhoneNumber || inputFieldPhoneNumber.length === 0
        ? ''
        : `${this.countryCodeMap[dropdownCountry]}${inputFieldPhoneNumber}`;
    return completePhoneNumber;
  };

  onInputFieldChange = e => {
    this.setPhoneFieldData(e.target.value, () =>
      // Save phone number with country code in Redux.
      this.props.onChange({
        target: {
          name: this.props.name,
          value: this.getCompletePhoneNumber(
            this.state.dropdownCountry,
            this.state.inputFieldPhoneNumber
          )
        }
      })
    );
  };

  onInputFieldBlur = e => {
    const autocorrectedValue = this.autocorrectPhoneNumber(
      this.state.dropdownCountry,
      e.target.value
    );
    const completePhoneNumber = this.getCompletePhoneNumber(
      this.state.dropdownCountry,
      autocorrectedValue
    );
    // Validates phone number with country code and saves messages in Redux.
    this.props.onBlur({
      target: {
        name: this.props.name,
        value: completePhoneNumber
      }
    });
  };

  onCountryCodeDropdownChange = option => {
    this.setState({ dropdownCountry: option.value });
    const completePhoneNumber = this.getCompletePhoneNumber(
      option.value,
      this.state.inputFieldPhoneNumber
    );
    // Saves phone number with country code in Redux.
    this.props.onChange({
      target: {
        name: this.props.name,
        value: completePhoneNumber
      }
    });
    // Selected country is saved in Redux so that the phone validator
    // can access market specific regex. In Redux selected country will
    // be set as "{name}Country": 'SE'.
    this.props.onChange({
      target: {
        name: `${this.props.name}Country`,
        value: option.value
      }
    });
    // Validates phone number with country code and saves messages in Redux.
    this.props.onBlur({
      target: {
        name: this.props.name,
        value: completePhoneNumber
      }
    });
  };

  stripCountryCode = (value, countryCode) => {
    const dropdownCountry00 = countryCode.replace('+', '00');
    const stripRegex = new RegExp(
      `^(?:\\${countryCode}|${dropdownCountry00})(.*)$`
    );
    const newValue = value.match(stripRegex);
    return (newValue && newValue[1]) || value;
  };

  getDropdownOptions = (countries = []) => {
    return countries.map(country => {
      return {
        name: country,
        value: country,
        code: this.countryCodeMap[country]
      };
    });
  };

  autocorrectPhoneNumber = (dropdownCountry, inputFieldPhoneNumber = '') => {
    const autoCorrectedValue = inputFieldPhoneNumber
      .replace(/[^\d]/g, '')
      .replace(/^0/, '');

    const newCompletePhoneNumber = this.getCompletePhoneNumber(
      dropdownCountry,
      autoCorrectedValue
    );

    if (this.props.value !== newCompletePhoneNumber) {
      // Saves phone number with country code in Redux.
      this.props.onChange({
        target: {
          name: this.props.name,
          value: newCompletePhoneNumber
        }
      });
      this.setState({
        inputFieldPhoneNumber: autoCorrectedValue
      });
    }
    return autoCorrectedValue;
  };

  render() {
    const dropdownOptions = this.getDropdownOptions(
      Object.keys(this.countryCodeMap)
    );
    const topDropdownOptions = this.getDropdownOptions(
      this.props.favoriteCountries
    );

    return (
      <div
        className={classNames(
          `${CONFIG.APP.STYLES_PREFIX}input-wrap`,
          `${CONFIG.APP.STYLES_PREFIX}input-wrap--labelled`,
          styles['phone-input-wrap']
        )}
      >
        <label
          htmlFor={`${this.props.formId}-${this.props.name}`}
          className={`${CONFIG.APP.STYLES_PREFIX}${
            CONFIG.FEATURE.RIGHT_TO_LEFT ? 'rtl' : ''
          }`}
        >
          {this.props.showOptional
            ? `${t(this.props.label)} ${t('forms.optional')}`
            : t(this.props.label)}
        </label>
        <CountryCodeDropdown
          id={`${this.props.formId}-${this.props.name}-country`}
          topOptions={topDropdownOptions}
          options={dropdownOptions}
          sortOptions={this.props.sortAcceptedCountries}
          value={this.state.dropdownCountry}
          onChange={this.onCountryCodeDropdownChange}
          formId={this.props.formId}
        />
        <InputFieldHandler
          id={`${this.props.formId}-${this.props.name}`}
          className={styles['phone-input-field']}
          name={this.props.name}
          value={this.state.inputFieldPhoneNumber}
          maxLength={20}
          type="tel"
          tooltip={this.props.tooltip}
          onChange={this.onInputFieldChange}
          onBlur={this.onInputFieldBlur}
        />
      </div>
    );
  }
}

PhoneInput.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  defaultCountry: PropTypes.string,
  // "acceptedCountries" can be e.g. ["SE", "DK, "FI", "NO", "GB"]
  acceptedCountries: PropTypes.arrayOf(PropTypes.string),
  // "favoriteCountries" can be e.g. ["SE", "DK"]
  favoriteCountries: PropTypes.arrayOf(PropTypes.string),
  sortAcceptedCountries: PropTypes.bool,
  showOptional: PropTypes.bool,
  hasErrors: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

PhoneInput.defaultProps = {
  acceptedCountries: [],
  favoriteCountries: []
};

export default PhoneInput;
