export const ConditionsValidator = {
  profileFieldValueMatch: (args, profile) => {
    const { fieldName, fieldValue } = args;
    return profile[fieldName] === fieldValue;
  },
  profileFieldValueDoNotMatch: (args, profile) => {
    const { fieldName, fieldValue } = args;
    return profile[fieldName] !== fieldValue;
  },
  profileFieldValueEmpty: (args, profile) => {
    const { fieldName } = args;
    return profile[fieldName] === undefined || profile[fieldName] === '';
  },
  profileFieldValuesEmpty: (args, profile) => {
    const { fieldNames } = args;
    return !fieldNames.some(
      fieldName => profile[fieldName] !== undefined && profile[fieldName] !== ''
    );
  }
};
