import React from 'react';

export default {
  'barcode-flip': (
    <path d="M6.046875,4 L6.27604167,4 L6.27604167,12.1023598 L6.046875,12.1023598 L6.046875,4 Z M6.50520833,4 L6.96354167,4 L6.96354167,12.1023598 L6.50520833,12.1023598 L6.50520833,4 Z M7.421875,4 L7.65104167,4 L7.65104167,12.1023598 L7.421875,12.1023598 L7.421875,4 Z M8.109375,4 L10.171875,4 L10.171875,12.1023598 L8.109375,12.1023598 L8.109375,4 Z M10.4010417,4 L10.6302083,4 L10.6302083,12.1023598 L10.4010417,12.1023598 L10.4010417,4 Z M10.859375,4 L11.7760417,4 L11.7760417,12.1023598 L10.859375,12.1023598 L10.859375,4 Z M12.2916667,4 L12.2916667,12.1023598 L12.0625,12.1023598 L12.0625,4 L12.2916667,4 Z M12.75,4 L12.75,12.1023598 L12.5208333,12.1023598 L12.5208333,4 L12.75,4 Z M12.921875,4 L14.7552083,4 L14.7552083,12.1023598 L12.921875,12.1023598 L12.921875,4 Z M14.984375,4 L15.2135417,4 L15.2135417,12.1023598 L14.984375,12.1023598 L14.984375,4 Z M15.4427083,4 L16.5885417,4 L16.5885417,12.1023598 L15.4427083,12.1023598 L15.4427083,4 Z M16.8177083,4 L17.046875,4 L17.046875,12.1023598 L16.8177083,12.1023598 L16.8177083,4 Z M17.5052083,4 L17.734375,4 L17.734375,12.1023598 L17.5052083,12.1023598 L17.5052083,4 Z M17.9635417,4 L18.4635417,4 L18.4635417,12.1023598 L17.9635417,12.1023598 L17.9635417,4 Z M11.0751588,15.4900213 L12.3583863,16.9935247 L13,17.7451435 L12.3583863,18.4967623 L11.0751588,20 L10.4335451,19.2483812 L11.3163312,18.2142408 C6.04956274,17.8408234 2,15.63487 2,12.9608824 C2,11.3866821 3.40279267,9.97327728 5.63006353,9 L5.63006353,10.0439741 C3.94321838,10.8056925 2.90751588,11.8315937 2.90751588,12.9611482 C2.90751588,15.065043 6.49719495,16.8085435 11.2085636,17.1495361 L10.4335451,16.2416401 L11.0751588,15.4900213 Z M18.8,9 C20.7632,9.95219802 22,11.3346138 22,12.8740396 C22,15.4496274 18.5398,17.5871989 14,18 L14,16.9633181 C18.0958,16.607966 21.2,14.91283 21.2,12.8742996 C21.2,11.7695107 20.2868,10.7658425 18.8,10.0210849 L18.8,9 Z" />
  ),
  'qr-code-flip': (
    <path d="M8.72730248,9 L10,9 L10,12 L8,12 L8,9 L8,6.81820165 L11.2726975,6.81820165 L11.2726975,5 L12,5 L12,7.54550413 L8.72730248,7.54550413 L8.72730248,9 Z M8,4 L10,4 L10,6 L8,6 L8,4 Z M9.42862041,5.42862041 L9.42862041,4.57146531 L8.57146531,4.57146531 L8.57146531,5.42862041 L9.42862041,5.42862041 Z M9.42862041,11.1429673 L9.42862041,9.85728979 L8.57146531,9.85728979 L8.57146531,11.1429673 L9.42862041,11.1429673 Z M14,4 L16,4 L16,6 L14,6 L14,4 Z M15.4285347,5.42862041 L15.4285347,4.57146531 L14.5713796,4.57146531 L14.5713796,5.42862041 L15.4285347,5.42862041 Z M15.4285347,7 L16,7 L16,9.5 L14,9.5 L14,8.87504688 L15.4285347,8.87504688 L15.4285347,7 Z M14,10 L16,10 L16,12 L15.4285347,12 L15.4285347,10.799952 L14.5713796,10.799952 L14.5713796,12 L14,12 L14,10 Z M11,4 L13,4 L13,6 L12.3333,6 L12.3333,4.57146531 L11,4.57146531 L11,4 Z M12.199982,7 L14,7 L14,8.3334 L13.400054,8.3334 L13.400054,7.6667 L12.800018,7.6667 L12.800018,9 L11.600036,9 L11.600036,9.6666 L12.800018,9.6666 L12.800018,12 L12.199982,12 L12.199982,10.3334 L11,10.3334 L11,8.3334 L12.199982,8.3334 L12.199982,7 Z M11,11 L11.5,11 L11.5,12 L11,12 L11,11 Z M9,8 L10,8 L10,8.5 L9,8.5 L9,8 Z M11.0751588,15.4900213 L12.3583863,16.9935247 L13,17.7451435 L12.3583863,18.4967623 L11.0751588,20 L10.4335451,19.2483812 L11.3163312,18.2142408 C6.04956274,17.8408234 2,15.63487 2,12.9608824 C2,11.3866821 3.40279267,9.97327728 5.63006353,9 L5.63006353,10.0439741 C3.94321838,10.8056925 2.90751588,11.8315937 2.90751588,12.9611482 C2.90751588,15.065043 6.49719495,16.8085435 11.2085636,17.1495361 L10.4335451,16.2416401 L11.0751588,15.4900213 Z M18.8,9 C20.7632,9.95219802 22,11.3346138 22,12.8740396 C22,15.4496274 18.5398,17.5871989 14,18 L14,16.9633181 C18.0958,16.607966 21.2,14.91283 21.2,12.8742996 C21.2,11.7695107 20.2868,10.7658425 18.8,10.0210849 L18.8,9 Z" />
  ),
  calendarBold: (
    <path
      fill="#111"
      d="M17,3V2H15V3H9V2H7V3H2V22H22V3Zm3,17H4V5H7V6H9V5h6V6h2V5h3ZM7,8h4v4H7Zm6,0h4v4H13ZM7,14h4v4H7Zm6,0h4v4H13Z"
    />
  ),
  communityProjectsBold: (
    <path
      fill="#111"
      d="M13.1635,7.11A3.1056,3.1056,0,0,1,12,4.73,2.8134,2.8134,0,0,1,14.82,2,2.2713,2.2713,0,0,1,17,3.1505,2.3047,2.3047,0,0,1,19.2242,2,2.7561,2.7561,0,0,1,22,4.73a3.5438,3.5438,0,0,1-1.359,2.38C19.76,7.9873,17,10,17,10A43.2646,43.2646,0,0,1,13.1635,7.11ZM20.5424,16h-7.393l-1.4276-.68,4.7052-1.7705A1.3206,1.3206,0,0,0,16,11c-.0138,0-.0251.0077-.0387.0081C15.9446,11.0075,15.93,11,15.9135,11H6.6894a2.8573,2.8573,0,0,0-2.0281.8447l-.906.8974A.8718.8718,0,0,1,3.1362,13H2v2.0315H3.1324a2.8694,2.8694,0,0,0,2.037-.8485l.9023-.9094A.951.951,0,0,1,6.6894,13h5.4457L6.5663,15.094a1.0071,1.0071,0,1,0,.6944,1.8907l1.8916-.7059,3.1687,1.5966a1.68,1.68,0,0,0,.756.18h4.8116l-4.3472,1.8726a.759.759,0,0,1-.46.0368L6.4521,18.11A3.1367,3.1367,0,0,0,5.6264,18H2v2H5.6353l.2953.0408,6.6475,1.875A2.7584,2.7584,0,0,0,13.25,22a2.8086,2.8086,0,0,0,1.0567-.21l6.7773-2.9194a1.4552,1.4552,0,0,0,.8887-1.6412A1.5183,1.5183,0,0,0,20.5424,16Z"
    />
  ),
  giftBold: (
    <path
      fill="#111"
      d="M22 6h-6.574a2.39 2.39 0 0 0 .34-.312 2.258 2.258 0 0 0-3.382-2.99 2.67 2.67 0 0 0-.384.578 2.672 2.672 0 0 0-.384-.578 2.258 2.258 0 0 0-3.382 2.99 2.39 2.39 0 0 0 .34.312H2v6h1v10h18V12h1zm-2 4h-7V8h7zM4 8h7v2H4zm1 12v-8h6v8zm14 0h-6v-8h6z"
    />
  ),
  heart: (
    <path
      fill="#111"
      d="M19.205 5.599c.954.954 1.415 2.279 1.42 3.614 0 3.065-2.204 5.726-4.138 7.501-1.215 1.116-2.554 2.14-3.98 2.973l-.507.314-.501-.309c-.975-.562-1.888-1.227-2.765-1.93-1.14-.911-2.46-2.127-3.502-3.553-1.038-1.418-1.86-3.124-1.86-4.995-.002-1.251.394-2.59 1.42-3.615a4.859 4.859 0 016.87 0l.335.335.336-.335c1.873-1.872 5-1.873 6.872 0zM11.998 17.65c.67-.43 1.91-1.284 3.137-2.41 1.878-1.725 3.488-3.87 3.49-6.026-.005-.85-.265-1.633-.834-2.202-1.104-1.103-2.941-1.1-4.045.002l-1.751 1.744-1.746-1.746a2.859 2.859 0 00-4.044 0c-1.683 1.684-.53 4.414.641 6.016.9 1.23 2.073 2.32 3.137 3.172a24.383 24.383 0 002.015 1.45z"
    />
  ),
  hotDrinkBold: (
    <path
      fill="#111"
      d="M6.731,7.4225a2.943,2.943,0,0,0,.0549-1.9486,4.19,4.19,0,0,1-.0538-2.4863L7.0143,2H9.3011L8.9026,3.3945a2.7184,2.7184,0,0,0,.0351,1.6109,4.4245,4.4245,0,0,1-.085,2.9673L8.8418,8H6.5ZM10.6989,8h2.3418l.0109-.0273a4.4251,4.4251,0,0,0,.0851-2.9673,2.7168,2.7168,0,0,1-.0351-1.6109L13.5,2H11.2132l-.2822.9876a4.1911,4.1911,0,0,0,.0539,2.4863,2.9431,2.9431,0,0,1-.055,1.9486ZM20,13.2837a4.2457,4.2457,0,0,1-1.3926,3.29,6.7,6.7,0,0,1-3.988,1.4008L14,22H6L4,9H16l-.1571,1.0212a5.473,5.473,0,0,1,3.18,1.0706A2.8191,2.8191,0,0,1,20,13.2837ZM13.6688,11H6.3312l1.3846,9h4.5684Zm4.0421,1.6016a4.2233,4.2233,0,0,0-2.174-.5917l-.6047,3.9307a5.6241,5.6241,0,0,0,2.353-.8678A1.6231,1.6231,0,0,0,17.7109,12.6016Z"
    />
  ),
  memberDiscountBold: (
    <path
      fill="#111"
      d="M13,4V2H11V4H4V22H20V4Zm5,16H6V6h5V7h2V6h5ZM9,10a1,1,0,1,1,1,1A1,1,0,0,1,9,10Zm6,6a1,1,0,1,1-1-1A1,1,0,0,1,15,16Zm-6.707-.707,6-6,1.414,1.414-6,6Z"
    />
  ),
  partnerShipThin: (
    <path
      fill="#111"
      d="M3 6.5A2.5 2.5 0 1 1 5.5 9 2.5 2.5 0 0 1 3 6.5zM18.5 9A2.5 2.5 0 1 0 16 6.5 2.5 2.5 0 0 0 18.5 9zm1.5 1h-2a1.852 1.852 0 0 0-.67.12 2.722 2.722 0 0 0-.4.22c-.11.08-.38.25-.47.3a8.862 8.862 0 0 1-2.85 1.18A7.16 7.16 0 0 1 12 12a1 1 0 0 0 0 2 8.551 8.551 0 0 0 1.83-.2 11.424 11.424 0 0 0 2.17-.69V20h6v-8a2 2 0 0 0-2-2zm-9.61 1.82a8.862 8.862 0 0 1-2.85-1.18c-.09-.05-.55-.38-.57-.39a1.883 1.883 0 0 0-.25-.11A2.143 2.143 0 0 0 6 10H4a2 2 0 0 0-2 2v8h6v-6.89a11.424 11.424 0 0 0 2.17.69A1.98 1.98 0 0 1 10 13a1.944 1.944 0 0 1 .39-1.18z"
    />
  ),
  planningConsultationBold: (
    <path
      fill="#111"
      d="M22 10l-9 9-4 2 2-4 9-9zM6 5.527a3.528 3.528 0 1 1 3.528 3.528A3.531 3.531 0 0 1 6 5.527zm2 0A1.528 1.528 0 1 0 9.528 4 1.53 1.53 0 0 0 8 5.527zM12 12a1.889 1.889 0 0 1 .998.295l1.441-1.441A3.927 3.927 0 0 0 12 10H7a4 4 0 0 0-4 4v5h2v-5a2.002 2.002 0 0 1 2-2zM3 22h2v-2H3zm3 0h2v-2H6z"
    />
  ),
  receiptBold: (
    <path
      fill="#111"
      d="M14 6v2H9V6zm-2 4H9v2h3v-2zm3 4H9v2h6v-2zm3-10H6v13.172l2 2 2-2 2 2 2-2 2 2 2-2V4m2-2v20l-2-2-2 2-2-2-2 2-2-2-2 2-2-2-2 2V2z"
    />
  ),
  rewardsBold: (
    <path
      fill="#111"
      d="M18.5,8.5a6.5,6.5,0,1,0-9.8445,5.5659L6.4141,21.9111,12,19.1182l5.5859,2.7929-2.2414-7.8452A6.49,6.49,0,0,0,18.5,8.5ZM12,4A4.5,4.5,0,1,1,7.5,8.5,4.5051,4.5051,0,0,1,12,4Zm2.4141,14.0889L12,16.8818,9.5859,18.0889l.933-3.265a6.3165,6.3165,0,0,0,2.9622,0Z"
    />
  ),
  returnsBold: (
    <path
      fill="#111"
      d="M12.3341 5.59721C14.2317 3.70042 17.3076 3.70067 19.2049 5.59795C20.2048 6.59781 20.6777 7.92671 20.6239 9.23427C20.6132 12.2905 18.4165 14.942 16.4873 16.7133C15.4907 17.6283 14.4984 18.369 13.7574 18.8802C13.3859 19.1365 13.0752 19.3367 12.8554 19.474C12.7455 19.5426 12.6582 19.5957 12.5972 19.6322C12.5667 19.6504 12.5428 19.6645 12.5259 19.6744L12.506 19.6861L12.5001 19.6895L12.4981 19.6906L12.0038 18.8349V17.6459C12.1756 17.5355 12.3847 17.3974 12.6217 17.234C13.3105 16.7588 14.2248 16.0754 15.1347 15.24C17.0137 13.5148 18.624 11.3685 18.624 9.21172V9.18879L18.625 9.16588C18.6607 8.38887 18.3825 7.60393 17.7907 7.01216C16.6742 5.89569 14.864 5.89568 13.7476 7.01216L13.7462 7.01355L11.9945 8.75832L10.2484 7.01216C9.13192 5.89569 7.32175 5.89569 6.20527 7.01217C5.61351 7.60393 5.33529 8.38887 5.37094 9.16588L5.37199 9.18879V9.21173C5.37199 10.4952 5.94133 11.7912 6.84588 13.0277C7.19716 13.5079 7.59014 13.9669 8.00204 14.3987L8.0018 11.9529L10.0018 11.9527L10.0024 17.64L4.30215 17.6394L4.30234 15.6394L6.42318 15.6396C6.00479 15.1916 5.60085 14.7132 5.23168 14.2085C4.19817 12.7957 3.37862 11.0967 3.37203 9.23431C3.31828 7.92674 3.79118 6.59783 4.79106 5.59795C6.68858 3.70043 9.76508 3.70042 11.6626 5.59795L11.9973 5.93266L12.3334 5.59795L12.3341 5.59721ZM12.0038 18.8349L12.4969 19.6913L12.0038 19.9752V18.8349Z"
    ></path>
  ),
  scanBarCode: (
    <path
      fill="#111"
      d="M9 2H2v7h2V4h5V2zm6 2h5v5h2V2h-7v2zM2 15h2v5h5v2H2v-7zm18 0h2v7h-7v-2h5v-5zM6.504 17V7h2v10h-2zm3-10v10h2V7h-2zm3 10V7h2v10h-2zm3-10v10h2V7h-2z"
    ></path>
  ),
  tag: (
    <path
      fill="#111"
      d="M4.204 4.204L2.95 12.143l10.387 10.386 9.192-9.192L12.143 2.95l-7.94 1.254zM13.337 19.7l-8.252-8.253.867-5.496 5.496-.867 8.253 8.252-6.364 6.364z"
    ></path>
  ),
  transportAssemblyAssuranceBold: (
    <path
      fill="#111"
      d="M21.17,19.1554l-6.7773,2.654a3.0059,3.0059,0,0,1-1.7285.1146l-6.6475-1.64a1.2624,1.2624,0,0,0-.3042-.0372H2V18.4141H5.7129a3.4289,3.4289,0,0,1,.8257.1L13.1675,20.15a.8313.8313,0,0,0,.46-.0335l4.3472-1.7024H12.7642l-3.5254-1.615-1.8916.6418L7.33,17.3976l-.1276.0433a1.0884,1.0884,0,0,1-.3471.0573A1.002,1.002,0,0,1,5.917,16.9a.8967.8967,0,0,1,.5908-1.1775l5.5688-1.8894H6.7759a.9094.9094,0,0,0-.6177.2344l-.9023.8268a2.99,2.99,0,0,1-2.0318.7714H2V13.8326H3.2241a.91.91,0,0,0,.6177-.2345l.9023-.8268A2.9906,2.9906,0,0,1,6.7759,12H16a1.301,1.301,0,0,1,1.2886.9346,1.1791,1.1791,0,0,1-.6963,1.3637l-.1.04-4.6839,1.5893,1.4275.6541h7.3931a1.4141,1.4141,0,0,1,1.43,1.0818A1.3044,1.3044,0,0,1,21.17,19.1554ZM11,10H9V2H22V14H19V12h1V9H11Zm0-3h9V4H11Zm3-1h3V5H14Zm3,5V10H14v1Z"
    />
  ),
  deliveryBold: (
    <path
      d="M22 11.815L20 11l-3-3h-3V6H2v11h3.214a2.121 2.121 0 0 0 4.03 0h5.939a2.122 2.122 0 0 0 4.03 0H22zM8.893 15a2.109 2.109 0 0 0-3.327 0H4V8h8v7zM20 15h-1.139a2.109 2.109 0 0 0-3.326 0h-1.512v-5H16l2 2 2 1z"
      fill="#111"
    />
  ),
  playArea: (
    <path
      d="M3 14.2498H7.125V18.3748H3V14.2498ZM9 9.37483H4.875V13.4998H9V9.37483ZM7.875 18.3748H12V14.2498H7.875V18.3748ZM7.875 4.76758L5.625 8.62483H10.125L7.875 4.76758ZM21.75 14.2498C21.75 15.0657 21.5081 15.8632 21.0548 16.5416C20.6016 17.2199 19.9573 17.7486 19.2036 18.0608C18.4498 18.373 17.6204 18.4547 16.8203 18.2956C16.0201 18.1364 15.2851 17.7435 14.7082 17.1666C14.1313 16.5898 13.7384 15.8547 13.5793 15.0546C13.4201 14.2544 13.5018 13.425 13.814 12.6713C14.1262 11.9175 14.6549 11.2733 15.3333 10.82C16.0116 10.3668 16.8092 10.1248 17.625 10.1248C18.7187 10.126 19.7672 10.561 20.5405 11.3343C21.3138 12.1077 21.7488 13.1562 21.75 14.2498V14.2498ZM17.625 17.6248C17.624 16.73 17.2681 15.8722 16.6354 15.2394C16.0027 14.6067 15.1448 14.2508 14.25 14.2498C14.251 15.1446 14.6069 16.0025 15.2396 16.6352C15.8723 17.2679 16.7302 17.6238 17.625 17.6248V17.6248ZM21 14.2498C20.999 13.355 20.6431 12.4972 20.0104 11.8644C19.3777 11.2317 18.5198 10.8758 17.625 10.8748C17.626 11.7696 17.9819 12.6275 18.6146 13.2602C19.2473 13.8929 20.1052 14.2488 21 14.2498Z"
      fill="#121212"
    />
  )
};
