import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, PageHeader } from 'Common';
import { t } from 'Utils/localization/i18next';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import styles from './DashboardHeaderContainer.scss';

const DashboardHeaderContainer = ({ profileType, selectedMenuTab }) => {
  const showFamilyCard =
    profileType === PROFILE_TYPES.FAMILY &&
    !CONFIG.FEATURE.ENABLE_IKEA_FAMILY_HUB;

  const className =
    styles[
      showFamilyCard ? 'dashboard-header-family' : 'dashboard-header-regular'
    ];

  return (
    <Grid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className={className}>
          <div className={styles['dashboard-header-text-wrap']}>
            <PageHeader
              margin={false}
              className={styles['dashboard-header-title']}
            >
              {t(`dashboard.${selectedMenuTab}.header.title`)}
            </PageHeader>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

DashboardHeaderContainer.propTypes = {
  profileType: PropTypes.string,
  selectedMenuTab: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    profileType: state.profile.profileType
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardHeaderContainer);
