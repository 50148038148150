import { ACTIONS } from './authConstants';

const initialState = {
  loggedIn: false,
  userLogin: undefined
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userLogin: action.userLogin
      };

    case ACTIONS.SET_USER_LOGIN:
      return {
        ...state,
        userLogin: action.userLogin
      };

    case ACTIONS.SET_USER_PASSWORD:
      return {
        ...state,
        password: action.password
      };

    case ACTIONS.SET_PARTY_UID:
      return {
        ...state,
        partyUid: action.partyUid
      };

    case ACTIONS.CLEAR_AUTH:
      return initialState;

    default:
      return state;
  }
};

export default auth;

// Actions should be mapped in the same order
// as they are defined in the actions file.
