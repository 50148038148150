import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { t } from 'Utils/localization/i18next';
import { isValidIKEAUrl } from 'Utils/url/urlUtils';
import { appendAuthTokenToUrl } from 'Utils/auth/authUtils';
import { setPageTitle } from 'Utils/document/documentUtils';
import { clearFormAction } from 'Redux/forms/formsActions';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { hasValidSession, login } from 'Redux/auth/authThunks';
import {
  getOrderHistoryCount,
  getShoppingListCount
} from 'Redux/commerce/commerceThunks';
import { getProfile, upgradeProfile } from 'Redux/profile/profileThunks';
import { getPayments } from 'Redux/payment/paymentThunks';
import { getDeliveryAddresses } from 'Redux/addresses/addressesThunks';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { Grid, Row, Col, Button, Text, Toast } from 'Common';
import Routes from 'Routes/profileRoutes';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import styles from './UpgradeToFamilyCard.scss';

class UpgradeToFamilyCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.upgradeFormId = 'regular-upgrade-form';
    this.state = {
      isEditing: false,
      showUpgrade: props.showUpgrade,
      showSuccessMessage: false,
      isConfirming: false,
      modalOpen: false
    };
    this.myRef = React.createRef();
    props.history.replace({
      ...props.history.location,
      state: {
        ...window.history.state,
        showUpgrade: false
      }
    });
  }

  componentDidMount() {
    setPageTitle(t(['metaData.upgradePageTitle', 'metaData.defaultPageTitle']));
    this.init();
  }

  componentWillUnmount() {
    this.props.clearFormAction(this.upgradeFormId);
  }

  init = async () => {
    const isLoggedIn = await this.props.hasValidSession();

    if (!isLoggedIn) {
      this.props.login(null, null, null, null, window.location);
    } else {
      this.getDashboardData();
    }
  };

  getDashboardData = async () => {
    if (!this.props.profileFetched) {
      await this.props.getProfile();
      this.props.getDeliveryAddresses();

      const isRegular = this.props.profileType === PROFILE_TYPES.REGULAR;
      const isFamily = this.props.profileType === PROFILE_TYPES.FAMILY;

      if (
        (CONFIG.FEATURE.REGULAR_LINKBOXES.ENABLE_ORDER_HISTORY && isRegular) ||
        (CONFIG.FEATURE.FAMILY_LINKBOXES.ENABLE_ORDER_HISTORY && isFamily)
      ) {
        this.props.getOrderHistoryCount();
      }

      if (
        (CONFIG.FEATURE.REGULAR_LINKBOXES.ENABLE_SHOPPING_LISTS && isRegular) ||
        (CONFIG.FEATURE.FAMILY_LINKBOXES.ENABLE_SHOPPING_LISTS && isFamily)
      ) {
        this.props.getShoppingListCount();
      }

      if (
        CONFIG.FEATURE.REGULAR_DASHBOARD.ENABLE_PAYMENTS_TAB ||
        CONFIG.FEATURE.FAMILY_DASHBOARD.ENABLE_PAYMENTS_TAB
      )
        await this.props.getPayments();
    }
  };

  onSubmit = async data => {
    const success = await this.props.upgradeProfile(
      { ...this.props.profile, ...data },
      this.upgradeFormId,
      this.props.redirectToAfterUpgrade
    );
    if (success) {
      this.setState({
        isEditing: false,
        showSuccessMessage: true
      });
    }
  };

  onClose = () => {
    this.setState({ isEditing: false, showUpgrade: false });
  };

  onEdit = () => {
    this.setState({ isEditing: true, showSuccessMessage: false });
  };

  render() {
    if (!CONFIG.FEATURE.PROFILE_UPGRADE.VISIBLE) {
      // Upgrade is disabled.
      return <Redirect to={Routes.DASHBOARD} />;
    }

    if (
      CONFIG.FEATURE.PROFILE_UPGRADE.VISIBLE &&
      CONFIG.FEATURE.PROFILE_UPGRADE.USE_EXTERNAL &&
      CONFIG.FEATURE.PROFILE_UPGRADE.EXTERNAL_URL
    ) {
      // Upgrade is handled by external upgrade page.
      window.location = CONFIG.FEATURE.PROFILE_UPGRADE.EXTERNAL_URL;
      return null;
    }
    // if (!this.props.profileFetched || this.props.isLoading) {
    //   return <Loading isLoading text={t('loaders.upgrade')} />;
    // }

    if (this.props.profileType === PROFILE_TYPES.FAMILY) {
      if (isValidIKEAUrl(this.from)) {
        // User is already logged in as Family user and
        // upgrade should return to a different URL "from".
        window.location = appendAuthTokenToUrl(this.from);
        return null;
      }
      // User is already logged in as Family user.
      return <Redirect to={Routes.DASHBOARD} />;
    }
    return CONFIG.FEATURE.PROFILE_UPGRADE.VISIBLE ? (
      <Grid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <React.Fragment>
              <div className={styles['upgrade-to-family-card']}>
                {this.state.showSuccessMessage && (
                  <Toast autoCloseMs={5000} closeText={t('common.dismiss')}>
                    <Text bold>
                      {t(
                        'dashboard.confirmation.addressPrimarySectionUpdateTitle'
                      )}
                    </Text>
                    <Text>
                      {t(
                        'dashboard.confirmation.addressPrimarySectionUpdateText'
                      )}
                    </Text>
                  </Toast>
                )}
                <div>
                  <Text className={styles['banner-title']}>
                    {t('forms.signup.pageTitleUpgrade')}
                  </Text>
                  <Text className={styles['body-style']}>
                    {t('dashboard.upgrade.body')}
                  </Text>
                </div>
                <div>
                  {/* <Button
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            >
              {'Learn More'}
            </Button> */}
                  {'  '}
                  <Button
                    type="primary"
                    onClick={this.onEdit}
                    id="link-upgrade-to-family"
                    inverseTheme
                  >
                    {t('dashboard.upgrade.linkText')}
                  </Button>
                </div>
              </div>
              <div>
                {(this.state.isEditing || this.state.showUpgrade) && (
                  <DynamicFormContainerModal
                    formId={this.upgradeFormId}
                    prefillValues={this.props.profile}
                    loadingSubmit={this.props.isLoading}
                    textSubmit={t('forms.signup.btnSubmit')}
                    textCancel={t('common.backToAccount')}
                    onSubmit={this.onSubmit}
                    onCancel={this.onClose}
                  />
                )}
              </div>
            </React.Fragment>
          </Col>
        </Row>
      </Grid>
    ) : null;
  }
}

UpgradeToFamilyCard.propTypes = {
  isLoading: PropTypes.bool,
  upgradeFormId: PropTypes.string,
  profile: PropTypes.object,
  profileType: PropTypes.string,
  profileFetched: PropTypes.bool.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getProfile: PropTypes.func.isRequired,
  getDeliveryAddresses: PropTypes.func.isRequired,
  getOrderHistoryCount: PropTypes.func.isRequired,
  getShoppingListCount: PropTypes.func.isRequired,
  getPayments: PropTypes.func,
  upgradeProfile: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired,
  hasValidSession: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  showUpgrade: PropTypes.bool,
  redirectToAfterUpgrade: PropTypes.string
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    profileType: state.profile.profileType,
    profileFetched: !!state.profile.profileType,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPGRADE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProfile,
      getDeliveryAddresses,
      getOrderHistoryCount,
      getShoppingListCount,
      getPayments,
      upgradeProfile,
      clearFormAction,
      hasValidSession,
      login
    },
    dispatch
  );
};

const UpgradeToFamilyCardWithCOnnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeToFamilyCard);

export default withRouter(UpgradeToFamilyCardWithCOnnect);
