import React from 'react';
import PropTypes from 'prop-types';

export class WithoutHeaderAndFooter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.hideNavigation();
    this.setScrollbar();
  }

  componentDidMount() {
    setTimeout(() => {
      // Traverse and hide elements again in order to catch
      // e.g. cookie consent with transition effects.
      this.hideNavigation();
      if (this.props.openLinksInSmallWindow) {
        this.setLinksToOpenInSmallWindow();
      }
    }, 1000);
  }

  hideNavigation = () => {
    [...document.body.children].forEach(child => {
      if (child.id !== 'profile-frontend' && child.style.display !== 'none') {
        // eslint-disable-next-line no-param-reassign
        child.style.display = 'none';
      }
    });
  };

  setScrollbar = () => {
    // On KIOSK machine the vertical scrollbar is hidden by default,
    // so we need to set it manually here.
    const profileWrapperElement = document.getElementById('profile-frontend');
    profileWrapperElement.style.maxHeight = '100vh';
    profileWrapperElement.style.overflowY = 'scroll';
  };

  setLinksToOpenInSmallWindow = () => {
    const profileElement = document.getElementById('profile-frontend');
    const linkElements = profileElement.getElementsByTagName('a');
    linkElements.forEach(link => {
      if (link.target && link.href) {
        const url = link.href;
        link.addEventListener('click', e => {
          e.preventDefault();
          window.open(url, '', ['innerWidth=600px', 'innerHeight=750px']);
        });
        // eslint-disable-next-line no-param-reassign
        link.href = '#';
        // eslint-disable-next-line no-param-reassign
        link.target = '';
      }
    });
  };

  render() {
    return this.props.children;
  }
}

WithoutHeaderAndFooter.propTypes = {
  openLinksInSmallWindow: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
