import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import { hasValidSession } from 'Redux/auth/authThunks';
import { getFormValues } from 'Redux/forms/formsHelper';
import { decodeBase64 } from 'Utils/string/stringUtils';
import analytics from 'Utils/analytics/analyticsWrapper';
import { clearFormAction } from 'Redux/forms/formsActions';
import { createProfile } from 'Redux/profile/profileThunks';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { isValidIKEAUrl, getQueryStringValue } from 'Utils/url/urlUtils';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { PLANNERS_URL_PATTERNS } from 'Utils/url/validIKEAUrls';
import {
  PageHeader,
  TextWithLink,
  Loading,
  Grid,
  Row,
  Col,
  WithoutHeaderAndFooter,
  Text
} from 'Common';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import Routes from 'Routes/profileRoutes';
import images from './images';
import styles from './SingleSignupContainer.scss';

class SingleSignupContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      profileType: this.props.defaultProfileType,
      prefillValues: {
        ...this.getPrefillValuesFromQueryParams(),
        profileType: this.props.defaultProfileType
      }
    };
    // "from" is a query string parameter that can be
    // used to redirect the user back after the signup.
    // The fragment identifier "hash" is required to support URLs with "hash" like:
    // https://events.ikea.com/gb/en/events/#/store/croydon/
    this.from = getQueryStringValue(
      `${this.props.location.search}${this.props.location.hash}`,
      Routes.QUERY_PARAMS.FROM
    );
    // Send the analytics event on only the signup page
    // display, not the actual call for the backend
    analytics.userDisplayedSignupPage(this.props.defaultProfileType);
  }

  componentDidUpdate() {
    if (
      this.state.profileType === PROFILE_TYPES.REGULAR &&
      this.props.regularSignupValues.profileType === PROFILE_TYPES.FAMILY
    ) {
      this.changeFromRegularToFamilySignup();
      return;
    }
    if (
      this.state.profileType === PROFILE_TYPES.FAMILY &&
      this.props.familySignupValues.profileType === PROFILE_TYPES.REGULAR
    ) {
      this.changeFromFamilyToRegularSignup();
    }
  }

  getPrefillValuesFromQueryParams = () => {
    try {
      const encodedString = getQueryStringValue(
        this.props.location.search,
        Routes.QUERY_PARAMS.PREFILL
      );
      const decodedFromBase64 = decodeBase64(encodedString);
      const decodedFromURI = decodeURIComponent(decodedFromBase64);
      return decodedFromURI ? JSON.parse(decodedFromURI) : {};
    } catch (e) {
      return {};
    }
  };

  getSignupConfirmationPageURL = () => {
    return `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/${
      CONFIG.APP.BASE_NAME
    }${
      this.props.isBasicSignup
        ? Routes.SIGNUP_CONFIRMATION_BASIC
        : Routes.SIGNUP_CONFIRMATION
    }${this.from ? `?from=${this.from}` : ''}`;
  };

  changeFromRegularToFamilySignup = () => {
    this.setState(
      {
        profileType: PROFILE_TYPES.FAMILY,
        prefillValues: {
          ...this.props.regularSignupValues,
          acceptPrivacyPolicy: undefined,
          acceptTermsAndConditions: undefined,
          acceptProcessingOfPI: undefined,
          acceptTransferringOfPI: undefined
        }
      },
      () => {
        this.props.clearFormAction('regular-signup-form');
        analytics.userDisplayedSignupPage(this.state.profileType);
      }
    );
  };

  changeFromFamilyToRegularSignup = () => {
    this.setState(
      {
        profileType: PROFILE_TYPES.REGULAR,
        prefillValues: {
          ...this.props.familySignupValues,
          acceptPrivacyPolicy: undefined,
          acceptTermsAndConditions: undefined,
          acceptProcessingOfPI: undefined,
          acceptTransferringOfPI: undefined
        }
      },
      () => {
        this.props.clearFormAction('family-signup-form');
        analytics.userDisplayedSignupPage(this.state.profileType);
      }
    );
  };

  onSubmit = data => {
    const trimmedData = {
      ...trimAllObjPropertiesSkipPasswords(data),
      profileType: this.state.profileType
    };

    const formId = `${this.state.profileType}-signup-form`;
    const confirmationPageUrl = this.getSignupConfirmationPageURL();

    this.props.createProfile(
      trimmedData,
      formId,
      this.from,
      confirmationPageUrl
    );
  };

  onCancel = () => {
    if (isValidIKEAUrl(this.from)) {
      const { from } = this;
      const newFrom = from.replace(/(.*)\?cancel=0#(.*)/, `$1?cancel=1#$2`);
      window.location = newFrom;
    }
  };

  render() {
    if (this.props.isLoading && this.props.isBasicSignup) {
      return (
        <WithoutHeaderAndFooter>
          <Loading
            isLoading={this.props.isLoading}
            text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
          />
        </WithoutHeaderAndFooter>
      );
    }

    if (this.props.isLoading) {
      return (
        <Loading
          isLoading={this.props.isLoading}
          text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
        />
      );
    }

    if (this.props.isBasicSignup) {
      return (
        <WithoutHeaderAndFooter
          openLinksInSmallWindow={[
            ...PLANNERS_URL_PATTERNS.NEW_KITCHEN_PLANNER_KIOSK,
            ...PLANNERS_URL_PATTERNS.IHP_KITCHEN_PLANNER
          ].some(pattern => pattern.test(this.from))}
        >
          <Grid className={styles['single-signup']}>
            <Row>
              <Col sm={5} xl={4}>
                <PageHeader margin={false}>
                  {this.state.profileType === PROFILE_TYPES.FAMILY
                    ? t('forms.signup.pageTitleFamily')
                    : t('forms.signup.pageTitleRegular')}
                </PageHeader>
              </Col>
              <Col sm={1} xl={2} />
              <Col sm={6}>
                {/* Use profile type as "key" here to force the form to remount */}
                <div key={this.state.profileType}>
                  <DynamicFormContainer
                    formId={`${this.state.profileType}-signup-form`}
                    prefillValues={this.state.prefillValues}
                    textSubmit={t('forms.signup.btnSubmit')}
                    textCancel={
                      isValidIKEAUrl(this.from) ? t('common.cancel') : ''
                    }
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        </WithoutHeaderAndFooter>
      );
    }

    if (CONFIG.FEATURE.INTERNAL_LOGIN) {
      return (
        <Grid className={styles['single-signup']}>
          <Row>
            <Col sm={5} xl={4}>
              <PageHeader margin={false}>
                {this.state.profileType === PROFILE_TYPES.FAMILY
                  ? t('forms.signup.pageTitleFamily')
                  : t('forms.signup.pageTitleRegular')}
              </PageHeader>
              <TextWithLink
                id="single-signup-login-link"
                labelBeforeLink={`${t('forms.signup.login')} `}
                labelLink={t('common.login')}
                link={`${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/${CONFIG.APP.BASE_NAME}${Routes.LOGIN}${this.props.location.search}`}
              />
            </Col>
            <Col sm={1} xl={2} />
            <Col sm={6}>
              {/* Use profile type as "key" here to force the form to remount */}
              <div key={this.state.profileType}>
                <DynamicFormContainer
                  formId={`${this.state.profileType}-signup-form`}
                  prefillValues={this.state.prefillValues}
                  textSubmit={t('forms.signup.btnSubmit')}
                  textCancel={
                    isValidIKEAUrl(this.from) ? t('common.cancel') : ''
                  }
                  onSubmit={this.onSubmit}
                  onCancel={this.onCancel}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }

    return (
      <Grid className={styles['single-signup']}>
        <Row>
          <Col sm={5} xl={4}>
            <PageHeader margin={false}>
              {this.state.profileType === PROFILE_TYPES.FAMILY
                ? t('forms.signup.pageTitleFamily')
                : t('forms.signup.pageTitleRegular')}
            </PageHeader>
            <TextWithLink
              id="single-signup-login-link"
              labelBeforeLink={`${t('forms.signup.login')} `}
              labelLink={t('common.login')}
              link={`${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/${CONFIG.APP.BASE_NAME}${Routes.LOGIN}${this.props.location.search}`}
            />
            {CONFIG.FEATURE.SIGNUP_IMAGE_MOBILE && (
              <div className={styles['single-signup-image-wrap-mobile']}>
                <img src={images.SINGLE_SIGNUP_IMAGE_9} />
              </div>
            )}
            {/* Used for A/B testing */}
            <div
              className={styles['single-signup-image-wrap-ab']}
              style={{
                backgroundImage: `url(${
                  images[`${CONFIG.COUNTRY.toUpperCase()}_SINGLE_SIGNUP_IMAGE`]
                })`
              }}
            />
            {/* END OF: Used for A/B testing */}
            {CONFIG.FEATURE.SIGNUP_DYNAMIC_IMAGES && (
              <div className={styles['single-signup-dynamic-image-wrap']}>
                <div className={styles['single-signup-dynamic-image-row']}>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[0]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption1')}
                    </Text>
                  </div>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[3]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption4')}
                    </Text>
                  </div>
                </div>
                <div className={styles['single-signup-dynamic-image-row']}>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[1]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption2')}
                    </Text>
                  </div>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[4]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption5')}
                    </Text>
                  </div>
                </div>
                <div className={styles['single-signup-dynamic-image-row']}>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[2]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption3')}
                    </Text>
                  </div>
                  <div
                    className={styles['single-signup-dynamic-image-text-wrap']}
                  >
                    <Image
                      shape="square"
                      src={images[CONFIG.FEATURE.SIGNUP_IMAGES[5]]}
                    />
                    <Text marginTop="xs" marginBottom="l" smaller="true">
                      {t('forms.signup.imageCaption6')}
                    </Text>
                  </div>
                </div>
              </div>
            )}
            {!CONFIG.FEATURE.SIGNUP_DYNAMIC_IMAGES && (
              <div className={styles['single-signup-image-wrap']}>
                <div className={styles['single-signup-image-col']}>
                  <Image shape="rect" src={images.SINGLE_SIGNUP_IMAGE_1} />
                  <Image shape="rect" src={images.SINGLE_SIGNUP_IMAGE_2} />
                  <Image shape="square" src={images.SINGLE_SIGNUP_IMAGE_3} />
                  <Image shape="rect" src={images.SINGLE_SIGNUP_IMAGE_4} />
                </div>
                <div className={styles['single-signup-image-col']}>
                  <Image shape="square" src={images.SINGLE_SIGNUP_IMAGE_5} />
                  <Image shape="square" src={images.SINGLE_SIGNUP_IMAGE_6} />
                  <Image shape="rect" src={images.SINGLE_SIGNUP_IMAGE_7} />
                  <Image shape="square" src={images.SINGLE_SIGNUP_IMAGE_8} />
                  <Image shape="rect" src={images.SINGLE_SIGNUP_IMAGE_9} />
                </div>
              </div>
            )}
          </Col>
          <Col sm={1} xl={2} />
          <Col sm={6}>
            {/* Use profile type as "key" here to force the form to remount */}
            <div key={this.state.profileType}>
              <DynamicFormContainer
                formId={`${this.state.profileType}-signup-form`}
                prefillValues={this.state.prefillValues}
                textSubmit={t('forms.signup.btnSubmit')}
                textCancel={isValidIKEAUrl(this.from) ? t('common.cancel') : ''}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
              />
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

SingleSignupContainer.propTypes = {
  defaultProfileType: PropTypes.oneOf([
    PROFILE_TYPES.REGULAR,
    PROFILE_TYPES.FAMILY
  ]),
  // If "isBasicSignup" is true, navigation and images will be hidden
  isBasicSignup: PropTypes.bool,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  regularSignupValues: PropTypes.object,
  familySignupValues: PropTypes.object,
  createProfile: PropTypes.func.isRequired,
  hasValidSession: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired
};

SingleSignupContainer.defaultProps = {
  defaultProfileType: PROFILE_TYPES.FAMILY
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createProfile,
      hasValidSession,
      clearFormAction
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.PROFILE_CREATE],
    regularSignupValues: getFormValues(state, 'regular-signup-form'),
    familySignupValues: getFormValues(state, 'family-signup-form')
  };
};

const SingleSignupWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleSignupContainer);

export default withRouter(SingleSignupWithConnect);

export const Image = props => (
  <div
    className={styles[`single-signup-image-${props.shape}`]}
    style={{ backgroundImage: `url(${props.src})` }}
  />
);

Image.propTypes = {
  shape: PropTypes.oneOf(['square', 'rect']),
  src: PropTypes.any.isRequired
};
