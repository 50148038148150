/* eslint-disable no-unused-expressions */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { hasValidSession } from 'Redux/auth/authThunks';
import { redirectToCIAMSignup } from 'Redux/profile/profileThunks';
import { appendAuthTokenToUrl } from 'Utils/auth/authUtils';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { setPageTitle } from 'Utils/document/documentUtils';
import Routes from 'Routes/profileRoutes';
import SingleSignupContainer from 'Components/Signup/SingleSignupContainer';
import SignupFamilyKioskContainer from 'Components/Signup/SignupFamilyKioskContainer';
import SignupConfirmationContainer from 'Components/Signup/SignupConfirmationContainer';
import { getQueryStringValue, isValidIKEAUrl } from 'Utils/url/urlUtils';
import { t } from 'Utils/localization/i18next';

const SignupComponents = {
  [PROFILE_TYPES.REGULAR]: SingleSignupContainer,
  [PROFILE_TYPES.FAMILY]: SingleSignupContainer,
  [Routes.SIGNUP_KIOSK]: SignupFamilyKioskContainer,
  [Routes.SIGNUP_CONFIRMATION]: SignupConfirmationContainer
};

class SignupContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      readyToRender: false
    };
  }

  async componentDidMount() {
    this.checkLoggedIn();
    setPageTitle(t('metaData.defaultPageTitle'));
  }

  checkLoggedIn = async () => {
    const alreadyHasValidSession = await this.props.hasValidSession();

    if (alreadyHasValidSession) {
      // "from" is a query string parameter that can be
      // used to redirect the user back after the login.
      // The fragment identifier "hash" is required to support URLs with "hash" like:
      // https://events.ikea.com/gb/en/events/#/store/croydon/
      const from = getQueryStringValue(
        `${this.props.location.search}${this.props.location.hash}`,
        Routes.QUERY_PARAMS.FROM
      );
      if (isValidIKEAUrl(from)) {
        window.location = appendAuthTokenToUrl(from);
      } else {
        this.props.history.push(Routes.DASHBOARD);
      }
    } else {
      this.setState({ readyToRender: true });
    }
  };

  getProfileType = () => {
    const { pathname } = this.props.location;
    const isRegular = new RegExp(PROFILE_TYPES.REGULAR).test(pathname);
    return isRegular ? PROFILE_TYPES.REGULAR : PROFILE_TYPES.FAMILY;
  };

  isBasicSignup = () => {
    const { pathname } = this.props.location;
    return new RegExp(Routes.SIGNUP_BASIC).test(pathname);
  };

  isKioskSignupPage = () => {
    const { pathname } = this.props.location;
    return new RegExp(Routes.SIGNUP_KIOSK).test(pathname);
  };

  isConfirmationPage = () => {
    const { pathname } = this.props.location;
    return new RegExp(Routes.SIGNUP_CONFIRMATION).test(pathname);
  };

  render() {
    if (!this.state.readyToRender) {
      return null;
    }

    if (this.isConfirmationPage()) {
      const SignupComponent = SignupComponents[Routes.SIGNUP_CONFIRMATION];
      return <SignupComponent />;
    }

    if (CONFIG.FEATURE.ENABLE_KIOSK_SIGNUP && this.isKioskSignupPage()) {
      const SignupComponent = SignupComponents[Routes.SIGNUP_KIOSK];
      return <SignupComponent />;
    }

    const profileType = this.getProfileType();
    const featureName = `${profileType.toUpperCase()}_SIGNUP`;

    if (CONFIG.FEATURE[featureName].USE_APPLICATION) {
      const SignupComponent = SignupComponents[profileType];
      return (
        <SignupComponent
          defaultProfileType={profileType}
          isBasicSignup={this.isBasicSignup()}
        />
      );
    }
    if (CONFIG.FEATURE[featureName].USE_EXTERNAL) {
      if (!CONFIG.FEATURE[featureName].EXTERNAL_URL) {
        const from = getQueryStringValue(
          `${this.props.location.search}${this.props.location.hash}`,
          Routes.QUERY_PARAMS.FROM
        );
        this.props.redirectToCIAMSignup(from, profileType);
        return null;
      }
      window.location = CONFIG.FEATURE[featureName].EXTERNAL_URL;
      return null;
    }

    // Handles the following scenarios:
    // 1. User goes to /profile/signup/ or /profile/signup/family/ and family
    //    signup is disabled but regular signup is enabled. We will show regular
    //    signup form.
    // 2. User goes to /profile/signup/regular/ and regular signup is disabled
    //    but family signup is enabled. We show family signup form.
    const fallbackProfileType =
      profileType === PROFILE_TYPES.REGULAR
        ? PROFILE_TYPES.FAMILY
        : PROFILE_TYPES.REGULAR;
    const fallbackFeatureName = `${fallbackProfileType.toUpperCase()}_SIGNUP`;

    if (CONFIG.FEATURE[fallbackFeatureName].USE_APPLICATION) {
      const SignupComponent = SignupComponents[fallbackProfileType];
      return (
        <SignupComponent
          defaultProfileType={fallbackProfileType}
          isBasicSignup={this.isBasicSignup()}
        />
      );
    }

    return <Redirect to={Routes.LOGIN} />;
  }
}

SignupContainer.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  hasValidSession: PropTypes.func.isRequired,
  redirectToCIAMSignup: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hasValidSession,
      redirectToCIAMSignup
    },
    dispatch
  );
};

const SignupWithConnect = connect(
  null,
  mapDispatchToProps
)(SignupContainer);

export default withRouter(SignupWithConnect);
