import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Row, Col, InlineText, Divider, Text } from 'Common';

const DashboardChecklistReadContainer = props => (
  <Row>
    <Col sm={6}>
      <Text marginBottom="s">{t(props.title)}</Text>
      {props.names.map((name, index) => (
        <div key={`${index}-${name}`}>
          <InlineText
            msg={t(`${props.keyPrefix}${name}`)}
            icon={props.profile[`${name}`] ? 'check' : 'remove'}
            iconPosition="trailing"
            spaceBetween
          />
          {index < props.names.length - 1 && <Divider margin="xs" />}
        </div>
      ))}
    </Col>
  </Row>
);

DashboardChecklistReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  keyPrefix: PropTypes.string.isRequired,
  profile: PropTypes.object,
  title: PropTypes.string
};

DashboardChecklistReadContainer.defaultProps = {
  names: [],
  profile: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardChecklistReadContainer);
