import { ACTIONS } from './commerceConstants';

const initialState = {
  activeOrdersCount: undefined,
  shoppingListCount: undefined
};

const commerce = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_ORDERS_COUNT:
      return {
        ...state,
        activeOrdersCount: action.activeOrdersCount
      };

    case ACTIONS.SET_SHOPPING_LIST_COUNT:
      return {
        ...state,
        shoppingListCount: action.shoppingListCount
      };

    default:
      return state;
  }
};

export default commerce;

// Actions should be mapped in the same order
// as they are defined in the actions file.
