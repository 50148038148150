import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './DashboardSectionForm.scss';

const DashboardSectionForm = props => {
  return (
    <div className={styles['dashboard-section-form']}>
      {!props.hideHeaderInDashboard && (
        <Text className={styles['dashboard-section-form-header']}>
          {t(props.header)}
        </Text>
      )}
      {props.title && (
        <Text className={styles['dashboard-section-form-title']}>
          {t(props.title)}
        </Text>
      )}
      {props.text && <Text marginBottom="m">{t(props.text)}</Text>}
      {props.text2 && <Text marginBottom="m">{t(props.text2)}</Text>}
      {props.children}
    </div>
  );
};

DashboardSectionForm.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  hideHeaderInDashboard: PropTypes.bool,
  text: PropTypes.string,
  text2: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

DashboardSectionForm.defaultProps = {
  textColWidthDesktop: 12,
  hideHeaderInDashboard: true
};

export default DashboardSectionForm;
