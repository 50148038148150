import React from 'react';
import PropTypes from 'prop-types';
import DropdownControlled from 'BaseForm/DropdownControlled';

// This dropdown can be used when the form field might have a preset value
// not available in the list of options. The preset value will still be allowed
// (initially available as option in dropdown); but after changing it once,
// it will no longer be possible to reselect the old value.

const DropdownWithPresetValueAsOption = props => {
  const options =
    !props.value || props.options.some(option => option.value === props.value)
      ? props.options
      : [...props.options, { name: props.value, value: props.value }];
  return <DropdownControlled {...props} options={options} />;
};

DropdownWithPresetValueAsOption.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  showOptional: PropTypes.bool,
  removeHintText: PropTypes.bool,
  // Array of { name: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  topOptions: PropTypes.arrayOf(PropTypes.object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

DropdownWithPresetValueAsOption.defaultProps = {
  value: '',
  options: [],
  topOptions: []
};

export default DropdownWithPresetValueAsOption;
