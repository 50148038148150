import React from 'react';
import PropTypes from 'prop-types';
import { H1 } from 'Common';

export class PageHeader extends React.PureComponent {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    // For accessibility; screen readers need a way to notify the user that a
    // new page has been rendered. It is recommended to actively manage focus,
    // on e.g. the new main heading, whenever a new page is loaded.
    const element = document.getElementById('profile-page-headline');
    if (element) {
      element.focus();
      element.classList.remove('focus-visible');
    }
  }

  render() {
    return (
      <H1
        id="profile-page-headline"
        tabIndex={0}
        center={this.props.center}
        margin={this.props.margin}
        className={this.props.className}
        tagName="h1"
        headingSize="l"
      >
        {this.props.children}
      </H1>
    );
  }
}

PageHeader.propTypes = {
  center: PropTypes.bool,
  margin: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.string
};

PageHeader.defaultProps = {
  children: '',
  center: false
};
