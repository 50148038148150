// DMP (Data Model/Management Platform)

export default class DMPApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.DMP.HOST;
    this.country = CONFIG.COUNTRY;
    this.language = CONFIG.LANGUAGE;
  }

  /**
   * Calls the API with ICM id and email id to get hashed user information.
   * This is used by other applications to track and observe user behaviour.
   * @param  {string} partyUid - ICM id of the logged in user
   * @param  {string} userLogin - email id of the logged in user
   * @return {Promise} fetch promise
   */
  getHashUserCookieContent(partyUid, userLogin) {
    if (!partyUid || !userLogin) {
      throw Error();
    }
    const requestBody = {
      icmID: partyUid,
      emailID: userLogin,
      market: this.country,
      language: this.language
    };
    return fetch(`${this.apiUrl}/hashService/hashUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }
}
