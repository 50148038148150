import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import MissingDataNotificationBanner from './MissingDataNotificationBanner';

const DynamicNotification = ({
  allowEmail,
  allowSMS,
  allowDirectMail,
  allowPhone
}) => {
  const isAllConsentNotSelected = () =>
    !allowEmail && !allowSMS && !allowDirectMail && !allowPhone;

  if (isAllConsentNotSelected()) {
    return (
      // eslint-disable-next-line no-unused-expressions
      <div style={{ marginBottom: '0' }}>
        <MissingDataNotificationBanner
          text={t('missingDataNotification.text')}
          action={t('missingDataNotification.button')}
          style={{ marginBottom: '3.5rem' }}
        />
      </div>
    );
  }
  return null;
};

DynamicNotification.propTypes = {
  allowEmail: PropTypes.bool,
  allowSMS: PropTypes.bool,
  allowDirectMail: PropTypes.bool,
  allowPhone: PropTypes.bool
};

export default DynamicNotification;
