import React from 'react';
import DeleteProfileContainer from '../DeleteProfileContainer';
import DeleteProfileCIAMContainer from '../DeleteProfileCIAMContainer';
import styles from './LoginSecurity.scss';

const DeleteAccountContainer = () => {
  return (
    <React.Fragment>
      <div className={styles['dashboard-section-read']}>
        {CONFIG.FEATURE.ENABLE_CIAM_API ? (
          <DeleteProfileCIAMContainer />
        ) : (
          <DeleteProfileContainer />
        )}
      </div>
    </React.Fragment>
  );
};

export default DeleteAccountContainer;
