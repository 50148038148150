import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SSRIcon } from 'Common';
import styles from './AddressAutocomplete.scss';

export const GoogleMapsOption = props => (
  <div
    id={`option-${props.index}`}
    className={classNames(styles['address-autocomplete-option'], {
      [styles.selected]: props.selected
    })}
    onKeyDown={props.onKeyDown}
    onClick={props.onClick}
  >
    {props.option.label}
    {props.option.isCompleteAddress && (
      <div className={styles['address-autocomplete-count-wrap']}>
        <SSRIcon
          icon="checkmark"
          colour="feedback-confirmation"
          className={styles['address-autocomplete-count-icon']}
        />
      </div>
    )}
  </div>
);

GoogleMapsOption.propTypes = {
  selected: PropTypes.bool,
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};
