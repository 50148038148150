export const ANALYTICS_USER_ACTIONS = {
  LOGIN_REGULAR_PROFILE: 'logins_regular_profile',
  LOGIN_FAMILY_PROFILE: 'logins_ikea_family',
  SIGNUP_DISPLAY_REGULAR_PROFILE: 'signup_begun_regular_profile',
  SIGNUP_DISPLAY_FAMILY_PROFILE: 'signup_begun_ikea_family',
  SIGNUP_COMPLETED_REGULAR_PROFILE: 'signup_completed_regular_profile',
  SIGNUP_COMPLETED_FAMILY_PROFILE: 'signup_complete_ikea_family',
  UPDATE_PROFILE: 'edit_profile',
  DELETE_PROFILE: 'delete_profile'
};

export const ANALYTICS_ACTIONS_LABELS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  REGULAR_PROFILE_UPDATE: 'regular profile update',
  FAMILY_PROFILE_UPDATE: 'family profile update',
  REGULAR_PROFILE_UPGRADE: 'regular to family upgrade',
  REGULAR_PROFILE_USER_LOGIN_UPDATE: 'regular profile userlogin update',
  FAMILY_PROFILE_USER_LOGIN__UPDATE: 'family profile userlogin update',
  REGULAR_PROFILE_DELETE: 'regular profile delete',
  FAMILY_PROFILE_DELETE: 'family profile delete'
};

export const ANALYTICS_FORM_MAPPING = {
  'family-dashboard-account-personal-form': 'family_form',
  'regular-dashboard-account-personal-form': 'regular_form',
  'family-dashboard-address-primary-form': 'family_form',
  'regular-dashboard-address-primary-form': 'regular_form',
  'family-dashboard-address-delivery-edit-form': 'family_form',
  'regular-dashboard-address-delivery-edit-form': 'regular_form',
  'family-dashboard-address-delivery-add-form': 'family_form',
  'regular-dashboard-address-delivery-add-form': 'regular_form',
  'family-dashboard-settings-store-form': 'family_form',
  'regular-dashboard-settings-store-form': 'regular_form',
  'family-dashboard-communication-store-preferences': 'family_form',
  'regular-dashboard-communication-store-preferences': 'regular_form',
  'family-dashboard-communication-preferences': 'family_form',
  'regular-dashboard-communication-preferences': 'regular_form',
  'family-dashboard-communication-subscribe-unsubscribe-all': 'family_form',
  'regular-dashboard-communication-subscribe-unsubscribe-all': 'regular_form',
  'family-dashboard-communication-subscribe-unsubscribe': 'family_form',
  'family-dashboard-communication-new-customer': 'family_form',
  'regular-dashboard-communication-new-customer': 'regular_form',
  'family-dashboard-home-preferences': 'family_form',
  'regular-dashboard-home-preferences': 'regular_form',
  'family-dashboard-account-contact-form': 'family_form',
  'regular-dashboard-account-contact-form': 'regular_form',
  'family-dashboard-change-password': 'family_form',
  'regular-dashboard-change-password': 'regular_form',
  'family-dashboard-change-email': 'family_form',
  'regular-dashboard-change-email': 'regular_form',
  'family-dashboard-change-mobile': 'family_form',
  'regular-dashboard-change-mobile': 'regular_form',
  'family-dashboard-delete-account': 'family_form',
  'regular-dashboard-delete-account': 'regular_form',
  'regular-upgrade-form': 'regular-form'
};

export const ANALYTICS_FIELD_MAPPING = {
  'family-allowSharingToSocialMedia': 'family_form',
  'regular-allowSharingToSocialMedia': 'regular_form'
};
