import React from 'react';
import PropTypes from 'prop-types';
import QuantityStepper from '@ingka/quantity-stepper';
import { t, exists } from 'Utils/localization/i18next';
import { Tooltip, SSRIcon } from 'Common';
import styles from './QuantityField.scss';
import '@ingka/focus/style.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/quantity-stepper/style.scss';

class QuantityField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    };
  }

  onChange = value => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value.toString()
      }
    });
  };

  render() {
    return (
      <div>
        {this.props.label && (
          <Tooltip
            isOpen={this.state.showTooltip}
            text={t(this.props.tooltip)}
            className={styles['quantity-field-label']}
          >
            <label htmlFor={`${this.props.formId}-${this.props.name}`}>
              {t(this.props.label)}
            </label>
            {this.props.tooltip && (
              <SSRIcon
                icon={this.props.tooltipIcon || 'tooltip'}
                onClick={() =>
                  this.setState({ showTooltip: !this.state.showTooltip })
                }
              />
            )}
          </Tooltip>
        )}
        <QuantityStepper
          id={`${this.props.formId}-${this.props.name}`}
          defaultValue={Number(this.props.value)}
          minVal={Number(this.props.min)}
          maxVal={Number(this.props.max)}
          compact={Boolean(this.props.compact)}
          prefix={CONFIG.APP.STYLES_PREFIX}
          className={styles['quantity-field']}
          ariaLabelIncrease={
            (exists('forms.quantityIncrease') && t('forms.quantityIncrease')) ||
            'Increase quantity'
          }
          ariaLabelDecrease={
            (exists('forms.quantityDecrease') && t('forms.quantityDecrease')) ||
            'Decrease quantity'
          }
          onIncrease={this.onChange}
          onDecrease={this.onChange}
        />
      </div>
    );
  }
}

QuantityField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  formId: PropTypes.string,
  compact: PropTypes.bool
};

QuantityField.defaultProps = {
  value: '0',
  min: '0',
  max: '100'
};

export default QuantityField;
