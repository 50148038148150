import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  getProfileExternal,
  getRedirectURLForUpdateMobile
} from 'Redux/profile/profileThunks';
import { bindActionCreators } from 'redux';
import Routes from 'Routes/profileRoutes';
import { t } from 'Utils/localization/i18next';
import { Loading } from 'Common/Loading';

class DashboardEditMobileExternalContainer extends React.PureComponent {
  async componentDidMount() {
    if (!this.props.partyUid) {
      await this.props.getProfileExternal();
      const redirectURL = await this.props.getRedirectURLForUpdateMobile();
      if (redirectURL) {
        window.location = redirectURL;
      } else {
        window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.DASHBOARD}`;
      }
    } else {
      window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Loading isLoading text={t('loaders.otp')} />;
  }
}

DashboardEditMobileExternalContainer.propTypes = {
  partyUid: PropTypes.string,
  getProfileExternal: PropTypes.func.isRequired,
  getRedirectURLForUpdateMobile: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    partyUid: state.auth.partyUid
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getRedirectURLForUpdateMobile,
      getProfileExternal
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardEditMobileExternalContainer);
