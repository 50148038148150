export default class BazaarvoiceUASApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.BAZAARVOICE_UAS.HOST;
  }

  /**
   * Calls the API with user id to get User Authentication
   * String used for BazaarVoice ratings and reviews
   * @param  {String} userId id of the logged in user
   * @return {Promise} fetch promise
   */
  getBVLoginToken(userId, userEmail) {
    const tokenData = JSON.stringify({
      userId,
      userEmail
    });
    return fetch(`${this.apiUrl}/uastoken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: tokenData
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json.token;
      })
      .catch(e => {
        return '';
      });
  }
}
