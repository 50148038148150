import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  createDeliveryAddress,
  updateDeliveryAddress,
  deleteDeliveryAddress
} from 'Redux/addresses/addressesThunks';
import { t } from 'Utils/localization/i18next';
import { InlineAlert, Text, Toast } from 'Common';
import { clearFormAction } from 'Redux/forms/formsActions';
import DashboardDeliveryAddresses from 'Components/Dashboard/Address/DashboardDeliveryAddresses';
import DashboardAddDeliveryAddress from 'Components/Dashboard/Address/DashboardAddDeliveryAddress';

class DashboardDeliveryAddressFieldReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCreateSuccessMessage: false,
      showUpdateSuccessMessage: false,
      showDeleteSuccessMessage: false
    };
  }

  onCreate = async (data, successCallback, failureCallback) => {
    const { addForm } = this.props;
    const success = await this.props.createDeliveryAddress(data, addForm);

    if (!success) {
      if (failureCallback) {
        failureCallback();
      }
      return;
    }
    if (successCallback) {
      successCallback();
    }
    this.clearAndSetSuccessMessageState({ showCreateSuccessMessage: true });
  };

  onUpdate = async (data, successCallback, failureCallback) => {
    const { editForm } = this.props;
    const success = await this.props.updateDeliveryAddress(data, editForm);

    if (!success) {
      if (failureCallback) {
        failureCallback();
      }
      return;
    }
    if (successCallback) {
      successCallback();
    }
    this.clearAndSetSuccessMessageState({ showUpdateSuccessMessage: true });
  };

  onDelete = async (addressId, successCallback, failureCallback) => {
    const { editForm } = this.props;
    const success = await this.props.deleteDeliveryAddress(addressId, editForm);

    if (!success) {
      if (failureCallback) {
        failureCallback();
      }
      return;
    }
    if (successCallback) {
      successCallback();
    }
    this.clearAndSetSuccessMessageState({ showDeleteSuccessMessage: true });
  };

  clearAndSetSuccessMessageState = (partialState = {}) => {
    this.setState({
      showCreateSuccessMessage: false,
      showUpdateSuccessMessage: false,
      showDeleteSuccessMessage: false,
      ...partialState
    });
  };

  render() {
    const successMessage = this.state.showCreateSuccessMessage
      ? {
          title: t('dashboard.confirmation.addressDeliverySectionCreateTitle'),
          text: t('dashboard.confirmation.addressDeliverySectionCreateText')
        }
      : this.state.showUpdateSuccessMessage
      ? {
          title: t('dashboard.confirmation.addressDeliverySectionUpdateTitle'),
          text: t('dashboard.confirmation.addressDeliverySectionUpdateText')
        }
      : this.state.showDeleteSuccessMessage && {
          title: t('dashboard.confirmation.addressDeliverySectionDeleteTitle'),
          text: t('dashboard.confirmation.addressDeliverySectionDeleteText')
        };
    return (
      <React.Fragment>
        {successMessage && (
          <Toast
            autoCloseMs={5000}
            closeText={t('common.dismiss')}
            onClose={this.clearAndSetSuccessMessageState}
          >
            <Text bold>{successMessage.title}</Text>
            <Text>{successMessage.text}</Text>
          </Toast>
        )}
        {this.props.deliveryAddressesFailed ? (
          <InlineAlert
            title="dashboard.address.loadingFailedTitle"
            text="dashboard.address.loadingFailedText"
            marginBottom="m"
          />
        ) : (
          <DashboardDeliveryAddresses
            deliveryAddresses={this.props.deliveryAddresses}
            rows={this.props.rows}
            name={this.props.name}
            contactTypes={this.props.contactTypes}
            icon={this.props.icon}
            editForm={this.props.editForm}
            onUpdate={this.onUpdate}
            onDelete={this.onDelete}
            clearFormAction={this.props.clearFormAction}
            maskRules={this.props.maskRules}
          />
        )}
        <DashboardAddDeliveryAddress
          addForm={this.props.addForm}
          titleAdd={this.props.titleAdd}
          onCreate={this.onCreate}
          deliveryAddressesFailed={this.props.deliveryAddressesFailed}
          deliveryAddressesCount={this.props.deliveryAddresses.length}
        />
      </React.Fragment>
    );
  }
}

DashboardDeliveryAddressFieldReadContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  addForm: PropTypes.string,
  editForm: PropTypes.string,
  name: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  contactTypes: PropTypes.arrayOf(PropTypes.string),
  titleAdd: PropTypes.string,
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  deliveryAddressesFailed: PropTypes.bool,
  createDeliveryAddress: PropTypes.func.isRequired,
  updateDeliveryAddress: PropTypes.func.isRequired,
  deleteDeliveryAddress: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired,
  maskRules: PropTypes.object
};

const mapStateToProps = state => {
  return {
    deliveryAddressesFailed: state.addresses.deliveryAddressesFailed,
    deliveryAddresses: state.addresses.deliveryAddresses
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createDeliveryAddress,
      updateDeliveryAddress,
      deleteDeliveryAddress,
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDeliveryAddressFieldReadContainer);
