import { ACTIONS } from './verificationConstants';

export const setOTPStatusAction = (otpSent, from) => {
  return {
    type: ACTIONS.SET_OTP_STATUS,
    otpSent,
    from
  };
};

export const setOTPSentErrorAction = errorKey => {
  return {
    type: ACTIONS.SET_OTP_SENT_ERROR,
    errorKey
  };
};

export const setEmailStatusAction = emailSent => {
  return {
    type: ACTIONS.SET_EMAIL_STATUS,
    emailSent
  };
};

export const setLinkAccountAction = accountsLinked => {
  return {
    type: ACTIONS.SET_ACCOUNT_LINKED_STATUS,
    accountsLinked
  };
};

export const setEmailVerifiedAction = emailVerified => {
  return {
    type: ACTIONS.SET_EMAIL_VERIFIED_STATUS,
    emailVerified
  };
};

export const setLoginIdentifierAction = identifier => {
  return {
    type: ACTIONS.SET_LOGIN_IDENTIFIER,
    identifier
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
