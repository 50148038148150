import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Text, FlexPanel, Link, SSRIcon } from 'Common';
import styles from './LinkBox.scss';

export const LinkBox = props => (
  <Link url={props.url} className={styles['link-box']}>
    <FlexPanel className={styles['link-box-wrap']}>
      {props.icon && (
        <SSRIcon
          icon={props.icon}
          className={styles['link-box-icon']}
          size="200"
        />
      )}
      <div className={styles['link-box-content']}>
        {props.title && (
          <Text className={styles['link-box-title']}>{props.title}</Text>
        )}
        {props.text && (
          <Text smaller colour="grey-700" className={styles['link-box-text']}>
            {props.text}
          </Text>
        )}
      </div>
      <SSRIcon
        icon="arrowForward"
        className={
          CONFIG.FEATURE.RIGHT_TO_LEFT
            ? styles['link-box-arrow-left']
            : styles['link-box-arrow-right']
        }
      />
    </FlexPanel>
  </Link>
);

LinkBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
  to: PropTypes.string
};

export const LinkBoxPanel = props => {
  const linkBoxes = Array.isArray(props.children)
    ? props.children
    : [props.children];
  return (
    <Row>
      {linkBoxes.filter(Boolean).map((linkBox, index) => (
        <Col sm={12} md={4} key={`box-${index}`}>
          {linkBox}
        </Col>
      ))}
    </Row>
  );
};

LinkBoxPanel.propTypes = {
  boxSize: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

LinkBoxPanel.defaultProps = {
  boxSize: 4
};
