import React from 'react';
import PropTypes from 'prop-types';
import DashboardDeliveryAddress from 'Components/Dashboard/Address/DashboardDeliveryAddress';

class DashboardDeliveryAddresses extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addressBeingEdited: undefined
    };
  }

  setAddressBeingEdited = addressId => {
    this.props.clearFormAction(this.props.editForm);
    this.setState({ addressBeingEdited: addressId });
  };

  render() {
    return (
      this.props.deliveryAddresses.length > 0 &&
      this.props.deliveryAddresses.map(deliveryAddress => (
        <DashboardDeliveryAddress
          deliveryAddress={deliveryAddress}
          key={deliveryAddress.addressId}
          setAddressBeingEdited={this.setAddressBeingEdited}
          addressBeingEdited={this.state.addressBeingEdited}
          onUpdate={this.props.onUpdate}
          onDelete={this.props.onDelete}
          rows={this.props.rows}
          name={this.props.name}
          contactTypes={this.props.contactTypes}
          icon={this.props.icon}
          editForm={this.props.editForm}
          maskRules={this.props.maskRules}
        />
      ))
    );
  }
}

DashboardDeliveryAddresses.propTypes = {
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  name: PropTypes.arrayOf(PropTypes.string),
  contactTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  editForm: PropTypes.string,
  maskRules: PropTypes.object,
  clearFormAction: PropTypes.func.isRequired
};

export default DashboardDeliveryAddresses;
