/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldComponents } from 'ExtendedForm/DynamicFormRead/components';

const conditionFunctions = {
  hideFieldIfProfilePropertyHasValue: (
    fields,
    profile,
    { fieldRef, profileProp }
  ) => {
    return profile[profileProp] === undefined || profile[profileProp] === ''
      ? fields
      : fields.filter(field => field.ref !== fieldRef);
  }
};

const DashboardFieldsWithVisibilityConditionsContainer = props => {
  const getVisibleFields = () => {
    return props.conditions.reduce(
      (visibleFields, condition) => {
        return conditionFunctions[condition.name]
          ? conditionFunctions[condition.name](visibleFields, props.profile, {
              ...condition
            })
          : visibleFields;
      },
      [...props.fields]
    );
  };

  return (
    <React.Fragment>
      {getVisibleFields().map((field, i) => {
        const Component = FieldComponents[field.component];
        return (
          <Component
            key={`${props.formId}-conditioned-field-${i}`}
            {...field.componentProps}
          />
        );
      })}
    </React.Fragment>
  );
};

DashboardFieldsWithVisibilityConditionsContainer.propTypes = {
  formId: PropTypes.string.isRequired,
  // "fields" are objects containing props that can be used by any type
  // of FieldComponent available in DynamicFormRead/components.js.
  fields: PropTypes.arrayOf(PropTypes.object),
  conditions: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object
};

DashboardFieldsWithVisibilityConditionsContainer.defaultProps = {
  fields: [],
  conditions: [],
  profile: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardFieldsWithVisibilityConditionsContainer);
