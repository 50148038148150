import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DashboardBody from 'Components/Dashboard/DashboardBody';
import DashboardHeaderContainer from 'Components/Dashboard/DashboardHeaderContainer';
import DashboardVerificationBannerContainer from 'Components/Dashboard/DashboardVerificationBannerContainer';
import DynamicNotificationsContainer from 'Notifications/DynamicNotifications/DynamicNotificationsContainer';
import DynamicNotification from '../_notifications/DynamicNotification';
import DashboardContentPaneContainer from '../Dashboard/DashboardContentPaneContainer';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/carousel/style.scss';
import '@ingka/tabs/style.scss';
import '@ingka/link-list/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/tabs/style.rtl.scss';
import styles from './DashboardFamilyContainer.scss';

const slugify = inputString =>
  inputString
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const DashboardFamilyContainer = props => {
  const accountSettingsTabs =
    CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS?.ACCOUNT_SETTINGS;
  const projectsTabs =
    CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS?.CUSTOMER_PROJECTS;
  // eslint-disable-next-line no-return-assign
  return (
    <React.Fragment>
      <DashboardVerificationBannerContainer />
      <DashboardBody>
        {!window.location.pathname.includes('/projects') && (
          <>
            <div style={{ marginBottom: '0' }}>
              <DynamicNotificationsContainer
                id="family-dashboard-notifications"
                style={{ marginBottom: '3.5rem' }}
              />
            </div>
            <DynamicNotification
              allowEmail={props.allowEmail}
              allowSMS={props.allowSMS}
              allowDirectMail={props.allowDirectMail}
              allowPhone={props.allowPhone}
            />
          </>
        )}
        {accountSettingsTabs &&
          accountSettingsTabs.length > 0 &&
          window.location.pathname.endsWith('/dashboard/') &&
          accountSettingsTabs.map(item => (
            <div id={slugify(item)} key={item} className={styles.contentPane}>
              <DashboardHeaderContainer
                selectedMenuTab={
                  window.location.pathname.endsWith('/dashboard/') &&
                  'account-settings'
                }
              />
              <DashboardContentPaneContainer
                selectedMenuTab={
                  window.location.pathname.endsWith('/dashboard/') &&
                  'account-settings'
                }
              />
            </div>
          ))}
        {projectsTabs &&
          projectsTabs.length > 0 &&
          window.location.pathname.includes('/dashboard/projects/') &&
          projectsTabs.map(item => (
            <div id={slugify(item)} key={item} className={styles.contentPane}>
              <DashboardContentPaneContainer
                selectedMenuTab={
                  window.location.pathname.includes('/dashboard/projects/') &&
                  'projects'
                }
              />
            </div>
          ))}
      </DashboardBody>
    </React.Fragment>
  );
};

DashboardFamilyContainer.propTypes = {
  payments: PropTypes.array,
  allowEmail: PropTypes.bool,
  allowSMS: PropTypes.bool,
  allowDirectMail: PropTypes.bool,
  allowPhone: PropTypes.bool,
  isPaymentsEnabled: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object
};
const mapStateToProps = state => {
  const payments = state.payment.payments || [];
  return {
    allowEmail: state.profile.allowEmail,
    allowSMS: state.profile.allowSMS,
    allowDirectMail: state.profile.allowDirectMail,
    allowPhone: state.profile.allowPhone,
    payments,
    isPaymentsEnabled:
      CONFIG.FEATURE.FAMILY_DASHBOARD.ENABLE_PAYMENTS_TAB && payments.length > 0
  };
};

const DashboardFamilyContainerWithConnect = connect(
  mapStateToProps,
  null
)(DashboardFamilyContainer);

export default withRouter(DashboardFamilyContainerWithConnect);
