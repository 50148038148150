import React from 'react';
import PropTypes from 'prop-types';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';

const ZipCodeInputWithLink = props => (
  <React.Fragment>
    <InputFieldHandler
      formId={props.formId}
      name={props.name}
      value={props.value}
      label={props.label}
      maxLength={20}
      showOptional={props.showOptional}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
    <Link
      url={props.linkUrl}
      target={props.linkTarget}
      ssrIcon={props.linkIcon}
      iconPosition={props.linkIconPosition}
    >
      {t(props.linkLabel)}
    </Link>
  </React.Fragment>
);

ZipCodeInputWithLink.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  linkIcon: PropTypes.string,
  linkIconPosition: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  linkTarget: PropTypes.string,
  showOptional: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

ZipCodeInputWithLink.defaultProps = {
  linkTarget: '_blank'
};

export default ZipCodeInputWithLink;
