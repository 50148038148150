// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__dynamic-child-birthdate-input-wrap{display:flex;align-items:center}.profile__dynamic-child-birthdate-field{flex-grow:1}.profile__dynamic-child-birthdate-count{margin:0 1.25rem 1.25rem 0;min-width:6rem}.profile__dynamic-child-birthdate-divider{margin-top:0;margin-bottom:1.75rem}", "",{"version":3,"sources":["webpack://./src/components/_form_extended/DynamicChildBirthdateContainer.scss"],"names":[],"mappings":"AAGE,6CACE,YAAA,CACA,kBAAA,CAEF,wCACE,WAAA,CAEF,wCACE,0BAAA,CACA,cAAA,CAEF,0CACE,YAAA,CACA,qBAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.dynamic-child-birthdate {\n  &-input-wrap {\n    display: flex;\n    align-items: center;\n  }\n  &-field {\n    flex-grow: 1;\n  }\n  &-count {\n    margin: 0 $space-125 $space-125 0;\n    min-width: 6rem;\n  }\n  &-divider {\n    margin-top: 0;\n    margin-bottom: $space-125 + $space-50;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamic-child-birthdate-input-wrap": "profile__dynamic-child-birthdate-input-wrap",
	"dynamic-child-birthdate-field": "profile__dynamic-child-birthdate-field",
	"dynamic-child-birthdate-count": "profile__dynamic-child-birthdate-count",
	"dynamic-child-birthdate-divider": "profile__dynamic-child-birthdate-divider"
};
export default ___CSS_LOADER_EXPORT___;
