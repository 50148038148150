import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Text.scss';

export const Text = props => (
  <span
    id={props.id}
    style={props.style}
    onClick={e => {
      if (props.onClick) {
        props.onClick(e);
      }
    }}
    className={classNames({
      [styles['text-ltr']]: props.ltr,
      [styles['text-center']]: props.center,
      [styles['text-block']]: !props.inline,
      [styles['text-bold']]: props.bold,
      [styles['text-underline']]: props.underline,
      [styles['text-smaller']]: props.smaller,
      [styles['text-larger']]: props.larger,
      [styles['text-italic']]: props.italic,
      [styles[`text-colour-${props.colour}`]]: props.colour !== undefined,
      [styles[`text-margin-${props.margin}`]]: props.margin !== undefined,
      [styles[`text-margin-top-${props.marginTop}`]]:
        props.marginTop !== undefined,
      [styles[`text-margin-left-${props.marginLeft}`]]:
        props.marginLeft !== undefined,
      [styles[`text-margin-right-${props.marginRight}`]]:
        props.marginRight !== undefined,
      [styles[`text-margin-bottom-${props.marginBottom}`]]:
        props.marginBottom !== undefined,
      [props.className]: props.className
    })}
  >
    {props.smaller ? <small>{props.children}</small> : props.children}
  </span>
);

Text.propTypes = {
  id: PropTypes.string,
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  ltr: PropTypes.bool,
  underline: PropTypes.bool,
  italic: PropTypes.bool,
  center: PropTypes.bool,
  smaller: PropTypes.bool,
  larger: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  colour: PropTypes.oneOf([
    'grey-300',
    'grey-500',
    'grey-700',
    'grey-900',
    'brand-blue',
    'feedback-error',
    'feedback-confirmation',
    'semantic-caution'
  ]),
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
