import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';

const DashboardTranslatedFieldReadContainer = props => {
  const placeholder =
    props.names.length === 1 &&
    !props.profile[props.names[0]] &&
    t(`dashboard.placeholders.${props.names[0]}`);

  return placeholder ? (
    <Text marginTop={props.margin && 'xl'} colour="grey-500">
      {placeholder}
    </Text>
  ) : (
    <Text marginTop={props.margin && 'xl'}>
      {props.label && `${t(props.label)} `}
      {props.names.map(
        (name, index) =>
          props.profile[name] && (
            <React.Fragment key={index}>
              {t(`${props.keyPrefix}${props.profile[name]}`)}
            </React.Fragment>
          )
      )}
    </Text>
  );
};

DashboardTranslatedFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  profile: PropTypes.object,
  keyPrefix: PropTypes.string.isRequired,
  label: PropTypes.string,
  margin: PropTypes.bool
};

DashboardTranslatedFieldReadContainer.defaultProps = {
  names: ['Value not found!']
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardTranslatedFieldReadContainer);
