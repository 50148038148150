import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Link, Text, SSRIcon, Row, Col } from 'Common';
import styles from './LinkBoxLarge.scss';

export const LinkBoxLarge = props => (
  <Row className={props.className}>
    <Col sm={8}>
      <Link
        className={styles['link-box-large']}
        to={props.to}
        id={props.id}
        url={props.url}
      >
        {props.icon && <SSRIcon icon={props.icon} />}
        {props.title && <Text bold>{t(props.title)}</Text>}
        {props.text && <Text colour="grey-700">{t(props.text)}</Text>}
        <Text bold underline marginTop="xs">
          {t(props.linkText)}
        </Text>
      </Link>
    </Col>
  </Row>
);

LinkBoxLarge.propTypes = {
  id: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string
};
