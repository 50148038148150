import {
  validIKEAUrlPatterns,
  validIKEAUrlPatternsStricter,
  validRedirectWithoutTokenPatterns
} from './validIKEAUrls';

/**
 * Returns true/false if the provided string is a valid IKEA URL.
 * @param {string} string - The URL need to be validated.
 * @returns {boolean} If the URL is valid IKEA URL.
 */
export const isValidIKEAUrl = (url, useStrictValidation = false) => {
  if (!url) {
    return false;
  }
  let result = false;
  if (useStrictValidation) {
    result = validIKEAUrlPatternsStricter.some(pattern => pattern.test(url));
  } else {
    result = validIKEAUrlPatterns.some(pattern => pattern.test(url));
  }
  return result;
};

/**
 * Returns true/false if the provided url require token
 * @param {string} string - The URL need to be validated.
 * @returns {boolean} If the URL is valid IKEA URL.
 */
export const isTokenRequired = url => {
  if (!url) {
    return false;
  }
  return validRedirectWithoutTokenPatterns.some(pattern => !pattern.test(url));
};

/**
 * Returns the value of the query string name from the provided string.
 * @param {string} fullString full string
 * @param {string} queryStringName query string name
 */
export const getQueryStringValue = (fullString, queryStringName) => {
  const params = new URLSearchParams(fullString);
  return params.get(queryStringName);
};

/**
 * Returns the domain name from the provided url.
 * @param {string} url
 */
export const parseDomainFromUrl = url => {
  if (typeof url !== 'string') {
    throw new Error('url input must be a string');
  }
  const regex = /^https?:\/\/([\w-]+\.)*(\w+\.\w+)($|\/)/;
  const match = url.match(regex);
  return match ? match[2] : match;
};

/**
 * Appends a query param to the provided url string.
 * @param {string} url the original url
 * @param {string} key name of the query param to append
 * @param {string} value value of the query param to append
 */
export const appendQueryParamToUrl = (url, key, value) => {
  try {
    const urlObject = new URL(url);
    urlObject.searchParams.append(key, value);
    return urlObject.href;
  } catch (e) {
    return url;
  }
};

export const getPreviousUrl = () => {
  const previousUrl = document.referrer;
  if (
    previousUrl &&
    !previousUrl.includes('/profile/login') &&
    !previousUrl.includes('accounts.ikea') &&
    isValidIKEAUrl(previousUrl)
  ) {
    return previousUrl;
  }
  if (
    CONFIG.FEATURE.ENABLE_IKEA_FAMILY_HUB ||
    CONFIG.FEATURE.ENABLE_IKEA_REGULAR_HUB
  ) {
    return CONFIG.URL.IKEA_FAMILY_HUB;
  }
  return '';
};

const getModeAndSectionFromUrl = url => {
  const regex = /(?:\?|&)([^&=]+)(?:=([^&]*))/g;
  let match;
  const queryStrings = {};
  /* eslint-disable no-cond-assign */
  while ((match = regex.exec(url)) !== null) {
    queryStrings[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }
  return queryStrings;
};

export const isEditModeEnabled = (url, sectionValue) => {
  const { mode, section } = getModeAndSectionFromUrl(url);
  return (
    mode?.toLowerCase() === 'edit' && section?.toLowerCase() === sectionValue
  );
};
