import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, FlexPanel } from 'Common';
import mask from 'Utils/mask/mask';

const DashboardFieldsWithCommonPrefixReadContainer = props => {
  if (!props.valuesArray.length) {
    return null;
  }
  return (
    <React.Fragment>
      {props.valuesArray.map((value, index) => (
        <FlexPanel key={`${props.formId}-${props.namePrefix}-${index}`}>
          <Text>{mask(value, props.maskRules)}</Text>
        </FlexPanel>
      ))}
    </React.Fragment>
  );
};

DashboardFieldsWithCommonPrefixReadContainer.propTypes = {
  // From yml form configuration
  formId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  namePrefix: PropTypes.string.isRequired,
  maskRules: PropTypes.arrayOf(PropTypes.object),
  // From Redux
  valuesArray: PropTypes.arrayOf(PropTypes.any)
};

DashboardFieldsWithCommonPrefixReadContainer.defaultProps = {
  valuesArray: []
};

const mapStateToProps = (state, ownProps) => {
  const namesArray = Object.keys(state.profile).filter(name =>
    name.startsWith(ownProps.namePrefix)
  );
  const valuesArray = namesArray
    .reduce((values, name) => {
      values.push(state.profile[name]);
      return values;
    }, [])
    .filter(Boolean);

  return {
    valuesArray
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardFieldsWithCommonPrefixReadContainer);
