// Actions constants should be mapped in the same order
// as they are defined in the actions file.
export const ACTIONS = {
  SET_PROFILE: 'set_profile',
  SET_USER_ID: 'set_user_id',
  SET_COWORKER_LINKING_STATUS: 'set_coworker_linking_status',
  CLEAR_PROFILE: 'clear_profile'
};

export const PROFILE_TYPES = {
  REGULAR: 'regular',
  FAMILY: 'family',
  BUSINESS: 'business'
};
