import Auth0Client from './auth0/Auth0Client';
import CIAMApiClient from './ciam/CIAMApiClient';
import CIAMApiClientMock from './ciam/CIAMApiClientMock';
import IRWProfileApiClientV2 from './profile/IRWProfileApiClientV2';
import CMSProfileApiClient from './profile/CMSProfileApiClient';
import IRWProfileApiClientV2Mock from './profile/IRWProfileApiClientV2Mock';
import IRWAuthenticationApiClient from './auth/IRWAuthenticationApiClient';
import StoresApiClient from './stores/StoresApiClient';
import CIAMStoresApiClient from './stores/CIAMStoresApiClient';
import StoreEventsApiClient from './storeEvents/StoreEventsApiClient';
import OrderHistoryApiClient from './commerce/OrderHistoryApiClient';
import ShoppingApiClient from './commerce/ShoppingApiClient';
import OWShoppingListApiClient from './commerce/OWShoppingListApiClient';
import OWShoppingListApiClientMock from './commerce/OWShoppingListApiClientMock';
import BazaarvoiceUASApiClient from './bazaarvoice/BazaarvoiceUASApiClient';
import BazaarvoiceUASApiClientMock from './bazaarvoice/BazaarvoiceUASApiClientMock';
import { AddressApiClients } from './address';
import IRWWCSApiClient from './profile/IRWWCSApiClient';
import PaymentsApiClient from './payment/PaymentsApiClient';
import PaymentsApiClientMock from './payment/PaymentsApiClientMock';
import LoyaltyApiClient from './loyalty/LoyaltyApiClient';
import SalutationApiClient from './salutation/SalutationApiClient';
import ProfanityFilterApiClient from './profanityFilter/ProfanityFilterApiClient';
import DMPApiClient from './dmp/DMPApiClient';
import CaptchaApiClient from './captcha/CaptchaApiClient';
import PreferencesApiClient from './preferences/PreferencesApiClient';
import EventApiClient from './events/EventApiClient';

const profileClient = CONFIG.FEATURE.ENABLE_ICM_CMS
  ? new CMSProfileApiClient()
  : new IRWProfileApiClientV2();

const storesClient = CONFIG.FEATURE.ENABLE_CIAM_STORES_API
  ? new CIAMStoresApiClient()
  : new StoresApiClient();

export const ProfileService = CONFIG.USE_MOCKS
  ? new IRWProfileApiClientV2Mock()
  : profileClient;

const verificationClient = CONFIG.FEATURE.ENABLE_CIAM_API
  ? new CIAMApiClient()
  : new IRWWCSApiClient();

export const VerificationService = CONFIG.API.IRWWCS && verificationClient;

export const AuthService = new IRWAuthenticationApiClient();

export const Auth0Service = Auth0Client;

export const CIAMService = CONFIG.USE_MOCKS
  ? new CIAMApiClientMock()
  : new CIAMApiClient();

export const StoresService = storesClient;

export const StoreEventsService = new StoreEventsApiClient();

export const OrderHistoryService = new OrderHistoryApiClient();

export const ShoppingService = CONFIG.FEATURE.ENABLE_NEW_SHOPPING_LIST_API
  ? CONFIG.USE_MOCKS
    ? new OWShoppingListApiClientMock()
    : new OWShoppingListApiClient()
  : new ShoppingApiClient();

export const AddressAutocompleteService =
  AddressApiClients[CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER] &&
  new AddressApiClients[CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER]();

export const BazaarvoiceUASService = CONFIG.USE_MOCKS
  ? new BazaarvoiceUASApiClientMock()
  : new BazaarvoiceUASApiClient();

export const PaymentsService = CONFIG.USE_MOCKS
  ? new PaymentsApiClientMock()
  : new PaymentsApiClient();

export const LoyaltyService = new LoyaltyApiClient();

export const SalutationService = new SalutationApiClient();

export const ProfanityFilterService = new ProfanityFilterApiClient();

export const DMPService = new DMPApiClient();

export const CaptchaService = new CaptchaApiClient();

export const PreferencesService = new PreferencesApiClient();

export const EventService = new EventApiClient();
