import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mask from 'Utils/mask/mask';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';
import styles from './SectionReadContainer.scss';

const PersonalInformationFieldReadContainer = props => {
  const genderCode = () => {
    if (props.genderCode === 'N') {
      return 'genderCode_N';
    }
    if (props.genderCode === 'F') {
      return 'genderCode_F';
    }
    if (props.genderCode === 'M') {
      return 'genderCode_M';
    }
    if (props.genderCode === 'O') {
      return 'genderCode_O';
    }
    return 'genderCode_NOTSET';
  };

  const birthDateConverter = () => {
    const myConverter = props.birthDate.reduce((concatenated, name) => {
      const value = props.profile[name]
        ? `${mask(props.profile[name], props.maskRules)} `
        : '';
      return `${concatenated}${value}`;
    }, '');
    return myConverter;
  };

  const maskRulesController = Object.keys(props.maskRules).length;

  return (
    <FlexPanel>
      <Text>
        <div className={styles['personal-information-container']}>
          <div id="personal-information">
            {props.rows.map((row, index) => (
              <div
                key={index}
                className={styles['horizontal-container-stacked']}
              >
                <div className={styles['personal-information-value']}>
                  <div className={styles['common-specifier']}>
                    {row.map(specifier =>
                      props.profile[specifier] &&
                      specifier !== 'zipCode' &&
                      specifier === 'genderCode'
                        ? `${t(
                            `dashboard.profile-details.personal-information.${specifier}`
                          )}`
                        : specifier === 'birthDate'
                        ? `${t(
                            `dashboard.profile-details.personal-information.${specifier}`
                          )}`
                        : specifier === 'personalId'
                        ? `${t(
                            `dashboard.profile-details.personal-information.${specifier}`
                          )}`
                        : specifier === 'lastName'
                        ? `${t('')}`
                        : specifier === 'firstName'
                        ? `${t(
                            `dashboard.profile-details.personal-information.name`
                          )}`
                        : `${t('')}`
                    )}
                  </div>
                  {row.map(
                    item =>
                      props.profile[item] &&
                      (item === 'genderCode' || ''
                        ? `${t(`forms.${genderCode()}`)}`
                        : maskRulesController > 0 && item === 'birthDate'
                        ? props.maskRules.filter(birthDateFinder =>
                            birthDateFinder.name.includes('birthDate')
                          ).length > 0
                          ? birthDateConverter()
                          : `${mask(
                              props.profile[item],
                              props.maskRules[item]
                            )} `
                        : `${mask(
                            item === 'title'
                              ? `${t(
                                  `forms.namePrefix_${props.profile.title}`
                                )} `
                              : props.profile[item],
                            props.maskRules[item]
                          )} `)
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {!props.hideGenderCode && !props.profile.genderCode && (
          <>
            <div className={styles['common-specifier-gender']}>
              {`${t(
                `dashboard.profile-details.personal-information.genderCode`
              )} `}
            </div>
            <div className={styles['common-specifier-value-gender']}>-</div>
          </>
        )}
      </Text>
    </FlexPanel>
  );
};

PersonalInformationFieldReadContainer.propTypes = {
  birthDate: PropTypes.arrayOf(PropTypes.string),
  names: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  profile: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  maskRules: PropTypes.array,
  genderCode: PropTypes.string,
  hideGenderCode: PropTypes.bool
};

PersonalInformationFieldReadContainer.defaultProps = {
  names: [],
  maskRules: [],
  birthDate: [],
  hideGenderCode: false
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    genderCode: state.profile.genderCode
  };
};

export default connect(
  mapStateToProps,
  null
)(PersonalInformationFieldReadContainer);
