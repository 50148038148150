import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Text, TextWithLink } from 'Common';
import { t, exists } from 'Utils/localization/i18next';

export const TextWithModal = props => {
  const onTextWrapClick = e => {
    // Prevent triggering onClick when user clicks "open modal" link.
    e.preventDefault();
  };

  const onTextClick = props.onTextClick
    ? e => {
        // Prevent triggering onClick when user clicks "open modal" link.
        e.stopPropagation();
        e.preventDefault();
        props.onTextClick();
      }
    : undefined;

  const {
    id,
    textBeforeLink,
    textLink,
    textAfterLink,
    textLinkLineBreak,
    modalButtonRedirectLink,
    modalButtonRedirectText,
    modalButtonCloseText,
    modalTitle,
    modalText1,
    modalText2,
    modalLink,
    modalLinkTextBefore,
    modalLinkTextAfter,
    modalLinkText,
    className
  } = props;

  const modalButtons = [];
  if (exists(modalButtonCloseText)) {
    modalButtons.push({
      id: `${id}-close-btn`,
      key: '1',
      htmlType: 'button',
      text: t(modalButtonCloseText),
      type: 'filled',
      onClick: (e, close) => {
        e.preventDefault();
        close();
      }
    });
  }
  if (modalButtonRedirectText) {
    modalButtons.push({
      id: `${id}-link-btn`,
      key: '2',
      htmlType: 'button',
      text: t(modalButtonRedirectText),
      type: 'primary',
      onClick: e => {
        e.preventDefault();
        window.open(modalButtonRedirectLink, '_blank');
      }
    });
  }

  return (
    <div onClick={onTextWrapClick} className={className}>
      {exists(textBeforeLink) && (
        <Text inline={!textLinkLineBreak} onClick={onTextClick}>
          {t(textBeforeLink)}{' '}
        </Text>
      )}
      <Modal
        modalType={props.modalType || 'informational'}
        title={exists(modalTitle) && t(modalTitle)}
        linkId={`${id}-link`}
        linkText={t(textLink)}
        linkStyle={{ textDecoration: 'underline' }}
        modalBtns={modalButtons.length ? modalButtons : undefined}
      >
        {exists(modalText1) && <Text marginTop="m">{t(modalText1)}</Text>}
        {exists(modalText2) && (
          <Text marginTop="m" marginBottom="m">
            {t(modalText2)}
          </Text>
        )}
        {modalLink && (
          <TextWithLink
            labelBeforeLink={t(modalLinkTextBefore)}
            labelAfterLink={t(modalLinkTextAfter)}
            labelLink={t(modalLinkText)}
            link={t(modalLink)}
            linkTarget="_blank"
          >
            {t(modalLinkText)}
          </TextWithLink>
        )}
        {props.children}
      </Modal>
      {exists(textAfterLink) && (
        <Text inline onClick={onTextClick}>
          {t(textAfterLink)}
        </Text>
      )}
    </div>
  );
};

TextWithModal.propTypes = {
  id: PropTypes.string.isRequired,
  textBeforeLink: PropTypes.string,
  textLink: PropTypes.string.isRequired,
  textAfterLink: PropTypes.string,
  textLinkLineBreak: PropTypes.bool,
  // Modal buttons.
  modalButtonRedirectLink: PropTypes.string,
  modalButtonRedirectText: PropTypes.string,
  modalButtonCloseText: PropTypes.string,
  // Modal content.
  modalTitle: PropTypes.string,
  modalText1: PropTypes.string,
  modalText2: PropTypes.string,
  modalLink: PropTypes.string,
  modalLinkTextBefore: PropTypes.string,
  modalLinkTextAfter: PropTypes.string,
  modalLinkText: PropTypes.string,
  modalType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // Used for triggering event on text click
  // (not triggered when user clicks on the "open modal" link).
  onTextClick: PropTypes.func,
  className: PropTypes.string
};
