import { ACTIONS } from './addressesConstants';

export const setDeliveryAddressesAction = deliveryAddresses => {
  return {
    type: ACTIONS.SET_DELIVERY_ADDRESSES,
    deliveryAddresses
  };
};

export const deleteDeliveryAddressAction = deliveryAddressId => {
  return {
    type: ACTIONS.DELETE_DELIVERY_ADDRESS,
    deliveryAddressId
  };
};

export const setDeliveryAddressesFailedAction = () => {
  return {
    type: ACTIONS.SET_DELIVERY_ADDRESSES_FAILED
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
