import React from 'react';
import PropTypes from 'prop-types';
import IkeaToast from '@ingka/toast';
import classNames from 'classnames';
import '@ingka/button/dist/style.css';
import '@ingka/toast/dist/style.css';
import styles from './Toast.scss';
import '@ingka/toast/style.rtl.scss';

export class Toast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
  }

  componentDidMount() {
    if (this.props.autoCloseMs) {
      setTimeout(() => this.onClose(), this.props.autoCloseMs);
    }
  }

  onClose = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  render() {
    return (
      <IkeaToast
        id={this.props.id}
        isOpen={this.state.isOpen}
        onCloseRequest={this.onClose}
        text={this.props.text}
        prefix={CONFIG.APP.STYLES_PREFIX}
        className={classNames(styles.toast, this.props.className)}
      >
        {this.props.children}
      </IkeaToast>
    );
  }
}

Toast.propTypes = {
  id: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  closable: PropTypes.bool,
  closeText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  autoCloseMs: PropTypes.number,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Toast.defaultProps = {
  closable: true
};
