import { readAuthTokenCookie } from 'Utils/auth/authUtils';
import { deleteWcCookies } from 'Utils/cookies/cookiesUtils';
import { ReasonCodesAuth } from 'Services/helper/IRWReasonCodes';
import * as loginUtils from 'Services/auth/loginUtils';
import * as IRWUtils from 'Services/helper/IRWUtils';
import { LoginRequest } from 'Services/helper/requestModels';
import { ResponseError } from 'Services/helper/models';
import { ErrorTypes } from 'Services/helper/ErrorTypes';
import { ErrorCodes } from 'Services/helper/ErrorCodes';
import { isUTCDateInPast } from 'Utils/date/dateUtils';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import JwtDecode from 'jwt-decode';
import { LoginResponse } from './models';
import { Auth0Service, ProfileService } from 'Services/ServiceFactory';

export default class IRWAuthenticationApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    this.apiSourceSystem = 'irw';
    this.apiUrl = `${API.IRW.HOST}/retail/iows/${COUNTRY}/${LANGUAGE}/customer`;
    this.headers = {
      Accept: 'application/vnd.ikea.api+json;version=2',
      Consumer: 'OWPRLO',
      Contract: '42427',
      ...(CONFIG.FEATURE.INTERNAL_LOGIN && { 'X-language': LANGUAGE })
    };
  }

  login({ username, password }) {
    return new Promise((resolve, reject) => {
      // Start by clearing cookies. Old session can interfere and cause the
      // response to return 200 with empty auth reason
      //deleteWcCookies(this.apiUrl); commentted
      const requestBody = new LoginRequest(username, password);
      const bodyData = `password=${encodeURIComponent(password)}`;
      let retObj = {
        response: {
          authenticated: false
        },
        request: { username, password }
      };
      if (requestBody.valid) {
        username = (username || '').trim();
        // Special for Indian login with phone as username, we
        // need to strip the country code
        if (username.startsWith('+91')) {
          username = username.substring(3);
        }
        if (username.startsWith('91') && username.length === 12) {
          username = username.substring(2);
        }
        const authTokenCookie = readAuthTokenCookie();
        const encodedUserName = encodeURIComponent(username);
        fetch(`${this.apiUrl}/${this.apiSourceSystem}/${encodedUserName}`, {
          method: 'POST',
          headers: {
            ...this.headers,
            ...(!CONFIG.FEATURE.INTERNAL_LOGIN &&
              authTokenCookie && {
                Authorization: `Bearer ${authTokenCookie}`
              }),
            Accept: 'application/vnd.ikea.api+json;version=1',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          ...(CONFIG.FEATURE.INTERNAL_LOGIN && {
            credentials: 'include'
          }),
          body: bodyData
        })
          .then(response => {
            if (!response.ok) {
              const error = IRWUtils.fromHttpError(response.status);
              retObj.response.errors = [error];
              reject(retObj);
            }
            // Resolve response body
            return response.json();
          })
          .then(body => {
            const reasonCode = body.authReason.reasonCode;

            if (reasonCode === ReasonCodesAuth.AUTH_OK) {
              retObj.response.authenticated = true;
              resolve(retObj);
            } else if (reasonCode === ReasonCodesAuth.AUTH_FAIL_PWD_EXPIRED) {
              resolve({ passwordExpired: true });
            } else {
              const error = loginUtils.reasonCodeToErrorObj(reasonCode);
              retObj.response.errors = [error];
              reject(retObj);
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            reject({ response: error });
          });
      } else {
        let error = new ResponseError(
          ErrorTypes.INTERNAL_DATA,
          ErrorCodes.INTERNAL_DATA_CREDENTIALS_EMPTY
        );
        retObj.response.errors = [error];
        reject(retObj);
      }
    });
  }

  resetPassword(data) {
    const bodyData = JSON.stringify({
      userId: data.username,
      distributionForm: data.sendPassMethod
    });
    return fetch(`${this.apiUrl}/password`, {
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json'
      },
      body: bodyData
    })
      .then(result => {
        if (!result.ok) {
          const err = new Error(
            'HTTP error with status code: ' + result.status
          );
          err.httpStatus = result.status;
          throw err;
        }
        return result.json();
      })
      .then(json => {
        if (json.authFailReasonList && json.authFailReasonList.length > 0) {
          const reasonCode = json.authFailReasonList[0].reasonCode;
          if (reasonCode === ReasonCodesAuth.PASSWORD_RESET_OK) {
            return true;
          } else {
            const err = new Error('Password reset failed: ' + reasonCode);
            err.httpStatus = 200;
            err.reasonCode = reasonCode;
            throw err;
          }
        } else {
          const err = new Error('Password reset failed, reason code missing');
          err.httpStatus = 200;
          throw err;
        }
      });
  }

  isAuthenticated() {
    return new Promise(resolve => {
      const response = new LoginResponse();
      response.authenticated = false;
      const authToken = readAuthTokenCookie();
      const decodedToken = authToken && JwtDecode(authToken);
      if (authToken && !isUTCDateInPast(decodedToken.exp)) {
        const partyUid = decodedToken['https://accounts.ikea.com/partyUId'];
        ProfileService.getProfileGraphql(partyUid)
          .then(user => {
            if (user) {
              resolve(ProfileService.extractUserInfo(user, decodedToken));
            } else {
              resolve(response);
            }
          })
          .catch(err => {
            console.log('Get user info failed with ' + err);
            // TODO::: resolve the line below when the logging is properly introduced in the application
            // logger.error('Get user info failed with ' + err);
            resolve(response);
          });
      } else {
        resolve(response);
      }
    });
  }

  refreshToken() {
    return new Promise(resolve => {
      const response = new LoginResponse();
      response.authenticated = false;
      Auth0Service.refreshToken()
        .then(authResult => {
          const user =
            authResult && authResult.idToken && JwtDecode(authResult.idToken);
          if (user) {
            resolve(this.extractUserInfo(user));
          } else {
            resolve(response);
          }
        })
        .catch(err => {
          console.log('Refresh token failed with ' + err);
          resolve(response);
        });
    });
  }

  getLoyaltyProgram(loyaltyPrograms, customerType) {
    if (customerType && customerType === 'business') {
      return PROFILE_TYPES.BUSINESS;
    }
    if (loyaltyPrograms && loyaltyPrograms.length) {
      switch (loyaltyPrograms[0]) {
        case 'IKEA_FAMILY':
          return PROFILE_TYPES.FAMILY;
        default:
          return PROFILE_TYPES.REGULAR;
      }
    }
    return PROFILE_TYPES.REGULAR;
  }

  extractUserInfo(user) {
    let response = new LoginResponse();
    const memberId = user['https://accounts.ikea.com/memberId'];
    const email =
      `ciam_dummy_${memberId}@ikea.com` === user.email ? '' : user.email;
    const mobile = user['https://accounts.ikea.com/CM_SMS'];
    const phoneVerified =
      user['https://accounts.ikea.com/CM_SMSVerificationStatus'];
    response.authenticated = true;

    response.user = {
      userId: memberId,
      userLogin: email ? email : mobile,
      email,
      mobile,
      partyUid: user['https://accounts.ikea.com/partyUId'],
      userName: user.name,
      phoneVerified,
      emailVerified:
        user['https://accounts.ikea.com/isEmailVerified'] ||
        user.email_verified,
      profileType: this.getLoyaltyProgram(
        user['https://accounts.ikea.com/loyaltyPrograms'],
        user['https://accounts.ikea.com/customerType']
      ),
      identifier:
        !email && mobile && phoneVerified === 'VERIFIED' ? 'mobile' : 'email',
      employeeId: user['https://accounts.ikea.com/employeeID']
    };
    return response;
  }

  /**
   * Set a new password
   *
   * @param {obj} data
   * data contains:
   * - userId
   * - currentPassword
   * - newPassword
   * - newPasswordRetype
   */
  setNewPassword(data) {
    const contract = {
      Consumer: 'OWPRLO',
      Contract: '42427'
    };
    return new Promise((resolve, reject) => {
      // Ugly hack for India until the MAMMUT consumer has rights for this
      // endpoint. Using the credentials for the family application. This
      // is only safe to do here since India is the only current user of
      // this API client.
      const authTokenCookie = readAuthTokenCookie();
      const { COUNTRY, LANGUAGE, API } = CONFIG;
      const cmsServiceUrl = `${API.CMS.HOST}/api/profile/iows/${COUNTRY}/${LANGUAGE}/password`;
      const irwServiceUrl = `${this.apiUrl}/password?authErrors=true`;
      const headers = {
        Accept: this.headers.Accept,
        ...(CONFIG.FEATURE.INTERNAL_LOGIN && { 'X-language': LANGUAGE }),
        ...(!CONFIG.FEATURE.ENABLE_IRW_CMS && contract),
        ...(CONFIG.FEATURE.ENABLE_IRW_CMS && {
          'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`
        }),
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      };

      const userId = data.userLogin ? data.userLogin : data.userId;

      const bodyObj = {
        userId,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        newPasswordRetype: data.newPasswordRetype
      };

      // Call fails with empty auth reason unless cookies are wiped first
      // deleteWcCookies(this.apiUrl);

      fetch(CONFIG.FEATURE.ENABLE_IRW_CMS ? cmsServiceUrl : irwServiceUrl, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        ...(CONFIG.FEATURE.INTERNAL_LOGIN && {
          credentials: 'include'
        }),
        body: JSON.stringify(bodyObj)
      })
        .then(response => {
          if (response.status === 200) {
            resolve({ response: {}, request: data });
          } else {
            // Handle HTTP response errors
            return response.json();
          }
        })
        .then(json => {
          if (
            json &&
            json.authFailReasonList &&
            json.authFailReasonList.length
          ) {
            const reasonCode = json.authFailReasonList[0].reasonCode;
            const error = loginUtils.reasonCodeToErrorObj(reasonCode);
            console.log('irw error1 ', error, ' reasonCode ', reasonCode);
            reject({ response: { errors: [error] } });
          }
          if (json && json.authReason) {
            const reasonCode = json.authReason.reasonCode;
            const error = loginUtils.reasonCodeToErrorObj(reasonCode);
            console.log('irw error2 ', error, ' reasonCode ', reasonCode);
            reject({ response: { errors: [error] } });
          }
        });
    });
  }

  verify(data) {
    return this.login(data);
  }

  logout() {
    const authTokenCookie = readAuthTokenCookie();
    return new Promise((resolve, reject) => {
      fetch(`${this.apiUrl}/sessioncontext`, {
        method: 'DELETE',
        headers: {
          ...this.headers,
          Accept: 'application/vnd.ikea.api+json;version=2', // Calling sessioncontext with DELETE verb only supported in version 2.
          ...(!CONFIG.FEATURE.INTERNAL_LOGIN &&
            authTokenCookie && {
              Authorization: `Bearer ${authTokenCookie}`
            })
        },
        ...(CONFIG.FEATURE.INTERNAL_LOGIN && {
          credentials: 'include'
        })
      })
        .then(() => {
          deleteWcCookies(this.apiUrl);
          resolve();
        })
        .catch(error => {
          // Delete cookies even if API call failed
          deleteWcCookies(this.apiUrl);
        });
    });
  }

  /**
   * The "getIRWProfileSession" function gets the IRW backend user session which is identified by the "WCS" set of cookies lives on the browser side.
   * The session tracking is required by few services (please add more services if they need the IRW session to work)
   *    - "Order History" service.
   *    - "Shopping List" service.
   *    - "sessioncontext V2" service (delete operation - used on the logout and delete profile)
   * Once all services listed above are independent in the session tracking, this function should be removed.
   */
  getIRWProfileSession(mergeCart = false) {
    if (mergeCart) {
      const authTokenCookie = readAuthTokenCookie();
      const headers = {
        ...this.headers,
        Accept: 'application/vnd.ikea.api+json;version=1',
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      };
      const url = `${this.apiUrl}/irw/sessionContext?includeEmail=true&mergeCart=true`;
      return fetch(url, {
        method: 'GET',
        headers: headers,
        credentials: 'include'
      })
        .then(gscResponse => {
          return gscResponse.json();
        })
        .catch(error => {});
    } else {
      const { COUNTRY, LANGUAGE, API } = CONFIG;
      const apiConfigs = CONFIG.ENV === 'development' && {
        baseUrl: API.IRW.HOST,
        retailId: COUNTRY,
        languageCode: LANGUAGE
      };
      return new Promise((resolve, reject) => {
        window.ikea.getSession((error, session) => {
          if (error) {
            reject(error);
          }
          resolve(session);
        }, apiConfigs);
      }).catch(error => {});
    }
  }
}
