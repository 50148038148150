// Actions constants should be mapped in the same order
// as they are defined in the actions file.
export const ACTIONS = {
  SET_FORM_FIELD_VALUE: 'set_form_field_value',
  SET_FORM_FIELD_MESSAGES: 'set_form_field_messages',
  SET_FORM_VALUES: 'set_form_values',
  SET_FORM_ERRORS: 'set_form_errors',
  SET_FORM_DISABLE_SUBMIT: 'set_form_disable_submit',
  CLEAR_FORM: 'clear_form',
  CLEAR_FORM_MESSAGES: 'clear_form_messages'
};

export const FORM_KEY_SUFFIX = {
  MESSAGES: '-messages',
  VALUES: '-values',
  ERRORS: '-errors',
  SUBMIT: '-submit'
};
