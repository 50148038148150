import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Button } from 'Common';
import Address from 'ExtendedForm/Address/Address';
import { AddressAutocompleteService } from 'Services/ServiceFactory';

class AddressWithDaumPostalService extends React.PureComponent {
  onSearchClick = e => {
    e.preventDefault();
    AddressAutocompleteService.init(data => this.onValuesChange(data));
  };

  onValuesChange = values => {
    if (values) {
      Object.keys(values).forEach(name => {
        this.props.onChange({
          target: {
            name,
            value: values[name]
          }
        });

        if (this.props.messages[name] && values[name]) {
          // Generally, we wait and let the "DynamicForm" validate the fields
          // when user submits the full form. However, in order to get rid of
          // any existing error messages as the user enters valid data, we do
          // validation here if the field had an error message before autofill.
          this.props.onBlur({
            target: {
              name,
              value: values[name]
            }
          });
        }
      });
    }
  };

  render() {
    return (
      <div>
        <Button
          marginBottom="m"
          type="emphasised"
          text={t('forms.addressSearch')}
          onClick={this.onSearchClick}
        />
        <Address
          formId={this.props.formId}
          nestedFields={this.props.nestedFields}
          values={this.props.values}
          messages={this.props.messages}
          checkMandatory={this.props.checkMandatory}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

AddressWithDaumPostalService.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  checkMandatory: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddressWithDaumPostalService;
