// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__update-password{min-height:50vh;padding-top:6rem !important;padding-bottom:6rem !important}@media(max-width: 576px){.profile__update-password{padding-top:2rem !important;padding-bottom:2rem !important}.profile__update-password-left{padding-right:6rem}}", "",{"version":3,"sources":["webpack://./src/components/Password/UpdatePasswordContainer.scss"],"names":[],"mappings":"AAEA,0BACE,eAAA,CACA,2BAAA,CACA,8BAAA,CAGF,yBACE,0BACE,2BAAA,CACA,8BAAA,CACA,+BACE,kBAAA,CAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.update-password {\n  min-height: 50vh;\n  padding-top: 6rem !important;\n  padding-bottom: 6rem !important;\n}\n\n@media (max-width: 576px) {\n  .update-password {\n    padding-top: 2rem !important;\n    padding-bottom: 2rem !important;\n    &-left {\n      padding-right: 6rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"update-password": "profile__update-password",
	"update-password-left": "profile__update-password-left"
};
export default ___CSS_LOADER_EXPORT___;
