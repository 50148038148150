import { VerificationService, Auth0Service } from 'Services/ServiceFactory';
import {
  setOTPStatusAction,
  setOTPSentErrorAction
} from 'Redux/verification/verificationActions';
import {
  getRedirectURLForPhoneVerification,
  getRedirectURLForEmailVerification
} from 'Redux/profile/profileThunks';
import { ReduxStore } from 'Redux';
import Routes from 'Routes/profileRoutes';
import { push } from 'connected-react-router';
import { mapBackendException } from 'Utils/errors/errorUtils';

export const sendOTP = data => {
  return async dispatch => {
    try {
      const { otpSentStatus, otpSentError } = await VerificationService.sendOTP(
        data.phone,
        data.partyUid
      );
      dispatch(setOTPStatusAction(otpSentStatus));
      if (otpSentError) {
        const errorKeys = mapBackendException(otpSentError);
        dispatch(setOTPSentErrorAction(errorKeys[0]));
      }
    } catch (e) {
      dispatch(setOTPStatusAction(false));
    }
  };
};

export const sendEmail = () => {
  return async dispatch => {
    const redirectURL = await dispatch(getRedirectURLForEmailVerification());
    if (redirectURL) {
      window.location = redirectURL;
    } else {
      dispatch(push(Routes.DASHBOARD));
    }
  };
};

export const verifyPhone = () => {
  return async dispatch => {
    const { partyUid } = ReduxStore.getState().auth;
    const { mobile } = ReduxStore.getState().profile;
    if (CONFIG.FEATURE.ENABLE_CIAM_API) {
      const redirectURL = await dispatch(getRedirectURLForPhoneVerification());
      if (redirectURL) {
        window.location = redirectURL;
      } else {
        dispatch(setOTPStatusAction(false, 'dashboard'));
        dispatch(push(Routes.DASHBOARD));
      }
    } else {
      Auth0Service.verifyPhone(mobile, partyUid);
    }
  };
};
