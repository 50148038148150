import { ErrorCodes } from 'Services/helper/ErrorCodes';
import { ErrorTypes } from 'Services/helper/ErrorTypes';
import { ResponseError } from 'Services/helper/models';
import { ReasonCodesAuth } from 'Services/helper/IRWReasonCodes';

/**
 * Maps reason (error) code from IRW to a type + code error that
 * is independent from the error structure for this specific client.
 * Note that this mapping is only valid for the post authentication
 * call.
 */
export const reasonCodeToErrorObj = (reasonCode, additional) => {
  return new ResponseError(
    ...reasonCodeToErrorCodes(reasonCode, additional),
    additional
  );
};

function reasonCodeToErrorCodes(reasonCode, additional) {
  switch (reasonCode) {
    case ReasonCodesAuth.BAD_REQUEST:
      return [ErrorTypes.ACCESS, ErrorCodes.ACCESS_USER_MISSING];
    case ReasonCodesAuth.AUTH_FAIL_CREDENTIALS:
      return [ErrorTypes.ACCESS, ErrorCodes.ACCESS_WRONG_CREDENTIALS];
    case ReasonCodesAuth.AUTH_FAIL_PWD_EXPIRED:
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_PASSWORD_EXPIRED];
    case ReasonCodesAuth.ACCOUNT_UNVERIFIED:
      // If additional data is sent along, it is a phone nr that needs to be verified
      if (additional) {
        return [
          ErrorTypes.VERIFICATION,
          ErrorCodes.VERIFICATION_UNVERIFIED_MOBILE
        ];
      } else {
        return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_MISSING_MOBILE];
      }
    case ReasonCodesAuth.ACCOUNT_MISSING_EMAIL:
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_MISSING_EMAIL];
    case ReasonCodesAuth.ACCOUNT_VERIFICATION_FAILED:
      return [ErrorTypes.VERIFICATION, ErrorCodes.VERIFICATION_FAILED];
    case ReasonCodesAuth.ACCOUNT_VERIFICATION_CODE_EXPIRED:
      return [ErrorTypes.VERIFICATION, ErrorCodes.VERIFICATION_COOLDOWN];
    case ReasonCodesAuth.ACCOUNT_NEW_VERSION_PRIVACY_POLICY:
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_MISSING_POLICY];
    case ReasonCodesAuth.ACCOUNT_NEW_VERSION_TERMS_AND_CONDITIONS:
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_MISSING_TERMS];
    case ReasonCodesAuth.ACCOUNT_LOCKED:
      return [ErrorTypes.ACCESS, ErrorCodes.ACCOUNT_LOCKED];
    case ReasonCodesAuth.ACCOUNT_PAUSED_GDPR:
      return [ErrorTypes.ACCESS, ErrorCodes.ACCOUNT_PAUSED_GDPR];
    case ReasonCodesAuth.PASSWORD_TOO_WEAK:
      return [ErrorTypes.ACCESS, ErrorCodes.PASSWORD_TOO_WEAK];
    case ReasonCodesAuth.PASSWORD_PREVIOUSLY_USED:
      return [ErrorTypes.ACCESS, ErrorCodes.PASSWORD_PREVIOUSLY_USED];
    case ReasonCodesAuth.PASSWORD_TOO_COMMON:
      return [ErrorTypes.ACCESS, ErrorCodes.PASSWORD_TOO_COMMON];
    case ReasonCodesAuth.PASSWORD_CONTAINS_USER_INFO:
      return [ErrorTypes.ACCESS, ErrorCodes.PASSWORD_CONTAINS_USER_INFO];
    case ReasonCodesAuth.PWD_INCORRECT:
      return [ErrorTypes.ACCESS, ErrorCodes.PWD_INCORRECT];
    default:
      return [
        ErrorTypes.UNKNOWN,
        ErrorCodes.UNKNOWN,
        'Unmapped reason code ' + reasonCode
      ];
  }
}
