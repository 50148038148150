import {
  transformFields,
  transformValues,
  transformOptions
} from './DaDataTransformers';

/**
 * DaData address data provider that can be used for address suggestions.
 *
 * Read more about the DaData API:
 * https://dadata.ru/en/
 *
 */

export default class DaDataApiClient {
  constructor() {
    this.apiUrl = 'https://suggestions.ikea.ru';
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
  }

  /**
   * Calls the API with the provided query string.
   *
   * "containerId" parameters are not used by this service,
   * but kept in order keep interface consistent with other address services.
   *
   * @param  {String} searchQuery the search string
   * @param  {String} containerId not used by this service
   * @param  {String} maxResults max results to show
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async complete(
    searchQuery,
    containerId,
    maxResults,
    successCallback,
    failureCallback
  ) {
    const result = await fetch(
      `${this.apiUrl}/suggestions/api/4_1/rs/suggest/address`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({ query: searchQuery, count: maxResults })
      }
    );

    if (!result.ok) {
      failureCallback();
      return;
    }

    const json = await result.json();
    const transformed = transformOptions(json, searchQuery);
    this.currentCompletePayload = json;

    if (!transformed) {
      failureCallback();
      return;
    }
    successCallback(transformed);
  }

  /**
   * Previous "complete" call contains the full address information,
   * hence no additional API call is required.
   *
   * The "searchQuery" and "captureId" parameter is not used by this service,
   * but kept in order keep interface consistent with other address services.
   *
   * @param  {String} searchQuery  the search string
   * @param  {String} captureId id not used by this service
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async capture(searchQuery, captureId, successCallback, failureCallback) {
    const json = this.currentCompletePayload;
    const transformed1 = transformFields(json, this.addressFieldMap);
    const transformed2 = transformValues(transformed1, this.addressValueMap);

    if (!transformed2) {
      failureCallback();
      return;
    }
    successCallback(transformed2);
  }
}
