import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Button } from 'Common';

const PillGroup = props => {
  const onPillClick = value => {
    const currentValues = props.value;
    const newValues = props.value.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    props.onChange({
      target: {
        name: props.name,
        value: newValues
      }
    });
  };

  return (
    <div>
      <label
        style={{ display: 'block', marginBottom: '0.5rem' }}
        htmlFor={`${props.formId}-${props.name}`}
      >
        {t(props.label)}
      </label>

      {props.options.map(option => (
        <Button
          id={option.value}
          small
          key={option.value}
          type={props.value.includes(option.value) ? 'primary' : 'secondary'}
          text={t(option.label)}
          style={{
            height: '2.75rem',
            fontWeight: 'normal',
            margin: '0.5rem 0.5rem 0 0'
          }}
          onClick={() => onPillClick(option.value)}
        />
      ))}
    </div>
  );
};

PillGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // Array of { label: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  // Array of selected option values e.g [61, 62, 74]
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formId: PropTypes.string
};

PillGroup.defaultProps = {
  value: [],
  options: []
};

export default PillGroup;
