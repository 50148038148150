import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { availableCountryCodeMap } from 'ExtendedForm/PhoneInput/countryCodes';
import DropdownControlled from 'BaseForm/DropdownControlled';

class CountryDropdown extends React.PureComponent {
  componentDidMount() {
    if (!this.props.value) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: CONFIG.COUNTRY.toUpperCase()
        }
      });
    }
  }

  render() {
    return (
      <DropdownControlled
        formId={this.props.formId}
        name={this.props.name}
        label={this.props.label}
        showOptional={this.props.showOptional}
        value={this.props.value}
        removeHintText={true}
        disabled={this.props.disabled}
        options={(this.props.sortAcceptedCountries
          ? this.props.acceptedCountries.sort((country1, country2) =>
              t(`countries.${country1}`).localeCompare(
                t(`countries.${country2}`)
              )
            )
          : this.props.acceptedCountries
        ).map(country => {
          return {
            name: t(`countries.${country}`),
            value: country
          };
        })}
        topOptions={this.props.favoriteCountries.map(country => {
          return {
            name: t(`countries.${country}`),
            value: country
          };
        })}
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
      />
    );
  }
}

CountryDropdown.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  acceptedCountries: PropTypes.arrayOf(PropTypes.string), // For example: ["CA", "US"]
  favoriteCountries: PropTypes.arrayOf(PropTypes.string), // For example: ["CA", "US"]
  showOptional: PropTypes.bool,
  sortAcceptedCountries: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

CountryDropdown.defaultProps = {
  sortAcceptedCountries: true,
  acceptedCountries: Object.keys(availableCountryCodeMap),
  favoriteCountries: []
};

export default CountryDropdown;
