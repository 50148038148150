export function transformFields(
  response,
  addressFieldMap,
  addressFieldValueMap
) {
  if (!response || !response['address_components']) {
    return {};
  }

  const addressComponents = response['address_components'];

  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const googleFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!googleFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = googleFieldNames.reduce(
      (concatenatedValue, googleFieldName) => {
        // Look for a separator config between address text, separator starts with { and ends with }
        // Example: {/} between apartment name and apartment number should look like apartment name/apartment number
        const separatorMatch = googleFieldName.match(/^\{(.*)\}$/);

        if (separatorMatch) {
          nextSeparator = separatorMatch[1];
          return concatenatedValue;
        }

        const addressComponent =
          addressComponents.find(component =>
            component.types.includes(googleFieldName)
          ) || {};

        const value =
          (addressFieldValueMap &&
            addressComponent[addressFieldValueMap[fieldName]]) ||
          addressComponent['long_name'] ||
          addressComponent['short_name'] ||
          '';
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (mappedValue.includes(prevValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  return data;
}

export function transformOptions(response = []) {
  let options = [];

  for (let i = 0; i < response.length; i++) {
    options.push({
      id: response[i]['place_id'],
      label: response[i].description,
      isCapturable: true,
      isCompleteAddress: response[i].types.includes('street_address')
    });
  }
  return options;
}
