import { ACTIONS } from './storesConstants';

export const setStores = stores => {
  return {
    type: ACTIONS.SET_STORES,
    stores
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
