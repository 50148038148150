import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, H2, Link, FlexPanel, Toast, Button } from 'Common';
import { t } from 'Utils/localization/i18next';
import analytics from 'Utils/analytics/analyticsWrapper';
import { getRedirectURLForUpdateMobile } from 'Redux/profile/profileThunks';
import SSRIcon from '@ingka/ssr-icon';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './SectionReadContainer.scss';

class MobileSectionReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showErrorMessage: false };
  }

  onEdit = async () => {
    const redirectURL = await this.props.getRedirectURLForUpdateMobile();
    if (redirectURL) {
      analytics.userProfileFormUpdate({
        status: 'started',
        form: `${this.props.profileType}-dashboard-change-mobile`
      });
      window.location = redirectURL;
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showErrorMessage && (
          <Toast
            autoCloseMs={5000}
            closeText={t('common.dismiss')}
            onClose={() => this.setState({ showErrorMessage: false })}
          >
            <Text bold>{t('forms.errors.title')}</Text>
            <Text>- {t('forms.errors.generic')}</Text>
          </Toast>
        )}
        <div className={styles['dashboard-section-read']}>
          <FlexPanel spread className={styles['dashboard-section-read-title']}>
            <H2 tagName="h2" headingSize="m" margin={false}>
              {t(this.props.title)}
            </H2>
            <Button
              className={styles['edit-link']}
              small
              type="tertiary"
              id={`${this.props.editForm}-link`}
              onClick={this.onEdit}
            >
              <SSRIcon colour="black" paths={pencil} />
              {t('common.edit')}
            </Button>
            <Link
              bold
              colour="grey-900"
              className={styles['edit-link-mobile']}
              id={`${this.props.editForm}-link`}
              onClick={this.onEdit}
            >
              <SSRIcon colour="black" paths={pencil} />
            </Link>
          </FlexPanel>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

MobileSectionReadContainer.propTypes = {
  profileType: PropTypes.string,
  title: PropTypes.string,
  editForm: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // From Redux/Thunks
  getRedirectURLForUpdateMobile: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profileType: state.profile.profileType
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getRedirectURLForUpdateMobile }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileSectionReadContainer);
