// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__inline-text-with-icon-trailing,.profile__inline-text-with-icon-leading{display:inline-flex;align-items:center}.profile__inline-text-with-icon-trailing-space-between,.profile__inline-text-with-icon-leading-space-between{width:100%;justify-content:space-between}.profile__inline-text-with-icon-trailing svg,.profile__inline-text-with-icon-leading svg{flex-shrink:0}.profile__inline-text-with-icon-trailing svg{margin-left:.5rem}.profile__inline-text-with-icon-leading svg{margin-right:.5rem}", "",{"version":3,"sources":["webpack://./src/components/_common/InlineText.scss"],"names":[],"mappings":"AAAA,iFAEE,mBAAA,CACA,kBAAA,CAEA,6GACE,UAAA,CACA,6BAAA,CAGF,yFACE,aAAA,CAKF,6CACE,iBAAA,CAKF,4CACE,kBAAA","sourcesContent":[".inline-text-with-icon-trailing,\n.inline-text-with-icon-leading {\n  display: inline-flex;\n  align-items: center;\n\n  &-space-between {\n    width: 100%;\n    justify-content: space-between;\n  }\n\n  svg {\n    flex-shrink: 0;\n  }\n}\n\n.inline-text-with-icon-trailing {\n  svg {\n    margin-left: 0.5rem;\n  }\n}\n\n.inline-text-with-icon-leading {\n  svg {\n    margin-right: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline-text-with-icon-trailing": "profile__inline-text-with-icon-trailing",
	"inline-text-with-icon-leading": "profile__inline-text-with-icon-leading",
	"inline-text-with-icon-trailing-space-between": "profile__inline-text-with-icon-trailing-space-between",
	"inline-text-with-icon-leading-space-between": "profile__inline-text-with-icon-leading-space-between"
};
export default ___CSS_LOADER_EXPORT___;
