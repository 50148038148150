/* eslint-disable react/jsx-key */
import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Row, Col, Link } from 'Common';
import FormField from 'BaseForm/FormField';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import DropdownControlled from 'BaseForm/DropdownControlled';
import QuantityField from 'BaseForm/QuantityField';
import ZipCodeInput from 'ExtendedForm/ZipCodeInput';
import ZipCodeInputWithLink from 'ExtendedForm/ZipCodeInputWithLink';
import DropdownWithPresetValueAsOption from 'ExtendedForm/DropdownWithPresetValueAsOption';
import styles from './Address.scss';

const Components = {
  InputField: InputFieldHandler,
  Dropdown: DropdownControlled,
  DropdownWithPresetValueAsOption,
  QuantityField,
  ZipCodeInput,
  ZipCodeInputWithLink
};

export class AddressTwoFieldsInARow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { nestedFields, values } = this.props;
    const addressLineFields = nestedFields.filter(field =>
      field.componentProps.name.startsWith('address')
    );
    const maxAddressLines = addressLineFields.length;

    // By default there will be one visible address line field,
    // but if form is prefilled with values, we need to display
    // all address line fields that has a value.
    const visibleAddressLines =
      addressLineFields.filter(
        field =>
          field.componentProps.visible ||
          (values[field.componentProps.name] &&
            values[field.componentProps.name].length)
      ).length || 1;
    return { maxAddressLines, visibleAddressLines };
  };

  addAddressLine = () => {
    this.setState({ visibleAddressLines: this.state.visibleAddressLines + 1 });
  };

  render() {
    let isShowingAddAddressLine;
    return (
      <div className={this.props.className}>
        <Row className={styles['horizontal-container-stacked']}>
          {this.props.fields.map((field, index) => {
            const Component = Components[field.component];
            const { name } = field.componentProps;
            const { hidden } = field.componentProps;
            let showComponent = !hidden;
            let showAddAddressLine = false;

            if (name.startsWith('address')) {
              // The component is an address line input field
              const nbr = parseInt(name.substring(7));
              showComponent = nbr <= this.state.visibleAddressLines;
              showAddAddressLine = !showComponent;
            }
            if (showComponent) {
              return (
                <Col sm={field.colWidth}>
                  <FormField
                    validation={{
                      id: `${this.props.formId}-${name}-error`,
                      msg: this.props.messages[name],
                      type: 'error'
                    }}
                    shouldValidate={this.props.messages[name] !== undefined}
                  >
                    <Component
                      {...field.componentProps}
                      formId={this.props.formId}
                      value={this.props.values[name]}
                      showOptional={!this.props.checkMandatory(name)}
                      onBlur={this.props.onBlur}
                      onChange={this.props.onChange}
                    />
                  </FormField>
                </Col>
              );
            }
            if (showAddAddressLine && !isShowingAddAddressLine) {
              isShowingAddAddressLine = true;
              return (
                <Link
                  key={`address-${index}`}
                  className={styles['add-address-line']}
                  onClick={this.addAddressLine}
                >
                  {t('forms.addressAddLine')}
                </Link>
              );
            }
            return null;
          })}
        </Row>
      </div>
    );
  }
}

AddressTwoFieldsInARow.propTypes = {
  formId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  checkMandatory: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

AddressTwoFieldsInARow.defaultProps = {
  nestedFields: [],
  values: {}
};

export default AddressTwoFieldsInARow;
