import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RadioButtonGroup from 'BaseForm/RadioButtonGroup';
import { getFieldValue } from 'Redux/forms/formsHelper';

const InterestCodeRadioButton = props => (
  <RadioButtonGroup
    formId={props.formId}
    name={`interest-code-${props.interestCode}`}
    label={props.label}
    value={props.selectedInterestCodes.includes(props.interestCode) ? '1' : '0'}
    options={[
      { label: props.optInLabel, value: '1' },
      { label: props.optOutLabel, value: '0' }
    ]}
    showOptional={props.showOptional}
    onBlur={props.onBlur}
    onChange={e => {
      const selected = e.target.value === '1';
      const newSelectedInterestCodes = selected
        ? [...props.selectedInterestCodes, props.interestCode]
        : props.selectedInterestCodes.filter(
            code => code !== props.interestCode
          );
      props.onChange({
        target: { name: 'interestCodes', value: newSelectedInterestCodes }
      });
    }}
  />
);

InterestCodeRadioButton.propTypes = {
  formId: PropTypes.string,
  label: PropTypes.string,
  interestCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optInLabel: PropTypes.string,
  optOutLabel: PropTypes.string,
  showOptional: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // From Redux
  selectedInterestCodes: PropTypes.array
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedInterestCodes: getFieldValue(
      state,
      ownProps.formId,
      'interestCodes'
    )
  };
};

export default connect(
  mapStateToProps,
  null
)(InterestCodeRadioButton);
