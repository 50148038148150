export function transformFields(response, addressFieldMap) {
  if (response.Status !== 'OK') {
    return false;
  }
  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const locateFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!locateFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = locateFieldNames.reduce(
      (concatenatedValue, locateFieldName) => {
        // Look for a separator config between address text, separator starts with { and ends with }
        // Example: {/} between apartment name and apartment number should look like apartment name/apartment number
        const separatorMatch = locateFieldName.match(/^\{(.*)\}$/);

        if (separatorMatch) {
          nextSeparator = separatorMatch[1];
          return concatenatedValue;
        }

        const value = response.output[locateFieldName];
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap || !data) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (mappedValue.includes(prevValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  return data;
}

export function transformOptions(response) {
  if (response.Status !== 'OK') {
    return false;
  }

  let options = [];

  for (let i = 0; i < response.output.length; i++) {
    options.push({
      id: response.metadata[i].Id,
      label: response.output[i],
      isCapturable: response.metadata[i].TotalRecordCount === 1,
      recordCount: response.metadata[i].TotalRecordCount
    });
  }
  return options;
}
