import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Modal } from 'Common';
import { t } from 'Utils/localization/i18next';
import classNames from 'classnames';
import {
  removePayment,
  setAsDefaultPayment
} from 'Redux/payment/paymentThunks';
import styles from './PaymentBox.scss';

class PaymentBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  getExpiryInfo = () => {
    const cardYear = this.paymentInfo.paymentCardDetails.expiryYear;
    return (
      <p>
        <span
          className={classNames({
            [styles.Expired]: this.expired
          })}
        >
          {this.expired
            ? `${t('dashboard.payment.expired')} XX-${cardYear}`
            : `${t('dashboard.payment.expires')} XX.${cardYear}`}
        </span>
        {this.paymentInfo.isPreferred && (
          <span className={styles.FlexItem}>{` - ${t(
            'dashboard.payment.default'
          )}`}</span>
        )}
      </p>
    );
  };

  isExpired = (year, month) => {
    return new Date() >= new Date(year, month - 1);
  };

  removePayment = () => {
    this.props.removePayment(this.paymentInfo.id);
  };

  render() {
    this.paymentInfo = this.props.payment;
    // eslint-disable-next-line global-require
    this.paymentIcon = require(`./images/_payment-${this.paymentInfo.paymentBrandName.toLowerCase()}.svg`); // eslint-disable-line import/no-dynamic-require
    this.expired = this.isExpired(
      this.paymentInfo.paymentCardDetails.expiryYear,
      this.paymentInfo.paymentCardDetails.expiryMonth
    );
    return (
      <div className={styles.FlexContainer}>
        {this.state.isSetDefaultLoading && (
          <div className={styles.PaymentBoxLoadingWrap}>
            <div className={styles.PaymentBoxLoading}>
              <div
                className={classNames(
                  `${CONFIG.APP.STYLES_PREFIX}loading__ball`,
                  `${CONFIG.APP.STYLES_PREFIX}loading__ball--active`
                )}
              ></div>
            </div>
          </div>
        )}
        <div className={styles.FlexPaymentInfo}>
          <span className={styles.FlexItem}>
            <span>
              {t('dashboard.payment.cardDetail', {
                brandname: this.paymentInfo.paymentBrandName,
                cardnumber: this.paymentInfo.paymentCardDetails.last4Digits
              })}
            </span>
            {/* <span>{`${this.paymentInfo.paymentBrandName} Card ending with ${this.paymentInfo.paymentCardDetails.last4Digits}`}</span> */}
            <br />
            {this.getExpiryInfo()}
          </span>
          <span
            className={classNames(
              styles.FlexItem,
              styles.PaymentOperationBlock
            )}
          >
            <Modal
              modalType="alert"
              title={t('dashboard.payment.removePaymentHeader')}
              className={styles.ModalWindow}
              linkId={this.props.id}
              linkText={t('dashboard.payment.remove')}
              linkStyle={{
                marginRight: '1rem',
                fontWeight: '700',
                color: 'black',
                textDecoration: 'underline'
              }}
              modalBtns={[
                {
                  id: this.props.id,
                  key: this.props.id,
                  htmlType: 'button',
                  text: t('dashboard.payment.remove'),
                  loading: this.state.isLoading,
                  type: 'primary',
                  onClick: (e, close) => {
                    e.preventDefault();
                    this.setState({ isLoading: true });
                    this.props.removePayment(this.paymentInfo.id, () => {
                      this.setState({ isLoading: false }, () => {
                        close();
                      });
                    });
                  }
                }
              ]}
            >
              <p>{t('dashboard.payment.removePaymentText')}</p>
            </Modal>
            {!this.paymentInfo.isPreferred && !this.expired && (
              <Link
                colour="grey-900"
                bold
                underline
                onClick={() => {
                  this.setState({ isSetDefaultLoading: true });
                  this.props.setAsDefaultPayment(this.paymentInfo.id, () => {
                    this.setState({ isSetDefaultLoading: false });
                  });
                }}
              >
                {t('dashboard.payment.setDefault')}
              </Link>
            )}
          </span>
        </div>
        <div
          className={classNames({
            [styles.FlexItem]: true,
            [styles.FlexItemCenterRight]: true
          })}
        >
          <img className={styles.PaymentIcon} src={this.paymentIcon} />
        </div>
      </div>
    );
  }
}

PaymentBox.defaultProps = {
  isLoading: false
};

PaymentBox.propTypes = {
  removePayment: PropTypes.func,
  setAsDefaultPayment: PropTypes.func,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  payment: PropTypes.PropTypes.shape({
    id: PropTypes.string,
    isPreferred: PropTypes.bool,
    psp: PropTypes.string,
    paymentBrandName: PropTypes.string.isRequired,
    optionCategory: PropTypes.string,
    paymentCardDetails: PropTypes.PropTypes.shape({
      last4Digits: PropTypes.string.isRequired || PropTypes.number.isRequired,
      expiryMonth: PropTypes.string.isRequired || PropTypes.number.isRequired,
      expiryYear: PropTypes.string.isRequired || PropTypes.number.isRequired
    })
  })
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      removePayment,
      setAsDefaultPayment
    },
    dispatch
  );
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(PaymentBox);
