import { ReduxStore } from 'Redux';
import { StoresService, StoreEventsService } from 'Services/ServiceFactory';
import { setStores } from './storesActions';

export const getStores = () => {
  return async dispatch => {
    const { stores } = ReduxStore.getState().stores;

    if (stores.length > 0) {
      return;
    }

    try {
      const storesResponse = await StoresService.getStores();
      dispatch(setStores(storesResponse));
    } catch (e) {
      // TODO: Handle get stores failure
      dispatch(setStores([]));
    }
  };
};

export const getStoresFromStoreEvents = () => {
  return async () => {
    try {
      return await StoreEventsService.getStores();
    } catch (e) {
      return [];
    }
  };
};
