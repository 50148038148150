import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Row, Col, Text } from 'Common';
import classNames from 'classnames';
import styles from './ImageBlockSelector.scss';

const ImageBlockSelector = props => {
  const propagateChangeEvent = value => {
    props.onChange({
      target: { name: props.name, value }
    });
  };

  const isSelected = option => {
    if (props.type === 'radiobutton') {
      return props.value === option.value;
    }
    const valueArr = props.value || [];
    return valueArr.some(value => value === option.value);
  };

  const onClick = option => {
    if (props.type === 'radiobutton') {
      propagateChangeEvent(option.value);
      return;
    }
    const valueArr = props.value || [];
    const maxSelection = props.maxSelection || props.options.length;
    const prevSelected = valueArr.some(value => value === option.value);

    if (!prevSelected && valueArr.length + 1 > maxSelection) {
      return;
    }
    const newValueArr = prevSelected
      ? valueArr.filter(value => value !== option.value)
      : [...valueArr, option.value];

    propagateChangeEvent(newValueArr);
  };

  return (
    <div className={styles['image-block-selector']}>
      {props.label && <label>{t(props.label)}</label>}
      <Row center={props.center}>
        {props.options.map(option => (
          <ImageBlock
            {...option}
            key={option.value}
            colWidth={props.imageColWidth}
            isSelected={isSelected(option)}
            onClick={() => onClick(option)}
          >
            {t(option.label)}
          </ImageBlock>
        ))}
      </Row>
    </div>
  );
};

ImageBlockSelector.propTypes = {
  // You can specify if the component should behave as a
  // radiobutton or checkbox group. If radiobutton, the value
  // will be a single selected value. If checkbox the value will
  // be an array of selected values. maxSelection is used when
  // type is radiobutton.
  type: PropTypes.oneOf(['radiobutton', 'checkbox']),
  maxSelection: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  // Array of { label: "", value: "", image: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  imageColWidth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  center: PropTypes.bool
};

ImageBlockSelector.defaultProps = {
  type: 'radiobutton',
  options: [],
  imageColWidth: 4
};

const ImageBlock = props => (
  <Col sm={props.colWidth}>
    <div
      name={props.name}
      value={props.value}
      className={classNames(styles['image-block-selector-image-block'], {
        [styles['image-block-selector-image-selected']]: props.isSelected
      })}
      onClick={props.onClick}
    >
      <img
        src={props.image}
        alt={t(props.label)}
        className={styles['image-block-selector-image']}
      />
      {props.label && (
        <Text smaller center className={styles['image-block-selector-text']}>
          {t(props.label)}
        </Text>
      )}
      <div className={styles['image-block-selector-selection-check']}>
        {props.isSelected && props.selectedImage && (
          <img src={props.selectedImage} />
        )}
        {!props.isSelected && props.notSelectedImage && (
          <img src={props.notSelectedImage} />
        )}
      </div>
    </div>
  </Col>
);

ImageBlock.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  isSelected: PropTypes.bool,
  selectedImage: PropTypes.node,
  notSelectedImage: PropTypes.node,
  colWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string
};

export default ImageBlockSelector;
