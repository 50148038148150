import * as stringUtils from 'Utils/string/stringUtils';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { getIRWResidenceType } from './transformers';

export function getProfileResponse(data) {
  return {
    cardNo: data.loyaltyProgram.loyaltyCardList[0].cardNo,
    // customerid is icmpartyuid
    customerId: data.customerRef.customerId,
    profileType: PROFILE_TYPES.FAMILY
  };
}

export function registerProfileRequest(data) {
  const transformed = {
    crmCustomerProfile: {
      personalIdType: data.personalIdType,
      personalId: data.personalId,
      personalIdSource: data.personalIdSource,
      documentId: data.documentId,
      noOfChildren: data.noOfChildren,
      privateMaintCustomer: {
        namePrefix: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        firstNamePhonetic: data.firstNamePhonetic,
        lastNamePhonetic: data.lastNamePhonetic,
        secondLastName: data.lastName2,
        languageCode: data.preferredLanguage,
        birthDate:
          data.birthDate &&
          stringUtils.reverseString(data.birthDate, '-').concat('Z'),
        genderCode: data.genderCode,
        contactMethodList: [
          {
            contactMethodType: 'MOBILE',
            contactMethodData: data.mobile
          },
          {
            contactMethodType: 'EMAIL',
            contactMethodData: data.email ? data.email.toLowerCase() : ''
          },
          {
            contactMethodType: 'PHONE',
            contactMethodData: data.landline
          },
          {
            contactMethodType: 'ADDRESS',
            address: {
              cityName: data.cityName,
              zipCode: data.zipCode,
              provinceCode: data.province,
              countryCode: data.addressCountryCode,
              formattedAddress: {
                address1: data.address1,
                address2: data.address2,
                address3: data.address3
              }
            }
          }
        ]
      },
      CrmCustomerIntegrity: {
        NotAllowPersonalEmailCode:
          data.allowEmail === true
            ? 0
            : data.allowEmail === false
            ? 1
            : undefined,
        NotAllowSms:
          data.allowSMS === true ? 0 : data.allowSMS === false ? 1 : undefined,
        NotAllowSharingToSocialMedia:
          data.allowSharingToSocialMedia === true
            ? 0
            : data.allowSharingToSocialMedia === false
            ? 1
            : undefined,
        NotAllowFindPotentialCustomers:
          data.allowFindPotentialCustomers === true
            ? 0
            : data.allowFindPotentialCustomers === false
            ? 1
            : undefined,
        NotAllowAppPushNotification:
          data.allowAppPushNotification === true
            ? 0
            : data.allowAppPushNotification === false
            ? 1
            : undefined,
        NotAllowDirectMailCode:
          data.allowDirectMail === true
            ? 0
            : data.allowDirectMail === false
            ? 1
            : undefined,
        NotAllowCollectBuyInfoCode:
          data.allowCollectBuyInfo === true
            ? 0
            : data.allowCollectBuyInfo === false
            ? 1
            : undefined,
        NotAllowPhoneContactCode:
          data.allowPhone === true
            ? 0
            : data.allowPhone === false
            ? 1
            : undefined,
        OptOutPlasticFamilyCard:
          data.sendFamilyPlasticCard === true
            ? 0
            : data.sendFamilyPlasticCard === false
            ? 1
            : undefined
      },
      CrmCustomerChildList: buildChildList(data),
      preferredBuCode: data.preferredStore,
      preferredBuType: 'STO',
      socialSecurityNo: data.socialSecurityNumber,
      residenceInfo: {
        noOfRooms: data.noOfRooms,
        coExistenceInfo: data.coExistenceInfo,
        homeOwnershipIndicator: data.homeOwnership
      }
    },
    profileType: 'FAMILY',
    authenticationContext: {
      userId: data.username.toLowerCase(),
      userIdRetype: data.username.toLowerCase(),
      password: data.password,
      passwordRetype: data.password
    },
    agreeToIKEAPolicy: data.acceptPrivacyPolicy,
    ...(data.captchaId && {
      captcha: {
        captchaId: data.captchaId
      }
    })
  };
  return transformed;
}

export function updateProfileRequest(currentProfile, updatedProfile) {
  const transformed = {
    crmCustomerProfile: {
      personalIdType: updatedProfile.personalIdType,
      personalId: updatedProfile.personalId,
      personalIdSource: updatedProfile.personalIdSource,
      documentId: updatedProfile.documentId,
      noOfChildren: updatedProfile.noOfChildren,
      educationLevel: updatedProfile.educationLevel,
      maritalStatusCode: updatedProfile.maritalStatus,
      privateMaintCustomer: {
        namePrefix: updatedProfile.title,
        firstName: updatedProfile.firstName,
        lastName: updatedProfile.lastName,
        firstNamePhonetic: updatedProfile.firstNamePhonetic,
        lastNamePhonetic: updatedProfile.lastNamePhonetic,
        secondLastName: updatedProfile.lastName2,
        languageCode: updatedProfile.preferredLanguage,
        countryCode: CONFIG.COUNTRY.toUpperCase(),
        birthDate: updatedProfile.birthDate
          ? CONFIG.FEATURE.ENABLE_ICM_CMS
            ? updatedProfile.birthDate
            : stringUtils
                .reverseString(updatedProfile.birthDate, '-')
                .concat('Z')
          : undefined,
        genderCode: updatedProfile.genderCode,
        contactMethodList: buildContactMethods(updatedProfile)
      },
      // The below check is required to send the customer integrity values only when it is updated.
      // Sending it without real update is considered as an update from the IRW side which is against the consents update requirements.
      ...(isCustomerIntegrityChanged(currentProfile, updatedProfile) && {
        crmCustomerIntegrity: {
          ...(isIntegrityValueChanged(
            currentProfile.allowEmail,
            updatedProfile.allowEmail
          ) && {
            notAllowPersonalEmailCode:
              updatedProfile.allowEmail === true
                ? 0
                : updatedProfile.allowEmail === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowSMS,
            updatedProfile.allowSMS
          ) && {
            notAllowSms:
              updatedProfile.allowSMS === true
                ? 0
                : updatedProfile.allowSMS === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowSharingToSocialMedia,
            updatedProfile.allowSharingToSocialMedia
          ) && {
            notAllowSharingToSocialMedia:
              updatedProfile.allowSharingToSocialMedia === true
                ? 0
                : updatedProfile.allowSharingToSocialMedia === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowFindPotentialCustomers,
            updatedProfile.allowFindPotentialCustomers
          ) && {
            notAllowFindPotentialCustomers:
              updatedProfile.allowFindPotentialCustomers === true
                ? 0
                : updatedProfile.allowFindPotentialCustomers === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowAppPushNotification,
            updatedProfile.allowAppPushNotification
          ) && {
            notAllowAppPushNotification:
              updatedProfile.allowAppPushNotification === true
                ? 0
                : updatedProfile.allowAppPushNotification === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowDirectMail,
            updatedProfile.allowDirectMail
          ) && {
            notAllowDirectMailCode:
              updatedProfile.allowDirectMail === true
                ? 0
                : updatedProfile.allowDirectMail === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowCollectBuyInfo,
            updatedProfile.allowCollectBuyInfo
          ) && {
            notAllowCollectBuyInfoCode:
              updatedProfile.allowCollectBuyInfo === true
                ? 0
                : updatedProfile.allowCollectBuyInfo === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.allowPhone,
            updatedProfile.allowPhone
          ) && {
            notAllowPhoneContactCode:
              updatedProfile.allowPhone === true
                ? 0
                : updatedProfile.allowPhone === false
                ? 1
                : undefined
          }),
          ...(isIntegrityValueChanged(
            currentProfile.sendFamilyPlasticCard,
            updatedProfile.sendFamilyPlasticCard
          ) && {
            OptOutPlasticFamilyCard:
              updatedProfile.sendFamilyPlasticCard === true
                ? 0
                : updatedProfile.sendFamilyPlasticCard === false
                ? 1
                : undefined
          })
        }
      }),
      crmInterestAreaGroupList: [
        {
          interestAreaGroupType: 'InterestCodes',
          crmInterestAreaList: buildInterests(
            updatedProfile.interestCodes,
            updatedProfile.participateInMemberPanel,
            updatedProfile.subscribeToIKEACatalogue
          )
        }
      ],
      CrmCustomerChildList: buildChildList(updatedProfile),
      preferredBuCode: updatedProfile.preferredStore,
      preferredBuType: 'STO',
      socialSecurityNo: updatedProfile.socialSecurityNumber,
      residenceInfo: {
        id: updatedProfile.residenceId,
        noOfRooms: updatedProfile.noOfRooms,
        coExistenceInfo: updatedProfile.coExistenceInfo,
        homeOwnershipIndicator: updatedProfile.homeOwnership,
        residenceType: getIRWResidenceType(
          updatedProfile.residenceType,
          updatedProfile.outdoorType
        ),
        holidayHomeOwnership: updatedProfile.holidayHomeOwnership,
        livingSpaceUnit: updatedProfile.livingSpace
      }
    },
    profileType: 'FAMILY',
    agreeToIKEAPolicy: updatedProfile.acceptPrivacyPolicy,
    agreeToIKEATerms: updatedProfile.agreeToIKEATerms
  };
  return transformed;
}

function buildInterests(
  interestCodes,
  participateInMemberPanel,
  subscribeToIKEACatalogue
) {
  let interestList = [];
  if (participateInMemberPanel) {
    interestList.push({ interestAreaCode: '60' });
  }
  if (subscribeToIKEACatalogue) {
    interestList.push({ interestAreaCode: '90' });
  }
  if (interestCodes) {
    interestCodes.forEach(interestCode => {
      interestList.push({
        interestAreaCode: interestCode
      });
    });
  }
  return interestList;
}

function buildContactMethods(updatedProfile) {
  let contactMethods = [];
  const extractHouseNumber = CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER;
  contactMethods.push({
    contactMethodType: 'MOBILE',
    contactMethodData: updatedProfile.mobile
  });
  contactMethods.push({
    contactMethodType: 'EMAIL',
    contactMethodData: updatedProfile.email
  });
  contactMethods.push({
    contactMethodType: 'PHONE',
    contactMethodData: updatedProfile.landline
  });
  (updatedProfile.address1 || updatedProfile.zipCode) &&
    contactMethods.push({
      contactMethodType: 'ADDRESS',
      contactMethodRoleList: [{ contactMethodRoleId: 'INVOICE_DEST' }],
      address: {
        cityName: updatedProfile.cityName,
        zipCode: updatedProfile.zipCode,
        provinceCode: updatedProfile.province,
        countryCode:
          updatedProfile.addressCountryCode || CONFIG.COUNTRY.toUpperCase(),
        addressId: updatedProfile.addressId,
        formattedAddress: {
          address1: extractHouseNumber
            ? updatedProfile.address1
                .trim()
                .concat(` ${updatedProfile.houseNumber}`)
            : updatedProfile.address1,
          address2: updatedProfile.address2,
          address3: updatedProfile.address3
        }
      }
    });
  updatedProfile.deliveryAddresses &&
    updatedProfile.deliveryAddresses.forEach(deliveryAddress => {
      contactMethods.push({
        contactMethodType: 'ADDRESS',
        contactMethodRoleList: [{ contactMethodRoleId: 'DELIVERY_DEST' }],
        address: {
          cityName: deliveryAddress.cityName,
          zipCode: deliveryAddress.zipCode,
          provinceCode: deliveryAddress.province,
          countryCode:
            deliveryAddress.addressCountryCode || CONFIG.COUNTRY.toUpperCase(),
          addressId: deliveryAddress.addressId,
          firstName: deliveryAddress.firstName,
          lastName: deliveryAddress.lastName,
          firstNamePhonetic: deliveryAddress.firstNamePhonetic,
          lastNamePhonetic: deliveryAddress.lastNamePhonetic,
          mobile: deliveryAddress.mobile,
          phone: deliveryAddress.landline,
          formattedAddress: {
            address1: extractHouseNumber
              ? deliveryAddress.address1
                  .trim()
                  .concat(` ${deliveryAddress.houseNumber}`)
              : deliveryAddress.address1,
            address2: deliveryAddress.address2,
            address3: deliveryAddress.address3
          }
        }
      });
    });
  return contactMethods;
}

function buildChildList(data) {
  const childFieldNames = Object.keys(data).filter(key =>
    key.startsWith('child-')
  );

  const newChildList = childFieldNames.reduce((childList, fieldName) => {
    if (!data[fieldName]) {
      return childList;
    }
    const [x, childProperty, childNumber] = fieldName.split('-');
    childList[childNumber] = childList[childNumber] || {};

    if (childProperty === 'birthDate') {
      childList[childNumber][childProperty] = CONFIG.FEATURE.ENABLE_ICM_CMS
        ? data[fieldName]
        : stringUtils.reverseString(data[fieldName], '-').concat('Z');
    } else {
      childList[childNumber][childProperty] = data[fieldName];
    }
    return childList;
  }, []);

  // Remove child objects that only has id.
  const filteredFromEmptyChildren = newChildList.filter(child => {
    const childKeys = Object.keys(child);
    const hasOnlyId =
      !child.hasOwnProperty('birthDate') && childKeys[0] === 'id';
    return !hasOnlyId;
  });

  return filteredFromEmptyChildren;
}

function isCustomerIntegrityChanged(currentProfile, updatedProfile) {
  if (
    currentProfile.allowEmail !== updatedProfile.allowEmail ||
    currentProfile.allowSMS !== updatedProfile.allowSMS ||
    currentProfile.allowSharingToSocialMedia !==
      updatedProfile.allowSharingToSocialMedia ||
    currentProfile.allowFindPotentialCustomers !==
      updatedProfile.allowFindPotentialCustomers ||
    currentProfile.allowAppPushNotification !==
      updatedProfile.allowAppPushNotification ||
    currentProfile.allowCollectBuyInfo !== updatedProfile.allowCollectBuyInfo ||
    currentProfile.allowDirectMail !== updatedProfile.allowDirectMail ||
    currentProfile.allowPhone !== updatedProfile.allowPhone ||
    currentProfile.sendFamilyPlasticCard !==
      updatedProfile.sendFamilyPlasticCard
  ) {
    return true;
  }
  return false;
}

function isIntegrityValueChanged(oldValue, newValue) {
  if (oldValue !== newValue) {
    return true;
  }
  return false;
}
