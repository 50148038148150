import React from 'react';
import PropTypes from 'prop-types';
import { InlineText } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './PasswordInfo.scss';

const PasswordInfo = props => {
  return (
    <div className={styles['password-info']}>
      <InlineText
        msg={t('forms.validation.password.title')}
        className={styles['password-info-text']}
      />
      <PasswordRule
        msg={t('forms.validation.password.invalidLength')}
        fulfilled={!props.invalidLength}
      />
      <PasswordRule
        msg={t('forms.validation.password.invalidIdenticalChars')}
        fulfilled={!props.invalidIdenticalChars}
      />
      <PasswordRule
        msg={t('forms.validation.password.invalidLowercase')}
        fulfilled={!props.invalidLowercase}
      />
      <PasswordRule
        msg={t('forms.validation.password.invalidUppercase')}
        fulfilled={!props.invalidUppercase}
      />
      <PasswordRule
        msg={t('forms.validation.password.invalidNumberOrSpecialChar')}
        fulfilled={!props.invalidNumberOrSpecialChar}
      />
    </div>
  );
};

PasswordInfo.propTypes = {
  id: PropTypes.string,
  invalidLength: PropTypes.bool,
  invalidIdenticalChars: PropTypes.bool,
  invalidLowercase: PropTypes.bool,
  invalidUppercase: PropTypes.bool,
  invalidNumberOrSpecialChar: PropTypes.bool
};

const PasswordRule = props => (
  <InlineText
    msg={props.msg}
    className={
      props.fulfilled
        ? styles['password-info-fulfilled']
        : styles['password-info-unfulfilled']
    }
  />
);

PasswordRule.propTypes = {
  fulfilled: PropTypes.bool,
  msg: PropTypes.string
};

export default PasswordInfo;
