import React from 'react';
import { PropTypes } from 'prop-types';
import { TextWithModal } from 'Common';
import IkeaCheckboxControlled from '../_form_ikea_copies/IkeaCheckboxControlled';

const CheckboxWithModal = props => {
  const onTextClick = () => {
    const event = {
      target: {
        name: props.name,
        type: 'checkbox',
        checked: !props.value
      }
    };
    props.onChange(event);
  };

  const { formId, name, value, onChange, ...textWithModalProps } = props;

  const textElement = (
    <TextWithModal
      {...textWithModalProps}
      id={`${formId}-${name}-modal`}
      onTextClick={onTextClick}
    />
  );

  return (
    <IkeaCheckboxControlled
      id={`${formId}-${name}`}
      name={name}
      checked={value}
      text={textElement}
      prefix={CONFIG.APP.STYLES_PREFIX}
      describedById={`${formId}-${name}-error`}
      onChange={onChange}
    />
  );
};

CheckboxWithModal.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  ...TextWithModal.propTypes
};

export default CheckboxWithModal;
