/**
 * Writes, deletes profile data for us to be able to fake API access.
 */
function createProfileKey(userId) {
  return 'mock_' + userId;
}

function createDeliveryAddressesKey(userId) {
  return 'mockDeliveryAddressData_' + userId;
}

export const save = (profile, userId) => {
  const key = createProfileKey(userId);
  profile = addMockedProfileData(profile);
  window.sessionStorage.setItem(key, JSON.stringify(profile));
  return true;
};

export const get = userId => {
  const key = createProfileKey(userId);
  const profile = window.sessionStorage.getItem(key);
  if (!profile) {
    return false;
  }
  return JSON.parse(profile);
};

export const deleteUser = userId => {
  const key = createProfileKey(userId);
  window.sessionStorage.removeItem(key);
};

export const getDeliveryAddressData = userId => {
  const key = createDeliveryAddressesKey(userId);
  const data = window.sessionStorage.getItem(key);
  return data && JSON.parse(data);
};

export const saveDeliveryAddress = (userId, requestData) => {
  const key = createDeliveryAddressesKey(userId);
  const address = extractAddressToBeStored(requestData);
  const storedData = getDeliveryAddressData(userId) || {
    deliveryAddressList: []
  };

  storedData.deliveryAddressList.push(address);
  window.sessionStorage.setItem(key, JSON.stringify(storedData));
};

export const updateDeliveryAddress = (userId, addressId, requestData) => {
  const key = createDeliveryAddressesKey(userId);
  const address = extractAddressToBeStored(requestData);
  const storedData = getDeliveryAddressData(userId) || {
    deliveryAddressList: []
  };

  const selected = storedData.deliveryAddressList.find(
    a => a.addressId === addressId
  );
  if (!selected) {
    throw Error();
  }

  const newList = storedData.deliveryAddressList.filter(
    a => a.addressId !== addressId
  );
  newList.push(address);
  storedData.deliveryAddressList = newList;
  window.sessionStorage.setItem(key, JSON.stringify(storedData));
};

export const deleteDeliveryAddress = (userId, addressId) => {
  const key = createDeliveryAddressesKey(userId);
  const storedData = getDeliveryAddressData(userId) || {
    deliveryAddressList: []
  };
  const newList = storedData.deliveryAddressList.filter(
    a => a.addressId !== addressId
  );

  if (newList.length === 0) {
    window.sessionStorage.removeItem(key);
    return;
  }

  storedData.deliveryAddressList = newList;
  window.sessionStorage.setItem(key, JSON.stringify(storedData));
};

function addMockedProfileData(profile) {
  switch (profile.profileType) {
    case 'FAMILY':
      profile.crmCustomerProfile.loyaltyProgram = {
        loyaltyCardList: [
          {
            cardType: 'FAMILY',
            cardNo: '6275986366000393715'
          }
        ]
      };
      return profile;
    case 'BUSINESS':
      profile.crmCustomerProfile.loyaltyProgram = {
        loyaltyCardList: [
          {
            cardType: 'BUSINESS',
            cardNo: '6275986366000393715'
          }
        ]
      };
      return profile;
    default:
      return profile;
  }
}

function extractAddressToBeStored(request) {
  request.deliveryAddress.addressId = Math.floor(Math.random() * 1000000);
  return request.deliveryAddress;
}
