import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import DropdownControlled from 'BaseForm/DropdownControlled';

const PreferredLanguageDropdown = props => (
  <DropdownControlled
    formId={props.formId}
    name={props.name}
    label={props.label}
    showOptional={props.showOptional}
    value={props.value}
    removeHintText={props.removeHintText}
    options={props.acceptedLanguages.map(languageCode => {
      return {
        name: t(`forms.preferredLanguage_${languageCode}`),
        value: languageCode
      };
    })}
    topOptions={props.favoriteLanguages.map(languageCode => {
      return {
        name: t(`forms.preferredLanguage_${languageCode}`),
        value: languageCode
      };
    })}
    onBlur={props.onBlur}
    onChange={props.onChange}
  />
);

PreferredLanguageDropdown.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  removeHintText: PropTypes.bool,
  acceptedLanguages: PropTypes.arrayOf(PropTypes.string), // For example: ["CA", "US"]
  favoriteLanguages: PropTypes.arrayOf(PropTypes.string), // For example: ["CA", "US"]
  showOptional: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

PreferredLanguageDropdown.defaultProps = {
  acceptedLanguages: [],
  favoriteLanguages: []
};

export default PreferredLanguageDropdown;
