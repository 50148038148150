// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__icon{width:24px;height:24px;fill:currentColor;fill-rule:evenodd;stroke:none}.profile__icon-sm{width:24px;height:24px}.profile__icon-md{width:30px;height:30px}.profile__icon-lg{width:48px;height:48px}.profile__icon-xl{width:62px;height:62px}.profile__icon-rotate{transform-origin:50% 50%;animation:profile__rotate 1.5s ease-in-out infinite}@keyframes profile__rotate{0%{transform:rotate(0);opacity:1}50%{transform:rotate(180deg);opacity:.9}100%{transform:rotate(360deg);opacity:1}}", "",{"version":3,"sources":["webpack://./src/components/_common/Icon/Icon.scss"],"names":[],"mappings":"AAAA,eACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,WAAA,CAEA,kBACE,UAAA,CACA,WAAA,CAGF,kBACE,UAAA,CACA,WAAA,CAEF,kBACE,UAAA,CACA,WAAA,CAEF,kBACE,UAAA,CACA,WAAA,CAGF,sBACE,wBAAA,CACA,mDAAA,CAGF,2BACE,GACE,mBAAA,CACA,SAAA,CAEF,IACE,wBAAA,CACA,UAAA,CAEF,KACE,wBAAA,CACA,SAAA,CAAA","sourcesContent":[".icon {\n  width: 24px;\n  height: 24px;\n  fill: currentColor;\n  fill-rule: evenodd;\n  stroke: none;\n\n  &-sm {\n    width: 24px;\n    height: 24px;\n  }\n\n  &-md {\n    width: 30px;\n    height: 30px;\n  }\n  &-lg {\n    width: 48px;\n    height: 48px;\n  }\n  &-xl {\n    width: 62px;\n    height: 62px;\n  }\n\n  &-rotate {\n    transform-origin: 50% 50%;\n    animation: rotate 1.5s ease-in-out infinite;\n  }\n\n  @keyframes rotate {\n    0% {\n      transform: rotate(0);\n      opacity: 1;\n    }\n    50% {\n      transform: rotate(180deg);\n      opacity: 0.9;\n    }\n    100% {\n      transform: rotate(360deg);\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "profile__icon",
	"icon-sm": "profile__icon-sm",
	"icon-md": "profile__icon-md",
	"icon-lg": "profile__icon-lg",
	"icon-xl": "profile__icon-xl",
	"icon-rotate": "profile__icon-rotate",
	"rotate": "profile__rotate"
};
export default ___CSS_LOADER_EXPORT___;
