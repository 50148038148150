import { FORM_KEY_SUFFIX } from 'Redux/forms/formsConstants';

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @return  {object}    Form values in format:
 *                      {
 *                        firstName: 'Alice',
 *                        lastName: 'Robinson'
 *                      }
 */
export const getFormValues = (state, formId) => {
  return state.forms[`${formId}${FORM_KEY_SUFFIX.VALUES}`] || {};
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @return  {object}    Form messages in format:
 *                      {
 *                        firstName: 'This is required',
 *                        lastName: 'This is required'
 *                      }
 */
export const getFormMessages = (state, formId) => {
  return state.forms[`${formId}${FORM_KEY_SUFFIX.MESSAGES}`] || {};
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @return  {object}    Form backend errors in format:
 *                      [
 *                        'forms.errors.generic',
 *                        'forms.errors.userAlreadyExists'
 *                      ]
 */
export const getFormBackendErrors = (state, formId) => {
  return state.forms[`${formId}${FORM_KEY_SUFFIX.ERRORS}`] || [];
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @param   {string}    name      Field name
 * @return  {string|number|bool}  Value for a specific field
 */
export const getFieldValue = (state, formId, name) => {
  return getFormValues(state, formId)[name];
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @param   {array}     names     Names of the fields to include
 * @return  {object}    {
 *                        firstName: 'Alice',
 *                        lastName: 'Robinson'
 *                      }
 */
export const getFieldValuesByNames = (state, formId, names = []) => {
  return names.reduce((prevValuesMap, name) => {
    const valuesMap = prevValuesMap;
    valuesMap[name] = getFieldValue(state, formId, name);
    return valuesMap;
  }, {});
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @param   {string}    name      Field name
 * @return  {string}    First validation message for a specific field
 */
export const getFieldMessage = (state, formId, name) => {
  const formMessagesMap =
    state.forms[`${formId}${FORM_KEY_SUFFIX.MESSAGES}`] || {};
  const fieldMessagesArr = formMessagesMap[name] || [];
  return fieldMessagesArr[0];
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @param   {string}    name      Field name
 * @return  {string}    All validation messages for a specific field
 */
export const getFieldMessages = (state, formId, name) => {
  const formMessagesMap =
    state.forms[`${formId}${FORM_KEY_SUFFIX.MESSAGES}`] || {};
  const fieldMessagesArr = formMessagesMap[name] || [];
  return fieldMessagesArr;
};

/**
 * @param   {object}    state     Redux state
 * @param   {string}    formId    Form id
 * @param   {array}     names     Names of the fields to include
 * @return  {object}    {
 *                        firstName: 'This is required',
 *                        lastName: 'This is required'
 *                      }
 */
export const getFieldMessagesByNames = (state, formId, names = []) => {
  return names.reduce((prevMessagesMap, name) => {
    const messagesMap = prevMessagesMap;
    messagesMap[name] = getFieldMessage(state, formId, name);
    return messagesMap;
  }, {});
};

export const getFormDisabledSubmit = (state, formId) => {
  return state.forms[`${formId}${FORM_KEY_SUFFIX.SUBMIT}`];
};
