import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Grid.scss';
import './style.scss';

export const Grid = props => (
  <div
    style={props.style}
    className={classNames(`${CONFIG.APP.STYLES_PREFIX}ikea-container`, {
      [styles['grid-fluid']]: props.fluid,
      [props.className]: props.className
    })}
  >
    {props.children}
  </div>
);

Grid.propTypes = {
  fluid: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
