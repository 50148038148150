import autocorrectors from './autocorrectors';
import { zipCode } from './zipCodeAutocorrector';

const allAutocorrectors = { ...autocorrectors, zipCode };

const autocorrect = (formId, value, rules) => {
  if (!value || !rules || !rules.length) {
    return value;
  }

  return rules.reduce((newValue, rule) => {
    const { name, ...args } = rule;
    const autoCorrectedValue = allAutocorrectors[name]
      ? allAutocorrectors[name](newValue, args, formId)
      : newValue;
    return autoCorrectedValue;
  }, value);
};

export default autocorrect;
