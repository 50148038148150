export function transformFields(response, addressFieldMap) {
  if (!response || !response.suggestions || !response.suggestions.length) {
    return false;
  }

  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const serviceFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!serviceFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = serviceFieldNames.reduce(
      (concatenatedValue, serviceFieldName) => {
        let value = '';
        // Look for a preconfigured suffix, prefix or separator.
        // Separator sample: '{, }' ==> ', '
        // Prefix sample: '{House nr. }HouseNumber' ==> 'House nr. 123'
        // Suffix sample: 'HouseNumber{ (House number)}' ==> '123 (House number)'
        const suffixPrefixOrSeparatorMatch = serviceFieldName.match(/\{(.*)\}/);

        if (suffixPrefixOrSeparatorMatch) {
          if (suffixPrefixOrSeparatorMatch[0] === serviceFieldName) {
            nextSeparator = suffixPrefixOrSeparatorMatch[1];
            return concatenatedValue;
          }

          const fieldName = serviceFieldName.replace(
            suffixPrefixOrSeparatorMatch[0],
            ''
          );
          const fieldValue = response.suggestions[0][fieldName];
          value = fieldValue
            ? serviceFieldName
                .replace(
                  suffixPrefixOrSeparatorMatch[0],
                  suffixPrefixOrSeparatorMatch[1]
                )
                .replace(fieldName, fieldValue)
            : '';
        } else {
          value = response.suggestions[0][serviceFieldName];
        }
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap || !data) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (mappedValue.includes(prevValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  return data;
}

export function transformOptions(response, searchQuery) {
  if (!response || !response.suggestions || !response.suggestions.length) {
    return false;
  }

  let options = [];

  for (let i = 0; i < response.suggestions.length; i++) {
    options.push({
      id: i,
      label: response.suggestions[i].text,
      // subLabel: undefined,
      isCapturable:
        response.suggestions.length > 0 ||
        response.suggestions[i].text === searchQuery
    });
  }

  return options;
}
