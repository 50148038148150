import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { t } from 'Utils/localization/i18next';
import { getQueryStringValue, isValidIKEAUrl } from 'Utils/url/urlUtils';
import {
  Grid,
  Row,
  Col,
  PageHeader,
  Text,
  Link,
  Button,
  WithoutHeaderAndFooter
} from 'Common';
import Routes from 'Routes/profileRoutes';
import styles from './SignupConfirmationContainer.scss';

const SignupConfirmationContainer = props => {
  // "from" is a query string parameter that can be
  // used to redirect the user back after the signup.
  // The fragment identifier "hash" is required to support URLs with "hash" like:
  // https://events.ikea.com/gb/en/events/#/store/croydon/
  const from = getQueryStringValue(
    `${props.location.search}${props.location.hash}`,
    Routes.QUERY_PARAMS.FROM
  );
  const isBasicSignup = new RegExp(Routes.SIGNUP_CONFIRMATION_BASIC).test(
    props.location.pathname
  );

  const onClick = () => {
    if (isValidIKEAUrl(from)) {
      window.location = from;
      return;
    }
    props.history.push({
      pathname: Routes.LOGIN,
      search: window.location.search
    });
  };

  if (isBasicSignup) {
    return (
      <WithoutHeaderAndFooter>
        <Grid className={styles['signup-confirmation']}>
          <Row>
            <Col md={6} className={styles['signup-confirmation-left']}>
              <PageHeader center={false} margin={false}>
                {t('signupConfirmation.pageTitle')}
              </PageHeader>
            </Col>
            <Col md={6} className={styles['signup-confirmation-right']}>
              <Text marginBottom="m" bold>
                {t('signupConfirmation.text1', {
                  country: CONFIG.COUNTRY.toLowerCase()
                })}
              </Text>
              <Text marginBottom="l">{t('signupConfirmation.text2')}</Text>
              <Button
                type="primary"
                text={
                  isValidIKEAUrl(from)
                    ? t('common.ok')
                    : t('common.backToLogin')
                }
                marginBottom="xl"
                onClick={onClick}
              />
            </Col>
          </Row>
        </Grid>
      </WithoutHeaderAndFooter>
    );
  }

  return (
    <Grid className={styles['signup-confirmation']}>
      <Row>
        <Col md={6} className={styles['signup-confirmation-left']}>
          <PageHeader center={false} margin={false}>
            {t('signupConfirmation.pageTitle')}
          </PageHeader>
          <Text marginBottom="m">
            {t('forms.signup.login')}{' '}
            <Link underline onClick={onClick}>
              {t('common.login')}
            </Link>
          </Text>
        </Col>
        <Col md={6} className={styles['signup-confirmation-right']}>
          <Text marginBottom="m" bold>
            {t('signupConfirmation.text1', {
              country: CONFIG.COUNTRY.toLowerCase()
            })}
          </Text>
          <Text marginBottom="l">{t('signupConfirmation.text2')}</Text>
          <Button
            type="primary"
            text={
              isValidIKEAUrl(from) ? t('common.ok') : t('common.backToLogin')
            }
            marginBottom="xl"
            onClick={onClick}
          />
        </Col>
      </Row>
    </Grid>
  );
};

SignupConfirmationContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(SignupConfirmationContainer);
