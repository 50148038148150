// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__flex-panel{display:flex;flex-direction:row;align-items:center;justify-content:flex-start}.profile__flex-panel-spread{justify-content:space-between}.profile__flex-panel-center{justify-content:center}.profile__flex-panel-align-start{align-items:start}", "",{"version":3,"sources":["webpack://./src/components/_common/FlexPanel.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,0BAAA,CAEA,4BACE,6BAAA,CAGF,4BACE,sBAAA,CAGF,iCACE,iBAAA","sourcesContent":[".flex-panel {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n\n  &-spread {\n    justify-content: space-between;\n  }\n\n  &-center {\n    justify-content: center;\n  }\n\n  &-align-start {\n    align-items: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex-panel": "profile__flex-panel",
	"flex-panel-spread": "profile__flex-panel-spread",
	"flex-panel-center": "profile__flex-panel-center",
	"flex-panel-align-start": "profile__flex-panel-align-start"
};
export default ___CSS_LOADER_EXPORT___;
