import { defaultCountryCodeMap } from 'ExtendedForm/PhoneInput/countryCodes';
import phoneMaskRules from './phoneMaskRules';

export const phone = (value, { useRetailUnitCountryCodeIfNotSet = false }) => {
  if (!value || !value.length) {
    return value;
  }

  let newValue = value.replace(/^0{2}/, '+');

  if (useRetailUnitCountryCodeIfNotSet) {
    const hasCountryCode = Object.keys(defaultCountryCodeMap).some(
      countryName => newValue.startsWith(defaultCountryCodeMap[countryName])
    );
    if (!hasCountryCode) {
      const valueWithoutFirstZero = newValue.replace(/^0/, '');
      const retailUnitCountryCode =
        defaultCountryCodeMap[CONFIG.COUNTRY.toUpperCase()];
      newValue = `${retailUnitCountryCode}${valueWithoutFirstZero}`;
    }
  }

  // Find the correct mask depending on country code
  const country = Object.keys(defaultCountryCodeMap).find(countryName =>
    newValue.startsWith(defaultCountryCodeMap[countryName])
  );

  if (!country || !phoneMaskRules[country]) {
    return newValue;
  }

  return phoneMaskRules[country].reduce(
    (currentValue, currentRule) => currentRule(currentValue),
    newValue
  );
};
