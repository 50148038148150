import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, FlexPanel } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './DashboardDeliveryAddressSectionReadContainer.scss';

const DashboardDeliveryAddressSectionReadContainer = props => {
  const deliveryAddressTitle = props.deliveryAddressesFailed
    ? t(props.title)
    : `${t(props.title)} (${props.deliveryAddresses.length})`;
  return (
    <div className={styles['dashboard-delivery-address-section-read']}>
      <FlexPanel
        spread
        className={styles['dashboard-delivery-address-section-read-title']}
      >
        <Text bold colour="grey-900">
          {deliveryAddressTitle}
        </Text>
      </FlexPanel>
      {props.children}
    </div>
  );
};

DashboardDeliveryAddressSectionReadContainer.propTypes = {
  title: PropTypes.string.isRequired,
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  deliveryAddressesFailed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

const mapStateToProps = state => {
  return {
    deliveryAddresses: state.addresses.deliveryAddresses,
    deliveryAddressesFailed: state.addresses.deliveryAddressesFailed
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardDeliveryAddressSectionReadContainer);
