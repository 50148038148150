import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { t } from 'Utils/localization/i18next';
import { deleteProfile } from 'Redux/profile/profileThunks';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import Routes from 'Routes/profileRoutes';
import FormField from 'BaseForm/FormField';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import { Row, Col, Text, Modal, Link } from 'Common';
import styles from './DeleteProfileContainer.scss';

class DeleteProfileContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      validationMessage: ''
    };
  }

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  onPasswordBlur = () => {
    const { password, validationMessage } = this.state;
    const mandatoryMessage = t('forms.validation.mandatoryGeneral');

    if (!password && validationMessage !== mandatoryMessage) {
      this.setState({ validationMessage: mandatoryMessage });
      return;
    }
    if (password && validationMessage) {
      this.setState({ validationMessage: '' });
    }
  };

  onDeleteClick = async e => {
    e.preventDefault();
    if (!this.state.password) {
      this.setState({
        validationMessage: t('forms.validation.mandatoryGeneral')
      });
      return;
    }
    const response = await this.props.deleteProfile(
      this.props.userId,
      this.state.password
    );
    if (response.success) {
      this.props.history.push({
        pathname: Routes.DELETED_PROFILE,
        profileType: this.props.profileType
      });
      return;
    }
    this.setState({
      validationMessage:
        response.messages && response.messages.length
          ? t(response.messages[0])
          : t('forms.errors.generic')
    });
  };

  onCloseModal = () => {
    this.setState({ password: '', validationMessage: '' });
  };

  render() {
    return (
      <Row className={styles['delete-profile']}>
        <Col sm={8}>
          <Text bold colour="grey-900" marginBottom="xs">
            {t('dashboard.settings.deleteTitle')}
          </Text>
          <Text>
            {this.props.profileType === PROFILE_TYPES.FAMILY
              ? t('dashboard.settings.deleteBodyFamily')
              : t('dashboard.settings.deleteBodyRegular')}
          </Text>
          <Modal
            modalType="informational"
            title={t('delete.prompt.title')}
            linkId="delete-profile-link"
            linkText={t('dashboard.settings.deleteLinkText')}
            modalBtns={[
              {
                id: 'delete-profile-submit',
                key: '2',
                htmlType: 'button',
                text: t('delete.prompt.submit'),
                type: 'danger',
                disabled: !this.state.password.length,
                loading: this.props.isLoading,
                onClick: this.onDeleteClick
              }
            ]}
            onClose={this.onCloseModal}
          >
            <div className={styles['delete-profile-text']}>
              <Text marginTop="l">{t('delete.prompt.text1')}</Text>
              <Text marginTop="l">
                {this.props.profileType === PROFILE_TYPES.FAMILY
                  ? t('delete.prompt.text2Family')
                  : t('delete.prompt.text2Regular')}
              </Text>
              <Text marginTop="l">{t('delete.prompt.text3')}</Text>
              <Text marginTop="l" bold>
                {t('delete.prompt.text4')}
              </Text>
              <Text>
                {t('delete.prompt.linkTextBefore')}
                <Link url={CONFIG.URL.CUSTOMER_SERVICE} target="_blank">
                  {t('delete.prompt.linkText')}
                </Link>
                {t('delete.prompt.linkTextAfter')}
              </Text>
              <Text marginTop="l" bold>
                {t('delete.prompt.passwordTitle')}
              </Text>
              <Text marginBottom="m">{t('delete.prompt.passwordText')}</Text>
            </div>
            <FormField
              validation={{
                id: 'delete-profile-password-error',
                msg: this.state.validationMessage,
                type: 'error'
              }}
              shouldValidate={this.state.validationMessage.length > 0}
            >
              <InputFieldHandler
                name="password"
                type="password"
                label={t('forms.password')}
                value={this.state.password}
                id="delete-profile-password"
                onChange={this.onPasswordChange}
                onBlur={this.onPasswordBlur}
              />
            </FormField>
          </Modal>
        </Col>
      </Row>
    );
  }
}

DeleteProfileContainer.propTypes = {
  userId: PropTypes.string,
  profileType: PropTypes.string,
  deleteProfile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    userId: state.profile.userId,
    profileType: state.profile.profileType,
    isLoading: state.userInterface.loading[LOADING.PROFILE_DELETE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteProfile
    },
    dispatch
  );
};

const DeleteProfileWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProfileContainer);

export default withRouter(DeleteProfileWithConnect);
