import {
  person,
  warningTriangle,
  arrowRight,
  shoppingBag,
  calendar,
  speechBubble,
  checkmark,
  chevronDownSmall,
  chevronUpSmall,
  cross,
  crossSmall,
  mail,
  membershipCard,
  fallingBoxes,
  heart,
  questionMarkCircle,
  questionMark,
  home,
  information,
  informationCircle,
  list,
  note,
  phone,
  pencil,
  minus,
  minusSmall,
  magnifyingGlass,
  lock,
  documentList
} from '@ingka/ssr-icon/icons';

export const SSRIcons = {
  person,
  warningTriangle,
  arrowRight,
  shoppingBag,
  calendar,
  speechBubble,
  checkmark,
  chevronDownSmall,
  chevronUpSmall,
  cross,
  crossSmall,
  mail,
  membershipCard,
  fallingBoxes,
  heart,
  questionMarkCircle,
  questionMark,
  home,
  information,
  informationCircle,
  list,
  note,
  phone,
  pencil,
  minus,
  minusSmall,
  magnifyingGlass,
  lock,
  documentList
};
