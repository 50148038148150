import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Row, Col } from 'Common';
import PaymentBox from './PaymentBox';
import styles from './PaymentBox.scss';

class PaymentBoxContainer extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col lg={8} sm={12} md={10}>
          <div className={styles.PaymentBoxContainer}>
            <h3>{t('dashboard.payment.header')}</h3>
            {this.props.payments.map((payment, index) => (
              <PaymentBox
                payment={payment}
                index={index}
                id={`paymentBox__${index}`}
                key={`paymentBox__${index}`}
              />
            ))}
          </div>
        </Col>
      </Row>
    );
  }
}

PaymentBoxContainer.propTypes = {
  payments: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    payments: state.payment.payments || []
  };
};

export default connect(
  mapStateToProps,
  null
)(PaymentBoxContainer);
