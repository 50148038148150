export const transform = data => {
  if (!data) {
    return;
  }
  return data.map(store => {
    if (CONFIG.FEATURE.ENABLE_NAVIGATION_STORES_API) {
      return {
        number: store.id,
        name: store.displayName
      };
    } else {
      return {
        number: store.storeNo,
        name: store.storeName
      };
    }
  });
};

export const transformCiamStores = data => {
  if (!data && !data.storeList) {
    return;
  }
  return data.storeList.map(store => {
    return {
      number: store.storeNumber,
      name: store.storeName
    };
  });
};
