import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Common';
import IkeaSkapaModal from '@ingka/modal';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/modal/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/svg-icon/style.rtl.scss';
import '@ingka/button/style.rtl.scss';
import '@ingka/modal/style.rtl.scss';

export const Modal = props => {
  let child = {};
  return (
    <>
      <IkeaSkapaModal
        ref={instance => {
          child = instance;
        }}
        prefix={CONFIG.APP.STYLES_PREFIX}
        handleCloseBtn={props.onClose}
      >
        <IkeaSkapaModal
          className={props.className}
          prefix={CONFIG.APP.STYLES_PREFIX}
          isOpen={props.isOpen}
          modalType={props.modalType}
          alert={props.alert}
          closeBtn={props.closeBtn}
          modalBtns={props.modalBtns}
          title={props.title}
          light={props.light}
        >
          {props.children}
        </IkeaSkapaModal>
      </IkeaSkapaModal>
      {props.linkText && (
        <Link
          id={props.linkId}
          onClick={() => {
            child.enter();
          }}
          style={props.linkStyle}
          defaultLink={props.isDefaultLink}
        >
          {props.linkText}
        </Link>
      )}
    </>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  linkStyle: PropTypes.object,
  modalType: PropTypes.oneOf(['alert', 'video', 'informational']),
  alert: PropTypes.bool,
  closeBtn: PropTypes.func,
  onClose: PropTypes.func,
  modalBtns: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  light: PropTypes.bool,
  linkId: PropTypes.string,
  linkText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  isDefaultLink: PropTypes.bool
};
