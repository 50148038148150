/**
 * This file contains generic functions to fire google analytics events for
 * login, signup, reset, pageview events.
 */

const eventCategories = {
  USER_ACTIONS: 'user_actions',
  USER_ACTIONS_ERROR: 'error',
  PAGE_VIEW: 'pageview'
};

const WEB_PROFILES = 'web_profiles';

/**
 * Sends event to Google Analytics.
 */
const sendEvent = (payload, noDelay) => {
  if (noDelay) {
    window.sendEvent(payload);
  } else {
    // adding one second delay to allow GA script to be loaded
    setTimeout(() => {
      if (window && window.sendEvent) {
        window.sendEvent(payload);
      }
    }, 1000);
  }
};

/**
 * Sends a user action analytics event.
 * @param {sting}  eventAction – The event action.
 * @param {string} eventLabel – The event label.
 * @param {Object} [custmoreId] The customerId(icmPartyUid) of logged in user.
 */
const sendUserActionAnalyticsEvent = (
  eventAction,
  eventLabel,
  customerId,
  noDelay
) => {
  const payload = {
    event_category: eventCategories.USER_ACTIONS,
    event_action: eventAction,
    event_label: eventLabel
  };
  if (customerId) {
    payload.custom = { user_id: customerId };
  }

  sendEvent(payload, noDelay);
};

/**
 * Sends a page view analytics event.
 */
const sendPageViewAnalyticsEvent = () => {
  if (!window && !window.location) {
    return;
  }
  const payload = {
    event_category: eventCategories.PAGE_VIEW,
    page_path: window.location.pathname || '',
    page_location: window.location.href || ''
  };
  sendEvent(payload);
};

const sendOptimizelyAnalyticsEvent = (experimentKey, variation, noDelay) => {
  const payload = {
    event_category: 'optimizely',
    event_action: 'user bucketed',
    event_label: `${experimentKey}:${variation}`,
    custom: {},
    non_interaction: true
  };
  sendEvent(payload, noDelay);
};

const sendProfileActionAnalyticsEvent = ({
  label,
  partyUid,
  action,
  profileType
}) => {
  const payload = {
    event_category: eventCategories.USER_ACTIONS,
    event_action: action,
    event_label: label
  };
  if (partyUid) {
    payload.custom = {
      user_id: partyUid,
      team: WEB_PROFILES,
      user_type: profileType
    };
  }
  sendEvent(payload, true);
};

const sendProfileErrorActionAnalyticsEvent = ({
  label,
  partyUid,
  action,
  profileType,
  errorKey,
  errorMessage
}) => {
  const payload = {
    event_category: eventCategories.USER_ACTIONS_ERROR,
    event_action: action,
    event_label: label
  };
  if (partyUid) {
    payload.custom = {
      user_id: partyUid,
      team: WEB_PROFILES,
      user_type: profileType,
      error_id: errorKey,
      error_message: errorMessage
    };
  }
  sendEvent(payload, true);
};

export default {
  sendUserActionAnalyticsEvent,
  sendPageViewAnalyticsEvent,
  sendOptimizelyAnalyticsEvent,
  sendProfileActionAnalyticsEvent,
  sendProfileErrorActionAnalyticsEvent
};
