import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Components } from './components';
import { ConditionsValidator } from './conditions';

class DynamicNotificationsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  onDismiss = () => {
    this.setState({ visible: false });
  };

  getFirstVisibleNotification = () => {
    const model = NOTIFICATION_CONFIG[this.props.id];
    if (!model || !model.notifications) {
      return {};
    }

    // The first notification with all conditions
    // fulfilled should be displayed to the user.
    for (let i = 0; i < model.notifications.length; i += 1) {
      const conditions = model.notifications[i].conditions || [];
      const fulfilled = !conditions.some(
        c => !ConditionsValidator[c.name]({ ...c }, this.props.profile)
      );
      if (fulfilled) {
        return model.notifications[i];
      }
    }
    return {};
  };

  render() {
    const notification = this.getFirstVisibleNotification();
    const NotificationComponent = Components[notification.component];

    if (!NotificationComponent || !this.state.visible) {
      return null;
    }

    const component = (
      <NotificationComponent
        {...notification.componentProps}
        onDismiss={this.onDismiss}
      />
    );

    return this.props.style ? (
      <div style={this.props.style}>{component}</div>
    ) : (
      component
    );
  }
}

DynamicNotificationsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  style: PropTypes.object
};

const mapStateToProps = state => {
  return {
    profile: state.profile || {}
  };
};

export default connect(
  mapStateToProps,
  null
)(DynamicNotificationsContainer);
