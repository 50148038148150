/*
  Any updates to the events, such as changing the name, adding or modifying the event body MUST follow "profile-login-fragment": https://git.build.ingka.ikea.com/one-web-profiles/profile-login-fragment.
  This is required since the events are the contract to other parts of the application.
  Example:
  window.ikea.pubsub.publish(LOGIN);
  window.ikea.pubsub.publish(LOGOUT);
*/
const LOGOUT = 'user/loggedOut';
const LOGIN = 'user/loggedIn';
const USER_INFO_AVAILABLE = 'user/infoAvailable';

export const events = { LOGIN, LOGOUT, USER_INFO_AVAILABLE };
