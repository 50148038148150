import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, Link, Row, Col, Grid } from 'Common';
import styles from './MissingDataNotification.scss';

const MissingDataNotification = props => (
  <Grid>
    <Row center className={styles['row-padding']}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <div className={styles['missing-data-notification']}>
          <div className={styles['missing-data-notification-content']}>
            <Text bold>{t(props.title)}</Text>
            <Text inline>{t(props.text)} </Text>
            <Text className={styles['missing-data-notification-action-links']}>
              {props.action && (
                <React.Fragment>
                  <Link
                    underline
                    colour="grey-900"
                    onClick={() => {
                      const tabButton = document.getElementById(
                        props.tabButtonId
                      );
                      const editButton = document.getElementById(
                        props.editButtonId
                      );
                      tabButton.click();
                      editButton.click();
                      tabButton.scrollIntoView();
                    }}
                  >
                    {t(props.action)}
                  </Link>
                </React.Fragment>
              )}
              {props.action2 && (
                <React.Fragment>
                  {` ${t('common.or')} `}
                  <Link
                    underline
                    colour="grey-900"
                    onClick={() => {
                      const tabButton = document.getElementById(
                        props.tabButtonId2
                      );
                      const editButton = document.getElementById(
                        props.editButtonId2
                      );
                      tabButton.click();
                      editButton.click();
                      tabButton.scrollIntoView();
                    }}
                  >
                    {t(props.action2)}
                  </Link>
                </React.Fragment>
              )}
            </Text>
          </div>
          <Link
            underline
            colour="grey-900"
            className={styles['missing-data-notification-dismiss-link']}
            onClick={props.onDismiss}
          >
            {t('common.dismiss')}
          </Link>
        </div>
      </Col>
    </Row>
  </Grid>
);

MissingDataNotification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.string,
  tabButtonId: PropTypes.string,
  editButtonId: PropTypes.string,
  action2: PropTypes.string,
  tabButtonId2: PropTypes.string,
  editButtonId2: PropTypes.string,
  dismissable: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired
};

MissingDataNotification.defaultProps = {
  dismissable: true
};

export default MissingDataNotification;
