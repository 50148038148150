export const IRW_RESIDENCE_TYPES = {
  '1_GARDEN': 6,
  '1_BALCONY': 7,
  '1_TERRACE': 8,
  '1_NO_OUTDOOR': 9,
  '2_GARDEN': 10,
  '2_BALCONY': 11,
  '2_TERRACE': 12,
  '2_NO_OUTDOOR': 13,
  '5_GARDEN': 14,
  '5_BALCONY': 15,
  '5_TERRACE': 16,
  '5_NO_OUTDOOR': 17,
  '4_GARDEN': 18,
  '4_BALCONY': 19,
  '4_TERRACE': 20,
  '4_NO_OUTDOOR': 21
};

export const OW_RESIDENCE_TYPES = {
  0: { residenceType: '0' },
  1: { residenceType: '1' },
  2: { residenceType: '2' },
  5: { residenceType: '5' },
  4: { residenceType: '4' },
  6: { residenceType: '1', outdoorType: 'GARDEN' },
  7: { residenceType: '1', outdoorType: 'BALCONY' },
  8: { residenceType: '1', outdoorType: 'TERRACE' },
  9: { residenceType: '1', outdoorType: 'NO_OUTDOOR' },
  10: { residenceType: '2', outdoorType: 'GARDEN' },
  11: { residenceType: '2', outdoorType: 'BALCONY' },
  12: { residenceType: '2', outdoorType: 'TERRACE' },
  13: { residenceType: '2', outdoorType: 'NO_OUTDOOR' },
  14: { residenceType: '5', outdoorType: 'GARDEN' },
  15: { residenceType: '5', outdoorType: 'BALCONY' },
  16: { residenceType: '5', outdoorType: 'TERRACE' },
  17: { residenceType: '5', outdoorType: 'NO_OUTDOOR' },
  18: { residenceType: '4', outdoorType: 'GARDEN' },
  19: { residenceType: '4', outdoorType: 'BALCONY' },
  20: { residenceType: '4', outdoorType: 'TERRACE' },
  21: { residenceType: '4', outdoorType: 'NO_OUTDOOR' },
  22: { residenceType: '22' }
};
