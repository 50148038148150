import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getFieldValue } from 'Redux/forms/formsHelper';

const MultiLinkConsentText = props => {
  return (
    <div>
      {t(props.labelBeforeLinks)}
      {props.labelBetweenLinks.map((item, index) => (
        <span key={index}>
          {props.lineBreak && <br />}
          {t(props.labelBetweenLinks[index])}
          <Link underline url={t(props.linksURLs[index])} target="_blank">
            {t(props.linkLabels[index])}
          </Link>
        </span>
      ))}
      {t(props.labelAfterLink)}
    </div>
  );
};

MultiLinkConsentText.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  labelBeforeLinks: PropTypes.string,
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  linksURLs: PropTypes.arrayOf(PropTypes.string),
  labelAfterLink: PropTypes.string,
  lineBreak: PropTypes.bool,
  onChange: PropTypes.func,
  // From Redux
  value: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getFieldValue(state, ownProps.formId, ownProps.name)
  };
};

export default connect(
  mapStateToProps,
  null
)(MultiLinkConsentText);
