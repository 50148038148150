import React from 'react';
import PropTypes from 'prop-types';
import { WindowListener, getIsMobile } from 'Common';
import ImageBlockSelector from 'BaseForm/ImageSliderSelector/ImageBlockSelector';
import ImageSliderSelector from 'BaseForm/ImageSliderSelector/ImageSliderSelector';
import images from './images';

class ResidenceTypeSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: getIsMobile()
    };
  }

  onChangeToMobile = () => {
    this.setState({ isMobile: true });
  };

  onChangeToDesktop = () => {
    this.setState({ isMobile: false });
  };

  render() {
    const options = this.props.options.map(option => {
      return {
        ...option,
        image: images[option.value],
        selectedImage: images.SELECTED,
        notSelectedImage: images.NOT_SELECTED
      };
    });
    return (
      <WindowListener
        onChangeToMobile={this.onChangeToMobile}
        onChangeToDesktop={this.onChangeToDesktop}
      >
        {this.state.isMobile ? (
          <ImageSliderSelector {...this.props} options={options} />
        ) : (
          <ImageBlockSelector {...this.props} options={options} />
        )}
      </WindowListener>
    );
  }
}

ResidenceTypeSelector.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  // Array of { label: "", value: "", imageName: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  center: PropTypes.bool
};

ResidenceTypeSelector.defaultProps = {
  center: true
};

export default ResidenceTypeSelector;
