import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './HouseholdSection.scss';

const HouseholdSection = props => (
  <div className={styles['household-section']}>
    <Text bold marginBottom="m" colour="grey-900">
      {t(props.title)}
    </Text>
    {props.children}
  </div>
);

HouseholdSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HouseholdSection;
