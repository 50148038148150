export const FormStateTriggers = {
  setHiddenIfPrepopulated: (state, triggerArgs, { prefillValues }) => {
    const { fieldsToHide } = triggerArgs;
    const hiddenFields = fieldsToHide.filter(
      name => prefillValues[name] !== undefined && prefillValues[name] !== ''
    );
    return {
      ...state,
      fieldsHiddenByTrigger: [
        ...new Set([...state.fieldsHiddenByTrigger, ...hiddenFields])
      ]
    };
  },
  setHiddenIfOtherFieldIsPrepopulated: (
    state,
    triggerArgs,
    { prefillValues }
  ) => {
    const { field, fieldsToHide } = triggerArgs;
    if (prefillValues[field] !== undefined) {
      return {
        ...state,
        fieldsHiddenByTrigger: [
          ...new Set([...state.fieldsHiddenByTrigger, ...fieldsToHide])
        ]
      };
    }
    return state;
  },
  setHiddenIfFieldValueMatch: (state, triggerArgs, { formValues }) => {
    const { fieldName, fieldValue, fieldsToHide } = triggerArgs;
    if (formValues[fieldName] === fieldValue) {
      return {
        ...state,
        fieldsHiddenByTrigger: [
          ...new Set([...state.fieldsHiddenByTrigger, ...fieldsToHide])
        ]
      };
    }
    return state;
  },
  setHiddenIfFieldValueUnMatch: (state, triggerArgs, { formValues }) => {
    const { fieldName, fieldValue, fieldsToHide } = triggerArgs;
    if (formValues[fieldName] !== fieldValue) {
      return {
        ...state,
        fieldsHiddenByTrigger: [
          ...new Set([...state.fieldsHiddenByTrigger, ...fieldsToHide])
        ]
      };
    }
    return state;
  },
  setDisabledIfFieldValueMatch: (state, triggerArgs, { formValues }) => {
    const { fieldName, fieldValue, fieldsToDisable } = triggerArgs;
    if (formValues[fieldName] === fieldValue) {
      return {
        ...state,
        fieldsDisabledByTrigger: [
          ...new Set([...state.fieldsDisabledByTrigger, ...fieldsToDisable])
        ]
      };
    }
    return state;
  },
  setFormBlockedIfFieldValueMatch: (state, triggerArgs, { formValues }) => {
    const { fieldName, fieldValue, messageTitle, messageText } = triggerArgs;
    if (formValues[fieldName] === fieldValue) {
      return {
        ...state,
        formBlockedByTrigger: { messageTitle, messageText }
      };
    }
    return state;
  }
};
