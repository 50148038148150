import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Grid, Row, Col, PageHeader, Loading } from 'Common';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { setNewPassword, logout } from 'Redux/auth/authThunks';
import Routes from 'Routes/profileRoutes';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import styles from './UpdatePasswordContainer.scss';

class UpdatePasswordContainer extends React.PureComponent {
  componentDidMount() {
    if (!this.props.currentPassword) {
      this.props.logout(
        `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`
      );
    }
  }

  onSubmit = data => {
    const formId = 'reset-password-form';
    const { search, hash } = this.props.location;
    const from = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.FROM
    );
    this.props.setNewPassword(
      { ...data, currentPassword: this.props.currentPassword },
      formId,
      from
    );
  };

  render() {
    if (this.props.isLoading) {
      return (
        <Loading
          isLoading={this.props.isLoading}
          text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
        />
      );
    }
    return (
      <Grid className={styles['update-password']}>
        <Row>
          <Col sm={5} lg={5} xl={4} className={styles['update-password-left']}>
            <PageHeader center={false} margin={false}>
              {t('password.updatePasswordPageTitle')}
            </PageHeader>
          </Col>
          <Col sm={1} lg={2} xl={3} />
          <Col sm={6} lg={5} xl={5}>
            <DynamicFormContainer
              formId="update-password-form"
              textSubmit={t('password.updatePasswordSubmitButton')}
              onSubmit={this.onSubmit}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

UpdatePasswordContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  setNewPassword: PropTypes.func,
  currentPassword: PropTypes.string,
  logout: PropTypes.func,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    currentPassword: state.auth.password,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setNewPassword,
      logout
    },
    dispatch
  );
};

const UpdatePasswordWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordContainer);
export default withRouter(UpdatePasswordWithConnect);
