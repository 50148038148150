export class LoginRequest {
  constructor(username, password, challengeContextList, consentList) {
    // constructor(username, password) {
    this.credentials = {
      userName: username,
      password: password
    };
    this.challengeContextList = challengeContextList || [];
    this.consentList = consentList || [];
    this.valid = Boolean(
      username && username !== '' && password && password !== ''
    );
  }
  toJSON() {
    const output = {
      credentials: { ...this.credentials }
    };
    if (this.challengeContextList.length) {
      output.ChallengeContextList = {
        ChallengeContext: this.challengeContextList[0]
      };
    }
    if (this.consentList.length) {
      output.ConsentList = {
        Consent: this.consentList[0]
      };
    }
    return output;
  }
}

export class Consent {
  constructor(version, type) {
    this.consentType = type || null;
    this.consentVersion = version || null;
  }
}

export class ChallengeContext {
  constructor(id, token, code) {
    this.challengeId = id; // .. for now removed, unused?
    this.secretToken = token;
    this.challengeCode = code;
  }
}

export class Challenge {
  constructor(contactAddress, contactMethod) {
    this.contactMethod = contactMethod || 'sms';
    this.contactAddress = contactAddress;
  }
}
