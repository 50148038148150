import Routes from 'Routes/profileRoutes';
import analytics from 'Utils/analytics/analyticsWrapper';
import { push } from 'connected-react-router';
import { events } from 'Utils/pubsub';
import { ReduxStore } from 'Redux';
import { login, getSession } from 'Redux/auth/authThunks';
import { loadingAction } from 'Redux/userInterface/userInterfaceActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import {
  clearAuthAction,
  setUserLoginAction,
  setPartyUidAction,
  setLoginSuccessAction
} from 'Redux/auth/authActions';
import {
  appendAuthTokenToUrl,
  storeAutoLoginToken,
  getOtpVerifiedStatus,
  readAuthTokenCookie,
  getDecodedAuthToken,
  writeSessionCookie
} from 'Utils/auth/authUtils';
import { checkForProfanityWords } from 'Utils/profanityFilter/profanityFilterUtils';
import {
  CIAMService,
  ProfileService,
  Auth0Service,
  AuthService,
  VerificationService,
  SalutationService,
  BazaarvoiceUASService,
  CaptchaService,
  PreferencesService
} from 'Services/ServiceFactory';
import {
  removeBVLoginTokenCookie,
  directToBVContainer,
  writeBVLoginTokenCookie
} from 'Utils/bazaarvoice/bazaarvoiceUtils';
import {
  mapBackendException,
  sendAnalyticsErrors
} from 'Utils/errors/errorUtils';
import { isValidIKEAUrl, getQueryStringValue } from 'Utils/url/urlUtils';
import {
  clearProfileAction,
  setProfileAction,
  setUserIdAction
} from 'Redux/profile/profileActions';
import {
  clearFormAction,
  setFormErrorsAction,
  setFormFieldValueAction
} from 'Redux/forms/formsActions';
import { sendOTP } from 'Redux/verification/verificationThunks';
import {
  setEmailVerifiedAction,
  setOTPSentErrorAction,
  setOTPStatusAction,
  setLoginIdentifierAction
} from 'Redux/verification/verificationActions';
import { setDeliveryAddressesAction } from '../addresses/addressesActions';

const updateIkeaSessionCookie = async updatedProfile => {
  if (
    window &&
    window.ikea &&
    window.ikea.cookieConsent &&
    window.ikea.cookieConsent.hasConsent(3)
  ) {
    const firstNameInitial = updatedProfile.firstName
      ? updatedProfile.firstName.charAt(0).toUpperCase()
      : undefined;
    const lastNameInitial = updatedProfile.lastName
      ? updatedProfile.lastName.charAt(0).toUpperCase()
      : undefined;
    const nameInitials =
      firstNameInitial && lastNameInitial
        ? `${firstNameInitial}${lastNameInitial}`
        : '';
    await writeSessionCookie(
      JSON.stringify({
        firstName: updatedProfile.firstName,
        nameInitials,
        preferredStore: updatedProfile.preferredStore
      })
    );
    if (
      window.ikea.pubsub &&
      typeof window.ikea.pubsub.publish === 'function'
    ) {
      await window.ikea.pubsub.publish(events.USER_INFO_AVAILABLE);
    }
  }
};

export const createProfile = (
  data,
  formId,
  from = '',
  confirmationPageURL,
  disableAutoLogin = false
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_CREATE, true));
      if (CONFIG.FEATURE.ENABLE_CAPTCHA) {
        const token = await grecaptcha.execute(CONFIG.API.CAPTCHA.SECRET, {
          action: CONFIG.COUNTRY
        });
        if (CONFIG.FEATURE.ENABLE_IRW_CMS) {
          // eslint-disable-next-line no-param-reassign
          data.captchaId = token;
        } else {
          const response = await CaptchaService.verifyToken(token);
          if (response.error) {
            throw response.error;
          }
        }
      }

      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const firstNamePhonetic = await SalutationService.getSalutationForFirstName(
          data.firstName,
          data.lastName,
          data.genderCode
        );
        // eslint-disable-next-line no-param-reassign
        data.firstNamePhonetic = firstNamePhonetic;
      }
      if (CONFIG.FEATURE.ENABLE_PROFANITY_FILTER_SERVICE) {
        // Check if the data input by user contains profanity words
        await checkForProfanityWords(data);
      }
      // The register call do both the registration and logging in the user.
      // Register response contain tokens "access token" & "id token".
      const autoLogin =
        !CONFIG.FEATURE.DISABLE_SIGNUP_AUTO_LOGIN && !disableAutoLogin;
      const resp = await ProfileService.register(data, autoLogin);
      // Auto Login tokens are not used when using internal Login
      const autoLoggedIn = CONFIG.FEATURE.INTERNAL_LOGIN
        ? resp.autoLogin
        : resp.autoLogin && storeAutoLoginToken(resp.tokens);
      if (!autoLoggedIn && confirmationPageURL) {
        analytics.userCompletedSignup(data.profileType, true);
        // Go to signup confirmation page and bring "from" parameter.
        const encodedConfirmationPageURL = confirmationPageURL.replace(
          from,
          encodeURIComponent(from)
        );
        window.location = encodedConfirmationPageURL;
        return;
      }

      analytics.userCompletedSignup(data.profileType);

      if (CONFIG.FEATURE.ENABLE_BAZAARVOICE) {
        const { userId, exp } = getDecodedAuthToken(readAuthTokenCookie());
        if (userId && exp) {
          const bvLoginToken = await BazaarvoiceUASService.getBVLoginToken(
            userId,
            data.username
          );
          writeBVLoginTokenCookie(bvLoginToken, exp * 1000);

          const bvEventId = getQueryStringValue(
            window.location.search,
            Routes.QUERY_PARAMS.BV_EVENT_ID
          );
          if (bvEventId) {
            directToBVContainer(bvLoginToken, bvEventId);
            return;
          }
        }
      }

      if (isValidIKEAUrl(from)) {
        if (CONFIG.FEATURE.INTERNAL_LOGIN) {
          await dispatch(
            login(
              { username: data.username, password: data.password },
              null,
              Routes.DASHBOARD,
              null,
              from,
              formId
            )
          );
          return;
        }
        window.location = appendAuthTokenToUrl(from);
        return;
      }

      if (
        CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION &&
        data.mobile &&
        data.username
      ) {
        const authResp = await getSession();
        const partyUid = authResp && authResp.user && authResp.user.partyUid;
        if (partyUid) {
          await dispatch(setPartyUidAction(partyUid));
          await dispatch(
            sendOTP({
              phone: data.mobile,
              partyUid
            })
          );
        }
      }

      const checkOtpStatus = ReduxStore.getState().verification.otpSentStatus;

      if (checkOtpStatus) {
        // TODO::: Handle phone verification flow with 'from' parameter?
        const { partyUid } = ReduxStore.getState().auth;
        Auth0Service.verifyPhone(data.mobile, partyUid);
        return;
      }
      if (CONFIG.FEATURE.INTERNAL_LOGIN) {
        await dispatch(
          login(
            { username: data.username, password: data.password },
            null,
            Routes.DASHBOARD,
            null,
            from,
            formId
          )
        );
      } else {
        dispatch(push(Routes.DASHBOARD));
      }
      dispatch(loadingAction(LOADING.PROFILE_CREATE, false));
      dispatch(clearFormAction(formId));
    } catch (e) {
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      dispatch(setFormFieldValueAction(formId, 'password'));
      dispatch(loadingAction(LOADING.PROFILE_CREATE, false));
    }
  };
};

export const getProfile = () => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_GET, true));

      const { loggedIn } = ReduxStore.getState().auth;

      if (!loggedIn) {
        await dispatch(login());
      }

      const { userId } = ReduxStore.getState().profile;
      const { partyUid } = ReduxStore.getState().auth;

      if (!userId && !partyUid) {
        // User did not have a valid session and
        // is redirected to CIAM hosted login page.
        return;
      }
      // undefined for now, but when setting up China, this value has to be sent
      let profileResp = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      if (!profileResp) {
        throw Error();
      }

      if (
        !CONFIG.FEATURE.ENABLE_CIAM_API &&
        CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION
      ) {
        const { search, hash } = window.location;
        const otpVerifiedStatus = getOtpVerifiedStatus(search || hash);
        if (otpVerifiedStatus) {
          const { mobile } = profileResp;
          const { userLogin } = ReduxStore.getState().auth;
          await VerificationService.linkAccount(mobile, userLogin, partyUid);
          profileResp = await ProfileService.getProfile(
            userId,
            undefined,
            partyUid
          );
          dispatch(push({ search: '' }));
        }
      }
      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const { firstNamePhonetic } = profileResp;
        if (!firstNamePhonetic) {
          const phoneticName = await SalutationService.getSalutationForFirstName(
            profileResp.firstName,
            profileResp.lastName,
            profileResp.genderCode
          );
          // eslint-disable-next-line no-param-reassign
          profileResp.firstNamePhonetic = phoneticName;
        }
      }

      if (CONFIG.FEATURE.ENABLE_PREFERENCES) {
        const preferencesResponse = await PreferencesService.getUserPreferences();
        const preferences = preferencesResponse.preference.preferenceCategory
          .filter(pref => pref.typeDecisionCode === 'YES')
          .map((userPreference, i) => {
            return {
              name: `preference-${i}`,
              label: userPreference.pouTransValue,
              type: userPreference.typeGlobalValue,
              channel: userPreference.communicationChannel[0].value
            };
          });
        profileResp.preferences = preferences;
        if (preferences.length) {
          preferences.forEach(preference => {
            profileResp[preference.name] = true;
          });
        }
      }

      await dispatch(setProfileAction(profileResp));
      await dispatch(setDeliveryAddressesAction(profileResp.deliveryAddresses));
      // Call session context to retrieve IRW member id to be used with non CFB1.3 markets.
      if (
        CONFIG.FEATURE.ENABLE_ICM_CMS &&
        !CONFIG.API.IRW.DISABLE_SESSION_CONTEXT
      ) {
        const irwSession = await AuthService.getIRWProfileSession(false);
        await dispatch(setUserIdAction(irwSession.userId));
      }
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.profileGet'
        })
      );
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_GET, false));
    }
  };
};

export const getProfileExternal = () => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_GET, true));

      const { loggedIn } = ReduxStore.getState().auth;

      if (!loggedIn) {
        const authResp = await getSession();
        await dispatch(setLoginSuccessAction(authResp.user.userLogin));
        await dispatch(setPartyUidAction(authResp.user.partyUid));
        await dispatch(setUserIdAction(authResp.user.userId));
      }

      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().auth;

      if (!partyUid) {
        return;
      }
      // undefined for now, but when setting up China, this value has to be sent
      const profileResp = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      if (!profileResp) {
        throw Error();
      }

      await dispatch(setProfileAction(profileResp));
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.profileGet'
        })
      );
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_GET, false));
    }
  };
};

export const updateProfile = (profile, formId, persistFormState, mode) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      analytics.userUpdatedProfile(profile.profileType);
      const currentProfile = ReduxStore.getState().profile;
      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const firstNamePhonetic = await SalutationService.getSalutationForFirstName(
          profile.firstName,
          profile.lastName,
          profile.genderCode
        );
        // eslint-disable-next-line no-param-reassign
        profile.firstNamePhonetic = firstNamePhonetic;
      }
      if (CONFIG.FEATURE.ENABLE_PROFANITY_FILTER_SERVICE) {
        // Check if the data input by user contains profanity words
        await checkForProfanityWords(
          ReduxStore.getState().forms[`${formId}-values`]
        );
      }
      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      await ProfileService.update(currentProfile, profile, partyUid, mode);
      const updatedProfile = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      await updateIkeaSessionCookie(updatedProfile);
      dispatch(setProfileAction(updatedProfile));
      if (persistFormState) {
        dispatch(setFormErrorsAction(formId, []));
      } else {
        dispatch(clearFormAction(formId));
      }
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

export const upgradeProfile = (profile, formId, from = '') => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPGRADE, true));
      analytics.userUpgradedProfile();
      const { partyUid } = ReduxStore.getState().auth;
      const currentProfile = ReduxStore.getState().profile;
      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const firstNamePhonetic = await SalutationService.getSalutationForFirstName(
          profile.firstName,
          profile.lastName,
          profile.genderCode
        );
        // eslint-disable-next-line no-param-reassign
        profile.firstNamePhonetic = firstNamePhonetic;
      }
      if (CONFIG.FEATURE.ENABLE_PROFANITY_FILTER_SERVICE) {
        // Check if the data input by user contains profanity words
        await checkForProfanityWords(
          ReduxStore.getState().forms[`${formId}-values`]
        );
      }
      await ProfileService.upgrade(
        currentProfile,
        { ...profile, profileType: PROFILE_TYPES.FAMILY },
        partyUid
      );
      await Auth0Service.refreshToken();
      if (isValidIKEAUrl(from)) {
        window.location = appendAuthTokenToUrl(from);
        return true;
      }
      dispatch(
        setProfileAction({ ...profile, profileType: PROFILE_TYPES.FAMILY })
      );
      dispatch(loadingAction(LOADING.PROFILE_UPGRADE, false));
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      dispatch(loadingAction(LOADING.PROFILE_UPGRADE, false));
      return false;
    }
  };
};

export const updateUserPreferences = (data, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      const uncheckedPreferences = data.preferences.filter(
        checkbox => !data[checkbox.name]
      );
      if (uncheckedPreferences.length) {
        await PreferencesService.updateUserPreferences(uncheckedPreferences);
        const { profile } = ReduxStore.getState();
        const updatedPreferences = data.preferences.filter(
          preference => uncheckedPreferences.indexOf(preference) < 0
        );
        const updatedProfile = {
          ...profile,
          preferences: updatedPreferences
        };
        dispatch(setProfileAction(updatedProfile));
      }
      dispatch(clearFormAction(formId));
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

export const updateUserLogin = (data, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));

      const { profile } = ReduxStore.getState();
      const oldUserLogin = ReduxStore.getState().auth.userLogin;
      const reqData = {
        userId: profile.userId,
        userLogin: oldUserLogin,
        password: data.password,
        newUserLogin: data.email.toLowerCase(),
        loginType: 'EMAIL'
      };
      // User updated userLogin. This will also update email contact method.
      if (
        !profile.email ||
        data.email.toLowerCase() !== profile.email.toLowerCase()
      ) {
        const updateUserLoginResp = await ProfileService.updateLogonId(reqData);

        if (updateUserLoginResp.error) {
          throw Error();
        }
        analytics.userUpdatedUserLogin(profile.profileType);
        await dispatch(setUserLoginAction(reqData.newUserLogin));
        const updatedProfile = {
          ...profile,
          email: reqData.newUserLogin
        };
        await dispatch(setProfileAction(updatedProfile));
        if (CONFIG.FEATURE.ENABLE_EMAIL_VERIFICATION) {
          const authResp = await getSession();
          await dispatch(setEmailVerifiedAction(authResp.user.emailVerified));
        }
      }

      const mobileOrPhoneChanged =
        data.mobile !== profile.mobile || data.landline !== profile.landline;
      if (mobileOrPhoneChanged) {
        const authResp = await getSession();
        let { newUserLogin } = reqData;
        if (authResp.user.identifier === 'mobile') {
          newUserLogin = ReduxStore.getState().profile.email;
          const updateUserLoginResp = await ProfileService.updateLogonId({
            ...reqData,
            password: undefined,
            newUserLogin
          });

          if (updateUserLoginResp.error) {
            throw Error();
          }
          await dispatch(setLoginIdentifierAction('email'));
        }
        await dispatch(updateProfile(data, newUserLogin, true, 'SYNC'));
        if (mobileOrPhoneChanged) {
          await dispatch(setOTPSentErrorAction(undefined));
          await dispatch(setOTPStatusAction(false));
        }
      }
      if (formId) {
        dispatch(clearFormAction(formId));
      }
      return true;
    } catch (e) {
      if (formId) {
        dispatch(setFormErrorsAction(formId, mapBackendException(e)));
        return false;
      }
      return { errors: mapBackendException(e) };
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

export const updateHouseHoldInfo = (
  homeAndInterest,
  formId,
  persistFormState
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      // analytics.userUpdatedProfile(profile.profileType);
      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      await ProfileService.updateHomeAndInterests(homeAndInterest, partyUid);
      const updatedProfile = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      dispatch(setProfileAction(updatedProfile));
      if (persistFormState) {
        dispatch(setFormErrorsAction(formId, []));
      } else {
        dispatch(clearFormAction(formId));
      }
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};
export const upsertContactMethod = (
  contactMethod,
  formId,
  persistFormState
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      // analytics.userUpdatedProfile(profile.profileType);
      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      await ProfileService.upsertContactMethod(contactMethod, partyUid);
      const updatedProfile = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      dispatch(setProfileAction(updatedProfile));
      if (persistFormState) {
        dispatch(setFormErrorsAction(formId, []));
      } else {
        dispatch(clearFormAction(formId));
      }
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};
export const updateCustomerPersonalInfo = (
  profile,
  formId,
  persistFormState
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      // analytics.userUpdatedProfile(profile.profileType);
      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      const currentProfile = ReduxStore.getState().profile;
      if (CONFIG.FEATURE.ENABLE_PROFANITY_FILTER_SERVICE) {
        // Check if the data input by user contains profanity words
        await checkForProfanityWords(
          ReduxStore.getState().forms[`${formId}-values`]
        );
      }
      await ProfileService.updateCustomerPersonalInfo(profile, currentProfile);
      const updatedProfile = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      await updateIkeaSessionCookie(updatedProfile);
      dispatch(setProfileAction(updatedProfile));
      if (persistFormState) {
        dispatch(setFormErrorsAction(formId, []));
      } else {
        dispatch(clearFormAction(formId));
      }
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};
export const updateProfileSettings = (profile, formId, persistFormState) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      analytics.userUpdatedProfile(profile.profileType);
      const currentProfile = ReduxStore.getState().profile;

      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      await ProfileService.updateProfileSettings(
        currentProfile,
        profile,
        partyUid
      );
      const updatedProfile = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      await updateIkeaSessionCookie(updatedProfile);
      dispatch(setProfileAction(updatedProfile));
      if (persistFormState) {
        dispatch(setFormErrorsAction(formId, []));
      } else {
        dispatch(clearFormAction(formId));
      }
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

export const deleteProfile = (userId, password) => {
  return async dispatch => {
    const response = {
      success: false,
      messages: []
    };
    try {
      // Delete profile
      dispatch(loadingAction(LOADING.PROFILE_DELETE, true));
      const deleteResp = await ProfileService.delete(userId, password);
      if (deleteResp.error) {
        response.messages = mapBackendException(deleteResp.error);
        return response;
      }

      const { profileType } = ReduxStore.getState().profile;
      analytics.userDeletedProfile(profileType);

      window.ikea.pubsub.publish(events.LOGOUT);
      dispatch(clearProfileAction());
      dispatch(clearAuthAction());
      removeBVLoginTokenCookie();
      response.success = true;
      return response;
    } catch (e) {
      response.messages = mapBackendException(e);
      return response;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_DELETE, false));
    }
  };
};

export const getRedirectURLForUpdateMobile = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForUpdateMobile(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const getRedirectURLForUpdateEmail = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForUpdateEmail(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const getRedirectURLForDeleteProfile = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForDeleteProfile(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const getRedirectURLForUpdatePassword = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForUpdatePassword(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const getRedirectURLForPhoneVerification = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForPhoneVerification(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const getRedirectURLForEmailVerification = () => {
  return async () => {
    try {
      if (!CONFIG.FEATURE.ENABLE_CIAM_API) {
        return false;
      }
      const { partyUid } = ReduxStore.getState().auth;
      const authTokenCookie = readAuthTokenCookie();
      const redirectURL = await CIAMService.getRedirectURLForEmailVerification(
        partyUid,
        authTokenCookie
      );
      return isValidIKEAUrl(redirectURL) ? redirectURL : false;
    } catch (e) {
      return false;
    }
  };
};

export const redirectToCIAMSignup = (from, profileType) => {
  return async () => {
    try {
      return await Auth0Service.redirectToCIAMSignup(
        from,
        profileType ? `${profileType}_signup` : 'signup'
      );
    } catch (e) {
      return false;
    }
  };
};
