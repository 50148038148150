import { ACTIONS } from './commerceConstants';

export const setActiveOrdersCountAction = activeOrdersCount => {
  return {
    type: ACTIONS.SET_ACTIVE_ORDERS_COUNT,
    activeOrdersCount
  };
};

export const setShoppingListCountAction = shoppingListCount => {
  return {
    type: ACTIONS.SET_SHOPPING_LIST_COUNT,
    shoppingListCount
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
