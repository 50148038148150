import SINGLE_SIGNUP_IMAGE_1 from './SINGLE_SIGNUP_IMAGE_1.jpg';
import SINGLE_SIGNUP_IMAGE_2 from './SINGLE_SIGNUP_IMAGE_2.jpg';
import SINGLE_SIGNUP_IMAGE_3 from './SINGLE_SIGNUP_IMAGE_3.jpg';
import SINGLE_SIGNUP_IMAGE_4 from './SINGLE_SIGNUP_IMAGE_4.jpg';
import SINGLE_SIGNUP_IMAGE_5 from './SINGLE_SIGNUP_IMAGE_5.jpg';
import SINGLE_SIGNUP_IMAGE_6 from './SINGLE_SIGNUP_IMAGE_6.jpg';
import SINGLE_SIGNUP_IMAGE_7 from './SINGLE_SIGNUP_IMAGE_7.jpg';
import SINGLE_SIGNUP_IMAGE_8 from './SINGLE_SIGNUP_IMAGE_8.jpg';
import SINGLE_SIGNUP_IMAGE_9 from './SINGLE_SIGNUP_IMAGE_9.jpg';
import REWARDS from './REWARDS.jpg';
import WORKSHOPS from './WORKSHOPS.jpg';
import GIVEBACK from './GIVEBACK.jpg';
import FAVORITES from './FAVORITES.jpg';
import COFFEE from './COFFEE.jpg';
import ASSEMBLY from './ASSEMBLY.jpg';
import CA_SINGLE_SIGNUP_IMAGE from './CA_SINGLE_SIGNUP_IMAGE.png';
import GB_SINGLE_SIGNUP_IMAGE from './GB_SINGLE_SIGNUP_IMAGE.png';

export default {
  SINGLE_SIGNUP_IMAGE_1,
  SINGLE_SIGNUP_IMAGE_2,
  SINGLE_SIGNUP_IMAGE_3,
  SINGLE_SIGNUP_IMAGE_4,
  SINGLE_SIGNUP_IMAGE_5,
  SINGLE_SIGNUP_IMAGE_6,
  SINGLE_SIGNUP_IMAGE_7,
  SINGLE_SIGNUP_IMAGE_8,
  SINGLE_SIGNUP_IMAGE_9,
  REWARDS,
  WORKSHOPS,
  GIVEBACK,
  FAVORITES,
  COFFEE,
  ASSEMBLY,
  CA_SINGLE_SIGNUP_IMAGE,
  GB_SINGLE_SIGNUP_IMAGE
};
