import {
  transformFields,
  transformValues
} from './DaumPostalServiceTransformers';

export default class DaumPostalServiceClient {
  constructor() {
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
  }

  init = captureCallback => {
    new daum.Postcode({
      oncomplete: result => {
        const fieldsTransformed = transformFields(result, this.addressFieldMap);
        const valuesTransformed = transformValues(fieldsTransformed);
        captureCallback(valuesTransformed);
      }
    }).open();
  };
}
