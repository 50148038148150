/* eslint-disable no-case-declarations */
import { generalRegex } from 'Utils/validation/regex/generalRegex';
import moment from 'moment';

const isValidDateObject = date => {
  return date instanceof Date && date.toString() !== 'Invalid Date';
};

export const convertUnifiedDateStringToDateObject = (dateStr = '') => {
  if (!generalRegex.dateFormat['DD-MM-YYYY'].test(dateStr)) {
    return false;
  }
  const [date, month, year] = [...dateStr.split('-')];
  const dateObject = new Date(year, month - 1, date);
  return isValidDateObject(dateObject) && dateObject;
};

/**
 * Returns a date string formatted as DD-MM-YYYY.
 * Does not validate if it is a valid date.
 * @param {string} dateStr - A string representing a date in a specific format.
 * @param {string} fromFormat - The original format of the date string.
 */
export const getSpecificDateStringInUnifiedFormat = (dateStr, fromFormat) => {
  if (!generalRegex.dateFormat[fromFormat].test(dateStr)) {
    return false;
  }
  let formattedDate;

  switch (fromFormat) {
    case 'DD-MM-YYYY':
      return dateStr;
    case 'MM-DD-YYYY':
      formattedDate = dateStr.match(/^(\d{2})-(\d{2})-(\d{4})$/);
      return formattedDate && formattedDate.length > 3
        ? `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[3]}`
        : false;
    case 'YYYY-MM':
      formattedDate = dateStr.match(/^(\d{4})-(\d{2})$/);
      return formattedDate && formattedDate.length > 2
        ? `01-${formattedDate[2]}-${formattedDate[1]}`
        : false;
    case 'YYYY.MM.':
      formattedDate = dateStr.match(/^(\d{4}).(\d{2}).$/);
      return formattedDate && formattedDate.length > 2
        ? `01-${formattedDate[2]}-${formattedDate[1]}`
        : false;
    case 'MM-YYYY':
      formattedDate = dateStr.match(/^(\d{2})-(\d{4})$/);
      return formattedDate && formattedDate.length > 2
        ? `01-${formattedDate[1]}-${formattedDate[2]}`
        : false;
    case 'MM.YYYY':
      formattedDate = dateStr.match(/^(\d{2}).(\d{4})$/);
      return formattedDate && formattedDate.length > 2
        ? `01-${formattedDate[1]}-${formattedDate[2]}`
        : false;
    case 'YYYYMMDD':
      formattedDate = dateStr.match(/^(\d{4})(\d{2})(\d{2})$/);
      return formattedDate && formattedDate.length > 3
        ? `${formattedDate[3]}-${formattedDate[2]}-${formattedDate[1]}`
        : false;
    case 'YYYY-MM-DD':
      formattedDate = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      return formattedDate && formattedDate.length > 3
        ? `${formattedDate[3]}-${formattedDate[2]}-${formattedDate[1]}`
        : false;
    case 'YYYYMMDDXXXX':
      formattedDate = dateStr.match(/^(\d{4})(\d{2})(\d{2})(\d{4})$/);
      return formattedDate && formattedDate.length > 3
        ? `${formattedDate[3]}-${formattedDate[2]}-${formattedDate[1]}`
        : false;
    case 'DD.MM.YYYY':
      return dateStr.replace(/\./g, '-');
    case 'YYYY.MM.DD':
      return dateStr.replace(/(\d{4}).(\d{2}).(\d{2})/, '$3-$2-$1');
    case 'YYYY.MM.DD.':
      return dateStr.replace(/(\d{4}).(\d{2}).(\d{2})./, '$3-$2-$1');
    default:
      return false;
  }
};
/**
 * Returns a date string transformed from unified format to a specific format.
 * Does not validate if it is a valid date.
 * @param {string} dateStr - A string representing a date in format DD-MM-YYYY.
 * @param {string} toFormat - The format to transform the "dateStr" into.
 */
export const getUnifiedDateStringInSpecificFormat = (dateStr, toFormat) => {
  if (!generalRegex.dateFormat['DD-MM-YYYY'].test(dateStr)) {
    return false;
  }
  const formattedDate = dateStr.match(/^(\d{2})[-](\d{2})[-](\d{4})$/);
  if (!formattedDate || formattedDate.length < 4) {
    return false;
  }
  const DD = formattedDate[1];
  const MM = formattedDate[2];
  const YYYY = formattedDate[3];

  switch (toFormat) {
    case 'DD-MM-YYYY': {
      return dateStr;
    }
    case 'MM-DD-YYYY': {
      return `${MM}-${DD}-${YYYY}`;
    }
    case 'MM-YYYY': {
      return `${MM}-${YYYY}`;
    }
    case 'MM.YYYY': {
      return `${MM}.${YYYY}`;
    }
    case 'YYYY-MM': {
      return `${YYYY}-${MM}`;
    }
    case 'YYYY.MM.': {
      return `${YYYY}.${MM}.`;
    }
    case 'YYYYMMDD': {
      return `${YYYY}${MM}${DD}`;
    }
    case 'YYYY-MM-DD': {
      return `${YYYY}-${MM}-${DD}`;
    }
    case 'DD.MM.YYYY': {
      return `${DD}.${MM}.${YYYY}`;
    }
    case 'YYYY.MM.DD.': {
      return `${YYYY}.${MM}.${DD}.`;
    }
    case 'YYYY.MM.DD': {
      return `${YYYY}.${MM}.${DD}`;
    }
    default: {
      return false;
    }
  }
};

export const getAge = birthDate => {
  if (!isValidDateObject(birthDate)) {
    return undefined;
  }
  const age = moment().diff(
    moment([birthDate.getFullYear(), birthDate.getMonth()]),
    'years',
    true
  );
  return age;
};

export function isUTCDateInPast(date) {
  return new Date(date * 1000) < new Date();
}
