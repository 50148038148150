import { PropTypes } from 'prop-types';

export const UserAgentSpecificRender = props => {
  // If needed, add additional props and return
  // statements for more specific user agents.
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  if (props.mobileElement && isMobile) {
    return props.mobileElement;
  }
  if (props.desktopElement && !isMobile) {
    return props.desktopElement;
  }
  return null;
};

UserAgentSpecificRender.propTypes = {
  mobileElement: PropTypes.element,
  desktopElement: PropTypes.element
};
