import autocorrectors from './autocorrectors';

const {
  toUppercase,
  toNumeric,
  toAlphanumeric,
  removeSpaces,
  matchAndInsert,
  matchAndReplace
} = autocorrectors;

export default {
  AD: [value => removeSpaces(value), value => toUppercase(value)],
  BE: [value => toNumeric(value)],
  CA: [value => toUppercase(value), value => toAlphanumeric(value)],
  CH: [value => toNumeric(value)],
  CZ: [value => toNumeric(value)],
  DE: [value => removeSpaces(value)],
  ES: [value => toNumeric(value)],
  FI: [value => toNumeric(value)],
  FR: [value => toNumeric(value)],
  GB: [
    value => toAlphanumeric(value),
    value => toUppercase(value),
    value =>
      matchAndInsert(value, {
        match: /^(.+)(\w{3})$/,
        insert: ' '
      })
  ],
  HU: [value => toNumeric(value)],
  IE: [
    value => removeSpaces(value),
    value => matchAndReplace(value, { match: /-/, replace: '' }),
    value => toUppercase(value),
    value =>
      matchAndInsert(value, {
        match: /^(\w{3})(.+)$/,
        insert: ' '
      })
  ],
  IT: [value => toNumeric(value)],
  JP: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /^([0-9]{3})([0-9]{4})$/,
        insert: '-'
      })
  ],
  LU: [value => toNumeric(value)],
  NL: [value => removeSpaces(value), value => toUppercase(value)],
  PL: [
    value => toNumeric(value),
    value =>
      matchAndInsert(value, {
        match: /^(\d{2})(\d{3})$/,
        insert: '-'
      })
  ],
  PT: [
    value => toNumeric(value),
    value =>
      matchAndInsert(value, {
        match: /^(\d{4})(\d{3})$/,
        insert: '-'
      })
  ],
  RU: [value => toNumeric(value)],
  SE: [value => toNumeric(value)],
  SK: [value => toNumeric(value)],
  US: [value => matchAndReplace(value, { match: /-.*/, replace: '' })]
};
