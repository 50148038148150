import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFormAction } from 'Redux/forms/formsActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { t } from 'Utils/localization/i18next';
import { Link, Text, Button } from 'Common';
import mask from 'Utils/mask/mask';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import { zipCode } from 'Utils/mask/zipCodeMasker';
import SSRIcon from '@ingka/ssr-icon';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './ShippingBillingAdresses.scss';

class DashboardDeliveryAddress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
    this.myRef = React.createRef();
  }

  onEdit = () => {
    // This will set the id of the delivery address that is being edited.
    // Any other delivery address form will be closed.
    this.props.setAddressBeingEdited(this.props.deliveryAddress.addressId);
    if (!this.state.isEditing) {
      this.setState({ isEditing: true });
    }
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onUpdate = data => {
    const { addressId } = this.props.deliveryAddress;
    const successCallback = () => {
      this.setState({ isEditing: false });
      this.scrollToCenter();
    };
    const failureCallback = this.scrollToTop;
    this.props.onUpdate(
      {
        ...data,
        addressId
      },
      successCallback,
      failureCallback
    );
  };

  onDelete = () => {
    const successCallback = () => {
      this.setState({ isEditing: false });
      this.scrollToCenter();
    };
    const failureCallback = this.scrollToTop;
    this.props.onDelete(
      this.props.deliveryAddress.addressId,
      successCallback,
      failureCallback
    );
  };

  scrollToTop = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const { deliveryAddress } = this.props;
    return (
      <>
        {this.state.isEditing &&
          (deliveryAddress.addressId === this.props.addressBeingEdited && (
            <div>
              <div ref={this.setRef}>
                <DynamicFormContainerModal
                  formId={this.props.editForm}
                  prefillValues={deliveryAddress}
                  loadingSubmit={this.props.isLoading}
                  textSubmit={t('common.save')}
                  textCancel={t('forms.addressRemove')}
                  onDelete={this.onDelete}
                  onCancel={this.onClose}
                  onSubmit={this.onUpdate}
                  title={this.props.title}
                ></DynamicFormContainerModal>
              </div>
            </div>
          ))}
        <div key={deliveryAddress.addressId}>
          <div
            ref={this.setRef}
            className={styles['dashboard-delivery-address']}
          >
            <div className={styles['delivery-address-container']}>
              <Text className={styles['dashboard-delivery-address-read-field']}>
                <div className={styles['common-specifier']}>
                  {t(`dashboard.addresses.delivery-address.address`)}
                </div>
                {this.props.rows.map((row, index) => (
                  <div
                    key={index}
                    className={styles['horizontal-container-stacked']}
                  >
                    <div className={styles['common-value']}>
                      {row.map(
                        item =>
                          deliveryAddress[item] &&
                          (item === 'addressCountryCode'
                            ? `${t(
                                `countries.${mask(
                                  deliveryAddress[item],
                                  this.props.maskRules[item]
                                )}`
                              )} `
                            : item === 'zipCode'
                            ? `${zipCode(
                                deliveryAddress[item],
                                deliveryAddress.addressCountryCode,
                                this.props.maskRules[item]
                              )} `
                            : `${mask(
                                deliveryAddress[item],
                                this.props.maskRules[item]
                              )} `)
                      )}
                    </div>
                  </div>
                ))}
              </Text>
              <div
                className={
                  styles['dashboard-delivery-address-read-field-second-box']
                }
              >
                <div
                  className={
                    styles[
                      'dashboard-delivery-address-read-field-second-box-content'
                    ]
                  }
                >
                  <Text
                    className={styles['dashboard-delivery-address-read-field']}
                  >
                    <div className={styles['common-specifier']}>
                      {t(`dashboard.addresses.delivery-address.contactDetails`)}
                    </div>
                    {this.props.secondRows.map((row, index) => (
                      <div
                        key={index}
                        className={styles['horizontal-container-stacked']}
                      >
                        <div className={styles['common-value']}>
                          {row.map(
                            item =>
                              deliveryAddress[item] &&
                              `${mask(
                                deliveryAddress[item],
                                this.props.maskRules[item]
                              )} `
                          )}
                        </div>
                      </div>
                    ))}
                  </Text>
                </div>
              </div>
            </div>
            {this.props.editForm && (
              <>
                <Button
                  className={styles['edit-link']}
                  small
                  type="tertiary"
                  id={`${this.props.editForm}-link`}
                  onClick={this.onEdit}
                >
                  <SSRIcon colour="black" paths={pencil} />
                  {t('common.edit')}
                </Button>
                <Link
                  bold
                  colour="grey-900"
                  id={`${this.props.editForm}-link`}
                  className={styles['edit-link-mobile']}
                  onClick={this.onEdit}
                >
                  <SSRIcon colour="black" paths={pencil} />
                </Link>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

DashboardDeliveryAddress.propTypes = {
  setAddressBeingEdited: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.object.isRequired,
  editForm: PropTypes.string.isRequired,
  addressBeingEdited: PropTypes.string,
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  secondRows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  name: PropTypes.arrayOf(PropTypes.string),
  contactTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  maskRules: PropTypes.object,
  amountOfDeliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  // From Redux/Thunks
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DashboardDeliveryAddress.defaultProps = {
  contactTypes: [],
  maskRules: {}
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.DELIVERY_ADDRESS_UPDATE],
    amountOfDeliveryAddresses: state.addresses.deliveryAddresses
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDeliveryAddress);
