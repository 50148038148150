import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, H2, FlexPanel } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './ShippingBillingAdresses.scss';

const DeliveryAddressSectionReadContainer = props => {
  const deliveryAddressTitle = props.deliveryAddressesFailed
    ? t(props.title)
    : `${t(props.title)} (${props.deliveryAddresses.length})`;
  return (
    <div className={styles['section-read']}>
      <FlexPanel spread className={styles['section-read-title']}>
        <H2 tagName="h2" headingSize="m" margin={false}>
          {deliveryAddressTitle}
        </H2>
      </FlexPanel>
      {props.children}
      {props.deliveryAddresses.length === 0 &&
        !props.deliveryAddressesFailed && (
          <Text marginBottom="s">{t('dashboard.address.deliveryMissing')}</Text>
        )}
    </div>
  );
};

DeliveryAddressSectionReadContainer.propTypes = {
  title: PropTypes.string.isRequired,
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  deliveryAddressesFailed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

const mapStateToProps = state => {
  return {
    deliveryAddresses: state.addresses.deliveryAddresses,
    deliveryAddressesFailed: state.addresses.deliveryAddressesFailed
  };
};

export default connect(
  mapStateToProps,
  null
)(DeliveryAddressSectionReadContainer);
