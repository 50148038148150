import React from 'react';
import { t } from 'Utils/localization/i18next';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import PropTypes from 'prop-types';
import { withOptimizely } from 'Components/Optimizely/Optimizely';
import DynamicFormRead from '../_form_extended/DynamicFormRead/DynamicFormRead';

const tabsHashMapping = {
  'tab-button-account': '#profile',
  'tab-button-address': '#address',
  'tab-button-login': '#login',
  'tab-button-preferences': '#preferences'
};

const hashTabsMapping = {
  '#profile': 'tab-button-account',
  '#address': 'tab-button-address',
  '#login': 'tab-button-login',
  '#preferences': 'tab-button-preferences'
};

const AccountSettingsTabs = props => {
  const decision = props.decide('profile_consent_boost');
  const sendOptimizelyEvent = event => {
    if (decision?.enabled) {
      props.trackEvent(event);
    }
  };
  const tabs = [
    <Tab
      key="tab-1"
      text={t(`dashboard.side_menu_tabs.account_settings.profile-details`)}
      tabPanelId="tab-button-account"
      prefix={CONFIG.APP.STYLES_PREFIX}
    />,
    <Tab
      key="tab-2"
      text={t(`dashboard.side_menu_tabs.account_settings.addresses`)}
      active="true"
      tabPanelId="tab-button-address"
      prefix={CONFIG.APP.STYLES_PREFIX}
    />,
    <Tab
      key="tab-3"
      text={t(`dashboard.side_menu_tabs.account_settings.login-security`)}
      tabPanelId="tab-button-login"
      prefix={CONFIG.APP.STYLES_PREFIX}
    />,
    ((props.profileType === 'regular' &&
      CONFIG.FEATURE.REGULAR_DASHBOARD.SHOW_PREFERENCES) ||
      (props.profileType === 'family' &&
        CONFIG.FEATURE.FAMILY_DASHBOARD.SHOW_PREFERENCES)) && (
      <Tab
        key="tab-4"
        text={t(`dashboard.side_menu_tabs.account_settings.communications`)}
        tabPanelId="tab-button-preferences"
        prefix={CONFIG.APP.STYLES_PREFIX}
        onClick={() => sendOptimizelyEvent('preferences_tab_clicked')}
      />
    )
  ];

  const tabPanels = [
    <TabPanel
      key="tab-1"
      tabPanelId="tab-button-account"
      prefix={CONFIG.APP.STYLES_PREFIX}
    >
      <DynamicFormRead
        formId={`${props.profileType}-dashboard-profile-details-read`}
      />
    </TabPanel>,
    <TabPanel
      key="tab-2"
      tabPanelId="tab-button-address"
      prefix={CONFIG.APP.STYLES_PREFIX}
    >
      <DynamicFormRead
        formId={`${props.profileType}-dashboard-billing-shipping-addresses-read`}
      />
    </TabPanel>,
    <TabPanel
      key="tab-3"
      tabPanelId="tab-button-login"
      prefix={CONFIG.APP.STYLES_PREFIX}
    >
      <DynamicFormRead
        formId={`${props.profileType}-dashboard-login-security`}
      />
    </TabPanel>,
    <TabPanel
      key="tab-4"
      tabPanelId="tab-button-preferences"
      prefix={CONFIG.APP.STYLES_PREFIX}
    >
      <DynamicFormRead
        formId={`${props.profileType}-dashboard-communication-preferences-read`}
      />
    </TabPanel>
  ];
  return (
    <React.Fragment>
      <Tabs
        tabs={tabs}
        tabPanels={tabPanels}
        defaultActiveTab={'tab-button-account'}
        activeTab={
          window.location.hash !== '' && hashTabsMapping[window.location.hash]
            ? hashTabsMapping[window.location.hash]
            : 'tab-button-account'
        }
        prefix={CONFIG.APP.STYLES_PREFIX}
        // eslint-disable-next-line no-return-assign
        onTabChanged={tab => (window.location.hash = tabsHashMapping[tab])}
      />
    </React.Fragment>
  );
};

AccountSettingsTabs.propTypes = {
  profileType: PropTypes.string,
  trackEvent: PropTypes.func,
  decide: PropTypes.func
};

const AccountSettingsTabsWithOptimizely = withOptimizely(AccountSettingsTabs);
export default AccountSettingsTabsWithOptimizely;
