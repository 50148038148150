import { t } from 'Utils/localization/i18next';

export function transformFields(response = {}, addressFieldMap) {
  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const daumFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!daumFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = daumFieldNames.reduce(
      (concatenatedValue, daumFieldName) => {
        // Look for a separator config between address text, separator starts with { and ends with }
        // Example: {/} between apartment name and apartment number should look like apartment name/apartment number
        const separatorMatch = daumFieldName.match(/^\{(.*)\}$/);

        if (separatorMatch) {
          nextSeparator = separatorMatch[1];
          return concatenatedValue;
        }

        const value = response[daumFieldName];
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap || !data) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (mappedValue.includes(prevValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  return data;
}
