import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { t, exists } from 'Utils/localization/i18next';
import {
  getFieldValuesByNames,
  getFieldMessagesByNames
} from 'Redux/forms/formsHelper';
import { Text, TextWithLink, TextWithModal } from 'Common';
import FormField from 'BaseForm/FormField';
import CheckboxControlled from 'BaseForm/CheckboxControlled';
import styles from './ContactMethodsGroupContainer.scss';

class ContactMethodsGroupContainer extends React.PureComponent {
  componentDidMount() {
    if (!this.isAllContactMethodsUnchecked(this.props.values)) {
      this.setAcceptContactValue(true);
    }
  }

  onAcceptContactChange = e => {
    // When "acceptContact" is checked,
    // all contact methods should be checked.
    this.setContactMethodsValues(e.target.checked);
    this.props.onChange(e);
  };

  onContactMethodChange = e => {
    const { checked, name } = e.target;
    const newValues = { ...this.props.values, [name]: checked };

    // "acceptContact" should be...
    // 1. Checked if any contact method is checked.
    // 2. Unchecked if no contact method is checked.
    if (checked || this.isAllContactMethodsUnchecked(newValues)) {
      this.setAcceptContactValue(checked);
    }

    this.props.onChange(e);
  };

  setAcceptContactValue = checked => {
    // Only propagate event if value has changed.
    if (this.props.values.acceptContact !== checked) {
      const e = this.newCheckboxEvent('acceptContact', checked);
      this.props.onChange(e);
    }
  };

  setContactMethodsValues = checked => {
    this.getContactMethodFields().forEach(field => {
      const { name } = field.componentProps;
      // Only propagate event if value has changed.
      if (this.props.values[name] !== checked) {
        const e = this.newCheckboxEvent(name, checked);
        this.props.onChange(e);
      }
    });
  };

  getAcceptContactField = () => {
    return this.props.nestedFields.find(
      field => field.componentProps.name === 'acceptContact'
    );
  };

  getContactMethodFields = () => {
    return this.props.nestedFields.filter(
      field => field.componentProps.name !== 'acceptContact'
    );
  };

  isAllContactMethodsUnchecked = values => {
    return !this.getContactMethodFields().some(
      field => values[field.componentProps.name]
    );
  };

  newCheckboxEvent = (name, checked) => {
    return {
      target: {
        name,
        checked,
        type: 'checkbox'
      }
    };
  };

  getInfoField = () => {
    const { infoField } = this.props;
    if (!infoField) {
      return null;
    }
    switch (infoField.component) {
      case 'Text':
        return (
          <Text className={styles['contact-methods-group-info']}>
            {t(infoField.componentProps.label)}
          </Text>
        );
      case 'TextWithLink':
        return (
          <TextWithLink
            className={styles['contact-methods-group-info']}
            labelBeforeLink={`${t(infoField.componentProps.labelBeforeLink)} `}
            labelLink={t(infoField.componentProps.labelLink)}
            labelAfterLink={
              exists(infoField.componentProps.labelAfterLink) &&
              t(infoField.componentProps.labelAfterLink)
            }
            link={t(infoField.componentProps.link)}
            linkTarget="_blank"
          />
        );
      case 'TextWithModal':
        return (
          <TextWithModal
            id={`${this.props.formId}-acceptContact-modal`}
            className={styles['contact-methods-group-info']}
            modalType="alert"
            {...infoField.componentProps}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const acceptContactField = this.getAcceptContactField();
    const contactMethodFields = this.getContactMethodFields();
    return (
      <div
        className={
          styles[
            !this.props.hideAcceptContactCheckbox && `contact-methods-group`
          ]
        }
      >
        {!this.props.hideAcceptContactCheckbox && (
          <CheckboxControlled
            formId={this.props.formId}
            name={acceptContactField.componentProps.name}
            label={acceptContactField.componentProps.label}
            value={this.props.values[acceptContactField.componentProps.name]}
            onChange={this.onAcceptContactChange}
            onBlur={this.props.onBlur}
          />
        )}
        <div
          className={
            CONFIG.FEATURE.RIGHT_TO_LEFT
              ? styles['contact-methods-group-checkboxes-rtl']
              : this.props.hideAcceptContactCheckbox
              ? styles['contact-methods-group-us-checkboxes']
              : styles['contact-methods-group-checkboxes']
          }
        >
          {contactMethodFields.map(field => {
            return (
              <FormField
                key={`contact-method-${field.componentProps.name}`}
                validation={{
                  id: `${this.props.formId}-${field.componentProps.name}-error`,
                  msg: this.props.messages[field.componentProps.name],
                  type: 'error'
                }}
                shouldValidate={
                  this.props.messages[field.componentProps.name] !== undefined
                }
              >
                <CheckboxControlled
                  formId={this.props.formId}
                  name={field.componentProps.name}
                  labelBeforeLink={field.componentProps.lableBeforeLinks}
                  label={field.componentProps.label}
                  value={this.props.values[field.componentProps.name]}
                  onChange={this.onContactMethodChange}
                  onBlur={this.props.onBlur}
                  lessBottomMargin
                  labelBetweenLinks={field.componentProps.labelBetweenLinks}
                  linkLabels={field.componentProps.linkLabels}
                  links={field.componentProps.linksURLs}
                />
              </FormField>
            );
          })}
        </div>
        {this.getInfoField()}
      </div>
    );
  }
}

ContactMethodsGroupContainer.propTypes = {
  formId: PropTypes.string.isRequired,
  hideAcceptContactCheckbox: PropTypes.bool,
  nestedFields: PropTypes.arrayOf(PropTypes.object),
  infoField: PropTypes.object, // { component: '', componentProps: '' }
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  labelBeforeLinks: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linksURLs: PropTypes.arrayOf(PropTypes.string),
  // From Redux
  values: PropTypes.object,
  messages: PropTypes.object
};

ContactMethodsGroupContainer.defaultProps = {
  values: {},
  messages: {},
  hideAcceptContactCheckbox: false
};

const mapStateToProps = (state, ownProps) => {
  const fieldNames =
    ownProps.nestedFields &&
    ownProps.nestedFields.map(field => field.componentProps.name);
  return {
    values: getFieldValuesByNames(state, ownProps.formId, fieldNames),
    messages: getFieldMessagesByNames(state, ownProps.formId, fieldNames)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactMethodsGroupContainer);
