// NOTE: these codes are internal for the application.
// They are not the codes returned by the backend.
// Those codes are translated to these internal error codes
// for consistency across clients.
//
// Prefix them with the type of error and group them below
export const ErrorCodes = {
  VERIFICATION_UNVERIFIED_MOBILE: 'VERIFICATION_UNVERIFIED_MOBILE',
  VERIFICATION_UNVERIFIED_EMAIL: 'VERIFICATION_UNVERIFIED_EMAIL',
  VERIFICATION_MOBILE_MUST_BE_VERIFIED: 'VERIFICATION_MOBILE_MUST_BE_VERIFIED',
  VERIFICATION_EMAIL_MUST_BE_VERIFIED: 'VERIFICATION_EMAIL_MUST_BE_VERIFIED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  VERIFICATION_STARTUP_FAILED: 'VERIFICATION_STARTUP_FAILED',
  VERIFICATION_COOLDOWN: 'VERIFICATION_COOLDOWN',
  VERIFICATION_FAILED_CONTEXT_NOT_FOUND:
    'VERIFICATION_FAILED_CONTEXT_NOT_FOUND',

  ACCOUNT_ALREADY_EXISTS: 'ACCOUNT_ALREADY_EXISTS',
  ACCOUNT_MOBILE_ALREADY_EXISTS: 'ACCOUNT_MOBILE_ALREADY_EXISTS',
  ACCOUNT_ALREADY_LOGGED_IN: 'ACCOUNT_ALREADY_LOGGED_IN',
  ACCOUNT_VALIDATION_FAILED: 'ACCOUNT_VALIDATION_FAILED',
  ACCOUNT_MISSING_PARAMETERS: 'ACCOUNT_MISSING_PARAMETERS',
  ACCOUNT_MISSING_MOBILE: 'ACCOUNT_MISSING_MOBILE',
  ACCOUNT_MISSING_EMAIL: 'ACCOUNT_MISSING_EMAIL',
  ACCOUNT_MISSING_POLICY: 'ACCOUNT_MISSING_POLICY',
  ACCOUNT_MISSING_TERMS: 'ACCOUNT_MISSING_TERMS',
  ACCOUNT_FAMILY_NOT_ENABLED: 'ACCOUNT_FAMILY_NOT_ENABLED',
  ACCOUNT_BUSINESS_NOT_ENABLED: 'ACCOUNT_BUSINESS_NOT_ENABLED',
  ACCOUNT_SSN_ALREADY_USED: 'ACCOUNT_SSN_ALREADY_USED',
  ACCOUNT_INVALID_EMAIL_DOMAIN: 'ACCOUNT_INVALID_EMAIL_DOMAIN',
  ACCOUNT_INVALID_ZIPCODE: 'ACCOUNT_INVALID_ZIPCODE',
  EMPTY_PAYLOAD: 'EMPTY_PAYLOAD',
  INVALID_PAYLOAD: 'INVALID_PAYLOAD',
  LOGONID_ALREADY_EXIST: 'LOGONID_ALREADY_EXIST',
  LOGONID_ALREADY_EXIST_ON_MOBILE_UPDATE:
    'LOGONID_ALREADY_EXIST_ON_MOBILE_UPDATE',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  LOGONID_TYPE_NOT_SUPPORTED: 'LOGONID_TYPE_NOT_SUPPORTED',
  ACCOUNT_INVALID_CHILD_BIRTH_DATE: 'ACCOUNT_INVALID_CHILD_BIRTH_DATE',

  PASSWORD_TOO_WEAK: 'PASSWORD_TOO_WEAK',
  PASSWORD_TOO_COMMON: 'PASSWORD_TOO_COMMON',
  PASSWORD_CONTAINS_USER_INFO: 'PASSWORD_CONTAINS_USER_INFO',
  PASSWORD_PREVIOUSLY_USED: 'PASSWORD_PREVIOUSLY_USED',
  PWD_INCORRECT: 'PWD_INCORRECT',

  ACCOUNT_PASSWORD_EXPIRED: 'ACCOUNT_PASSWORD_EXPIRED',
  ACCOUNT_MOBILE_BAD_FORMAT: 'ACCOUNT_MOBILE_BAD_FORMAT',
  ACCOUNT_DELIVERY_ADDRESS_QUANTITY_LIMIT:
    'ACCOUNT_DELIVERY_ADDRESS_QUANTITY_LIMIT',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  ACCOUNT_SESSION_EXPIRED: 'ACCOUNT_SESSION_EXPIRED',
  ACCOUNT_PAUSED_GDPR: 'ACCOUNT_PAUSED_GDPR',

  NETWORK_RESOURCE_NOT_FOUND: 'NETWORK_RESOURCE_NOT_FOUND',

  SERVICE_INTERNAL_ERROR: 'SERVICE_INTERNAL_ERROR',
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  SERVICE_TIMEOUT: 'SERVICE_TIMEOUT',
  SERVICE_BAD_REQUEST: 'SERVICE_BAD_REQUEST',

  ACCESS_DENIED: 'ACCESS_DENIED',
  ACCESS_INVALID_COOKIE: 'ACCESS_INVALID_COOKIE',
  ACCESS_USER_MISSING: 'ACCESS_USER_MISSING',
  ACCESS_WRONG_CREDENTIALS: 'ACCESS_WRONG_CREDENTIALS',

  INTERNAL_DATA_CREDENTIALS_EMPTY: 'INTERNAL_DATA_CREDENTIALS_EMPTY',

  UPDATE_PASSWORD_FAILED: 'UPDATE_PASSWORD_FAILED',

  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',

  UNKNOWN: 'UNKNOWN',
  PAYMENTID_MISSING: 'PAYMENT_ID_MISSING'
};
