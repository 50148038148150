export default class PostCodeNLClient {
  constructor() {
    this.apiUrl = CONFIG.API.ADDRESS_AUTOCOMPLETE.HOST;
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressFieldValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_VALUE_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
    this.addressFilterMap = Object.keys(
      CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP
    ).reduce((filterMap, fieldName) => {
      if (CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP[fieldName]) {
        filterMap[fieldName] =
          CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP[fieldName];
      }
      return filterMap;
    }, {});
  }

  async getAddress(houseNumber, postCode) {
    const requestBody = {
      country: this.countryCode,
      language: this.languageCode,
      postCode,
      houseNumber
    };
    return fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-client-id': `${CONFIG.API.ADDRESS_AUTOCOMPLETE.CLIENT_ID}`
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }

  async filter(searchData, successCallback) {
    if (!searchData.houseNumber || !searchData.zipCode) {
      successCallback();
    } else {
      let data = await this.getAddress(
        searchData.houseNumber,
        searchData.zipCode
      );
      successCallback({
        address1: data.addressLine1
          ? data.addressLine1?.match(/^[^0-9]+/)[0]?.trim()
          : searchData.address1,
        cityName: data.city ? data.city : searchData.cityName
      });
    }
  }
}
