import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import validate from 'Utils/validation/validate';
import autocorrect from 'Utils/autocorrect/autocorrect';
import FormField from 'BaseForm/FormField';
import { getFormValues, getFieldMessage } from 'Redux/forms/formsHelper';
import { Row, Col } from 'Common';
import {
  setFormFieldValueAction,
  setFormFieldMessagesAction
} from 'Redux/forms/formsActions';

import {
  SimpleInputComponents,
  NestedInputComponents,
  StaticComponents,
  Components
} from './components';

class DynamicFormFieldContainer extends React.PureComponent {
  onChange = e => {
    const { formId } = this.props;
    const { name, checked, type } = e.target;
    const value = e.target.type === 'checkbox' ? checked : e.target.value;

    if (/checkbox|radio|select/.test(type)) {
      // Checkbox and dropdown are validated "onChange" in order to display
      // immediate validation feedback if user unchecks a mandatory checkbox
      // or selects a value in a mandatory dropdown.
      const messages = this.validateField(name, value);
      this.props.setFormFieldMessagesAction(formId, name, messages);
    }
    this.props.setFormFieldValueAction(formId, name, value);
  };

  onBlur = e => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    const { name, value, type } = e.target;

    if (/checkbox|radio/.test(type)) {
      return;
    }

    const { formId } = this.props;
    const newValue = this.autocorrectField(name, value);
    const messages = this.validateField(name, newValue);
    this.props.setFormFieldMessagesAction(formId, name, messages);

    if (value !== newValue) {
      // Need to set value in Redux if auto correction changed it.
      this.props.setFormFieldValueAction(this.props.formId, name, newValue);
    }
  };

  validateField = (name, value) => {
    const field = this.getFieldPropsByName(name);
    return validate(
      this.props.formId,
      value,
      field.componentProps.label,
      field.validationRules,
      true,
      this.props.setFormFieldMessagesAction
    );
  };

  autocorrectField = (name, value) => {
    const { autocorrectRules } = this.getFieldPropsByName(name);
    let autocorrectValue = autocorrect(
      this.props.formId,
      value,
      autocorrectRules
    );
    autocorrectValue =
      typeof autocorrectValue === 'string'
        ? autocorrectValue.trim()
        : autocorrectValue;
    return autocorrectValue;
  };

  getFieldPropsByName = name => {
    let fieldProps;

    if (SimpleInputComponents[this.props.component]) {
      fieldProps = this.props;
    }
    if (NestedInputComponents[this.props.component]) {
      fieldProps = this.props.componentProps.nestedFields.find(
        field => field.componentProps.name === name
      );
    }
    return fieldProps || { componentProps: {} };
  };

  checkMandatory = fieldName => {
    const { validationRules } = this.getFieldPropsByName(fieldName);
    return (
      validationRules &&
      validationRules.some(rule => rule.name.startsWith('mandatory'))
    );
  };

  render() {
    const Component = Components[this.props.component];

    if (!Component || this.props.componentProps.hidden) {
      return null;
    }

    if (StaticComponents[this.props.component]) {
      return (
        <FormField>
          <Row>
            <Col sm={this.props.colWidth}>
              <Component {...this.props.componentProps} />
            </Col>
          </Row>
        </FormField>
      );
    }

    if (NestedInputComponents[this.props.component]) {
      // If a nested component should display validation
      // messages for their inner fields, this needs to be
      // handled by the component itself.
      return (
        <Row>
          <Col sm={this.props.colWidth}>
            <Component
              {...this.props.componentProps}
              formId={this.props.formId}
              checkMandatory={this.checkMandatory}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />
          </Col>
        </Row>
      );
    }

    const fieldName = this.props.componentProps.name;

    return (
      <Row>
        <Col sm={this.props.colWidth}>
          <FormField
            validation={{
              id: `${this.props.formId}-${fieldName}-error`,
              msg: this.props.validationMessage,
              type: 'error'
            }}
            fieldHelper={{
              id: `${fieldName}-info`,
              msg: t(this.props.infoMessage)
            }}
            shouldValidate={this.props.validationMessage !== undefined}
          >
            <Component
              {...this.props.componentProps}
              formId={this.props.formId}
              // Only controlled input components should accept
              // "value" in their propTypes.
              value={this.props.formValues[fieldName]}
              // Only uncontrolled input components should accept
              // "defaultValue" in their propTypes.
              defaultValue={this.props.formValues[fieldName]}
              showOptional={
                this.props.componentProps.showOptional !== undefined
                  ? this.props.componentProps.showOptional
                  : !this.checkMandatory(fieldName)
              }
              hasErrors={this.props.validationMessage !== undefined}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={this.props.onKeyDown}
            />
          </FormField>
        </Col>
      </Row>
    );
  }
}

DynamicFormFieldContainer.propTypes = {
  // Props from yml form configurations
  formId: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  componentProps: PropTypes.object,
  colWidth: PropTypes.number,
  infoMessage: PropTypes.string,
  validationRules: PropTypes.arrayOf(PropTypes.object),
  autocorrectRules: PropTypes.arrayOf(PropTypes.object),
  // Props from Redux/Thunks
  formValues: PropTypes.object,
  validationMessage: PropTypes.string,
  setFormFieldValueAction: PropTypes.func.isRequired,
  setFormFieldMessagesAction: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func
};

DynamicFormFieldContainer.defaultProps = {
  colWidth: 12,
  componentProps: {},
  validationRules: []
};

const mapStateToProps = (state, ownProps) => {
  if (!ownProps || !ownProps.componentProps) {
    return {};
  }
  return {
    formValues: getFormValues(state, ownProps.formId),
    validationMessage: getFieldMessage(
      state,
      ownProps.formId,
      ownProps.componentProps.name
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormFieldValueAction,
      setFormFieldMessagesAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicFormFieldContainer);
