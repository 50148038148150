import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlexPanel, Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import mask from 'Utils/mask/mask';
import styles from './CommunicationPreferences.scss';

const HouseHoldInformationReadContainer = props => {
  const EducationalLevelFunc = param => {
    switch (param) {
      case '1':
        return 'forms.household.educationLevel_1';
      case '3':
        return 'forms.household.educationLevel_3';
      case '5':
        return 'forms.household.educationLevel_5';
    }
    return '';
  };

  const MaritalStatusFunc = param => {
    switch (param) {
      case 1:
        return 'forms.household.maritalStatus_single';
      case 2:
        return 'forms.household.maritalStatus_married';
      case 3:
        return 'forms.household.maritalStatus_cohabitor';
    }
    return '';
  };

  const LivingSituationFunc = param => {
    switch (param) {
      case '0':
        return 'forms.household.livingSituation_defaultEmptyValue';
      case '4':
        return 'forms.household.livingSituation_partner';
      case '1':
        return 'forms.household.livingSituation_parents';
      case '2':
        return 'forms.household.livingSituation_spouse';
      case '11':
        return 'forms.household.livingSituation_noKids';
      case '3':
        return 'forms.household.livingSituation_familyWithKids';
      case '12':
        return 'forms.household.livingSituation_single';
      case '6':
        return 'forms.household.livingSituation_livingalone_roommate';
      case '5':
        return 'forms.household.livingSituation_other';
      case '13':
        return 'forms.household.livingSituation_single_with_children';
      case '8':
        return 'forms.household.livingSituation_couple_without_children';
      case '7':
        return 'forms.household.livingSituation_couple_with_children';
    }
    return '';
  };

  const contentItems = [...props.content];
  props.translationIndex.forEach(index => contentItems.splice(index));

  const placeholder =
    contentItems.length === 1 &&
    !props.profile[contentItems[0]] &&
    t(`dashboard.placeholders.${contentItems[0]}`);
  return (
    <>
      {(props.profile.educationLevel && props.profile.educationLevel !== '0') ||
      (props.profile.maritalStatus && props.profile.maritalStatus !== '0') ||
      props.profile.coExistenceInfo ||
      (props.profile.noOfChildren && props.profile.noOfChildren !== '0') ? (
        <Text marginBottom="s" className={styles['common-specifier-title']}>
          {t(
            `dashboard.communications.home-interest-section.subtitle-household`
          )}
        </Text>
      ) : (
        ''
      )}

      {!props.hideEducationLevel &&
        (props.profile.educationLevel !== '0' && props.profile.educationLevel && (
          <div>
            <div className={styles['sub-category-header']}>
              <p>
                {t(
                  `dashboard.communications.home-interest-section.educationLevel`
                )}
              </p>
            </div>
            <FlexPanel className={styles['residence-type-container-value']}>
              <Text className={styles['sub-category-value']}>
                {t(`${EducationalLevelFunc(props.profile.educationLevel)}`)}
              </Text>
            </FlexPanel>
          </div>
        ))}

      {!props.hideMaritalStatus &&
      props.profile.maritalStatus &&
      props.profile.maritalStatus !== '0' ? (
        <div>
          <div className={styles['sub-category-header']}>
            <p>
              {t(
                `dashboard.communications.home-interest-section.maritalStatus`
              )}
            </p>
          </div>
          <FlexPanel className={styles['residence-type-container-value']}>
            <Text className={styles['sub-category-value']}>
              {t(`${MaritalStatusFunc(props.profile.maritalStatus)}`)}
            </Text>
          </FlexPanel>
        </div>
      ) : (
        ''
      )}
      {!props.hideCoExistenceInfo && props.profile.coExistenceInfo && (
        <div>
          <div className={styles['sub-category-header']}>
            <p>
              {t(
                `dashboard.communications.home-interest-section.livingSituation`
              )}
            </p>
          </div>
          <FlexPanel className={styles['residence-type-container-value']}>
            <Text className={styles['sub-category-value']}>
              {t(`${LivingSituationFunc(props.profile.coExistenceInfo)}`)}
            </Text>
          </FlexPanel>
        </div>
      )}
      {!props.hideNoOfChildren && props.profile.noOfChildren && (
        <>
          <div className={styles['sub-category-header']}>
            <p>
              {t(`dashboard.communications.home-interest-section.children`)}
            </p>
          </div>
          <FlexPanel>
            {placeholder ? (
              <Text colour="grey-500">{placeholder}</Text>
            ) : (
              <Text className={styles['sub-category-value']}>
                {props.content.reduce((concatenated, name, index) => {
                  const key =
                    props.plural &&
                    props.profile[contentItems[props.pluralItemIndex]] > 1
                      ? `${name}_plural`
                      : name;
                  const value = props.translationIndex.includes(index)
                    ? t(key)
                    : props.profile[name]
                    ? `${mask(props.profile[name], props.maskRules)} `
                    : '';
                  return `${concatenated}${value}`;
                }, '')}
              </Text>
            )}
          </FlexPanel>
        </>
      )}
    </>
  );
};

HouseHoldInformationReadContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  profile: PropTypes.object,
  content: PropTypes.arrayOf(PropTypes.string),
  translationIndex: PropTypes.arrayOf(PropTypes.number),
  pluralItemIndex: PropTypes.number,
  plural: PropTypes.bool,
  maskRules: PropTypes.arrayOf(PropTypes.object),
  hideEducationLevel: PropTypes.bool,
  hideMaritalStatus: PropTypes.bool,
  hideCoExistenceInfo: PropTypes.bool,
  hideNoOfChildren: PropTypes.bool,
  showPets: PropTypes.bool
};

HouseHoldInformationReadContainer.defaultProps = {
  hideEducationLevel: false,
  hideMaritalStatus: false,
  hideCoExistenceInfo: false,
  hideNoOfChildren: false,
  showPets: false,
  name: ''
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(HouseHoldInformationReadContainer);
