export default class BazaarvoiceUASApiClientMock {
  /**
   * Mock function for getting User Authentication
   * String used for Bazaarvoice ratings and reviews
   * @param  {String} userId id of the logged in user
   * @return {Promise} fetch promise
   */
  getBVLoginToken(userId) {
    // logger.debug('Bazaarvoice UAS mock API getBVLoginToken called', userId);
    console.log('Bazaarvoice UAS mock API getBVLoginToken called', userId);
    return new Promise(resolve => {
      const mockToken = '123456789';
      resolve(mockToken);
    });
  }
}
