import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlexPanel, Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './CommunicationPreferences.scss';

const HomeInterestFieldReadContainer = props => {
  const Interests = param => {
    switch (param) {
      case '61':
        return 'forms.household.interests_61';
      case '62':
        return 'forms.household.interests_62';
      case '63':
        return 'forms.household.interests_63';
      case '64':
        return 'forms.household.interests_64';
      case '65':
        return 'forms.household.interests_65';
      case '66':
        return 'forms.household.interests_66';
      case '67':
        return 'forms.household.interests_67';
      case '68':
        return 'forms.household.interests_68';
      case '69':
        return 'forms.household.interests_69';
      case '71':
        return 'forms.household.interests_71';
      case '72':
        return 'forms.household.interests_72';
      case '73':
        return 'forms.household.interests_73';
      case '74':
        return 'forms.household.interests_74';
      case '75':
        return 'forms.household.interests_75';
      case '76':
        return 'forms.household.interests_76';
      case '77':
        return 'forms.household.interests_77';
      case '78':
        return 'forms.household.interests_78';
      case '79':
        return 'forms.household.interests_79';
      case '80':
        return 'forms.household.interests_80';
      case '81':
        return 'forms.household.interests_81';
      case '82':
        return 'forms.household.interests_82';
      case '83':
        return 'forms.household.interests_83';
      case '84':
        return 'forms.household.interests_84';
      case '85':
        return 'forms.household.interests_85';
      case '97':
        return 'forms.household.interests_97';
    }
    return '';
  };

  const interests = props.profile.interestCodes.filter(
    interest => !(props.showPets && interest === '69')
  );
  return (
    <div>
      <Text marginTop="s">
        {props.showPets && (
          <div>
            <div className={styles['sub-category-header']}>
              <p>{t('forms.household.interests_69')}</p>
            </div>
            {props.profile.interestCodes.includes('69') ? (
              <FlexPanel>
                <Text className={styles['sub-category-value']}>
                  {t(`forms.household.interests_69_optIn`)}
                </Text>
              </FlexPanel>
            ) : (
              <FlexPanel>
                <Text className={styles['sub-category-value']}>
                  {t(`forms.household.interests_69_optOut`)}
                </Text>
              </FlexPanel>
            )}
          </div>
        )}
      </Text>
      <Text marginTop="s" marginBottom="s">
        <div>
          {!props.hideInterests && interests.length > 0 && (
            <>
              <Text
                className={styles['common-specifier-title']}
                marginBottom="s"
              >
                {t(
                  'dashboard.communications.home-interest-section.subtitle-interests'
                )}
              </Text>

              <Text className={styles['sub-category-value']}>
                {interests
                  .map(element => t(`${Interests(element)}`))
                  .join(', ')}
              </Text>
            </>
          )}
        </div>
      </Text>
    </div>
  );
};

HomeInterestFieldReadContainer.propTypes = {
  profile: PropTypes.object,
  subscriptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  interestCodes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  allowSMS: PropTypes.string,
  allowEmail: PropTypes.string,
  AllowDirectMail: PropTypes.string,
  hidePreferredLanguage: PropTypes.bool,
  hideParticipateInCustomerPanel: PropTypes.bool,
  hideInterests: PropTypes.bool,
  showPets: PropTypes.bool
};

HomeInterestFieldReadContainer.defaultProps = {
  profile: {},
  interestCodes: [],
  hideInterests: false,
  showPets: false
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(HomeInterestFieldReadContainer);
