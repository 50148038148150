import { string, bool, func, oneOfType, element } from 'prop-types';
import React, { Component, createRef } from 'react';
import Label from '@ingka/label';
import classNames from 'classnames';
import Hyperlink from '@ingka/hyperlink';

const propTypes = {
  /** Unique ID string */
  id: string.isRequired,
  /** Unique name string */
  name: string,
  /** Unique value string */
  value: string,
  /** Text for the label */
  text: oneOfType([string, element]),
  /** Dispatched function onChange */
  onChange: func,
  /** Boolean if the checkbox is disabled */
  disabled: bool,
  checked: bool,
  labelLink: string,
  labelLinkText: string,
  describedById: string,
  prefix: string,
  className: string
};

class IkeaCheckboxControlled extends Component {
  constructor(props) {
    super(props);

    if (createRef) {
      this.checkbox = createRef();
    }
  }

  render() {
    const {
      className,
      prefix,
      id,
      checked,
      text,
      name,
      value,
      onChange,
      disabled,
      labelLink,
      labelLinkText,
      describedById
    } = this.props;
    const classes = classNames(`${prefix}checkbox`, className);

    return (
      <div className={classes}>
        <input
          ref={el => {
            this.checkbox = el;
          }}
          type="checkbox"
          value={value}
          name={name}
          id={id}
          disabled={disabled}
          onChange={onChange ? onChange.bind(this) : null}
          checked={checked}
          aria-describedby={describedById}
        />
        <span className={`${prefix}checkbox__symbol`} />
        {text && (
          <span className={`${prefix}checkbox__label`}>
            <Label
              prefix={prefix}
              htmlFor={id}
              label={text}
              reqText={''}
              id={describedById}
            />{' '}
            {labelLinkText && labelLink && (
              <Hyperlink prefix={prefix} text={labelLinkText} url={labelLink} />
            )}
          </span>
        )}
      </div>
    );
  }
}

IkeaCheckboxControlled.defaultProps = {
  id: 'checkbox',
  name: 'checkboxname',
  value: 'ett',
  checked: false,
  disabled: false,
  onChange: () => {},
  prefix: ''
};

IkeaCheckboxControlled.propTypes = propTypes;

export default IkeaCheckboxControlled;
