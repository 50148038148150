import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { t } from 'Utils/localization/i18next';
import {
  Grid,
  Row,
  Col,
  PageHeader,
  Text,
  Button,
  Loading,
  WithoutHeaderAndFooter
} from 'Common';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import Routes from 'Routes/profileRoutes';
import { login } from 'Redux/auth/authThunks';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import styles from './LoginInternalContainer.scss';

class LoginInternalContainer extends React.PureComponent {
  componentDidMount() {
    // Get query strings that might contain authorization code "code" and state "state".
    const {
      search,
      hash,
      showInternalLoginPage,
      pathname
    } = this.props.location;
    const isBasicLogin = new RegExp(Routes.LOGIN_BASIC).test(pathname);
    // "from" is a query string parameter that can be used
    // to redirect the user back after the login.
    // The fragment identifier "hash" is required to support URLs with "hash" like:
    // https://events.ikea.com/gb/en/events/#/store/croydon/
    const from = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.FROM
    );

    // "bveventid" is a query string parameter that is
    // used to identify a product page.
    const bveventid = getQueryStringValue(
      `${search}`,
      Routes.QUERY_PARAMS.BV_EVENT_ID
    );

    // User is already logged in
    if (!showInternalLoginPage) {
      this.props.login(
        null,
        bveventid,
        Routes.DASHBOARD,
        null,
        from,
        null,
        null,
        isBasicLogin
      );
    }
  }

  onSubmit = data => {
    // Get query strings that might contain authorization code "code" and state "state".
    const { search, hash, pathname } = this.props.location;
    const isBasicLogin = new RegExp(Routes.LOGIN_BASIC).test(pathname);

    // "from" is a query string parameter that can be used
    // to redirect the user back after the login.
    // The fragment identifier "hash" is required to support URLs with "hash" like:
    // https://events.ikea.com/gb/en/events/#/store/croydon/
    const from = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.FROM
    );

    // "bveventid" is a query string parameter that is
    // used to identify a product page.
    const bveventid = getQueryStringValue(
      `${search}`,
      Routes.QUERY_PARAMS.BV_EVENT_ID
    );
    this.props.login(
      data,
      bveventid,
      Routes.DASHBOARD,
      null,
      from,
      isBasicLogin ? 'login-basic-form' : 'login-form'
    );
  };

  onSignupClick = () => {
    this.props.history.push({
      pathname: Routes.SIGNUP,
      search: this.props.location.search
    });
  };

  onSignupBasicClick = () => {
    this.props.history.push({
      pathname: Routes.SIGNUP_BASIC,
      search: this.props.location.search
    });
  };

  render() {
    const { pathname } = this.props.location;
    const isBasicLogin = new RegExp(Routes.LOGIN_BASIC).test(pathname);

    if (!this.props.location.showInternalLoginPage && isBasicLogin) {
      return (
        <WithoutHeaderAndFooter>
          <Loading isLoading text="" />
        </WithoutHeaderAndFooter>
      );
    }
    if (!this.props.location.showInternalLoginPage)
      return <Loading isLoading text="" />;

    if (this.props.isLoading && isBasicLogin) {
      return (
        <WithoutHeaderAndFooter>
          <Loading isLoading text="" />
        </WithoutHeaderAndFooter>
      );
    }
    if (this.props.isLoading) {
      return (
        <Loading
          isLoading={this.props.isLoading}
          text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
        />
      );
    }
    if (isBasicLogin) {
      return (
        <WithoutHeaderAndFooter>
          <Grid className={styles['login-internal']}>
            <Row>
              <Col
                sm={5}
                lg={5}
                xl={4}
                className={
                  CONFIG.FEATURE.RIGHT_TO_LEFT
                    ? styles['login-internal-right']
                    : styles['login-internal-left']
                }
              >
                <PageHeader center={false} margin={false}>
                  {t('login.pageTitle')}
                </PageHeader>
                <Text marginBottom="m" marginTop="m">
                  {t('login.text')}{' '}
                </Text>
                <Button
                  marginTop="xl"
                  type="secondary"
                  text={t('login.signupButton')}
                  className={styles['login-internal-signup-btn-desktop']}
                  onClick={this.onSignupBasicClick}
                />
              </Col>
              <Col sm={1} lg={2} xl={3} />
              <Col sm={6} lg={5} xl={5}>
                <DynamicFormContainer
                  formId="login-basic-form"
                  textSubmit={t('login.submitButton')}
                  onSubmit={this.onSubmit}
                />
                <Button
                  mobileFullWidth
                  marginTop="m"
                  type="secondary"
                  text={t('login.signupButton')}
                  className={styles['login-internal-signup-btn-mobile']}
                  onClick={this.onSignupBasicClick}
                />
              </Col>
            </Row>
          </Grid>
        </WithoutHeaderAndFooter>
      );
    }

    return (
      <Grid className={styles['login-internal']}>
        <Row>
          <Col
            sm={5}
            lg={5}
            xl={4}
            className={
              CONFIG.FEATURE.RIGHT_TO_LEFT
                ? styles['login-internal-right']
                : styles['login-internal-left']
            }
          >
            <PageHeader center={false} margin={false}>
              {t('login.pageTitle')}
            </PageHeader>
            <Text marginBottom="m" marginTop="m">
              {t('login.text')}{' '}
            </Text>
            <Button
              marginTop="xl"
              type="secondary"
              text={t('login.signupButton')}
              className={styles['login-internal-signup-btn-desktop']}
              onClick={this.onSignupClick}
            />
          </Col>
          <Col sm={1} lg={2} xl={3} />
          <Col sm={6} lg={5} xl={5}>
            <DynamicFormContainer
              formId="login-form"
              textSubmit={t('login.submitButton')}
              onSubmit={this.onSubmit}
            />
            <Button
              mobileFullWidth
              marginTop="m"
              type="secondary"
              text={t('login.signupButton')}
              className={styles['login-internal-signup-btn-mobile']}
              onClick={this.onSignupClick}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

LoginInternalContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  login: PropTypes.func,
  isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login
    },
    dispatch
  );
};
const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.LOGIN]
  };
};
const LoginInternalWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginInternalContainer);
export default withRouter(LoginInternalWithConnect);
