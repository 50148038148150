// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body:focus:not(.profile__focus-visible){outline:none}.profile__no-focus-outline a:focus,.profile__no-focus-outline button:focus,.profile__no-focus-outline select:focus,.profile__no-focus-outline div:focus{outline:none}", "",{"version":3,"sources":["webpack://./src/styles/common.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CAGF,wJAIE,YAAA","sourcesContent":["body:focus:not(.focus-visible) {\n  outline: none;\n}\n\n.no-focus-outline a:focus,\n.no-focus-outline button:focus,\n.no-focus-outline select:focus,\n.no-focus-outline div:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"focus-visible": "profile__focus-visible",
	"no-focus-outline": "profile__no-focus-outline"
};
export default ___CSS_LOADER_EXPORT___;
