import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SSRIcon from '@ingka/ssr-icon';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right';
import { t } from 'Utils/localization/i18next';
import styles from './AddressAutocomplete.scss';

export const LoqateAddressyOption = props => {
  return (
    <div
      id={`option-${props.index}`}
      className={classNames(styles['address-autocomplete-option'], {
        [styles.selected]: props.selected
      })}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
    >
      <div>
        <div>{props.option.label}</div>
        <div>
          <small>
            {props.option.subLabel.replace(
              'Addresses',
              t('forms.addressResults')
            )}
          </small>
        </div>
      </div>
      {!props.option.isCapturable && (
        <div className={styles['address-autocomplete-count-wrap']}>
          <SSRIcon
            colour="grey-300"
            paths={arrowRight}
            className={styles['address-autocomplete-count-icon']}
          />
        </div>
      )}
    </div>
  );
};

LoqateAddressyOption.propTypes = {
  selected: PropTypes.bool,
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};
