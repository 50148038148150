// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__reset-password{min-height:50vh;padding-top:6rem !important;padding-bottom:6rem !important}.profile__reset-password-login-btn-desktop{min-width:16rem}.profile__reset-password-login-btn-mobile{display:none;width:100%}@media(max-width: 576px){.profile__reset-password{padding-top:2rem !important;padding-bottom:2rem !important}.profile__reset-password-left{padding-right:6rem}.profile__reset-password-login-btn-desktop{display:none}.profile__reset-password-login-btn-mobile{display:block}}", "",{"version":3,"sources":["webpack://./src/components/Password/ResetPasswordContainer.scss"],"names":[],"mappings":"AAEA,yBACE,eAAA,CACA,2BAAA,CACA,8BAAA,CAEA,2CACE,eAAA,CAGF,0CACE,YAAA,CACA,UAAA,CAIJ,yBACE,yBACE,2BAAA,CACA,8BAAA,CAEA,8BACE,kBAAA,CAGF,2CACE,YAAA,CAGF,0CACE,aAAA,CAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.reset-password {\n  min-height: 50vh;\n  padding-top: 6rem !important;\n  padding-bottom: 6rem !important;\n\n  &-login-btn-desktop {\n    min-width: 16rem;\n  }\n\n  &-login-btn-mobile {\n    display: none;\n    width: 100%;\n  }\n}\n\n@media (max-width: 576px) {\n  .reset-password {\n    padding-top: 2rem !important;\n    padding-bottom: 2rem !important;\n\n    &-left {\n      padding-right: 6rem;\n    }\n\n    &-login-btn-desktop {\n      display: none;\n    }\n\n    &-login-btn-mobile {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-password": "profile__reset-password",
	"reset-password-login-btn-desktop": "profile__reset-password-login-btn-desktop",
	"reset-password-login-btn-mobile": "profile__reset-password-login-btn-mobile",
	"reset-password-left": "profile__reset-password-left"
};
export default ___CSS_LOADER_EXPORT___;
