import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Modal, Text, TextWithLink } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getFieldValuesByNames } from 'Redux/forms/formsHelper';
import IkeaCheckboxControlled from '../_form_ikea_copies/IkeaCheckboxControlled';

class MultipleConsentCheckbox extends React.PureComponent {
  componentDidMount() {
    const hasInconsistentValues = this.props.extraNames.some(
      extraName =>
        this.props.values[extraName] !== this.props.values[this.props.name]
    );
    if (hasInconsistentValues) {
      this.changeExtraValues(this.props.values[this.props.name]);
    }
  }

  onChange = e => {
    this.props.onChange(e);
    this.changeExtraValues(e.target.checked);
  };

  changeExtraValues = value => {
    this.props.extraNames.forEach(extraName => {
      this.props.onChange({
        target: {
          name: extraName,
          value
        }
      });
    });
  };

  render() {
    if (!this.props.labelInfoLink) {
      return (
        <IkeaCheckboxControlled
          id={`${this.props.formId}-${this.props.name}`}
          name={this.props.name}
          checked={this.props.values[this.props.name]}
          text={t(this.props.label)}
          prefix={CONFIG.APP.STYLES_PREFIX}
          describedById={`${this.props.formId}-${this.props.name}-error`}
          onChange={this.onChange}
        />
      );
    }

    const infoPopupButtonText = t(this.props.infoPopupButtonText);

    const textElement = (
      <div>
        {this.props.labelInfoLinkLineBreak ? (
          <Text>{t(this.props.label)}</Text>
        ) : (
          t(this.props.label)
        )}
        <Modal
          modalType="informational"
          title={t(this.props.infoPopupTitle)}
          linkId="multiple-consent-info-link"
          linkText={t(this.props.labelInfoLink)}
          linkStyle={{ textDecoration: 'underline' }}
          modalBtns={
            infoPopupButtonText && [
              {
                id: 'multiple-consent-confirm',
                key: '2',
                htmlType: 'button',
                text: infoPopupButtonText,
                type: 'filled',
                onClick: (e, close) => {
                  e.preventDefault();
                  close();
                }
              }
            ]
          }
        >
          {this.props.infoPopupText1 && (
            <Text marginTop="m">
              {t(this.props.infoPopupText1)}
              {this.props.infoPopupBoldText && (
                <b>{t(this.props.infoPopupBoldText)}</b>
              )}
              {this.props.infoPopupAfterBoldText &&
                t(this.props.infoPopupAfterBoldText)}
            </Text>
          )}
          {this.props.infoPopupText2 && (
            <Text marginTop="m" marginBottom="m">
              {t(this.props.infoPopupText2)}
            </Text>
          )}
          {this.props.infoPopupLink && (
            <TextWithLink
              labelBeforeLink={t(this.props.infoPopupLinkTextBefore)}
              labelAfterLink={t(this.props.infoPopupLinkTextAfter)}
              labelLink={t(this.props.infoPopupLinkText)}
              link={t(this.props.infoPopupLink)}
              linkTarget="_blank"
            >
              {t(this.props.infoPopupLinkText)}
            </TextWithLink>
          )}
        </Modal>
      </div>
    );

    return (
      <IkeaCheckboxControlled
        id={`${this.props.formId}-${this.props.name}`}
        name={this.props.name}
        checked={this.props.values[this.props.name]}
        text={textElement}
        prefix={CONFIG.APP.STYLES_PREFIX}
        describedById={`${this.props.formId}-${this.props.name}-error`}
        onChange={this.onChange}
      />
    );
  }
}

MultipleConsentCheckbox.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string.isRequired,
  extraNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  labelInfoLink: PropTypes.string,
  labelInfoLinkLineBreak: PropTypes.bool,
  infoPopupButtonText: PropTypes.string,
  infoPopupTitle: PropTypes.string,
  infoPopupText1: PropTypes.string,
  infoPopupBoldText: PropTypes.string,
  infoPopupAfterBoldText: PropTypes.string,
  infoPopupText2: PropTypes.string,
  infoPopupLink: PropTypes.string,
  infoPopupLinkTextBefore: PropTypes.string,
  infoPopupLinkTextAfter: PropTypes.string,
  infoPopupLinkText: PropTypes.string,
  onChange: PropTypes.func,
  // From Redux
  values: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    values: getFieldValuesByNames(state, ownProps.formId, [
      ...ownProps.extraNames,
      ownProps.name
    ])
  };
};

export default connect(
  mapStateToProps,
  null
)(MultipleConsentCheckbox);
