// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__h-center{text-align:center}.profile__h-margin{margin-bottom:1rem}.profile__h:focus{outline:none !important}", "",{"version":3,"sources":["webpack://./src/components/_common/Headers/Headers.scss"],"names":[],"mappings":"AAGE,mBACE,iBAAA,CAGF,mBACE,kBAAA,CAIJ,kBACE,uBAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.h {\n  &-center {\n    text-align: center;\n  }\n\n  &-margin {\n    margin-bottom: 1rem;\n  }\n}\n\n.h:focus {\n  outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h-center": "profile__h-center",
	"h-margin": "profile__h-margin",
	"h": "profile__h"
};
export default ___CSS_LOADER_EXPORT___;
