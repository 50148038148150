import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, TextWithLink, InlineText, LegalText } from 'Common';
// import classNames from 'classnames';
import Switch from '@ingka/switch';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';
import styled from 'styled-components';
import styles from './SwitchControlled.scss';

export const StyledTitle = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  color: #111111;
`;

const SwitchControlled = props => {
  return (
    <React.Fragment>
      {props.findPotentialCustomerForm && (
        <Text marginTop="m" marginBottom="s" />
      )}
      <div className={styles[!props.hideSubText && 'switch-container']}>
        <StyledTitle>{t(props.title)}</StyledTitle>
        <Switch
          id={`${props.formId}-${props.name}`}
          name={props.name}
          // Defines default state of the IkeaCheckbox.
          checked={props.value}
          // It is not possible to handle IkeaCheckbox as a "controlled"
          // input. A workaround is to use the "checked" prop and set a
          // new default state of the checkbox. Default state is only
          // considered when the component is mounting. Component remount
          // can be triggered by toggling the "key" prop.
          // key={`${props.formId}-${props.name}-${props.value}`}
          text={
            props.label ? (
              <Text>{t(props.label)}</Text>
            ) : (
              <TextWithLink
                labelBeforeLink={t(props.labelBeforeLink)}
                labelAfterLink={t(props.labelAfterLink)}
                labelLink={t(props.labelLink)}
                link={t(props.link)}
                linkTarget="_blank"
                lineBreak={props.labelLinkLineBreak}
              />
            )
          }
          prefix={CONFIG.APP.STYLES_PREFIX}
          disabled={props.disabled}
          describedById={`${props.formId}-${props.name}-error`}
          // className={classNames(styles.checkbox, props.className, {
          //   [styles[`checkbox-margin-${props.margin}`]]: props.margin !== undefined,
          //   [styles[`checkbox-margin-top-${props.marginTop}`]]:
          //     props.marginTop !== undefined,
          //   [styles[`checkbox-margin-left-${props.marginLeft}`]]:
          //     props.marginLeft !== undefined,
          //   [styles[`checkbox-margin-right-${props.marginRight}`]]:
          //     props.marginRight !== undefined,
          //   [styles[`checkbox-margin-bottom-${props.marginBottom}`]]:
          //     props.marginBottom !== undefined
          // })}
          onChange={props.onChange}
          label={
            props.hideSubText &&
            (props.value
              ? t('forms.subscription_on')
              : t('forms.subscription_off'))
          }
        />
        {props.extraTextMsg && <InlineText msg={t(props.extraTextMsg)} />}
      </div>
      {props.findPotentialCustomerForm && <Text>{t(props.subtitle)}</Text>}
      {props.legalText && <LegalText props={props.legalPhraseKeys} />}
    </React.Fragment>
  );
};

SwitchControlled.propTypes = {
  formId: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  currentSwitch: PropTypes.string,
  label: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  labelLinkLineBreak: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  extraTextMsg: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
  hideSmsExplanation: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  onChange: PropTypes.func,
  termsConditionsLink: PropTypes.string,
  privacyPolicyLink: PropTypes.string,
  findPotentialCustomerForm: PropTypes.bool,
  subtitle: PropTypes.string,
  hideSubText: PropTypes.bool,
  legalText: PropTypes.bool,
  legalPhraseKeys: PropTypes.arrayOf(PropTypes.string)
};

SwitchControlled.defaultProps = {
  hideSmsExplanation: true,
  findPotentialCustomerForm: false,
  currentSwitch: 'default',
  hideSubText: true,
  legalText: false
};

export default SwitchControlled;
