import { writeCookie, deleteCookie } from '../cookies/cookiesUtils';

const BV_LOGIN_TOKEN_COOKIE_NAME = `IRMW_USER_BV_ID_${CONFIG.COUNTRY.toUpperCase()}`;

export const writeBVLoginTokenCookie = (bvLoginToken, expiryTimeMs) => {
  if (!bvLoginToken) {
    return;
  }
  const expiryDate = new Date();
  expiryDate.setMilliseconds(expiryDate.getMilliseconds() + expiryTimeMs);
  writeCookie(BV_LOGIN_TOKEN_COOKIE_NAME, bvLoginToken, expiryDate);
};

export const removeBVLoginTokenCookie = () => {
  deleteCookie(BV_LOGIN_TOKEN_COOKIE_NAME);
};

export const getBVEventIdFromQueryStrings = queryStrings => {
  const params = decodeURIComponent(queryStrings).match(
    /state="bveventid=([^"&]*)/
  );
  return params && params.length > 1 ? params[1] : null;
};

export const directToBVContainer = (bvLoginToken, bvEventId) => {
  const containerUrl = `${CONFIG.URL.BAZAARVOICE_CONTAINER}?bveventid=${bvEventId}&bvuserToken=${bvLoginToken}`;
  window.location = containerUrl;
};
