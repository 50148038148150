import PropTypes from 'prop-types';

const AuthSection = props => props.children;

AuthSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default AuthSection;
