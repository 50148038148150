import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getFieldValue } from 'Redux/forms/formsHelper';
import IkeaCheckboxControlled from '../_form_ikea_copies/IkeaCheckboxControlled';

const MultiLinkConsent = props => {
  const onChange = e => {
    props.onChange({
      target: {
        name: props.name,
        value: e.target.checked
      }
    });
    props.onChange(e);
  };

  const elementText = (
    <div>
      {t(props.labelBeforeLinks)}
      {props.labelBetweenLinks.map((item, index) => (
        <span key={index}>
          {props.lineBreak && <br />}
          {t(props.labelBetweenLinks[index])}
          <Link underline url={t(props.linksURLs[index])} target="_blank">
            {t(props.linkLabels[index])}
          </Link>
        </span>
      ))}
      {t(props.labelAfterLink)}
    </div>
  );

  return (
    <IkeaCheckboxControlled
      id={`${props.formId}-${props.name}`}
      name={props.name}
      checked={props.value}
      text={elementText}
      prefix={CONFIG.APP.STYLES_PREFIX}
      describedById={`${props.formId}-${props.name}-error`}
      onChange={onChange}
    />
  );
};

MultiLinkConsent.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  labelBeforeLinks: PropTypes.string,
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  linksURLs: PropTypes.arrayOf(PropTypes.string),
  labelAfterLink: PropTypes.string,
  lineBreak: PropTypes.bool,
  onChange: PropTypes.func,
  // From Redux
  value: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getFieldValue(state, ownProps.formId, ownProps.name)
  };
};

export default connect(
  mapStateToProps,
  null
)(MultiLinkConsent);
