import { push } from 'connected-react-router';
import { ReduxStore } from 'Redux';
import { login } from 'Redux/auth/authThunks';
import { loadingAction } from 'Redux/userInterface/userInterfaceActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { PaymentsService } from 'Services/ServiceFactory';
import Routes from '../../routes/profileRoutes';
import { setPayments } from './paymentActions';

export const getPayments = () => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PAYMENTS_GET, true));

      const { loggedIn } = ReduxStore.getState().auth;

      if (!loggedIn) {
        await dispatch(login());
      }

      const { userId } = ReduxStore.getState().profile;
      const paymentResp = await PaymentsService.getPayments(userId);
      if (!paymentResp) {
        throw Error();
      }
      await dispatch(setPayments(paymentResp));
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.paymentGet'
        })
      );
    } finally {
      dispatch(loadingAction(LOADING.PAYMENTS_GET, false));
    }
  };
};

export const removePayment = (paymentId, cb) => {
  return async dispatch => {
    try {
      const { loggedIn } = ReduxStore.getState().auth;
      if (!loggedIn) {
        await dispatch(login());
      }
      const { userId } = ReduxStore.getState().profile;
      const removePayResp = await PaymentsService.removePayment(paymentId);
      if (!removePayResp) {
        throw Error();
      }
      const paymentResp = await PaymentsService.getPayments(userId);
      await dispatch(setPayments(paymentResp));

      if (cb && typeof cb === 'function') {
        cb();
      }

      if (
        paymentResp &&
        Array.isArray(paymentResp) &&
        paymentResp.length === 0
      ) {
        dispatch(push(Routes.LOGIN));
      }
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.paymentDelete'
        })
      );
    } finally {
      if (!cb && typeof cb === 'function') {
        cb();
      }
    }
  };
};

export const setAsDefaultPayment = (paymentId, cb) => {
  return async dispatch => {
    try {
      const { loggedIn } = ReduxStore.getState().auth;
      if (!loggedIn) {
        await dispatch(login());
      }
      const { userId } = ReduxStore.getState().profile;
      const defaultPayResponse = await PaymentsService.setAsDefaultPayment(
        paymentId
      );
      if (!defaultPayResponse) {
        throw Error();
      }
      const paymentResp = await PaymentsService.getPayments(userId);
      await dispatch(setPayments(paymentResp));
      if (cb && typeof cb === 'function') {
        cb();
      }
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.paymentDefault'
        })
      );
    } finally {
      if (!cb && typeof cb === 'function') cb();
    }
  };
};
