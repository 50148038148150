export default class StoreEventsApiClient {
  constructor() {
    this.apiUrl = `${CONFIG.API.STORE_EVENTS.HOST}/retail/Store/${CONFIG.COUNTRY}`;
    this.headers = {
      'content-type': 'application/json',
      'accept-language': `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY}`
    };
  }

  getStores() {
    return fetch(this.apiUrl, {
      method: 'GET',
      headers: {
        ...this.headers
      }
    })
      .then(response => {
        return response.status === 200 ? response.json() : [];
      })
      .then(data => {
        return data;
      })
      .catch(() => {
        return [];
      });
  }
}
