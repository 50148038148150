import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, SSRIcon } from 'Common';
import classNames from 'classnames';
import styles from './InlineAlert.scss';

export const InlineAlert = props => (
  <div
    id={props.id}
    style={props.style}
    className={classNames(styles['inline-alert'], {
      [styles[`inline-alert-margin-${props.margin}`]]:
        props.margin !== undefined,
      [styles[`inline-alert-margin-top-${props.marginTop}`]]:
        props.marginTop !== undefined,
      [styles[`inline-alert-margin-left-${props.marginLeft}`]]:
        props.marginLeft !== undefined,
      [styles[`inline-alert-margin-right-${props.marginRight}`]]:
        props.marginRight !== undefined,
      [styles[`inline-alert-margin-bottom-${props.marginBottom}`]]:
        props.marginBottom !== undefined,
      [props.className]: props.className
    })}
  >
    <SSRIcon icon={props.icon} size="200" />
    <div>
      {props.title && <Text bold>{t(props.title)}</Text>}
      {props.text && <Text>{t(props.text)}</Text>}
    </div>
  </div>
);

InlineAlert.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  className: PropTypes.string,
  style: PropTypes.object
};

export default InlineAlert;
