import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendEmail } from 'Redux/verification/verificationThunks';
import DashboardVerificationMessage from './DashboardVerificationMessage';

const DashboardVerificationBannerContainer = props => {
  return CONFIG.FEATURE.ENABLE_EMAIL_VERIFICATION &&
    props.emailSentStatus === false ? (
    <DashboardVerificationMessage
      title="dashboard.emailVerification.errorTitle"
      body="dashboard.emailVerification.errorBody"
      dismissLink="dashboard.emailVerification.dismiss"
    />
  ) : CONFIG.FEATURE.ENABLE_EMAIL_VERIFICATION &&
    props.emailSentStatus === true ? (
    <DashboardVerificationMessage
      title="dashboard.emailVerification.title"
      body="dashboard.emailVerification.body"
      dismissLink="dashboard.emailVerification.dismiss"
      emailSent="dashboard.emailVerification.emailSent"
    />
  ) : CONFIG.FEATURE.ENABLE_EMAIL_VERIFICATION &&
    !props.verified.email &&
    props.email ? (
    <DashboardVerificationMessage
      title="dashboard.emailVerification.title"
      body="dashboard.emailVerification.body"
      dismissLink="dashboard.emailVerification.dismiss"
      verifyLink="dashboard.emailVerification.verify"
      verifyLinkAction={props.sendEmail}
    />
  ) : CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION &&
    !props.isOTPSent &&
    props.otpSentFrom === 'dashboard' &&
    !props.otpSentError ? (
    <DashboardVerificationMessage
      title="dashboard.mobileVerification.errorTitle"
      body="dashboard.mobileVerification.errorBody"
      dismissLink="dashboard.mobileVerification.dismiss"
    />
  ) : CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION &&
    !props.isOTPSent &&
    props.otpSentFrom === 'dashboard' &&
    props.otpSentError ? (
    <DashboardVerificationMessage
      title="dashboard.mobileVerification.errorTitle"
      body={props.otpSentError}
      dismissLink="dashboard.mobileVerification.dismiss"
      openContactSectionLink="dashboard.mobileVerification.editMobile"
      profileType={props.profileType}
    />
  ) : null;
};

DashboardVerificationBannerContainer.propTypes = {
  verified: PropTypes.object,
  isOTPSent: PropTypes.bool,
  otpSentError: PropTypes.string,
  otpSentFrom: PropTypes.string,
  sendEmail: PropTypes.func,
  emailSentStatus: PropTypes.bool,
  email: PropTypes.string,
  mobile: PropTypes.string,
  profileType: PropTypes.string
};

const mapStateToProps = state => {
  return {
    verified: {
      mobile: state.profile.phoneVerified,
      email: state.verification.emailVerifiedStatus
    },
    isOTPSent: state.verification.otpSentStatus,
    otpSentError: state.verification.otpSentError,
    otpSentFrom: state.verification.otpSentFrom,
    emailSentStatus: state.verification.emailSentStatus,
    email: state.profile.email,
    mobile: state.profile.mobile,
    profileType: state.profile.profileType
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendEmail
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardVerificationBannerContainer);
