import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, InlineAlert } from 'Common';
import { t } from 'Utils/localization/i18next';
import FormField from 'BaseForm/FormField';
import InputField from 'BaseForm/InputField';
import PhoneInput from 'ExtendedForm/PhoneInput/PhoneInput';
import {
  setFormFieldValueAction,
  setFormFieldMessagesAction
} from 'Redux/forms/formsActions';
import {
  getFieldValuesByNames,
  getFieldMessagesByNames
} from 'Redux/forms/formsHelper';

class UserLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatingEmail: false,
      isUpdatingMobile: false
    };
  }

  onEmailChange = e => {
    e.preventDefault();
    const newEmail = e.target.value ? e.target.value.toLowerCase() : '';
    const isUpdatingEmail = newEmail !== this.props.profile.email;
    if (this.state.isUpdatingEmail !== isUpdatingEmail) {
      this.setState({ isUpdatingEmail }, this.clearHiddenFields);
    }
    this.props.onChange(e);
  };

  onMobileChange = e => {
    const isUpdatingMobile = e.target.value !== this.props.profile.mobile;
    if (this.state.isUpdatingMobile !== isUpdatingMobile) {
      this.setState({ isUpdatingMobile });
    }
    this.props.onChange(e);
  };

  clearHiddenFields = () => {
    if (!this.state.isUpdatingEmail) {
      this.props.setFormFieldValueAction(this.props.formId, 'email2');
      this.props.setFormFieldValueAction(this.props.formId, 'password');
      this.props.setFormFieldMessagesAction(this.props.formId, 'email2');
      this.props.setFormFieldMessagesAction(this.props.formId, 'password');
    }
  };

  render() {
    const fields = this.props.nestedFields.reduce(
      (namedFields, currentField) => {
        const fieldName = currentField.componentProps.name;
        // eslint-disable-next-line no-param-reassign
        namedFields[fieldName] = currentField;
        return namedFields;
      },
      {}
    );

    return (
      <React.Fragment>
        {fields.mobile && (
          <Row>
            <Col sm={fields.mobile.colWidth}>
              {this.props.isMobileUserLogin && this.state.isUpdatingMobile && (
                <InlineAlert
                  title={t('forms.phoneUpdateInfoBoxTitle')}
                  text={t('forms.phoneUpdateInfoBoxText')}
                  marginTop="m"
                  marginBottom="m"
                />
              )}
              <FormField
                validation={{
                  id: `${this.props.formId}-${fields.mobile.componentProps.name}-error`,
                  msg: this.props.messages[fields.mobile.componentProps.name],
                  type: 'error'
                }}
                shouldValidate={
                  this.props.messages[fields.mobile.componentProps.name] !==
                  undefined
                }
              >
                <PhoneInput
                  {...fields.mobile.componentProps}
                  formId={this.props.formId}
                  value={this.props.values[fields.mobile.componentProps.name]}
                  // extraTextMsg={t('forms.emailUpdateInfo')}
                  showOptional={
                    !this.props.checkMandatory(
                      fields.mobile.componentProps.name
                    )
                  }
                  onChange={this.onMobileChange}
                  onBlur={this.props.onBlur}
                />
              </FormField>
            </Col>
          </Row>
        )}

        {fields.email && (
          <Row>
            <Col sm={fields.email.colWidth}>
              <FormField
                validation={{
                  id: `${this.props.formId}-${fields.email.componentProps.name}-error`,
                  msg: this.props.messages[fields.email.componentProps.name],
                  type: 'error'
                }}
                shouldValidate={
                  this.props.messages[fields.email.componentProps.name] !==
                  undefined
                }
              >
                <InputField
                  formId={this.props.formId}
                  name={fields.email.componentProps.name}
                  defaultValue={
                    this.props.values[fields.email.componentProps.name]
                  }
                  maxLength={fields.email.componentProps.maxLength}
                  label={fields.email.componentProps.label}
                  extraTextMsg={t('forms.emailUpdateInfo')}
                  showOptional={
                    !this.props.checkMandatory(fields.email.componentProps.name)
                  }
                  onChange={this.onEmailChange}
                  onBlur={this.props.onBlur}
                />
              </FormField>
            </Col>
          </Row>
        )}

        {fields.email2 && this.state.isUpdatingEmail && (
          <Row>
            <Col sm={fields.email2.colWidth}>
              <FormField
                validation={{
                  id: `${this.props.formId}-${fields.email2.componentProps.name}-error`,
                  msg: this.props.messages[fields.email2.componentProps.name],
                  type: 'error'
                }}
                shouldValidate={
                  this.props.messages[fields.email2.componentProps.name] !==
                  undefined
                }
              >
                <InputField
                  formId={this.props.formId}
                  name={fields.email2.componentProps.name}
                  defaultValue={
                    this.props.values[fields.email2.componentProps.name]
                  }
                  maxLength={fields.email2.componentProps.maxLength}
                  label={fields.email2.componentProps.label}
                  showOptional={
                    !this.props.checkMandatory(
                      fields.email2.componentProps.name
                    )
                  }
                  onChange={this.props.onChange}
                  onBlur={this.props.onBlur}
                />
              </FormField>
            </Col>
          </Row>
        )}

        {fields.password && this.state.isUpdatingEmail && (
          <Row>
            <Col sm={fields.password.colWidth}>
              <InlineAlert
                title={t('forms.emailUpdateInfoBoxTitle')}
                text={t('forms.emailUpdateInfoBoxText')}
                marginTop="m"
                marginBottom="m"
              />
              <FormField
                validation={{
                  id: `${this.props.formId}-${fields.password.componentProps.name}-error`,
                  msg: this.props.messages[fields.password.componentProps.name],
                  type: 'error'
                }}
                shouldValidate={
                  this.props.messages[fields.password.componentProps.name] !==
                  undefined
                }
              >
                <InputField
                  formId={this.props.formId}
                  name={fields.password.componentProps.name}
                  defaultValue={
                    this.props.values[fields.password.componentProps.name]
                  }
                  type={fields.password.componentProps.type}
                  maxLength={fields.password.componentProps.maxLength}
                  label={fields.password.componentProps.label}
                  onChange={this.props.onChange}
                  onBlur={this.props.onBlur}
                />
              </FormField>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

UserLoginContainer.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkMandatory: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // From Redux
  profile: PropTypes.object.isRequired,
  values: PropTypes.object,
  messages: PropTypes.object,
  isMobileUserLogin: PropTypes.bool,
  setFormFieldValueAction: PropTypes.func.isRequired,
  setFormFieldMessagesAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const fieldNames =
    ownProps.nestedFields &&
    ownProps.nestedFields.map(field => field.componentProps.name);
  return {
    profile: state.profile,
    values: getFieldValuesByNames(state, ownProps.formId, fieldNames),
    messages: getFieldMessagesByNames(state, ownProps.formId, fieldNames),
    isMobileUserLogin:
      state.verification.identifier === 'mobile' && !state.profile.phoneVerified
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormFieldValueAction,
      setFormFieldMessagesAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLoginContainer);
