import React from 'react';
import PropTypes from 'prop-types';

export const MIN_WIDTH_DESKTOP = 768;

export const getIsMobile = () => {
  return window.innerWidth < MIN_WIDTH_DESKTOP;
};

export const getIsDesktop = () => {
  return window.innerWidth >= MIN_WIDTH_DESKTOP;
};

export class WindowListener extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: getIsMobile()
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    if (this.state.isMobile && window.innerWidth >= MIN_WIDTH_DESKTOP) {
      this.setState({ isMobile: false }, () => this.props.onChangeToDesktop());
    }
    if (!this.state.isMobile && window.innerWidth < MIN_WIDTH_DESKTOP) {
      this.setState({ isMobile: true }, () => this.props.onChangeToMobile());
    }
  }

  render() {
    return this.props.children ? this.props.children : null;
  }
}

WindowListener.propTypes = {
  onChangeToMobile: PropTypes.func.isRequired,
  onChangeToDesktop: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
