import { readAuthTokenCookie } from 'Utils/auth/authUtils';
export default class PaymentsApiClient {
  constructor() {
    const { COUNTRY, API } = CONFIG;
    this.apiUrl = `${API.PAYMENT.HOST}/${COUNTRY}`;
    this.headers = {
      'Content-Type': 'application/json'
    };
  }

  getPayments = userId => {
    if (!userId) {
      throw new Error('User must be logged in for fetching payments');
    }
    const authTokenCookie = readAuthTokenCookie();
    return fetch(`${this.apiUrl}/tokens`, {
      method: 'GET',
      headers: {
        ...this.headers,
        Authorization: `Bearer ${authTokenCookie}`
      }
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        return []; //don't throw error just load dashboard page without payments
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        return []; //don't throw error just load dashboard page without payments
      });
  };

  removePayment = paymentId => {
    return new Promise((resolve, reject) => {
      if (!paymentId) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          response: {
            errors: [
              {
                type: ErrorCodes.PAYMENTID_MISSING,
                code: 'PAYMENT_ID_MISSING'
              }
            ]
          }
        });
      }

      const authTokenCookie = readAuthTokenCookie();
      fetch(`${this.apiUrl}/tokens/${paymentId}`, {
        method: 'DELETE',
        headers: {
          ...this.headers,
          Authorization: `Bearer ${authTokenCookie}`
        }
      }).then(response => {
        if (response.status >= 200 || response.status <= 299) {
          resolve({ response: response.status });
        }
        reject({ response: { errors: response.status } });
      });
    });
  };

  setAsDefaultPayment = paymentId => {
    return new Promise((resolve, reject) => {
      if (!paymentId) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          response: {
            errors: [
              {
                type: ErrorCodes.PAYMENTID_MISSING,
                code: 'PAYMENT_ID_MISSING'
              }
            ]
          }
        });
      }
      const authTokenCookie = readAuthTokenCookie();
      fetch(`${this.apiUrl}/tokens/${paymentId}`, {
        method: 'PATCH',
        headers: {
          ...this.headers,
          Authorization: `Bearer ${authTokenCookie}`
        },
        body: JSON.stringify({
          isPreferred: true
        })
      }).then(response => {
        if (response.status >= 200 || response.status <= 299) {
          resolve({ response: response.status });
        }
        reject({ response: { errors: response.status } });
      });
    });
  };
}
