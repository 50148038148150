export default {
  LOGIN: '/login/',
  LOGIN_BASIC: '/login/basic/',
  RESET_PASSWORD: '/reset/',
  RESET_PASSWORD_BASIC: '/reset/basic/',
  RESET_PASSWORD_CONFIRMATION: '/reset/success/',
  RESET_PASSWORD_CONFIRMATION_BASIC: '/reset/success/basic/',
  RESET_UPDATE_PASSWORD: '/reset/updatepassword/',
  LOGOUT: '/logout/',
  SIGNUP: '/signup/',
  // "SIGNUP_BASIC" is signup page without navigation and images. This
  // route should be used together with valid "from" parameter, or else the
  // logged in user will end up on dashboard WITH header and footer.
  SIGNUP_BASIC: '/signup/basic/',
  SIGNUP_CONFIRMATION: '/signup/confirmation/',
  SIGNUP_CONFIRMATION_BASIC: '/signup/confirmation/basic/',
  SIGNUP_KIOSK: '/signup/family_kiosk/',
  UPGRADE: '/upgrade/',
  DASHBOARD: '/dashboard/',
  CUSPRO: '/dashboard/projects/*',
  HOUSEHOLD: '/household/',
  FAMILY_MICROSITE_IFRAME: '/family/',
  DELETED_PROFILE: '/deleted-profile/',
  ERROR: '/error/',
  SEND_OTP: '/send-otp/',
  EDIT_MOBILE: '/edit-mobile/',
  ROOT_CONTEXT: '/',
  QUERY_PARAMS: {
    BV_EVENT_ID: 'bveventid',
    FROM: 'from',
    PREFILL: 'prefill',
    TOKEN: 'token',
    TRANSLATE: 'TRANSLATE',
    ICW: 'icw',
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CAMPAIGN: 'utm_campaign',
    ITM_CAMPAIGN: 'itm_campaign',
    ITM_ELEMENT: 'itm_element',
    ITM_CONTENT: 'itm_content'
  }
};
