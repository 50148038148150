import * as mockServer from './mockServer';
import { getProfileResponse } from './transformers';

export default class IRWProfileApiClientV2Mock {
  getProfile = userId => {
    console.log('Profile mock API getProfile called with', userId);
    return new Promise((resolve, reject) => {
      let resp;
      if (!userId) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          response: {
            errors: [
              {
                type: ErrorCodes.ACCESS,
                code: 'ACCESS_USER_MISSING'
              }
            ]
          }
        });
      }

      // Assume that anyone who has a user ID has a profile. Return
      // regular profile as default
      /*if (userId === 'business@ikea.com') {
        resp = getBusinessProfile(userId);
      } else if (userId === 'family@ikea.com') {
        resp = getFamilyProfile(userId);
      } else {
        resp = getRegularProfile(userId);
      }*/
      //TODO:: replace with real mocks for regular, family and business profiles.
      resp = this.getRegularProfile(userId);
      // See if we have stored local data to merge with the hard coded data
      const storedData = mockServer.get(userId);
      resp = Object.assign(resp, storedData && storedData.crmCustomerProfile);
      const transformed = getProfileResponse(resp);
      transformed.userId = userId;
      transformed.userLogin = userId;
      resolve(transformed);
    });
  };

  getRegularProfile = userId => {
    return {
      customerRef: {
        sourceId: 'ICM',
        customerId: 'C262DE15-F161-11e8-95BC-0050569953B7'
      },
      crmCustomerIntegrity: {
        notAllowDirectMailCode: '0',
        notAllowPersonalEmailCode: '1',
        notAllowSms: '0',
        notAllowSharingToSocialMedia: '1',
        notAllowAppPushNotification: '0',
        notAllowFindPotentialCustomers: '1'
      },
      privateMaintCustomer: {
        title: 'Dr',
        firstName: 'Firstname',
        lastName: 'Lastname',
        countryCode: 'ZZ',
        genderCode: 'F',
        contactMethodList: [
          {
            contactMethodType: 'ADDRESS',
            contactMethodRoleList: [
              {
                contactMethodRoleId: 'INVOICE_DEST'
              }
            ],
            address: {
              addressId: 'AddressId',
              cityName: 'Cityname',
              countryCode: 'ZZ',
              zipCode: '1234',
              formattedAddress: {
                address1: 'Address Line 1',
                address2: 'Address Line 2'
              }
            }
          },
          {
            contactMethodType: 'EMAIL',
            contactMethodData: userId
          },
          {
            contactMethodType: 'PHONE',
            contactMethodData: '+00123456789'
          },
          {
            contactMethodType: 'MOBILE',
            contactMethodData: '+11234567891'
          }
        ]
      },
      residenceInfo: {
        noOfRooms: '3',
        coExistenceInfo: '2',
        homeOwnershipIndicator: '1'
      },
      noOfChildren: '2'
    };
  };
}
