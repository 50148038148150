import React from 'react';
/* eslint-disable react/display-name */
export function withOptimizely(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.init = this.init.bind(this);
      this.decide = this.decide.bind(this);
      this.trackEvent = this.trackEvent.bind(this);
      this.state = {};
    }

    init = async () => {
      if (CONFIG.FEATURE.OPTIMIZELY_ENABLED) {
        const { key } = OPTIMIZELY;
        if (this.state.wrapper || !key) {
          return;
        }
        if (!window.ikea?.experiment?.init) {
          return;
        }
        const wrapper = await window.ikea.experiment.init(key, {});
        this.setState({ wrapper });
      }
    };

    decide = experiment => {
      const decision = this.state.wrapper?.decide(experiment);
      return decision;
    };

    trackEvent = (event, eventValue) => {
      // eslint-disable-next-line no-unused-expressions
      eventValue
        ? this.state.wrapper?.track(event, {}, { value: eventValue })
        : this.state.wrapper?.track(event);
    };

    componentDidMount() {
      this.init();
      window.ikea?.pubsub?.subscribe('ikeaCookieConsent/changed', this.init);
    }

    componentWillUnmount() {
      window.ikea?.pubsub?.unsubscribe('ikeaCookieConsent/changed', this.init);
    }

    render() {
      return (
        <WrappedComponent
          decide={this.decide}
          trackEvent={this.trackEvent}
          {...this.props}
        />
      );
    }
  };
}
