import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import {
  Select as IkeaDropdown,
  Option as IkeaDropdownItem
} from '@ingka/select';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

// IkeaDropdown does not allow using both value and defaultValue props.
// This component should be used only with _defaultValue_. The value is
// controlled by the IkeaDropdown and can not be updated/changed from
// outside. For IkeaDropdown with value prop, use DropdownControlled.

const Dropdown = props => {
  const id = props.formId ? `${props.formId}-${props.name}` : props.id;
  const hintProp = props.removeHintText
    ? { hintText: null }
    : { hintText: t('common.dropdown.default') };

  return (
    <div style={props.hide ? { display: 'none' } : {}}>
      <IkeaDropdown
        id={id}
        name={props.name}
        label={
          props.showOptional
            ? `${t(props.label)} ${t('forms.optional')}`
            : t(props.label)
        }
        {...hintProp}
        defaultValue={props.defaultValue}
        describedById={`${id}-error`}
        prefix={CONFIG.APP.STYLES_PREFIX}
        disabled={props.disabled}
        className={props.className}
        onBlur={props.onBlur}
        onChange={props.onChange}
      >
        <IkeaDropdownItem
          style={!props.showOptional ? { display: 'none' } : {}}
        />
        {props.topOptions.map((option, index) => (
          <IkeaDropdownItem
            {...option}
            key={`${option.value}-${index}`}
            id={`${props.formId}-${props.name}-${index}`}
            name={t(option.name)}
            style={{ fontWeight: 'bold' }}
          />
        ))}
        {props.options.map((option, index) => (
          <IkeaDropdownItem
            {...option}
            key={`${option.value}-${index}`}
            id={`${props.formId}-${props.name}-${index}`}
            name={t(option.name)}
          />
        ))}
      </IkeaDropdown>
    </div>
  );
};
Dropdown.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  showOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  removeHintText: PropTypes.bool,
  // Array of { name: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  topOptions: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Dropdown.defaultProps = {
  options: [],
  topOptions: []
};

export default Dropdown;
