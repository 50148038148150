import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import { getOrdinalNumberCount, capitalize } from 'Utils/string/stringUtils';
import { getFormValues, getFormMessages } from 'Redux/forms/formsHelper';
import {
  setFormFieldValueAction,
  setFormFieldMessagesAction
} from 'Redux/forms/formsActions';
import FormField from 'BaseForm/FormField';
import { Row, Col, Text, Divider } from 'Common';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import styles from './DynamicChildBirthdateContainer.scss';

class DynamicChildBirthdateContainer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const prevNoOfChildren = prevProps.formValues.noOfChildren || 0;
    const currNoOfChildren = this.props.formValues.noOfChildren || 0;
    // When user decrease "noOfChildren" with one,
    // we need to clear the form data added for that child.
    this.clearUnusedBirthdateFields(prevNoOfChildren, currNoOfChildren);
  }

  clearUnusedBirthdateFields = (prevNoOfChildren, currNoOfChildren) => {
    if (currNoOfChildren >= prevNoOfChildren) {
      return;
    }
    for (let i = prevNoOfChildren; i > currNoOfChildren; i -= 1) {
      const fieldName = `${this.props.namePrefix}${i - 1}`;
      if (this.props.formValues[fieldName]) {
        this.props.setFormFieldValueAction(this.props.formId, fieldName);
        this.props.setFormFieldMessagesAction(this.props.formId, fieldName);
      }
    }
  };

  getVisibleFields = () => {
    const { noOfChildren = 0 } = this.props.formValues;
    return this.props.nestedFields.slice(0, noOfChildren);
  };

  isFieldMandatory = field => {
    const { validationRules = [] } = field;
    return validationRules.some(
      rule => rule.name === 'childBirthDate' && rule.required
    );
  };

  isSomeFieldMandatory = () => {
    return this.props.nestedFields.some(field => this.isFieldMandatory(field));
  };

  getChildFieldLabel = index => {
    try {
      const countLabel = getOrdinalNumberCount(index + 1);
      const childLabel = t('forms.household.child');
      return this.props.countLabelMask
        .replace('{countLabel}', countLabel)
        .replace('{childLabel}', childLabel);
    } catch (e) {
      return `${capitalize(t('forms.household.child'))} ${index + 1}`;
    }
  };

  render() {
    const visibleFields = this.getVisibleFields();

    if (!visibleFields.length) {
      return null;
    }

    const asterix = (
      <Text inline colour="feedback-error">
        *
      </Text>
    );

    return (
      <div className={styles['dynamic-child-birthdate']}>
        <Text marginTop="m" marginBottom="m">
          {t('forms.household.childBirthdateText')}
        </Text>
        {visibleFields.map((field, index) => {
          const messages = this.props.messages[field.componentProps.name] || [];
          return (
            <React.Fragment
              key={`${this.props.formId}-${field.componentProps.name}`}
            >
              <Row>
                <Col className={styles['dynamic-child-birthdate-input-wrap']}>
                  <Text className={styles['dynamic-child-birthdate-count']}>
                    {this.getChildFieldLabel(index)}
                    {this.isFieldMandatory(field) && asterix}
                  </Text>
                  <FormField
                    className={styles['dynamic-child-birthdate-field']}
                    validation={{
                      id: `${this.props.formId}-${field.componentProps.name}-error`,
                      msg: messages[0],
                      type: 'error'
                    }}
                    shouldValidate={messages.length > 0}
                  >
                    <InputFieldHandler
                      formId={this.props.formId}
                      name={field.componentProps.name}
                      label={field.componentProps.label}
                      value={
                        this.props.formValues[field.componentProps.name] || ''
                      }
                      onChange={this.props.onChange}
                      onBlur={this.props.onBlur}
                    />
                  </FormField>
                </Col>
              </Row>
              {index < visibleFields.length - 1 && (
                <Divider
                  className={styles['dynamic-child-birthdate-divider']}
                />
              )}
            </React.Fragment>
          );
        })}
        {this.isSomeFieldMandatory() && (
          <Text smaller italic marginBottom="xl">
            {asterix} {t('forms.validation.mandatoryFields')}
          </Text>
        )}
      </div>
    );
  }
}

DynamicChildBirthdateContainer.propTypes = {
  // From yml form configuration
  formId: PropTypes.string,
  namePrefix: PropTypes.string.isRequired,
  countLabelMask: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  // From DynamicFormContainer
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  // From Redux
  profile: PropTypes.object,
  formValues: PropTypes.object,
  messages: PropTypes.object,
  setFormFieldValueAction: PropTypes.func.isRequired,
  setFormFieldMessagesAction: PropTypes.func.isRequired
};

DynamicChildBirthdateContainer.defaultProps = {
  countLabelMask: '{countLabel} {childLabel}'
};

const mapStateToProps = (state, ownProps) => {
  if (!ownProps) {
    return {};
  }
  return {
    formValues: getFormValues(state, ownProps.formId) || {},
    messages: getFormMessages(state, ownProps.formId) || {},
    profile: state.profile
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormFieldValueAction,
      setFormFieldMessagesAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicChildBirthdateContainer);
