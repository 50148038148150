import React from 'react';
import PropTypes from 'prop-types';
import InlineMessage from '@ingka/inline-message';
import { SSRIcon } from 'Common';
import classNames from 'classnames';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/hyperlink/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/focus/style.scss';
import styles from './InlineText.scss';

export const InlineText = props =>
  props.icon ? (
    <span
      style={props.style}
      className={classNames(
        styles[`inline-text-with-icon-${props.iconPosition}`],
        props.className,
        {
          [styles[`inline-text-with-icon-${props.iconPosition}-space-between`]]:
            props.spaceBetween
        }
      )}
    >
      {props.iconPosition === 'leading' && (
        <SSRIcon icon={props.icon} size="100" />
      )}
      <InlineMessage
        prefix={CONFIG.APP.STYLES_PREFIX}
        msg={props.msg}
        link={props.link}
        linkText={props.linkText}
      />
      {props.iconPosition === 'trailing' && (
        <SSRIcon icon={props.icon} size="100" />
      )}
    </span>
  ) : (
    <InlineMessage
      style={props.style}
      className={props.className}
      prefix={CONFIG.APP.STYLES_PREFIX}
      msg={props.msg}
      link={props.link}
      linkText={props.linkText}
    />
  );

InlineText.propTypes = {
  className: PropTypes.string,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  link: PropTypes.string,
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['leading', 'trailing']),
  spaceBetween: PropTypes.bool,
  style: PropTypes.object
};

InlineText.defaultProps = {
  iconPosition: 'leading'
};
