import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, Link, FlexPanel, Divider, Toast } from 'Common';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import { updateUserPreferences } from 'Redux/profile/profileThunks';
import { clearFormAction } from 'Redux/forms/formsActions';
import { t } from 'Utils/localization/i18next';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import styles from './DashboardPreferencesSectionReadContainer.scss';

class DashboardPreferencesSectionReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isEditing: false, showSuccessMessage: false };
    this.myRef = React.createRef();
  }

  onEdit = () => {
    this.setState({ isEditing: true, showSuccessMessage: false });
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onSubmit = async data => {
    const newProfile = { ...this.props.profile, ...data };
    const success = await this.props.updateUserPreferences(
      trimAllObjPropertiesSkipPasswords(newProfile),
      this.props.editForm
    );
    if (success) {
      this.setState({ isEditing: false, showSuccessMessage: true });
    }
    this.scrollToCenter();
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const preferences = this.props.profile[this.props.name];
    if (!preferences || !preferences.length) {
      return false;
    }
    return (
      <>
        <div ref={this.setRef} className={styles['dashboard-section-read']}>
          {this.state.showSuccessMessage && (
            <Toast autoCloseMs={5000} closeText={t('common.dismiss')}>
              <Text bold>
                {t('dashboard.confirmation.communicationSectionUpdateTitle')}
              </Text>
              <Text>
                {t('dashboard.confirmation.communicationSectionUpdateText')}
              </Text>
            </Toast>
          )}
          <FlexPanel spread className={styles['dashboard-section-read-title']}>
            {this.props.title && (
              <Text bold colour="grey-900">
                {t(this.props.title)}
              </Text>
            )}
            {this.props.editForm && (
              <Link
                bold
                underline
                colour="grey-900"
                id={`${this.props.editForm}-link`}
                onClick={this.state.isEditing ? this.onClose : this.onEdit}
              >
                {this.state.isEditing ? t('common.close') : t('common.edit')}
              </Link>
            )}
          </FlexPanel>
          {this.state.isEditing ? (
            <DynamicFormContainer
              formId={this.props.editForm}
              prefillValues={this.props.profile}
              loadingSubmit={this.props.isLoading}
              textSubmit={t('common.save')}
              textCancel={t('common.cancel')}
              onCancel={this.onClose}
              onSubmit={this.onSubmit}
            />
          ) : (
            this.props.children
          )}
        </div>
        {!this.props.isLastElement && <Divider dashboard />}
      </>
    );
  }
}

DashboardPreferencesSectionReadContainer.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  editForm: PropTypes.string,
  isLastElement: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // From Redux/Thunks
  profile: PropTypes.object,
  isLoading: PropTypes.bool,
  updateUserPreferences: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateUserPreferences,
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPreferencesSectionReadContainer);
