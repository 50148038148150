// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__signup-confirmation{min-height:50vh;padding-top:6rem}.profile__signup-confirmation-left{padding-right:20%}.profile__signup-confirmation-right{padding-right:20%}@media(max-width: 48rem){.profile__signup-confirmation{padding-top:1rem}.profile__signup-confirmation-left{padding-right:10%}.profile__signup-confirmation-right{padding-right:10%}}", "",{"version":3,"sources":["webpack://./src/components/Signup/SignupConfirmationContainer.scss"],"names":[],"mappings":"AAEA,8BACE,eAAA,CACA,gBAAA,CAEA,mCACE,iBAAA,CAEF,oCACE,iBAAA,CAIJ,yBACE,8BACE,gBAAA,CAEA,mCACE,iBAAA,CAEF,oCACE,iBAAA,CAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.signup-confirmation {\n  min-height: 50vh;\n  padding-top: 6rem;\n\n  &-left {\n    padding-right: 20%;\n  }\n  &-right {\n    padding-right: 20%;\n  }\n}\n\n@media (max-width: 48rem) {\n  .signup-confirmation {\n    padding-top: 1rem;\n\n    &-left {\n      padding-right: 10%;\n    }\n    &-right {\n      padding-right: 10%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signup-confirmation": "profile__signup-confirmation",
	"signup-confirmation-left": "profile__signup-confirmation-left",
	"signup-confirmation-right": "profile__signup-confirmation-right"
};
export default ___CSS_LOADER_EXPORT___;
