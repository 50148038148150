import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFormAction } from 'Redux/forms/formsActions';
import { Button } from 'Common';
import { t } from 'Utils/localization/i18next';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import styles from './ShippingBillingAdresses.scss';

class AddDeliveryAddressButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isAdding: false };
    this.myRef = React.createRef();
  }

  onAdd = () => {
    this.setState({ isAdding: true });
    this.scrollToTop();
  };

  onClose = () => {
    this.setState({ isAdding: false }, () => {
      this.props.clearFormAction(this.props.addForm);
      this.scrollToCenter();
    });
  };

  onCreate = data => {
    const successCallback = () => {
      this.setState({ isAdding: false });
      this.scrollToCenter();
    };
    const failureCallback = () => {
      this.scrollToCenter();
    };
    this.props.onCreate(data, successCallback, failureCallback);
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  scrollToTop = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    return (
      <>
        {this.state.isAdding && (
          <DynamicFormContainerModal
            formId={this.props.addForm}
            loadingSubmit={this.props.isLoading}
            textSubmit={t('forms.addressSave')}
            textCancel={t('common.cancel')}
            onCancel={this.onClose}
            onSubmit={this.onCreate}
            title={this.props.titleAdd}
          />
        )}
        <div className={styles['alternative-address-read']} ref={this.setRef}>
          {this.props.deliveryAddressesCount < 10 && (
            <Button
              small
              bold="true"
              id={`${this.props.addForm}-link`}
              className={styles['add-address-button']}
              onClick={this.onAdd}
            >
              {t('dashboard.addresses.delivery-address.titleButton')}
            </Button>
          )}
        </div>
      </>
    );
  }
}

AddDeliveryAddressButton.propTypes = {
  titleAdd: PropTypes.string,
  deliveryAddressesFailed: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  addForm: PropTypes.string.isRequired,
  deliveryAddressesCount: PropTypes.number,
  // From Redux/Thunks
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.DELIVERY_ADDRESS_CREATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDeliveryAddressButton);
