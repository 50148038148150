import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFormAction } from 'Redux/forms/formsActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { t } from 'Utils/localization/i18next';
import { Link, Text, Divider } from 'Common';
import mask from 'Utils/mask/mask';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import { zipCode } from 'Utils/mask/zipCodeMasker';
import styles from './DashboardDeliveryAddress.scss';

class DashboardDeliveryAddress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
    this.myRef = React.createRef();
  }

  onEdit = () => {
    // This will set the id of the delivery address that is being edited.
    // Any other delivery address form will be closed.
    this.props.setAddressBeingEdited(this.props.deliveryAddress.addressId);
    if (!this.state.isEditing) {
      this.setState({ isEditing: true });
    }
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onUpdate = data => {
    const { addressId } = this.props.deliveryAddress;
    const successCallback = () => {
      this.setState({ isEditing: false });
      this.scrollToCenter();
    };
    const failureCallback = this.scrollToTop;
    this.props.onUpdate(
      {
        ...data,
        addressId
      },
      successCallback,
      failureCallback
    );
  };

  onDelete = () => {
    const successCallback = this.scrollToCenter;
    const failureCallback = this.scrollToTop;
    this.props.onDelete(
      this.props.deliveryAddress.addressId,
      successCallback,
      failureCallback
    );
  };

  scrollToTop = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const { deliveryAddress } = this.props;
    const fullName = this.props.name
      .map(name => deliveryAddress[name])
      .filter(Boolean)
      .join(' ');
    const contactTypes = this.props.contactTypes.filter(
      contactType => deliveryAddress[contactType]
    );

    return this.state.isEditing &&
      deliveryAddress.addressId === this.props.addressBeingEdited ? (
      <div>
        <div className={styles['dashboard-delivery-address']}>
          <div
            ref={this.setRef}
            className={styles['dashboard-delivery-address-edit-wrap']}
          >
            <Link
              bold
              underline
              colour="grey-900"
              className={styles['dashboard-delivery-address-edit-close']}
              onClick={this.onClose}
            >
              {t('common.close')}
            </Link>

            <DynamicFormContainer
              formId={this.props.editForm}
              prefillValues={deliveryAddress}
              loadingSubmit={this.props.isLoading}
              textSubmit={t('common.save')}
              textCancel={t('common.cancel')}
              onCancel={this.onClose}
              onSubmit={this.onUpdate}
            >
              <Text center marginTop="m">
                <Link bold onClick={this.onDelete}>
                  {t('forms.addressRemove')}
                </Link>
              </Text>
            </DynamicFormContainer>
          </div>
        </div>
        <Divider dashed />
      </div>
    ) : (
      <div key={deliveryAddress.addressId}>
        <div ref={this.setRef} className={styles['dashboard-delivery-address']}>
          <Text className={styles['dashboard-delivery-address-read-field']}>
            <Text bold>{fullName}</Text>
            {this.props.rows.map((row, index) => (
              <div key={index}>
                {row.map(
                  item =>
                    deliveryAddress[item] &&
                    (item === 'addressCountryCode'
                      ? `${t(
                          `countries.${mask(
                            deliveryAddress[item],
                            this.props.maskRules[item]
                          )}`
                        )} `
                      : item === 'zipCode'
                      ? `${zipCode(
                          deliveryAddress[item],
                          deliveryAddress.addressCountryCode,
                          this.props.maskRules[item]
                        )} `
                      : `${mask(
                          deliveryAddress[item],
                          this.props.maskRules[item]
                        )} `)
                )}
              </div>
            ))}
            {contactTypes.length > 0 && (
              <Text marginTop="m">
                {contactTypes.map((contactType, index) => (
                  <div key={index}>
                    <Text inline bold>
                      {t(`forms.${contactType}`)}:{' '}
                    </Text>
                    {mask(
                      deliveryAddress[contactType],
                      this.props.maskRules[contactType]
                    )}
                  </div>
                ))}
              </Text>
            )}
          </Text>
          {this.props.editForm && (
            <Link
              bold
              underline
              colour="grey-900"
              id={`${this.props.editForm}-link`}
              onClick={this.onEdit}
            >
              {t('common.edit')}
            </Link>
          )}
        </div>
        <Divider dashed />
      </div>
    );
  }
}

DashboardDeliveryAddress.propTypes = {
  setAddressBeingEdited: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.object.isRequired,
  editForm: PropTypes.string.isRequired,
  addressBeingEdited: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  name: PropTypes.arrayOf(PropTypes.string),
  contactTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  maskRules: PropTypes.object,
  // From Redux/Thunks
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

DashboardDeliveryAddress.defaultProps = {
  contactTypes: [],
  maskRules: {}
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.DELIVERY_ADDRESS_UPDATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDeliveryAddress);
