import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Divider.scss';

export const Divider = props => (
  <hr
    className={classNames(styles.divider, {
      [styles['divider-dashboard']]: props.dashboard,
      [styles['divider-dashed']]: props.dashed,
      [styles[`divider-margin-${props.margin}`]]: props.margin !== undefined,
      [props.className]: props.className !== undefined
    })}
  />
);

Divider.propTypes = {
  className: PropTypes.string,
  dashboard: PropTypes.bool,
  dashed: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl'])
};
