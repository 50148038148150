// Count active orders where status is Created or In progress.

export function getNumOfActiveOrders(orderHistoryList) {
  const activeOrders = orderHistoryList.filter(
    order => order.status === 'CREATED' || order.status === 'IN_PROGRESS'
  );
  return activeOrders.length;
}

export function getNumOfShoppingLists(shoppingListsResponse) {
  // The current implementation only supports one shopping list. This transformation need to be adjusted to read more than one shopping list
  let shoppingListsNumber = 0;
  if (shoppingListsResponse) {
    shoppingListsResponse.forEach(shoppingList => {
      if (shoppingList && shoppingList.noOfItems > 0) {
        shoppingListsNumber++;
      }
    });
  }
  return shoppingListsNumber;
}
