import React from 'react';
import { Grid } from 'Common';
import { Loading } from 'Common/Loading';

class FamilyMicrositeIframe extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  onLoad = () => {
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Grid>
        {this.state.isLoading && <Loading isLoading />}
        <iframe
          src={CONFIG.URL.FAMILY_MICROSITE_IFRAME}
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
            minHeight: '1024px'
          }}
          onLoad={this.onLoad}
        />
      </Grid>
    );
  }
}

export default FamilyMicrositeIframe;
