import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'Common';

const HouseholdPage = props => (
  <Grid style={{ paddingTop: '2rem', paddingBottom: '6rem' }}>
    <Row center>
      <Col xs={12} sm={10} lg={7}>
        {props.children}
      </Col>
    </Row>
  </Grid>
);

HouseholdPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default HouseholdPage;
