import * as stringUtils from 'Utils/string/stringUtils';
const consentsMapping = {
  allowEmail: 'allowPersonalEmail',
  allowSMS: 'allowSms',
  allowSharingToSocialMedia: 'allowSharingToSocialMedia',
  allowFindPotentialCustomers: 'allowFindPotentialCustomers',
  allowAppPushNotification: 'allowAppPushNotification',
  allowDirectMail: 'allowDirectMail',
  allowCollectBuyInfo: 'allowCollectBuyInfo',
  sendFamilyPlasticCard: 'sendFamilyPlasticCard',
  participateInMemberPanel: 'joinCustomerPanel'
};

const defaultGovPersonalId = ['NIF', 'FISCAL_CODE', 'NIE', 'DNI'];

export function getProfileSettings(currentProfile, updatedProfile) {
  let consents = {};
  let profileSettings = {};
  if (isCustomerIntegrityChanged(currentProfile, updatedProfile)) {
    Object.keys(consentsMapping).forEach(key => {
      if (isValueChanged(currentProfile[key], updatedProfile[key])) {
        consents[consentsMapping[key]] = updatedProfile[key];
      }
    });
  }

  profileSettings = {
    ...(updatedProfile.preferredStore && {
      preferredStore: updatedProfile.preferredStore
    }),
    ...(updatedProfile.preferredLanguage && {
      preferredLanguage: updatedProfile.preferredLanguage
    }),
    ...(Object.keys(consents).length > 0 && {
      consents
    })
  };
  return profileSettings;
}

export function getConsentString(consents) {
  if (consents) {
    let consentsString = 'consents: { ';
    Object.keys(consents).forEach(key => {
      consentsString = consentsString.concat(`${key}: ${consents[key]} `);
    });
    consentsString = consentsString.concat('}');
    return consentsString;
  }
  return '';
}

export function getUpdateCustomerPersonalInfoString(profile, currentProfile) {
  let {
    title,
    firstName,
    lastName,
    firstNamePhonetic,
    lastNamePhonetic,
    lastName2,
    socialSecurityNumber,
    genderCode,
    birthDate,
    personalId,
    personalIdType,
    personalIdSource,
    documentId
  } = profile;

  const personalIdCurrent = currentProfile.personalId;
  const personalIdTypeCurrent = currentProfile.personalIdType;
  const personalIdSourceCurrent = currentProfile.personalIdSource;
  const documentIdCurrent = currentProfile.documentId;

  let customerPersonalInfoString = 'customerInfo: { ';
  if (title) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `title:\"${title}\"`
    );
  }
  if (firstName) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `firstName: \"${firstName}\"`
    );
  }
  if (lastName) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `lastName: \"${lastName}\"`
    );
  }
  if (firstNamePhonetic) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `firstNamePhonetic: \"${firstNamePhonetic}\"`
    );
  }
  if (lastNamePhonetic) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `lastNamePhonetic: \"${lastNamePhonetic}\"`
    );
  }
  if (lastName2) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `secondLastName:\"${lastName2}\"`
    );
  }
  if (genderCode) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `genderCode: \"${genderCode}\"`
    );
  } else {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `genderCode: \"NOTSET\"`
    );
  }
  if (birthDate) {
    let birthDateStr = stringUtils.reverseString(birthDate, '-');
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `birthDate: \"${birthDateStr}\" `
    );
  }
  if (socialSecurityNumber) {
    customerPersonalInfoString = customerPersonalInfoString.concat(
      `socialSecurityNumber: \"${socialSecurityNumber}\" `
    );
  }
  if (personalIdType) {
    if (
      !personalId &&
      !documentId &&
      (personalIdTypeCurrent !== undefined &&
        personalIdSourceCurrent !== undefined &&
        (personalIdCurrent !== undefined || documentIdCurrent !== undefined))
    ) {
      customerPersonalInfoString = customerPersonalInfoString
        .concat(`customerIdType: \"NOTSET\"`)
        .concat(`customerId: \"NOTSET\"`)
        .concat(`customerIdSource: \"NOTSET\"`);
    } else {
      if (defaultGovPersonalId.includes(personalIdType) && personalId) {
        documentId = '';
      }
      if (documentId) {
        personalId = '';
        customerPersonalInfoString = customerPersonalInfoString.concat(
          `customerId: \"${documentId}\" `
        );
      }
      if (personalId) {
        customerPersonalInfoString = customerPersonalInfoString.concat(
          `customerId: \"${personalId}\" `
        );
      }
      if (personalId || documentId) {
        customerPersonalInfoString = customerPersonalInfoString.concat(
          `customerIdType: \"${personalIdType}\"`
        );
        if (
          defaultGovPersonalId.includes(personalIdType) ||
          !personalIdSource
        ) {
          personalIdSource = CONFIG.COUNTRY.toUpperCase().concat('_GOV');
          customerPersonalInfoString = customerPersonalInfoString.concat(
            `customerIdSource: \"${personalIdSource}\"`
          );
        } else {
          customerPersonalInfoString = customerPersonalInfoString.concat(
            `customerIdSource: \"${personalIdSource}\"`
          );
        }
      }
    }
  } else {
    if (
      personalIdTypeCurrent !== undefined &&
      personalIdSourceCurrent !== undefined &&
      (personalIdCurrent !== undefined || documentIdCurrent !== undefined)
    ) {
      customerPersonalInfoString = customerPersonalInfoString
        .concat(`customerIdType: \"NOTSET\"`)
        .concat(`customerId: \"NOTSET\"`)
        .concat(`customerIdSource: \"NOTSET\"`);
    }
  }
  customerPersonalInfoString = customerPersonalInfoString.concat('}');
  return customerPersonalInfoString;
}

export function getProfileSettingsString(profileSettings) {
  let profileSettingString = 'profileSettings: { ';
  profileSettingString = profileSettingString.concat(
    getConsentString(profileSettings.consents)
  );
  if (profileSettings.preferredStore)
    profileSettingString = profileSettingString.concat(
      `preferredStore: \"${profileSettings.preferredStore}\" `
    );
  profileSettingString =
    profileSettings.preferredLanguage &&
    profileSettingString.concat(
      `preferredLanguage: \"${profileSettings.preferredLanguage}\" `
    );
  profileSettingString = profileSettingString.concat('}');
  return profileSettingString;
}

export function getContactMethodString(contactMethod) {
  let contactMethodString = 'contactMethod: { ';

  if (contactMethod.landline) {
    contactMethodString = contactMethodString.concat(
      `type:PHONE value: \"${contactMethod.landline}\" `
    );
  } else {
    contactMethodString = contactMethodString.concat(`type:PHONE value: \"\" `);
  }
  contactMethodString = contactMethodString.concat('}');
  return contactMethodString;
}

export function getHouseHoldString(homeAndInterest) {
  let homeAndInterestString = 'homeAndInterests: { ';
  let contactResidenceString = 'contactResidence: { ';
  let childrenString = 'children: [ ';
  let interestsString = 'interests: [ ';
  let educationLevelString = '';
  let maritalStatusString = '';
  let noOfChildrenString = '';

  if (homeAndInterest.noOfChildren) {
    noOfChildrenString = noOfChildrenString.concat(
      `noOfChildren:\"${homeAndInterest.noOfChildren}\"`
    );
    homeAndInterestString = homeAndInterestString.concat(noOfChildrenString);
  }

  if (homeAndInterest.educationLevel) {
    const educationLevelMapping = {
      '0': 'NOTSET',
      '1': 'LOW_EDUCATION',
      '2': 'MEDIUM_LOW_EDUCATION',
      '3': 'MEDIUM_EDUCATION',
      '4': 'MEDIUM_HIGH_EDUCATION',
      '5': 'HIGH_EDUCATION'
    };
    educationLevelString = educationLevelString.concat(
      `education:${educationLevelMapping[homeAndInterest.educationLevel]} `
    );
    homeAndInterestString = homeAndInterestString.concat(educationLevelString);
  } else {
    homeAndInterestString = homeAndInterestString.concat(`education:NOTSET `);
  }

  if (homeAndInterest.maritalStatus) {
    const maritalStatusMapping = {
      '0': 'NOTSET',
      '1': 'SINGLE',
      '2': 'MARRIED',
      '3': 'COHABITOR'
    };
    maritalStatusString = maritalStatusString.concat(
      `maritalStatus:${maritalStatusMapping[homeAndInterest.maritalStatus]} `
    );
    homeAndInterestString = homeAndInterestString.concat(maritalStatusString);
  } else {
    homeAndInterestString = homeAndInterestString.concat(
      `maritalStatus:NOTSET `
    );
  }

  if (homeAndInterest.noOfChildren && homeAndInterest.noOfChildren > 0) {
    for (let i = 0; i < 20; i++) {
      const birthDateKey = `child-birthDate-${i}`;
      const childIDKey = `child-id-${i}`;
      if (homeAndInterest[birthDateKey] !== undefined) {
        let birthdate = homeAndInterest[birthDateKey].split('-');
        let day = birthdate[0];
        let month = birthdate[1];
        let year = birthdate[2];
        let formattedDate = `${year}-${month}-${day}`;
        homeAndInterest[birthDateKey] = formattedDate;
        if (homeAndInterest[childIDKey] !== undefined) {
          childrenString = childrenString.concat(
            `{id:"${homeAndInterest[childIDKey]}",birthDate:"${formattedDate}"},`
          );
        } else {
          childrenString = childrenString.concat(
            `{birthDate:"${formattedDate}"},`
          );
        }
      }
    }
    childrenString = childrenString.slice(0, -1);
    childrenString = childrenString.concat(' ]');
    homeAndInterestString = homeAndInterestString.concat(childrenString);
  }
  if (homeAndInterest.noOfChildren == 0) {
    childrenString = childrenString.concat(' ]');
    homeAndInterestString = homeAndInterestString.concat(childrenString);
  }

  if (
    homeAndInterest.interestCodes &&
    homeAndInterest.interestCodes.length > 0
  ) {
    homeAndInterest.interestCodes.forEach(interest => {
      interestsString = interestsString.concat(
        `{ category: INTEREST_CODE, categoryValue: "${interest}" },`
      );
    });
    interestsString = interestsString.slice(0, -1);
    interestsString = interestsString.concat(' ]');
    homeAndInterestString = homeAndInterestString.concat(interestsString);
  }
  if (homeAndInterest.interestCodes.length == 0) {
    interestsString = interestsString.concat(' ]');
    homeAndInterestString = homeAndInterestString.concat(interestsString);
  }

  if (homeAndInterest.livingSpace) {
    contactResidenceString = contactResidenceString.concat(
      `livingSpace:\"${homeAndInterest.livingSpace}\"`
    );
  } else {
    contactResidenceString = contactResidenceString.concat(`livingSpace:\"\"`);
  }
  if (homeAndInterest.holidayHomeOwnership) {
    const holidayOwnershipValues = {
      '1': 'Y',
      '0': 'N'
    };
    contactResidenceString = contactResidenceString.concat(
      `holidayHomeOwnership:${
        holidayOwnershipValues[homeAndInterest.holidayHomeOwnership]
      } `
    );
  }
  if (homeAndInterest.homeOwnership) {
    const ownershipValues = {
      '1': 'Y',
      '0': 'N'
    };
    contactResidenceString = contactResidenceString.concat(
      `homeOwnership: ${ownershipValues[homeAndInterest.homeOwnership]} `
    );
  }

  if (homeAndInterest.noOfRooms) {
    contactResidenceString = contactResidenceString.concat(
      `numberRooms:\"${homeAndInterest.noOfRooms}\"`
    );
  } else {
    contactResidenceString = contactResidenceString.concat(`numberRooms:\"\"`);
  }
  if (homeAndInterest.residenceId) {
    contactResidenceString = contactResidenceString.concat(
      `id:\"${homeAndInterest.residenceId}\"`
    );
  }
  if (homeAndInterest.residenceType) {
    const residenceTypeMapping = {
      '0': 'NOTSET',
      '1': 'APPARTMENT',
      '2': 'HOUSE',
      '3': 'PARENTS',
      '4': 'OTHER',
      '5': 'FLAT',
      '22': 'BEDROOM'
    };
    const residenceTypeWithOutdoorMapping = {
      '1_GARDEN': 'APARTMENT_WITH_GARDEN',
      '1_BALCONY': 'APARTMENT_WITH_BALCONY',
      '1_TERRACE': 'APARTMENT_WITH_TERRACE',
      '1_WITHOUT_OUTDOOR': 'APARTMENT_WITHOUT_OUTDOOR',
      '2_GARDEN': 'HOUSE_WITH_GARDEN',
      '2_BALCONY': 'HOUSE_WITH_BALCONY',
      '2_TERRACE': 'HOUSE_WITH_TERRACE',
      '2_WITHOUT_OUTDOOR': 'HOUSE_WITHOUT_OUTDOOR',
      '4_GARDEN': 'OTHER_WITH_GARDEN',
      '4_BALCONY': 'OTHER_WITH_BALCONY',
      '4_TERRACE': 'OTHER_WITH_TERRACE',
      '4_WITHOUT_OUTDOOR': 'OTHER_WITHOUT_OUTDOOR',
      '5_GARDEN': 'FLAT_WITH_GARDEN',
      '5_BALCONY': 'FLAT_WITH_BALCONY',
      '5_TERRACE': 'FLAT_WITH_TERRACE',
      '5_WITHOUT_OUTDOOR': 'FLAT_WITHOUT_OUTDOOR'
    };
    const homeType = residenceTypeMapping[homeAndInterest.residenceType];
    const homeTypeWithOutdoor =
      residenceTypeWithOutdoorMapping[
        `${homeAndInterest.residenceType}_${homeAndInterest.outdoorType}`
      ];
    homeTypeWithOutdoor
      ? (contactResidenceString = contactResidenceString.concat(
          `homeTypeCode: ${homeTypeWithOutdoor} `
        ))
      : (contactResidenceString = contactResidenceString.concat(
          `homeTypeCode: ${homeType} `
        ));
  } else {
    contactResidenceString = contactResidenceString.concat(
      `homeTypeCode: NOTSET `
    );
  }
  if (homeAndInterest.coExistenceInfo) {
    const coExistenceInfoMapping = {
      '0': 'NOTSET',
      '1': 'WITHPARENTS',
      '2': 'SPOUSE',
      '3': 'FAMILYWITHKIDS',
      '4': 'PARTNER',
      '5': 'OTHER',
      '6': 'LIVINGALONE_ROOMMATES',
      '7': 'COUPLE_WITH_CHILDREN',
      '8': 'COUPLE_WITHOUT_CHILDREN',
      '9': 'JOINTFAMILY_WITH_CHILDREN',
      '10': 'JOINTFAMILY_WITHOUT_CHILDREN',
      '11': 'NOKIDS',
      '12': 'SINGLE',
      '13': 'SINGLE_WITH_CHILDREN'
    };

    contactResidenceString = contactResidenceString.concat(
      `coExistenceInfo: ${
        coExistenceInfoMapping[homeAndInterest.coExistenceInfo]
      } `
    );
  } else {
    contactResidenceString = contactResidenceString.concat(
      `coExistenceInfo: NOTSET`
    );
  }

  contactResidenceString = contactResidenceString.concat(' }');
  homeAndInterestString = homeAndInterestString.concat(contactResidenceString);
  return homeAndInterestString.concat(' }');
}

export function getAddressString(address) {
  let addressString = 'address: { ';
  if (address.address1) {
    const address1Value = CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER
      ? address.address1.trim().concat(` ${address.houseNumber}`)
      : address.address1;
    addressString = addressString.concat(`addressLine1:\"${address1Value}\"`);
  }
  if (address.address2) {
    addressString = addressString.concat(
      `addressLine2:\"${address.address2}\"`
    );
  } else {
    addressString = addressString.concat(`addressLine2:\"\"`);
  }
  if (address.address3) {
    addressString = addressString.concat(
      `addressLine3:\"${address.address3}\"`
    );
  } else {
    addressString = addressString.concat(`addressLine3:\"\"`);
  }
  if (address.address4) {
    addressString = addressString.concat(
      `addressLine4:\"${address.address4}\"`
    );
  } else {
    addressString = addressString.concat(`addressLine4:\"\"`);
  }
  if (address.address5) {
    addressString = addressString.concat(
      `addressLine5:\"${address.address5}\"`
    );
  } else {
    addressString = addressString.concat(`addressLine5:\"\"`);
  }
  if (address.address6) {
    addressString = addressString.concat(
      `addressLine6:\"${address.address6}\"`
    );
  } else {
    addressString = addressString.concat(`addressLine6:\"\"`);
  }
  if (address.province) {
    addressString = addressString.concat(`state:\"${address.province}\"`);
  } else {
    addressString = addressString.concat(`state:\"\"`);
  }
  if (address.cityName) {
    addressString = addressString.concat(`city:\"${address.cityName}\"`);
  }
  if (address.zipCode) {
    addressString = addressString.concat(`zipCode:\"${address.zipCode}\"`);
  }
  if (address.addressCountryCode) {
    addressString = addressString.concat(
      `country:\"${address.addressCountryCode}\"`
    );
  } else {
    addressString = addressString.concat(
      `country:\"${CONFIG.COUNTRY.toUpperCase()}\"`
    );
  }
  if (address.firstName) {
    addressString = addressString.concat(`firstName:\"${address.firstName}\"`);
  }
  if (address.firstNamePhonetic) {
    addressString = addressString.concat(
      `firstNamePhonetic:\"${address.firstNamePhonetic}\"`
    );
  }
  if (address.lastName) {
    addressString = addressString.concat(`lastName:\"${address.lastName}\"`);
  }
  if (address.lastNamePhonetic) {
    addressString = addressString.concat(
      `lastNamePhonetic:\"${address.lastNamePhonetic}\"`
    );
  }
  if (address.mobile) {
    addressString = addressString.concat(`mobile:\"${address.mobile}\"`);
  }
  if (address.landline && address.landline !== '') {
    addressString = addressString.concat(`landLine:\"${address.landline}\"`);
  }
  addressString = addressString.concat('}');
  return addressString;
}

function isCustomerIntegrityChanged(currentProfile, updatedProfile) {
  if (
    currentProfile.allowEmail !== updatedProfile.allowEmail ||
    currentProfile.allowSMS !== updatedProfile.allowSMS ||
    currentProfile.allowSharingToSocialMedia !==
      updatedProfile.allowSharingToSocialMedia ||
    currentProfile.allowFindPotentialCustomers !==
      updatedProfile.allowFindPotentialCustomers ||
    currentProfile.allowAppPushNotification !==
      updatedProfile.allowAppPushNotification ||
    currentProfile.allowCollectBuyInfo !== updatedProfile.allowCollectBuyInfo ||
    currentProfile.allowDirectMail !== updatedProfile.allowDirectMail ||
    currentProfile.allowPhone !== updatedProfile.allowPhone ||
    currentProfile.sendFamilyPlasticCard !==
      updatedProfile.sendFamilyPlasticCard ||
    currentProfile.participateInMemberPanel !==
      updatedProfile.participateInMemberPanel
  ) {
    return true;
  }
  return false;
}

function isValueChanged(oldValue, newValue) {
  if (oldValue !== newValue) {
    return true;
  }
  return false;
}
