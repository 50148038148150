import residenceImageApartment from './RESIDENCE_TYPE_APARTMENT.svg';
import residenceImageHouse from './RESIDENCE_TYPE_HOUSE.svg';
import residenceImageFlat from './RESIDENCE_TYPE_FLAT.svg';
import residenceImageBedroom from './RESIDENCE_TYPE_BEDROOM.svg';
import residenceImageOther from './RESIDENCE_TYPE_OTHER.svg';
import residenceImageGarden from './RESIDENCE_TYPE_GARDEN.svg';
import residenceImageBalacony from './RESIDENCE_TYPE_BALCONY.svg';
import residenceImageTerrace from './RESIDENCE_TYPE_TERRACE.svg';
import residenceImageNoOutdoor from './RESIDENCE_TYPE_NOOUTDOOR.svg';
import residenceTypeSelected from './RESIDENCE_TYPE_SELECTED.svg';
import residenceTypeNotSelected from './RESIDENCE_TYPE_NOT_SELECTED.svg';

export default {
  '1': residenceImageApartment,
  '2': residenceImageHouse,
  '5': residenceImageFlat,
  '22': residenceImageBedroom,
  '4': residenceImageOther,
  GARDEN: residenceImageGarden,
  BALCONY: residenceImageBalacony,
  TERRACE: residenceImageTerrace,
  NO_OUTDOOR: residenceImageNoOutdoor,
  SELECTED: residenceTypeSelected,
  NOT_SELECTED: residenceTypeNotSelected
};
