// Please keep the below list sorted
export const defaultCountryCodeMap = {
  AD: '+376',
  AE: '+971',
  AL: '+355',
  AT: '+43',
  AU: '+61',
  AO: '+244',
  // AX: '+358', // Using 'FI' dial code
  BA: '+387',
  BE: '+32',
  BG: '+359',
  BH: '+973',
  BR: '+55',
  BY: '+375',
  // CA: '+1', // Using 'USA/CA' dial code
  CH: '+41',
  CL: '+56',
  CN: '+86',
  CO: '+57',
  CV: '+238',
  CY: '+357',
  CZ: '+420',
  DE: '+49',
  DK: '+45',
  // DO: '+1', // Using 'USA/CA' dial code
  EE: '+372',
  EG: '+20',
  ES: '+34',
  FI: '+358',
  FO: '+298',
  FR: '+33',
  GB: '+44',
  GE: '+995',
  GL: '+299',
  GR: '+30',
  GW: '+245',
  HK: '+852',
  HR: '+385',
  HU: '+36',
  ID: '+62',
  IE: '+353',
  IL: '+972',
  IN: '+91',
  IS: '+354',
  IT: '+39',
  JO: '+962',
  JP: '+81',
  KR: '+82',
  KW: '+965',
  // KZ: '+7', // Using 'RU' dial code
  LI: '+423',
  LT: '+370',
  LU: '+352',
  LV: '+371',
  MA: '+212',
  MC: '+377',
  MD: '+373',
  ME: '+382',
  MK: '+389',
  MT: '+356',
  MX: '+52',
  MY: '+60',
  MZ: '+258',
  NL: '+31',
  NO: '+47',
  NZ: '+64',
  OM: '+968',
  PH: '+63',
  PL: '+48',
  // PR: '+1', // Using 'USA/CA' dial code
  PT: '+351',
  QA: '+974',
  RO: '+40',
  RS: '+381',
  // RU: '+7', // Using 'RU/KZ' dial code
  'RU/KZ': '+7',
  SA: '+966',
  SAF: '+27',
  SE: '+46',
  // SG: '+65', SG is not support SMSs anymore since Jan-2023
  SI: '+386',
  SK: '+421',
  SM: '+378',
  ST: '+239',
  SY: '+963',
  TH: '+66',
  TR: '+90',
  TW: '+886',
  UA: '+380',
  // US: '+1', // Using 'USA/CA' dial code
  'USA/CA': '+1'
};

// Please keep the below list sorted
const availableCountryCodeMap = {
  ...defaultCountryCodeMap,
  AX: '+358',
  CA: '+1',
  DO: '+1',
  KZ: '+7',
  PR: '+1',
  RU: '+7',
  US: '+1'
};

delete availableCountryCodeMap['USA/CA'];
export { availableCountryCodeMap };
