import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './ShippingBillingAdresses.scss';

const DeliveryAddressSectionReadContainerButton = props => {
  return (
    <div className={styles['section-read-add-alternative-address']}>
      {props.children}
    </div>
  );
};

DeliveryAddressSectionReadContainerButton.propTypes = {
  title: PropTypes.string.isRequired,
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  deliveryAddressesFailed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

const mapStateToProps = state => {
  return {
    deliveryAddresses: state.addresses.deliveryAddresses,
    deliveryAddressesFailed: state.addresses.deliveryAddressesFailed
  };
};

export default connect(
  mapStateToProps,
  null
)(DeliveryAddressSectionReadContainerButton);
