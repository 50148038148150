export default class EventApiClient {
  constructor() {
    this.apiUrl = `${CONFIG.API.CMS.HOST}/api/profile/events/login/${CONFIG.COUNTRY}`;
  }

  /**
   * Calls the CMS login event service with auth token and guest id
   * @param  {string} authToken
   * @param  {string} guestUserId
   */
  sendLoginEvent(authToken, guestUserId) {
    const requestBody = {
      guestUserId,
      authToken
    };
    return fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }
}
