import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FlexPanel.scss';

export const FlexPanel = props => (
  <div
    style={props.style}
    className={classNames(styles['flex-panel'], {
      [styles['flex-panel-spread']]: props.spread,
      [styles['flex-panel-center']]: props.center,
      [styles['flex-panel-align-start']]: props.alignStart,
      [props.className]: props.className
    })}
  >
    {props.children}
  </div>
);

FlexPanel.propTypes = {
  spread: PropTypes.bool,
  alignStart: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
