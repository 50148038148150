import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'Common';
import { ADDRESS_SERVICE_PROVIDERS } from 'Services/address';
import { Components } from 'ExtendedForm/DynamicForm/components';
import FormField from 'BaseForm/FormField';
import AddressWithFilter from 'ExtendedForm/Address/AddressWithFilter';
import AddressWithDaumPostalService from 'ExtendedForm/Address/AddressWithDaumPostalService';
import AddressAutocomplete from 'ExtendedForm/Address/AddressAutocomplete/AddressAutocomplete';
import {
  getFieldValuesByNames,
  getFieldMessagesByNames
} from 'Redux/forms/formsHelper';

const AddressFormComponents = {
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_ADDRESSY]: AddressAutocomplete,
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_EVERYTHING_LOCATION]: AddressAutocomplete,
  [ADDRESS_SERVICE_PROVIDERS.GOOGLE_MAPS_GEOCODER]: AddressWithFilter,
  [ADDRESS_SERVICE_PROVIDERS.GOOGLE_MAPS_AUTOCOMPLETE]: AddressAutocomplete,
  [ADDRESS_SERVICE_PROVIDERS.DAUM_POSTAL_SERVICE]: AddressWithDaumPostalService,
  [ADDRESS_SERVICE_PROVIDERS.DA_DATA]: AddressAutocomplete,
  [ADDRESS_SERVICE_PROVIDERS.POSTCODE_NL]: AddressWithFilter,
  fallback: AddressAutocomplete
};

class AddressContainer extends React.PureComponent {
  onCountryChange = e => {
    this.props.onChange(e);

    // If any address field value requires prefix based on country code
    // selection (for Moldova postcode should be prefixed with MD-).
    this.addFieldsPrefix(e.target.value);

    if (!this.isAddressFormEmpty()) {
      Object.keys(this.props.addressValues).forEach(name => {
        // Trigger revalidation of address field.
        this.props.onBlur({
          target: {
            name,
            value: this.props.addressValues[name]
          }
        });
        // Clear address field if it is not considered a global field.
        if (
          !this.props.globalFields.some(
            field => field.componentProps.name === name
          )
        ) {
          this.props.onChange({ target: { name, value: undefined } });
        }
      });
    }
  };

  addFieldsPrefix = countryCode => {
    const fields = this.props.nestedFields.filter(
      field => field.componentProps.prefixAddressCountryCode === countryCode
    );
    fields.forEach(field => {
      const fieldName = field.componentProps.name;
      if (!this.props.addressValues[fieldName]) {
        const fieldValue = field.componentProps.prefix;
        this.props.onChange({
          target: {
            name: fieldName,
            value: fieldValue
          }
        });
      }
    });
  };

  isAddressFormEmpty = () => {
    return !Object.keys(this.props.addressValues).some(
      fieldName => this.props.addressValues[fieldName]
    );
  };

  render() {
    const {
      formId,
      messages,
      checkMandatory,
      countryField,
      retailUnitFields,
      globalFields,
      countryValue,
      addressValues
    } = this.props;

    const CountryComponent = Components[countryField && countryField.component];

    const AddressFormComponent =
      AddressFormComponents[CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER] ||
      AddressFormComponents.fallback;

    // Display only global fields ("address1", "address2", "zipCode" and
    // "cityName") when selected address country is not retail unit country.
    const displayOnlyGlobalAddressFields =
      countryValue && countryValue !== CONFIG.COUNTRY.toUpperCase();

    return (
      <React.Fragment>
        {CountryComponent && (
          <FormField
            validation={{
              id: `${formId}-${countryField.componentProps.name}-error`,
              msg: messages[countryField.componentProps.name],
              type: 'error'
            }}
            shouldValidate={
              messages[countryField.componentProps.name] !== undefined
            }
          >
            <Row>
              <Col sm={countryField.colWidth}>
                <CountryComponent
                  {...countryField.componentProps}
                  formId={formId}
                  value={countryValue}
                  showOptional={
                    !checkMandatory(countryField.componentProps.name)
                  }
                  onBlur={this.props.onBlur}
                  onChange={this.onCountryChange}
                />
              </Col>
            </Row>
          </FormField>
        )}
        <AddressFormComponent
          formId={formId}
          nestedFields={
            displayOnlyGlobalAddressFields ? globalFields : retailUnitFields
          }
          values={addressValues}
          messages={this.props.messages}
          checkMandatory={this.props.checkMandatory}
          disableAutocomplete={
            !CONFIG.FEATURE.ENABLE_ADDRESS_AUTOCOMPLETE ||
            displayOnlyGlobalAddressFields
          }
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

AddressContainer.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkMandatory: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // From redux
  messages: PropTypes.object.isRequired,
  // Reduced from "nestedFields"
  countryField: PropTypes.object,
  retailUnitFields: PropTypes.arrayOf(PropTypes.object),
  globalFields: PropTypes.arrayOf(PropTypes.object),
  // Reduced from "values"
  countryValue: PropTypes.string,
  addressValues: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const names =
    ownProps.nestedFields &&
    ownProps.nestedFields.map(field => field.componentProps.name);

  const values = getFieldValuesByNames(state, ownProps.formId, names);

  return {
    countryField: ownProps.nestedFields.find(
      field => field.componentProps.name === 'addressCountryCode'
    ),
    retailUnitFields: ownProps.nestedFields.filter(
      field => field.componentProps.name !== 'addressCountryCode'
    ),
    globalFields: ownProps.nestedFields.filter(
      field =>
        field.componentProps.name === 'address1' ||
        field.componentProps.name === 'address2' ||
        field.componentProps.name === 'zipCode' ||
        field.componentProps.name === 'cityName'
    ),
    countryValue: values.addressCountryCode,
    addressValues: Object.keys(values).reduce((newValues, fieldName) => {
      if (fieldName === 'addressCountryCode') {
        return newValues;
      }
      // eslint-disable-next-line no-param-reassign
      newValues[fieldName] = values[fieldName];
      return newValues;
    }, {}),
    messages: getFieldMessagesByNames(state, ownProps.formId, names)
  };
};

export default connect(
  mapStateToProps,
  null
)(AddressContainer);
