// These are the codes returned by the API
// They are translated into the app internal
// error codes defined in ErrorCodes.js
export const ReasonCodesAuth = {
  AUTH_OK: '1001',
  TBD_OK: '1101',
  PASSWORD_RESET_OK: '1101',
  PASSWORD_UPDATE_OK: '1201',
  BAD_REQUEST: '2001',
  VERIFICATION_FAILED_MISSING_PHONE_NUMBER: '2101',
  NOT_FAMILY_ACCOUNT: '2102',
  VERIFICATION_MISSING_EMAIL: '2103',
  VERIFICATION_UNAVAILABLE: '2104',
  VERIFICATION_UNKNOWN_ERROR: '2105',
  AUTH_FAIL_CREDENTIALS: '3001',
  PWD_INCORRECT: '3201',
  ACCOUNT_LOCKED: '4001',
  ACCOUNT_PAUSED_GDPR: '4010',
  ACCOUNT_MISSING: '4101',
  ACCOUNT_PWD_NOT_MATCHING: '4201',
  AUTH_FAIL_PWD_EXPIRED: '5001',
  ACCOUNT_UNVERIFIED: '5010',
  ACCOUNT_MISSING_EMAIL: '5011',
  ACCOUNT_VERIFICATION_FAILED: '5015',
  ACCOUNT_VERIFICATION_CODE_EXPIRED: '5016',
  VERIFICATION_COOLDOWN: '5017',
  ACCOUNT_NEW_VERSION_PRIVACY_POLICY: '5020',
  ACCOUNT_NEW_VERSION_TERMS_AND_CONDITIONS: '5021',
  ACCOUNT_PWD_RESET_WRONG_WCS: '5101',
  ACCOUNT_PWD_NOT_CONFORM: '5201',
  PASSWORD_TOO_WEAK: '5202',
  PASSWORD_TOO_COMMON: '5203',
  PASSWORD_PREVIOUSLY_USED: '5204',
  PASSWORD_CONTAINS_USER_INFO: '5205',
  AUTH_FAIL_TIMEOUT: '6001',
  UNSPECIFIED_ERROR: '9999',
  ACCOUNT_SSN_ALREADY_USED: '10150'
};
