import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IkeaButton from '@ingka/button';
import styles from './Button.scss';

export const Button = props => {
  const {
    margin,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    fullWidth,
    autoWidth,
    className,
    ...rest
  } = props;
  return (
    <IkeaButton
      {...rest}
      prefix={CONFIG.APP.STYLES_PREFIX}
      style={props.style}
      className={classNames({
        [styles[`btn-margin-${margin}`]]: margin !== undefined,
        [styles[`btn-margin-right-${marginRight}`]]: marginRight !== undefined,
        [styles[`btn-margin-left-${marginLeft}`]]: marginLeft !== undefined,
        [styles[`btn-margin-top-${marginTop}`]]: marginTop !== undefined,
        [styles[`btn-margin-bottom-${marginBottom}`]]:
          marginBottom !== undefined,
        [styles['btn-full-width']]: fullWidth,
        [styles['btn-auto-width']]: autoWidth,
        [className]: className
      })}
    />
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([
    'transactional',
    'primary',
    'secondary',
    'tertiary',
    'danger'
  ]),
  htmlType: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  mobileFullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  ssrIcon: PropTypes.func,
  iconPosition: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool
};
