import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { getProfileExternal } from 'Redux/profile/profileThunks';
import { verifyPhone } from 'Redux/verification/verificationThunks';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import { Loading } from 'Common/Loading';

class DashboardVerificationOtpContainer extends React.PureComponent {
  async componentDidMount() {
    if (!this.props.partyUid) {
      await this.props.getProfileExternal();
    }
    this.props.verifyPhone();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Loading isLoading text={t('loaders.otp')} />;
  }
}

DashboardVerificationOtpContainer.propTypes = {
  partyUid: PropTypes.string,
  verifyPhone: PropTypes.func.isRequired,
  getProfileExternal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    partyUid: state.auth.partyUid
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      verifyPhone,
      getProfileExternal
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardVerificationOtpContainer);
