import { ACTIONS } from './formsConstants';

export const setFormFieldValueAction = (formId, fieldName, fieldValue) => {
  return {
    type: ACTIONS.SET_FORM_FIELD_VALUE,
    formId,
    fieldName,
    fieldValue
  };
};

export const setFormFieldMessagesAction = (
  formId,
  fieldName,
  fieldMessages
) => {
  return {
    type: ACTIONS.SET_FORM_FIELD_MESSAGES,
    formId,
    fieldName,
    fieldMessages
  };
};

export const setFormValuesAction = (formId, values) => {
  return {
    type: ACTIONS.SET_FORM_VALUES,
    formId,
    values
  };
};

export const setFormErrorsAction = (formId, errors) => {
  return {
    type: ACTIONS.SET_FORM_ERRORS,
    errors,
    formId
  };
};

export const setFormDisableSubmit = (formId, disableSubmit) => {
  return {
    type: ACTIONS.SET_FORM_DISABLE_SUBMIT,
    formId,
    disableSubmit
  };
};

export const clearFormAction = formId => {
  return {
    type: ACTIONS.CLEAR_FORM,
    formId
  };
};

export const clearFormMessagesAction = formId => {
  return {
    type: ACTIONS.CLEAR_FORM_MESSAGES,
    formId
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
