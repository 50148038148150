import { ACTIONS } from './storesConstants';

const initialState = {
  stores: []
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_STORES:
      return {
        ...state,
        stores: action.stores || []
      };

    default:
      return state;
  }
};

export default stores;

// Actions should be mapped in the same order
// as they are defined in the actions file.
