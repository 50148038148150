import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Row, Col, Text } from 'Common';

const DashboardCheckboxReadContainer = props => (
  <Row>
    <Col sm={8}>
      <Text marginTop={props.margin && 'xl'}>
        {t(
          `${
            props.profile[`${props.name}`]
              ? props.labelChecked
              : props.labelUnchecked
          }`
        )}
      </Text>
    </Col>
  </Row>
);

DashboardCheckboxReadContainer.propTypes = {
  name: PropTypes.string,
  labelChecked: PropTypes.string.isRequired,
  labelUnchecked: PropTypes.string.isRequired,
  profile: PropTypes.object,
  margin: PropTypes.bool
};

DashboardCheckboxReadContainer.defaultProps = {
  names: [],
  profile: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardCheckboxReadContainer);
