import { ACTIONS } from './verificationConstants';

const initialState = {
  accountLinkedStatus: false,
  emailVerifiedStatus: false,
  identifier: 'email'
};

const verification = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_OTP_STATUS:
      return {
        ...state,
        otpSentStatus: action.otpSent,
        otpSentFrom: action.from
      };

    case ACTIONS.SET_OTP_SENT_ERROR:
      return {
        ...state,
        otpSentError: action.errorKey
      };

    case ACTIONS.SET_EMAIL_STATUS:
      return {
        ...state,
        emailSentStatus: action.emailSent
      };

    case ACTIONS.SET_ACCOUNT_LINKED_STATUS:
      return {
        ...state,
        accountLinkedStatus: action.accountsLinked
      };

    case ACTIONS.SET_EMAIL_VERIFIED_STATUS:
      return {
        ...state,
        emailVerifiedStatus: action.emailVerified
      };

    case ACTIONS.SET_LOGIN_IDENTIFIER:
      return {
        ...state,
        identifier: action.identifier
      };

    default:
      return state;
  }
};

export default verification;

// Actions should be mapped in the same order
// as they are defined in the actions file.
