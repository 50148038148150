// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__error-page{margin:auto}.profile__error-page__title{margin-top:6rem;margin-bottom:1rem;font-size:2.25rem;font-weight:700;line-height:1.3333333333;letter-spacing:-0.04rem}.profile__error-page__message{font-size:.875rem;line-height:1.7142857143}.profile__error-page__image{margin-top:3rem;margin-bottom:6rem;width:100%}@media(max-width: 48rem){.profile__error-page__title{margin-top:1rem;font-size:1.5rem;letter-spacing:-0.0075rem}.profile__error-page__image{margin-top:3rem;margin-bottom:3rem}}", "",{"version":3,"sources":["webpack://./src/components/ErrorPage/ErrorPage.scss"],"names":[],"mappings":"AAAA,qBACE,WAAA,CACA,4BACE,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,uBAAA,CAGF,8BACE,iBAAA,CACA,wBAAA,CAGF,4BACE,eAAA,CACA,kBAAA,CACA,UAAA,CAIJ,yBAEI,4BACE,eAAA,CACA,gBAAA,CACA,yBAAA,CAGF,4BACE,eAAA,CACA,kBAAA,CAAA","sourcesContent":[".error-page {\n  margin: auto;\n  &__title {\n    margin-top: 6rem;\n    margin-bottom: 1rem;\n    font-size: 2.25rem;\n    font-weight: 700;\n    line-height: 1.3333333333;\n    letter-spacing: -0.04rem;\n  }\n\n  &__message {\n    font-size: 0.875rem;\n    line-height: 1.7142857143;\n  }\n\n  &__image {\n    margin-top: 3rem;\n    margin-bottom: 6rem;\n    width: 100%;\n  }\n}\n\n@media (max-width: 48rem) {\n  .error-page {\n    &__title {\n      margin-top: 1rem;\n      font-size: 1.5rem;\n      letter-spacing: -0.0075rem;\n    }\n\n    &__image {\n      margin-top: 3rem;\n      margin-bottom: 3rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-page": "profile__error-page",
	"error-page__title": "profile__error-page__title",
	"error-page__message": "profile__error-page__message",
	"error-page__image": "profile__error-page__image"
};
export default ___CSS_LOADER_EXPORT___;
