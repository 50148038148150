/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'Common';
import { connect } from 'react-redux';
import styles from './DashboardContentPaneContainer.scss';
import AccountSettingsTabs from './AccountSettingsTabs';
import CustomerProject from './CustomerProject/CustomerProject';

const DashboardContentPaneContainer = props => {
  const components = {
    'account-settings': (
      <AccountSettingsTabs profileType={props.profile.profileType} />
    ),
    projects: <CustomerProject />
  };

  return (
    <Grid>
      <Row center>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={styles.contentPaneContainer}
        >
          {components[props.selectedMenuTab] && (
            <div className={styles.contentPaneContainerSpecified}>
              {components[props.selectedMenuTab]}
            </div>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

DashboardContentPaneContainer.propTypes = {
  className: PropTypes.string,
  selectedMenuTab: PropTypes.string,
  profile: PropTypes.object
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardContentPaneContainer);
