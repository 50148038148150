import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router/immutable';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import forms from 'Redux/forms/formsReducer';
import stores from 'Redux/stores/storesReducer';
import profile from 'Redux/profile/profileReducer';
import auth from 'Redux/auth/authReducer';
import userInterface from 'Redux/userInterface/userInterfaceReducer';
import addresses from 'Redux/addresses/addressesReducer';
import commerce from 'Redux/commerce/commerceReducer';
import verification from 'Redux/verification/verificationReducer';
import payment from 'Redux/payment/paymentReducer';

const history = createBrowserHistory({
  basename: `/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/${CONFIG.APP.BASE_NAME}`
});
const router = connectRouter(history);

const reducers = combineReducers({
  router,
  forms,
  stores,
  profile,
  auth,
  userInterface,
  addresses,
  commerce,
  verification,
  payment
});

const ReduxStore = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);

export { history, ReduxStore };
