import { ACTIONS } from './authConstants';

export const setLoginSuccessAction = userLogin => {
  return {
    type: ACTIONS.SET_LOGIN_SUCCESS,
    userLogin
  };
};

export const setUserLoginAction = userLogin => {
  return {
    type: ACTIONS.SET_USER_LOGIN,
    userLogin
  };
};

export const setUserPasswordAction = password => {
  return {
    type: ACTIONS.SET_USER_PASSWORD,
    password
  };
};

export const setPartyUidAction = partyUid => {
  return {
    type: ACTIONS.SET_PARTY_UID,
    partyUid
  };
};

export const clearAuthAction = () => {
  return {
    type: ACTIONS.CLEAR_AUTH
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
