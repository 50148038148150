import React from 'react';
import PropTypes from 'prop-types';
import IkeaCheckbox from '@ingka/checkbox';
import { Text, TextWithLink, InlineText } from 'Common';
import classNames from 'classnames';
import { t } from 'Utils/localization/i18next';
import styles from './Checkbox.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

const Checkbox = props => (
  <React.Fragment>
    <IkeaCheckbox
      id={`${props.formId}-${props.name}`}
      name={props.name}
      checked={props.defaultValue}
      text={
        props.label ? (
          <Text>{t(props.label)}</Text>
        ) : (
          <TextWithLink
            label={t(props.label)}
            labelBeforeLink={t(props.labelBeforeLink)}
            labelAfterLink={t(props.labelAfterLink)}
            labelLink={t(props.labelLink)}
            link={t(props.link)}
            linkTarget="_blank"
            lineBreak={props.labelLinkLineBreak}
          />
        )
      }
      prefix={CONFIG.APP.STYLES_PREFIX}
      disabled={props.disabled}
      describedById={`${props.formId}-${props.name}-error`}
      className={classNames(styles.checkbox, props.className)}
      onChange={props.onChange}
    />
    {props.extraTextMsg && <InlineText msg={t(props.extraTextMsg)} />}
  </React.Fragment>
);

Checkbox.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  labelLinkLineBreak: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  extraTextMsg: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func
};

export default Checkbox;
