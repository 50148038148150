import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import mask from 'Utils/mask/mask';
import { zipCode } from 'Utils/mask/zipCodeMasker';
import styles from './ShippingBillingAdresses.scss';

const PrimaryAddressFieldReadContainer = props =>
  props.profile.address1 || props.profile.zipCode || props.profile.cityName ? (
    <div className={styles['horizontal-container-spread']}>
      <Text>
        {props.profile.address1 &&
          props.profile.zipCode &&
          props.profile.cityName && (
            <div className={styles['primary-delivery-address-indicator']}>
              {t(
                'dashboard.addresses.billing-address.primary-delivery-address-indicator'
              )}
            </div>
          )}
        <div className={styles['common-specifier']}>
          {t(`dashboard.addresses.billing-address.address`)}
        </div>
        {props.rows.map((row, index) => (
          <div key={index} className={styles['horizontal-container-stacked']}>
            <div className={styles['common-value']}>
              {row.map(
                item =>
                  props.profile[item] &&
                  (item === 'addressCountryCode'
                    ? `${t(
                        `countries.${mask(
                          props.profile[item],
                          props.maskRules[item]
                        )}`
                      )} `
                    : item === 'zipCode'
                    ? `${zipCode(
                        props.profile[item],
                        props.profile.addressCountryCode,
                        props.maskRules[item]
                      )} `
                    : `${mask(props.profile[item], props.maskRules[item])} `)
              )}
            </div>
          </div>
        ))}
      </Text>
    </div>
  ) : (
    <p>{t(`dashboard.address.primaryMissing`)}</p>
  );

PrimaryAddressFieldReadContainer.propTypes = {
  profile: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  maskRules: PropTypes.object
};

PrimaryAddressFieldReadContainer.defaultProps = {
  profile: {},
  maskRules: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(PrimaryAddressFieldReadContainer);
