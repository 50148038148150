import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import Routes from 'Routes/profileRoutes';
import { t } from 'Utils/localization/i18next';

let CusAppLazy =
  CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS?.CUSTOMER_PROJECTS &&
  lazy(() => import('./CusProApp'));
const CustomerProject = () => {
  if (!CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS?.CUSTOMER_PROJECTS) {
    return <Redirect to={Routes.DASHBOARD} />;
  }
  if (window.location.href?.toLowerCase().endsWith('/projects/')) {
    CusAppLazy = lazy(() => import('./CusProApp'));
  }
  return (
    <React.Fragment>
      <Suspense
        fallback={
          <Loading
            text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
            prefix={CONFIG.APP.STYLES_PREFIX}
          >
            <LoadingBall prefix={CONFIG.APP.STYLES_PREFIX} color={'primary'} />
          </Loading>
        }
      >
        <CusAppLazy />
      </Suspense>
    </React.Fragment>
  );
};

export default CustomerProject;
