import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import {
  Select as IkeaDropdown,
  Option as IkeaDropdownItem
} from '@ingka/select';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

// IkeaDropdown does not allow using both value and defaultValue props.
// This component should be used only with _value_. For IkeaDropdown with
// defaultValue prop, use Dropdown.

class DropdownControlled extends React.PureComponent {
  render() {
    const id = this.props.formId
      ? `${this.props.formId}-${this.props.name}`
      : this.props.id;
    const hintProp = this.props.removeHintText
      ? { hintText: null }
      : { hintText: t('common.dropdown.default') };

    return (
      <IkeaDropdown
        id={id}
        name={this.props.name}
        {...hintProp}
        label={
          this.props.showOptional
            ? `${t(this.props.label)} ${t('forms.optional')}`
            : t(this.props.label)
        }
        describedById={`${id}-error`}
        prefix={CONFIG.APP.STYLES_PREFIX}
        className={this.props.className}
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
      >
        <IkeaDropdownItem
          style={!this.props.showOptional ? { display: 'none' } : {}}
        />
        {this.props.topOptions.map((option, index) => (
          <IkeaDropdownItem
            {...option}
            key={`${option.value}-${index}`}
            id={`${this.props.formId}-${this.props.name}-${index}`}
            name={t(option.name)}
            style={{ fontWeight: 'bold' }}
          />
        ))}
        {this.props.options.map((option, index) => (
          <IkeaDropdownItem
            {...option}
            key={`${option.value}-${index}`}
            id={`${this.props.formId}-${this.props.name}-${index}`}
            name={t(option.name)}
          />
        ))}
      </IkeaDropdown>
    );
  }
}
DropdownControlled.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  showOptional: PropTypes.bool,
  removeHintText: PropTypes.bool,
  // Array of { name: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  topOptions: PropTypes.arrayOf(PropTypes.object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

DropdownControlled.defaultProps = {
  value: '',
  options: [],
  topOptions: []
};

export default DropdownControlled;
