const FormData = require('form-data');
export default class SalutationApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    this.apiUrl = `${API.SALUTATION_SERVICE.HOST}`;
    this.x_client_id = `${API.SALUTATION_SERVICE.CLIENT_ID}`;
    this.retailUnit = `${COUNTRY}`;
    this.language = `${LANGUAGE}`;
  }
  getSalutationForFirstName(firstName, lastName, genderCode) {
    if (!firstName) {
      throw new Error('User First name must be provided to  fetch salutation');
    }

    const form = new FormData();
    form.append('retailUnit', this.retailUnit);
    form.append('language', this.language);
    form.append('firstName', firstName);
    form.append('lastName', lastName);
    if (
      !(genderCode === undefined || genderCode === null || genderCode === '')
    ) {
      form.append('genderCode', genderCode);
    }

    return fetch(this.apiUrl, {
      method: 'POST',
      headers: {
        'x-client-id': this.x_client_id
      },
      body: form
    })
      .then(res => res.json())
      .then(json => {
        return json;
      })
      .catch(error => {
        return firstName;
      });
  }
}
