import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlexPanel, Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import mask from 'Utils/mask/mask';

const DashboardFieldWithTranslationReadContainer = props => {
  const contentItems = [...props.content];
  props.translationIndex.forEach(index => contentItems.splice(index));

  const placeholder =
    contentItems.length === 1 &&
    !props.profile[contentItems[0]] &&
    t(`dashboard.placeholders.${contentItems[0]}`);

  return (
    <FlexPanel>
      {placeholder ? (
        <Text colour="grey-500">{placeholder}</Text>
      ) : (
        <Text>
          {props.content.reduce((concatenated, name, index) => {
            const key =
              props.plural &&
              props.profile[contentItems[props.pluralItemIndex]] > 1
                ? `${name}_plural`
                : name;
            const value = props.translationIndex.includes(index)
              ? t(key)
              : props.profile[name]
              ? `${mask(props.profile[name], props.maskRules)} `
              : '';
            return `${concatenated}${value}`;
          }, '')}
        </Text>
      )}
    </FlexPanel>
  );
};

DashboardFieldWithTranslationReadContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  profile: PropTypes.object,
  content: PropTypes.arrayOf(PropTypes.string),
  translationIndex: PropTypes.arrayOf(PropTypes.number),
  pluralItemIndex: PropTypes.number,
  plural: PropTypes.bool,
  maskRules: PropTypes.arrayOf(PropTypes.object)
};

DashboardFieldWithTranslationReadContainer.defaultProps = {
  name: ''
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardFieldWithTranslationReadContainer);
