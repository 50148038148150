import React from 'react';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import Routes from 'Routes/profileRoutes';

export const ResetPasswordBasicLink = () => {
  return (
    <Link
      id="reset-password-link"
      to={{
        pathname: Routes.RESET_PASSWORD_BASIC,
        search: window.location.search,
        hash: window.location.hash
      }}
      underline
    >
      {t('login.resetPasswordLink')}
    </Link>
  );
};
