import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, FlexPanel } from 'Common';

const DashboardFieldArrayReadContainer = props => {
  const arrayValues = props.profile[props.name];

  return arrayValues.map((value, index) => (
    <FlexPanel key={index}>
      <Text ltr>{value.label}</Text>
    </FlexPanel>
  ));
};

DashboardFieldArrayReadContainer.propTypes = {
  name: PropTypes.string.isRequired,
  profile: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

DashboardFieldArrayReadContainer.defaultProps = {
  name: ''
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardFieldArrayReadContainer);
