// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__input-field .profile__input-wrap--trailing-icon input{padding-right:2rem !important}.profile__input-field-rtl .profile__input-wrap--trailing-icon input{padding-left:2rem !important}", "",{"version":3,"sources":["webpack://./src/components/_form_base/InputFieldHandler.scss"],"names":[],"mappings":"AACE,gEACE,6BAAA,CAIF,oEACE,4BAAA","sourcesContent":[".input-field {\n  .input-wrap--trailing-icon input {\n    padding-right: 2rem !important;\n  }\n}\n.input-field-rtl {\n  .input-wrap--trailing-icon input {\n    padding-left: 2rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-field": "profile__input-field",
	"input-wrap--trailing-icon": "profile__input-wrap--trailing-icon",
	"input-field-rtl": "profile__input-field-rtl"
};
export default ___CSS_LOADER_EXPORT___;
