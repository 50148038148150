// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__svg-icon-blue{fill:rgb(var(--colour-static-ikea-brand-blue, 0, 88, 163)) !important}.profile__svg-icon-white{fill:rgb(var(--colour-neutral-1, 255, 255, 255)) !important}.profile__svg-icon-grey-300{fill:rgb(var(--colour-neutral-4, 204, 204, 204)) !important}.profile__svg-icon-grey-500{fill:rgb(var(--colour-neutral-5, 146, 146, 146)) !important}.profile__svg-icon-grey-700{fill:rgb(var(--colour-neutral-6, 72, 72, 72)) !important}.profile__svg-icon-grey-900{fill:rgb(var(--colour-neutral-7, 17, 17, 17)) !important}.profile__svg-icon-feedback-confirmation{fill:rgb(var(--colour-semantic-positive, 10, 138, 0)) !important}.profile__svg-icon-black{fill:rgb(var(--colour-static-black, 17, 17, 17)) !important}", "",{"version":3,"sources":["webpack://./src/components/_common/Icon/SSRIcon.scss"],"names":[],"mappings":"AAGE,wBACE,qEAAA,CAGF,yBACE,2DAAA,CAGF,4BACE,2DAAA,CAGF,4BACE,2DAAA,CAGF,4BACE,wDAAA,CAGF,4BACE,wDAAA,CAEF,yCACE,gEAAA,CAIJ,yBACE,2DAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.svg-icon {\n  &-blue {\n    fill: $colour-static-ikea-brand-blue !important;\n  }\n\n  &-white {\n    fill: $colour-neutral-1 !important;\n  }\n\n  &-grey-300 {\n    fill: $colour-neutral-4 !important;\n  }\n\n  &-grey-500 {\n    fill: $colour-neutral-5 !important;\n  }\n\n  &-grey-700 {\n    fill: $colour-neutral-6 !important;\n  }\n\n  &-grey-900 {\n    fill: $colour-neutral-7 !important;\n  }\n  &-feedback-confirmation {\n    fill: $colour-semantic-positive !important;\n  }\n}\n\n.svg-icon-black {\n  fill: $colour-static-black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg-icon-blue": "profile__svg-icon-blue",
	"svg-icon-white": "profile__svg-icon-white",
	"svg-icon-grey-300": "profile__svg-icon-grey-300",
	"svg-icon-grey-500": "profile__svg-icon-grey-500",
	"svg-icon-grey-700": "profile__svg-icon-grey-700",
	"svg-icon-grey-900": "profile__svg-icon-grey-900",
	"svg-icon-feedback-confirmation": "profile__svg-icon-feedback-confirmation",
	"svg-icon-black": "profile__svg-icon-black"
};
export default ___CSS_LOADER_EXPORT___;
