import i18next from 'i18next';

export const t = (keys, options) => {
  return i18next.t(keys, { ...options, interpolation: { escapeValue: false } });
};

export const exists = (key, options) => {
  return (
    key &&
    i18next.exists(key, options) &&
    t(key) !== null &&
    t(key).trim() !== ''
  );
};

export const i18nextInit = mode => {
  i18next.init({
    lng: '_default',
    resources: {
      _default: {
        translation: mode === 'OFF' ? null : TRANSLATIONS
      }
    }
  });
};
