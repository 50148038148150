import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Row, Col, Text } from 'Common';

const DashboardBooleanFieldReadContainer = props => (
  <Row>
    <Col sm={8}>
      <Text marginTop={props.margin && 'xl'}>
        {props.names.map((name, index) => (
          <React.Fragment key={`${index}-${name}`}>
            {t(
              `${props.keyPrefix}${
                props.profile[name] ? props.keySuffix[0] : props.keySuffix[1]
              }`
            )}
          </React.Fragment>
        ))}
      </Text>
    </Col>
  </Row>
);

DashboardBooleanFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  profile: PropTypes.object,
  keyPrefix: PropTypes.string.isRequired,
  keySuffix: PropTypes.arrayOf(PropTypes.string).isRequired,
  margin: PropTypes.bool
};

DashboardBooleanFieldReadContainer.defaultProps = {
  names: [],
  profile: {}
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardBooleanFieldReadContainer);
