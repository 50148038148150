import { readAuthTokenCookie } from 'Utils/auth/authUtils';
import { updatePreferencesRequest } from './preferencesTransformers';

export default class PreferencesApiClient {
  constructor() {
    this.apiUrl = `${
      CONFIG.API.PREFERENCES ? CONFIG.API.PREFERENCES.HOST : ''
    }/con/pref/v1/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/private-customer`;
    this.preferencesHeaders = {
      'X-IBM-Client-Id': '284bc6db-c908-458f-a911-b8d1768ab750',
      'X-IBM-Client-Secret':
        'F2cR4aH6vB3fN6kQ6xM4kB2rQ5eR0cP3jG4iN2tL3fX3xG5eY4'
    };
  }

  getUserPreferences() {
    const authTokenCookie = readAuthTokenCookie();
    if (!authTokenCookie) {
      throw Error();
    }
    return fetch(this.apiUrl, {
      method: 'GET',
      headers: {
        ...this.preferencesHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }

  updateUserPreferences(uncheckedPreferences) {
    const authTokenCookie = readAuthTokenCookie();
    if (!authTokenCookie) {
      throw Error();
    }
    const requestBody = updatePreferencesRequest(uncheckedPreferences);
    return fetch(this.apiUrl, {
      method: 'PUT',
      headers: {
        ...this.preferencesHeaders,
        'Content-Type': 'application/json',
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          resolve({
            response: { ok: true },
            request: requestBody
          });
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }
}
