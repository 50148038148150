import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Headers.scss';
import '@ingka/typography/style.scss';

const setIkeaBlue = (text, word) => {
  return text.replace(word, `<span style="color: #0058a3;">${word}</span>`);
};

const getClassName = props => {
  return classNames(styles.h, {
    [`header__h1`]: props.displayAs === 'h1',
    [`header__h2`]: props.displayAs === 'h2',
    [`header__h3`]: props.displayAs === 'h3',
    [`header__h4`]: props.displayAs === 'h4',
    [`header__h5`]: props.displayAs === 'h5',
    [`header__h6`]: props.displayAs === 'h6',
    [styles['h-center']]: props.center,
    [styles['h-margin']]: props.margin,
    [props.className]: props.className
  });
};

const getInnerHTML = props => {
  return {
    __html: setIkeaBlue(props.children, 'IKEA Family')
  };
};

export const H1 = props => (
  <h1
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

export const H2 = props => (
  <h2
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

export const H3 = props => (
  <h3
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

export const H4 = props => (
  <h4
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

export const H5 = props => (
  <h5
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

export const H6 = props => (
  <h6
    id={props.id}
    style={props.style}
    tabIndex={props.tabIndex}
    className={getClassName(props)}
    dangerouslySetInnerHTML={getInnerHTML(props)}
  />
);

const propTypes = {
  id: PropTypes.string,
  displayAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  center: PropTypes.bool,
  margin: PropTypes.bool,
  style: PropTypes.string,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.string
};

H1.propTypes = propTypes;
H2.propTypes = propTypes;
H3.propTypes = propTypes;
H4.propTypes = propTypes;
H5.propTypes = propTypes;
H6.propTypes = propTypes;

H1.defaultProps = { children: '', displayAs: 'h1', margin: true };
H2.defaultProps = { children: '', displayAs: 'h2', margin: true };
H3.defaultProps = { children: '', displayAs: 'h3', margin: true };
H4.defaultProps = { children: '', displayAs: 'h4', margin: true };
H5.defaultProps = { children: '', displayAs: 'h5', margin: true };
H5.defaultProps = { children: '', displayAs: 'h6', margin: true };
