import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, TextWithLink } from 'Common';

const DashboardCatalogExternalRead = props =>
  props.margin ? (
    <Text marginTop="l">
      <TextWithLink
        labelAfterLink={t(props.text)}
        labelLink={t(props.labelLink)}
        link={CONFIG.URL.CATALOG}
      />
    </Text>
  ) : (
    <TextWithLink
      labelAfterLink={t(props.text)}
      labelLink={t(props.labelLink)}
      link={CONFIG.URL.CATALOG}
    />
  );

DashboardCatalogExternalRead.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  labelLink: PropTypes.string.isRequired,
  margin: PropTypes.string
};

export default DashboardCatalogExternalRead;
