/**
 * Returns a new object identical to the passed object with all the string properties trimmed
 * except for properties with key that might conntain 'password' part of the key name like 'currentPassword'.
 * It does not support nested objects.
 * @param {Object} obj - Object with some string properties to be trimmed.
 * @returns {Object} A new object with its string properties trimmed.
 */
export const trimAllObjPropertiesSkipPasswords = obj => {
  const trimmedObj = { ...obj };
  const trimmedObjKeys = Object.keys(trimmedObj);
  trimmedObjKeys.map(trimmedObjKey => {
    if (
      !trimmedObjKey.toLocaleLowerCase().includes('password') &&
      typeof trimmedObj[trimmedObjKey] === 'string'
    ) {
      trimmedObj[trimmedObjKey] = trimmedObj[trimmedObjKey].trim();
      return trimmedObj[trimmedObj];
    }
    return trimmedObj[trimmedObj];
  });
  return trimmedObj;
};
