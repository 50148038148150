export function transformFields(response, addressFieldMap) {
  if (!response) {
    return false;
  }
  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const experianFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!experianFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = experianFieldNames.reduce(
      (concatenatedValue, experianFieldName) => {
        const separatorMatch = experianFieldName.match(/^\{(.*)\}$/);

        if (separatorMatch) {
          nextSeparator = separatorMatch[1];
          return concatenatedValue;
        }

        const value = response[experianFieldName];
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap || !data) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (new RegExp(prevValue, 'i').test(mappedValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  return data;
}

export function transformOptions(response) {
  if (!response || !response.suggestions || !response.suggestions.length) {
    return false;
  }
  let options = [];
  const suggestions = response.suggestions;
  for (let i = 0; i < suggestions.length; i++) {
    options.push({
      id: suggestions[i].captureId,
      label: suggestions[i].text,
      isCapturable: true
    });
  }
  return options;
}
