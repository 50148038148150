import React from 'react';
import PropTypes from 'prop-types';
import IkeaSSRIcon from '@ingka/ssr-icon';
import classNames from 'classnames';
import { SSRIcons } from './SSRIcons';
import '@ingka/svg-icon/style.scss';
import styles from './SSRIcon.scss';

export const SSRIcon = props =>
  SSRIcons[props.icon] ? (
    <IkeaSSRIcon
      paths={SSRIcons[props.icon]}
      size={props.size}
      modifier={props.modifier}
      viewBox={props.viewBox}
      ariaHidden={props.ariaHidden}
      alt={props.alt}
      className={classNames({
        [props.className]: props.className,
        [styles[`svg-icon-${props.colour}`]]: props.colour
      })}
      style={props.style}
      prefix={CONFIG.APP.STYLES_PREFIX}
      onClick={props.onClick}
    />
  ) : null;

SSRIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['100', '150', '200', '250', '300']),
  colour: PropTypes.oneOf([
    'blue',
    'white',
    'grey-300',
    'grey-500',
    'grey-700',
    'grey-900',
    'black',
    'feedback-confirmation'
  ]),
  modifier: PropTypes.string,
  viewBox: PropTypes.string,
  ariaHidden: PropTypes.bool,
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
