import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'Utils/localization/i18next';
import { FORM_KEY_SUFFIX } from 'Redux/forms/formsConstants';
import {
  setFormFieldValueAction,
  setFormFieldMessagesAction,
  setFormErrorsAction,
  setFormDisableSubmit
} from 'Redux/forms/formsActions';
import { getFormDisabledSubmit } from 'Redux/forms/formsHelper';
import { Row, Col, Button, Toast, Text, InlineAlert } from 'Common';
import validate from 'Utils/validation/validate';
import autocorrect from 'Utils/autocorrect/autocorrect';
import transform from 'Utils/transform/transform';
import DynamicFormSection from './DynamicFormSection';
import DynamicFormFieldContainer from './DynamicFormFieldContainer';
import { FormStateTriggers } from './formStateTriggers';

import {
  SimpleInputComponents,
  NestedInputComponents,
  StaticComponents
} from './components';

class DynamicFormContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      model: FORM_CONFIG[this.props.formId],
      readyToRender: false,
      formBlockedByTrigger: null,
      fieldsHiddenByTrigger: [],
      fieldsDisabledByTrigger: []
    };
    this.formWrapRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.model) {
      if (this.state.model.enableCaptcha && CONFIG.FEATURE.ENABLE_CAPTCHA) {
        const script = document.createElement('script');
        const { API, LANGUAGE } = CONFIG;
        const { CAPTCHA } = API;
        script.async = true;
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${CAPTCHA.SECRET}&hl=${LANGUAGE}`;
        document.body.appendChild(script);
      }
      this.setInitialFormValuesInRedux();
      const newState = this.getStateAfterApplyingFormStateTriggers();
      this.setState({ ...newState, readyToRender: true }, () => {
        this.updateDisabledFieldsInDOM([], newState.fieldsDisabledByTrigger);
        this.focusOnBackendErrorAlert();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const formValuesKey = `${this.props.formId}${FORM_KEY_SUFFIX.VALUES}`;
    if (prevProps.forms[formValuesKey] !== this.props.forms[formValuesKey]) {
      // If a field value in this form was changed, we need to re-evaluate
      // any "formStateTriggers" defined in yml form configuration / model.
      // A trigger can e.g. toggle field visibility or disabled state.
      const newState = this.getStateAfterApplyingFormStateTriggers();
      if (this.state !== newState) {
        this.setState(newState);
        this.clearFieldValidation([
          ...newState.fieldsDisabledByTrigger,
          ...newState.fieldsHiddenByTrigger
        ]);
        this.updateDisabledFieldsInDOM(
          this.state.fieldsDisabledByTrigger,
          newState.fieldsDisabledByTrigger
        );
      }
      const disableSubmit = this.isFieldsSame(
        this.getFormValues(),
        this.props.prefillValues,
        Object.keys(this.getFormValues())
      );
      this.props.setFormDisableSubmit(this.props.formId, disableSubmit);
    }
  }

  isFieldsSame = (newValues, oldValues, keys) =>
    keys.every(key => newValues[key] === oldValues[key]);

  // Sets initial form values to Redux store. The initial field value can be
  // provided either as part of the "prefillValues" prop to DynamicFormContainer
  // or as "defaultValue" for one or more fields in the yml form configuration.
  // Values in "prefillValues" will precede "defaultValue" in yml configuration.
  setInitialFormValuesInRedux = () => {
    if (this.hasFormValues()) {
      // Prevent any current form values form being overridden by
      // default values if DynamicFormContainer is remounted.
      return;
    }
    const setInitialFieldValueInRedux = field => {
      const {
        name,
        label,
        defaultValue,
        disablePrefill
      } = field.componentProps;

      const prefillValue = this.props.prefillValues[name];

      // Set field value to prefill value or default value.
      const value =
        prefillValue !== undefined && !disablePrefill
          ? prefillValue
          : defaultValue;

      // Transform field value.
      const displayValue = transform(value, field.displayRules);

      // Autocorrect field value.
      const autocorrectedValue = autocorrect(
        this.props.formId,
        displayValue,
        field.autocorrectRules
      );

      // If the field value is a prefill value, perform validation so
      // that the user can easily see which fields are invalid.
      const shouldValidate =
        prefillValue !== undefined &&
        prefillValue !== '' &&
        !disablePrefill &&
        !this.isFieldHiddenByTrigger(name) &&
        !this.isFieldDisabledByTrigger(name);

      const messages = shouldValidate
        ? validate(
            this.props.formId,
            autocorrectedValue,
            label,
            field.validationRules,
            true
          )
        : [];
      if (messages.length) {
        this.props.setFormFieldMessagesAction(
          this.props.formId,
          name,
          messages
        );
      }

      if (value !== undefined) {
        this.props.setFormFieldValueAction(
          this.state.model.id,
          name,
          autocorrectedValue
        );
      }
    };
    this.forEachFormField(setInitialFieldValueInRedux);
  };

  // Validates each form field based on their list of validation
  // rules and sets validation messages to Redux store.
  setFormValidationInRedux = async () => {
    const formId = this.state.model.id;
    const formValues = this.getFormValues();

    const setFieldValidationInRedux = async field => {
      const { validationRules } = field;
      const { name, label } = field.componentProps;

      const shouldValidate =
        !this.isFieldHiddenByTrigger(name) &&
        !this.isFieldDisabledByTrigger(name);

      const messages = shouldValidate
        ? validate(formId, formValues[name], label, validationRules, true)
        : [];
      await this.props.setFormFieldMessagesAction(formId, name, messages);
    };
    this.forEachFormField(setFieldValidationInRedux);
  };

  setDisabledAndHiddenFieldValuesToInitialValue = () => {
    const { fieldsDisabledByTrigger, fieldsHiddenByTrigger } = this.state;
    [...fieldsDisabledByTrigger, ...fieldsHiddenByTrigger].forEach(name => {
      this.props.setFormFieldValueAction(
        this.state.model.id,
        name,
        this.props.prefillValues[name]
      );
    });
  };

  // Iterates and performs an action on all input fields in this form.
  forEachFormField = fieldAction => {
    const { model } = this.state;

    model.sections.forEach(section => {
      section.fields.forEach(field => {
        if (StaticComponents[field.component]) {
          return;
        }
        if (SimpleInputComponents[field.component]) {
          fieldAction(field);
        }
        if (NestedInputComponents[field.component]) {
          field.componentProps.nestedFields.forEach(nestedField => {
            fieldAction(nestedField);
          });
        }
      });
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    // All fields (except for hidden and disabled fields) will be revalidated.
    await this.setFormValidationInRedux();

    // The form will be submitted if there are no validation messages in Redux.
    if (this.props.onSubmit && this.isFormValid()) {
      // In case the user typed or cleared something in field before
      // the field was disabled/hidden, we need to reset the field to
      // its initial value so it will not be overridden.
      await this.setDisabledAndHiddenFieldValuesToInitialValue();
      // "transformRules" can be defined in in yml form configuration / model.
      const values = this.getTransformedFormValues();
      this.props.onSubmit(values);
    } else {
      this.scrollToFirstValidationMessage();
    }
  };

  isFormValid = () => {
    const messages = this.getFormMessages();
    return !Object.keys(messages).some(
      field => messages[field] && messages[field].length > 0
    );
  };

  hasFormValues = () => {
    const formValues = this.getFormValues();
    const fieldNames = Object.keys(formValues) || [];
    return fieldNames.some(
      name => formValues[name] !== undefined && formValues[name] !== ''
    );
  };

  getFormValues = () => {
    const formId = this.state.model.id;
    const formsKey = `${formId}${FORM_KEY_SUFFIX.VALUES}`;
    return this.props.forms[formsKey] || {};
  };

  getFormMessages = () => {
    const formId = this.state.model.id;
    const formsKey = `${formId}${FORM_KEY_SUFFIX.MESSAGES}`;
    return this.props.forms[formsKey] || {};
  };

  getFormErrors = () => {
    const formId = this.state.model.id;
    const formsKey = `${formId}${FORM_KEY_SUFFIX.ERRORS}`;
    return this.props.forms[formsKey] || [];
  };

  getFormFieldNamesInOrder = () => {
    return this.state.model.sections.reduce((fieldNames, section) => {
      section.fields.forEach(field => {
        if (SimpleInputComponents[field.component]) {
          fieldNames.push(field.componentProps.name);
        }
        if (NestedInputComponents[field.component]) {
          field.componentProps.nestedFields.forEach(nestedField => {
            fieldNames.push(nestedField.componentProps.name);
          });
        }
      });
      return fieldNames;
    }, []);
  };

  getTransformedFormValues = () => {
    // "transformRules" can be defined in in yml form configuration / model.
    const values = this.getFormValues();
    const transformedValues = { ...values };
    this.state.model.sections.forEach(section => {
      section.fields.forEach(field => {
        if (StaticComponents[field.component]) {
          return;
        }
        if (SimpleInputComponents[field.component]) {
          const { name } = field.componentProps;
          transformedValues[name] = transform(
            values[name],
            field.transformRules
          );
        }
        if (NestedInputComponents[field.component]) {
          field.componentProps.nestedFields.forEach(nestedField => {
            const { name } = nestedField.componentProps;
            transformedValues[name] = transform(
              values[name],
              nestedField.transformRules
            );
          });
        }
      });
    });
    return transformedValues;
  };

  focusOnBackendErrorAlert = () => {
    const errorAlertId = `${this.state.model.id}-error-alert`;
    const element = document.getElementById(errorAlertId);
    if (element) {
      element.focus();
    }
  };

  scrollToFirstValidationMessage = () => {
    const fieldNames = this.getFormFieldNamesInOrder();
    const messages = this.getFormMessages();

    const firstFieldNameWithMessages = fieldNames.find(
      name => messages[name] && messages[name].length
    );
    const inputFieldId = `${this.state.model.id}-${firstFieldNameWithMessages}`;
    const element = document.getElementById(inputFieldId) || this.formWrapRef;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.focus();
    }
  };

  isFieldDisabledByTrigger = fieldName => {
    return this.state.fieldsDisabledByTrigger.some(name => name === fieldName);
  };

  isFieldHiddenByTrigger = fieldName => {
    return this.state.fieldsHiddenByTrigger.some(name => name === fieldName);
  };

  isFormComponentHidden = fieldReferences => {
    if (!fieldReferences) {
      return false;
    }
    return !fieldReferences.some(name => !this.isFieldHiddenByTrigger(name));
  };

  setFormWrapRef = node => {
    if (node) {
      this.formWrapRef = node;
    }
  };

  getStateAfterApplyingFormStateTriggers = () => {
    const { model } = this.state;
    if (!model || !model.formStateTriggers) {
      return this.state;
    }
    const initialState = {
      ...this.state,
      formBlockedByTrigger: null,
      fieldsHiddenByTrigger: [],
      fieldsDisabledByTrigger: []
    };
    const newState = model.formStateTriggers.reduce((state, trigger) => {
      return FormStateTriggers[trigger.name](state, trigger.args, {
        prefillValues: this.props.prefillValues,
        formValues: this.getFormValues()
      });
    }, initialState);
    return newState;
  };

  updateDisabledFieldsInDOM = (prevDisabled, nextDisabled) => {
    if (
      prevDisabled === nextDisabled ||
      (!prevDisabled.length && !nextDisabled.length)
    ) {
      return;
    }
    const fieldsToEnable = prevDisabled.filter(
      prevName => !nextDisabled.some(nextName => nextName === prevName)
    );
    const fieldsToDisable = nextDisabled.filter(
      nextName => !prevDisabled.some(prevName => prevName === nextName)
    );
    fieldsToEnable.forEach(name => {
      this.setDOMElementDisabled(`${this.props.formId}-${name}`, false);
    });
    fieldsToDisable.forEach(name => {
      this.setDOMElementDisabled(`${this.props.formId}-${name}`, true);
    });
  };

  setDOMElementDisabled = (elementId, attributeValue) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.disabled = attributeValue;
    }
  };

  clearFieldValidation = (fieldNames = []) => {
    fieldNames.forEach(name =>
      this.props.setFormFieldMessagesAction(this.props.formId, name, [])
    );
  };

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      this.onSubmit(e);
    }
  };

  render() {
    const { model, readyToRender } = this.state;

    if (!readyToRender) {
      return null;
    }

    const backendErrors = this.getFormErrors();

    return (
      <div ref={this.setFormWrapRef}>
        <Row>
          <Col md={model.colWidth || 12}>
            <form>
              {backendErrors.length > 0 && (
                <Toast
                  onClose={() =>
                    this.props.setFormErrorsAction(this.props.formId)
                  }
                >
                  <Text bold>{t('forms.errors.title')}</Text>
                  {backendErrors.map(error => (
                    <Text key={error}>- {t(error)}</Text>
                  ))}
                </Toast>
              )}
              {model.sections.map((section, sectionIndex) => {
                if (this.isFormComponentHidden(section.fieldReferences)) {
                  return null;
                }
                const { fields, ...sectionProps } = section;
                return (
                  <DynamicFormSection
                    key={`${model.id}-section-${sectionIndex}`}
                    {...sectionProps}
                  >
                    {fields.map((field, fieldIndex) => {
                      if (
                        this.isFieldHiddenByTrigger(
                          field.componentProps && field.componentProps.name
                        ) ||
                        this.isFormComponentHidden(field.fieldReferences)
                      ) {
                        return null;
                      }
                      return (
                        <DynamicFormFieldContainer
                          key={`${model.id}-field-${fieldIndex}`}
                          formId={model.id}
                          {...field}
                          onKeyDown={
                            field.componentProps
                              ? field.componentProps.submitOnEnter
                                ? this.onKeyDown
                                : undefined
                              : undefined
                          }
                        />
                      );
                    })}
                  </DynamicFormSection>
                );
              })}
              {this.state.formBlockedByTrigger && (
                <InlineAlert
                  id={`${model.id}-blocked-alert`}
                  title={this.state.formBlockedByTrigger.messageTitle}
                  text={this.state.formBlockedByTrigger.messageText}
                  marginBottom="m"
                />
              )}
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={model.colWidthButton || model.colWidth || 12}>
            {this.props.textRemove && (
              <Button
                fullWidth
                marginTop="s"
                type="secondary"
                text={this.props.textRemove}
                loading={this.props.loadingRemove}
                id={`${this.props.formId}-remove`}
                onClick={this.props.onRemove}
              />
            )}
            {this.props.textSubmit && !this.state.formBlockedByTrigger && (
              <Button
                autoWidth
                marginTop="s"
                type="primary"
                marginRight="s"
                small={true}
                htmlType="submit"
                text={this.props.textSubmit}
                loading={this.props.loadingSubmit}
                id={`${this.props.formId}-submit`}
                onClick={this.onSubmit}
                disabled={this.props.submitDisabled}
              />
            )}
            {this.props.textCancel && (
              <Button
                autoWidth
                marginTop="s"
                type="secondary"
                small={true}
                text={this.props.textCancel}
                loading={this.props.loadingCancel}
                id={`${this.props.formId}-cancel`}
                onClick={this.props.onCancel}
              />
            )}
          </Col>
        </Row>
        {this.props.children && (
          <Row>
            <Col md={model.colWidth || 12}>{this.props.children}</Col>
          </Row>
        )}
      </div>
    );
  }
}

DynamicFormContainer.propTypes = {
  formId: PropTypes.string.isRequired,
  prefillValues: PropTypes.object,
  textSubmit: PropTypes.string,
  textCancel: PropTypes.string,
  textRemove: PropTypes.string,
  loadingSubmit: PropTypes.bool,
  loadingCancel: PropTypes.bool,
  loadingRemove: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // From Redux
  forms: PropTypes.object.isRequired,
  setFormFieldValueAction: PropTypes.func.isRequired,
  setFormFieldMessagesAction: PropTypes.func.isRequired,
  setFormErrorsAction: PropTypes.func.isRequired,
  setFormDisableSubmit: PropTypes.func
};

DynamicFormContainer.defaultProps = {
  forms: {},
  prefillValues: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    forms: state.forms,
    submitDisabled: getFormDisabledSubmit(state, ownProps.formId)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormFieldValueAction,
      setFormFieldMessagesAction,
      setFormErrorsAction,
      setFormDisableSubmit
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicFormContainer);
