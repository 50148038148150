import React from 'react';
import PropTypes from 'prop-types';
import { t, exists } from 'Utils/localization/i18next';

export const TranslateToInnerHTML = props => {
  const html = exists(props.translationKey) && t(props.translationKey);
  return !html ? null : <div dangerouslySetInnerHTML={{ __html: html }} />;
};

TranslateToInnerHTML.propTypes = {
  translationKey: PropTypes.string
};
