import zipCodeMaskRules from './zipCodeMaskRules';

export const zipCode = (value, country, rule) => {
  if (!value || !value.length || !rule) {
    return value;
  }

  if (!country) {
    return value;
  }

  if (!zipCodeMaskRules[country]) {
    // If there should be a fallback masking, add it here.
    return value;
  }

  return zipCodeMaskRules[country].reduce(
    (currentValue, currentRule) => currentRule(currentValue),
    value
  );
};
