import { ErrorCodes } from './ErrorCodes';
import { ErrorTypes } from './ErrorTypes';
import { ResponseError } from './models';

const httpStatusCodeToTypeAndError = {
  400: [ErrorTypes.SERVICE, ErrorCodes.SERVICE_BAD_REQUEST],
  401: [ErrorTypes.SERVICE, ErrorCodes.SERVICE_INTERNAL],
  403: [],
  404: [ErrorTypes.NETWORK, ErrorCodes.NETWORK_RESOURCE_NOT_FOUND],
  429: [ErrorTypes.VERIFICATION, ErrorCodes.VERIFICATION_COOLDOWN],
  500: [ErrorTypes.SERVICE, ErrorCodes.UNKNOWN],
  503: [ErrorTypes.SERVICE, ErrorCodes.SERVICE_UNAVAILABLE],
  504: [ErrorTypes.SERVICE, ErrorCodes.SERVICE_TIMEOUT]
};

export function fromHttpError(httpStatusCode) {
  const codes = httpStatusCodeToTypeAndError[httpStatusCode] || [
    ErrorTypes.UNKNOWN,
    ErrorCodes.UNKNOWN
  ];
  return new ResponseError(...codes);
}
