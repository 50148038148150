import analytics from 'Utils/analytics/analyticsWrapper';
import { getProfanityError } from 'Utils/profanityFilter/profanityFilterUtils';

const errorsMap = {
  PWD_INCORRECT: 'forms.errors.incorrectPassword',
  ACCOUNT_LOCKED: 'forms.errors.accountLocked',
  ACCOUNT_PAUSED_GDPR: 'forms.errors.accountPausedGDPR',
  ACCOUNT_PWD_NOT_MATCHING: 'forms.errors.passwordNotMatching',
  AUTH_FAIL_PWD_EXPIRED: 'forms.errors.passwordExpired',
  ACCOUNT_PWD_NOT_CONFORM: 'forms.errors.passwordNotConform',
  PASSWORD_TOO_COMMON: 'forms.errors.passwordTooCommon',
  PASSWORD_PREVIOUSLY_USED: 'forms.errors.passwordPreviouslyUsed',
  ACCOUNT_ALREADY_EXISTS: 'forms.errors.userAlreadyExists',
  LOGONID_ALREADY_EXIST: 'forms.errors.userAlreadyExists',
  LOGONID_ALREADY_EXIST_ON_MOBILE_UPDATE:
    'forms.errors.userAlreadyExistsOnMobileUpdate',
  ACCESS_WRONG_CREDENTIALS: 'forms.errors.incorrectPassword',
  ACCOUNT_VALIDATION_FAILED: 'forms.errors.validationFailed',
  ACCOUNT_ALREADY_LOGGED_IN: 'forms.errors.alreadyLoggedIn',
  ACCOUNT_DELIVERY_ADDRESS_QUANTITY_LIMIT:
    'forms.errors.deliveryAddressQuantityLimit',
  ACCOUNT_SSN_ALREADY_USED: 'forms.errors.ssnAlreadyUsed',
  ACCOUNT_INVALID_EMAIL_DOMAIN: 'forms.errors.emailDomain',
  ACCOUNT_INVALID_ZIPCODE: 'forms.validation.zipCode',
  ACCOUNT_INVALID_CHILD_BIRTH_DATE: 'forms.validation.childBirthdate',
  PASSWORD_TOO_WEAK: 'forms.errors.weakPassword',
  PASSWORD_CONTAINS_USER_INFO: 'forms.errors.passwordHasUserInfo',
  VERIFICATION_UNVERIFIED_MOBILE: 'forms.errors.mobileUnverified',
  VERIFICATION_UNVERIFIED_EMAIL: 'forms.errors.emailUnverified',
  VERIFICATION_FAILED: 'forms.errors.verificationFailed',
  VERIFICATION_FAILED_CONTEXT_NOT_FOUND: 'forms.errors.verificationFailed',
  VERIFICATION_COOLDOWN: 'forms.errors.verificationCooldown',
  USER_EXIST_AUTH0_VERIFIED: 'forms.errors.phoneAleadyVerified',
  USER_EXIST_IRW_MOBILE_LOGON_IDENTIFIER: 'forms.errors.phoneAleadyVerified',
  PROFILE_UNIQUE_FAILURE: 'forms.errors.taxidNotUnique',
  NOT_AUTHORIZED: 'forms.errors.incorrectPassword',
  10150: 'forms.errors.phoneAleadyVerified',
  K8S_FORBIDDEN: 'forms.errors.botAttack',
  CF_FORBIDDEN: 'forms.errors.botAttack'
};

const mapErrors = errors => {
  const mapped = errors.map(error => errorsMap[error.code]).filter(Boolean);
  return mapped.length ? mapped : ['forms.errors.generic'];
};

export const mapBackendException = e => {
  const errors = e.response && e.response.errors ? e.response.errors : [];
  const PROFILE_UNIQUE_FAILURE = 'PROFILE_UNIQUE_FAILURE';

  if (CONFIG.FEATURE.ENABLE_PROFANITY_FILTER_SERVICE) {
    // If exception is of type profanity then profanityError will be true
    if (e.profanityError) {
      // Length of errors will always be 1 - Refer ProfanityFilterService
      return getProfanityError(
        'forms.validation.profanity',
        errors[0].textForValidation,
        errors[0].fieldName
      );
    }
  }
  if (e?.message === PROFILE_UNIQUE_FAILURE) {
    return [errorsMap[(e?.message)]];
  }

  return mapErrors(errors);
};

const mapAnalyticsErrors = e => {
  const errors =
    (e.response && e.response.errors) || (Array.isArray(e) && e) || [];
  const mapped = errors.map(error => {
    const validationErrors = error.validationErrors || [];
    return {
      errorKey: error.errorType,
      errorMessage: validationErrors[0]?.message || error.message || 'generic'
    };
  });
  return mapped.length
    ? mapped
    : [{ errorKey: 'generic', errorMessage: 'unexpected error' }];
};

export const sendAnalyticsErrors = (e, formId) => {
  const analyticsErrors = mapAnalyticsErrors(e);
  analyticsErrors.forEach(error => {
    analytics.userProfileUpdateError({
      status: 'error',
      form: formId,
      errorKey: error.errorKey,
      errorMessage: error.errorMessage
    });
  });
};
