import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'Utils/localization/i18next';
import { Text } from 'Common';
import { Swipeable } from 'Common/Swipeable';
import styles from './ImageSliderSelector.scss';
import arrowRight from './images/arrow-right.svg';
import arrowLeft from './images/arrow-left.svg';

const ImageSliderSelector = props => {
  const { options } = props;

  if (!options || options.length < 2) {
    return null;
  }

  let currIndex = options.findIndex(option => option.value === props.value);
  currIndex = currIndex === -1 ? 0 : currIndex;
  const prevIndex = currIndex - 1 >= 0 ? currIndex - 1 : options.length - 1;
  const nextIndex = currIndex + 1 < options.length ? currIndex + 1 : 0;

  const prevOption = options[prevIndex];
  const currOption = options[currIndex];
  const nextOption = options[nextIndex];
  const isSelected = props.value === currOption.value;

  const select = value => {
    props.onChange({
      target: { name: props.name, value }
    });
  };

  return (
    <Swipeable
      onSwipedLeft={() => select(nextOption.value)}
      onSwipedRight={() => select(prevOption.value)}
      className={styles['image-slider-selector']}
    >
      <div className={styles['image-slider-selector-row']}>
        <img
          src={arrowLeft}
          className={styles['image-slider-selector-arrow']}
          onClick={() => select(nextOption.value)}
        />
        <div
          className={classNames(styles['image-slider-selector-image-wrap'], {
            [styles['image-slider-selector-image-wrap-selected']]: isSelected
          })}
          onClick={() => select(currOption.value)}
        >
          <img
            src={currOption.image}
            alt={t(currOption.label)}
            className={styles['image-slider-selector-image']}
          />
          {currOption.label && (
            <Text
              smaller
              className={styles['image-slider-selector-image-text']}
            >
              {t(currOption.label)}
            </Text>
          )}
          <img
            src={
              isSelected
                ? currOption.selectedImage
                : currOption.notSelectedImage
            }
            className={styles['image-slider-selector-check']}
          />
        </div>
        <img
          src={arrowRight}
          className={styles['image-slider-selector-arrow']}
          onClick={() => select(nextOption.value)}
        />
      </div>
      <div className={styles['image-slider-selector-row']}>
        {props.options.map((option, index) => (
          <div
            key={`image-slider-selector-bullet-${index}`}
            className={classNames(styles['image-slider-selector-bullet'], {
              [styles['image-slider-selector-bullet-selected']]:
                index === currIndex
            })}
          />
        ))}
      </div>
    </Swipeable>
  );
};

ImageSliderSelector.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  // Array of { label: "", value: "", image: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired
};

export default ImageSliderSelector;
