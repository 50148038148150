import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFormAction } from 'Redux/forms/formsActions';
import { getStores } from 'Redux/stores/storesThunks';
import {
  updateProfileSettings,
  updateProfile
} from 'Redux/profile/profileThunks';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { Text, Link, H2, FlexPanel, Toast, Button } from 'Common';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import { t } from 'Utils/localization/i18next';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { isEditModeEnabled } from 'Utils/url/urlUtils';
import SSRIcon from '@ingka/ssr-icon';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './CommunicationPreferences.scss';

class PreferredStoreSectionReadContainer extends React.PureComponent {
  componentDidMount() {
    this.props.getStores();
  }

  state = { modalOpen: false };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: isEditModeEnabled(window.location.search, 'shopping') || false,
      showSuccessMessage: false
    };
    this.myRef = React.createRef();
  }

  onEdit = () => {
    this.setState({ isEditing: true, showSuccessMessage: false });
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onSubmit = async data => {
    const newProfile = { ...this.props.profile, ...data };
    const success = await this.props.updateProfileSettings(
      trimAllObjPropertiesSkipPasswords(newProfile),
      this.props.editForm
    );
    if (success) {
      this.setState({ isEditing: false, showSuccessMessage: true });
    }
    this.scrollToCenter();
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const filteredStoreList = this.props.stores.filter(
      store => store.number === this.props.profile.preferredStore
    );

    return (
      <React.Fragment>
        <div ref={this.setRef} className={styles['section-read']}>
          {this.state.showSuccessMessage && (
            <Toast autoCloseMs={5000} closeText={t('common.dismiss')}>
              <Text bold>
                {t('dashboard.confirmation.communicationSectionUpdateTitle')}
              </Text>
              <Text>
                {t('dashboard.confirmation.communicationSectionUpdateText')}
              </Text>
            </Toast>
          )}
          <FlexPanel spread className={styles['section-read-title']}>
            {this.props.title && (
              <H2 tagName="h2" headingSize="m" margin={false}>
                {t(this.props.title)}
              </H2>
            )}
            {this.props.editForm && (
              <Button
                className={styles['edit-link']}
                small
                type="tertiary"
                id={`${this.props.editForm}-link`}
                onClick={this.onEdit}
              >
                <SSRIcon colour="black" paths={pencil} />
                {t('common.edit')}
              </Button>
            )}
            {this.props.editForm && (
              <Link
                bold
                colour="grey-900"
                id={`${this.props.editForm}-link`}
                className={styles['edit-link-mobile']}
                onClick={this.onEdit}
              >
                <SSRIcon colour="black" paths={pencil} />
              </Link>
            )}
          </FlexPanel>
          <>
            {this.state.isEditing && (
              <DynamicFormContainerModal
                formId={this.props.editForm}
                prefillValues={this.props.profile}
                loadingSubmit={this.props.isLoading}
                title={this.props.title}
                onCancel={this.onClose}
              />
            )}
            <>
              <div>
                <p className={styles['sub-category-header']}>
                  {t(
                    'dashboard.shipping-billing-addresses.dataPromise.shippingPreferredStore'
                  )}
                </p>
                {filteredStoreList.length > 0 ? (
                  filteredStoreList.map(preferredStore => {
                    return (
                      <div key={this.props.userIdKey}>
                        <Text className={styles['sub-category-value']}>
                          {preferredStore.name}
                        </Text>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <Text className={styles['sub-category-value']}>-</Text>
                  </div>
                )}
              </div>
            </>
          </>
          {this.state.isEditing && (
            <DynamicFormContainerModal
              formId={this.props.editForm}
              prefillValues={this.props.profile}
              loadingSubmit={this.props.isLoading}
              textSubmit={t('common.save')}
              textCancel={t('common.cancel')}
              onCancel={this.onClose}
              onSubmit={this.onSubmit}
              title={t(this.props.title)}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

PreferredStoreSectionReadContainer.propTypes = {
  editForm: PropTypes.string,
  title: PropTypes.string,
  profile: PropTypes.object,
  // From Redux/Thunks
  getStores: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  updateProfileSettings: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  stores: PropTypes.arrayOf(PropTypes.object),
  userIdKey: PropTypes.string,
  deliveryOptionFlag: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE],
    stores: state.stores.stores,
    userIdKey: state.profile.userId
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateProfileSettings,
      updateProfile,
      clearFormAction,
      getStores
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferredStoreSectionReadContainer);
