export const transformAddressString = addressString => {
  if (!addressString) return '';

  switch (CONFIG.COUNTRY) {
    case 'sk':
      return transformSKAddress(addressString);
    case 'cz':
      return transformCZAddress(addressString);
    case 'hu':
      return transformHuAddress(addressString);
    default:
      return addressString;
  }
};

const transformSKAddress = address => {
  const patternWithDistrict = /^(\d+[\w/]*)\s+([^\d]+?)\s+([\w\s]+)\s+(\d+)\s+(\d{3}\s?\d{2})$/;
  const patternWithoutDistrict = /^(\d+[\w/]*)\s+([^\d]+?)\s+(\d{3}\s?\d{2})$/;

  if (patternWithDistrict.test(address)) {
    return address.replace(
      patternWithDistrict,
      (match, houseNo, streetName, city, districtNo, postcode) => {
        return `${streetName.trim()} ${houseNo}, ${postcode} ${city.trim()} ${districtNo}`;
      }
    );
  }
  if (patternWithoutDistrict.test(address)) {
    return address.replace(
      patternWithoutDistrict,
      (match, houseNo, streetCity, postcode) => {
        const lastSpaceIndex = streetCity.trim().lastIndexOf(' ');
        const streetName = streetCity.substring(0, lastSpaceIndex);
        const cityName = streetCity.substring(lastSpaceIndex + 1);
        return `${streetName} ${houseNo}, ${postcode} ${cityName}`;
      }
    );
  }
  return address;
};

const transformCZAddress = address => {
  return address.replace(/(\d+)\s+(\d{3}\s+\d{2})/, '$1, $2');
};

const transformHuAddress = address => {
  const regex = /^(.+?)\s(\d+)\s(\d{4})\s(.+)$/;
  const match = address.match(regex);
  if (match) {
    const [_, streetName, houseNo, postcode, city] = match;
    return `${postcode} ${city}, ${streetName} ${houseNo}`;
  }
  return address;
};
