import * as IRWAddressesThunks from './IRWAddressesThunks';
import * as CMSAddressesThunks from './CMSAddressesThunks';

export const getDeliveryAddresses = () => {
  return CONFIG.FEATURE.ENABLE_ICM_CMS
    ? CMSAddressesThunks.getDeliveryAddresses()
    : IRWAddressesThunks.getDeliveryAddresses();
};

export const createDeliveryAddress = (data, formId) => {
  return CONFIG.FEATURE.ENABLE_ICM_CMS
    ? CMSAddressesThunks.createDeliveryAddress(data, formId)
    : IRWAddressesThunks.createDeliveryAddress(data, formId);
};

export const updateDeliveryAddress = (data, formId) => {
  return CONFIG.FEATURE.ENABLE_ICM_CMS
    ? CMSAddressesThunks.updateDeliveryAddress(data, formId)
    : IRWAddressesThunks.updateDeliveryAddress(data, formId);
};

export const deleteDeliveryAddress = (addressId, formId) => {
  return CONFIG.FEATURE.ENABLE_ICM_CMS
    ? CMSAddressesThunks.deleteDeliveryAddress(addressId, formId)
    : IRWAddressesThunks.deleteDeliveryAddress(addressId, formId);
};
