import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { t } from 'Utils/localization/i18next';
import { PageHeader, Text, Loading, Toast } from 'Common';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { getProfile, updateProfile } from 'Redux/profile/profileThunks';
import { clearFormAction } from 'Redux/forms/formsActions';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';
import HouseholdPage from 'Components/Household/HouseholdPage';
import Routes from 'Routes/profileRoutes';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { setPageTitle } from 'Utils/document/documentUtils';

class HouseholdContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessMessage: false
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.props.history.push(Routes.DASHBOARD);
  }

  componentWillUnmount() {
    const { profileType } = this.props.profile;
    this.props.clearFormAction(`${profileType}-household-form`);
  }

  onSubmit = async data => {
    const { profileType } = this.props.profile;
    const newProfile = { ...this.props.profile, ...data };
    const success = await this.props.updateProfile(
      trimAllObjPropertiesSkipPasswords(newProfile),
      `${profileType}-household-form`,
      true,
      'SYNC'
    );
    this.setState({ showSuccessMessage: success }, () => this.scrollToTop());
  };

  redirectToDashboard = () => {
    this.props.history.push(Routes.DASHBOARD);
  };

  scrollToTop = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    setPageTitle(
      t(['metaData.householdPageTitle', 'metaData.defaultPageTitle'])
    );
    if (this.props.isLoading || !this.props.profileFetched) {
      return <Loading isLoading text="" />;
    }
    const { profileType } = this.props.profile;
    const featureName = `${profileType.toUpperCase()}_HOUSEHOLD`;

    if (CONFIG.FEATURE[featureName].USE_APPLICATION) {
      return (
        <div ref={this.setRef}>
          <HouseholdPage>
            <PageHeader>
              {t(`forms.household.${profileType}PageTitle`)}
            </PageHeader>
            <Text style={{ marginBottom: '2rem' }}>
              {t(`forms.household.${profileType}PageIngress`)}
            </Text>
            {this.state.showSuccessMessage && (
              <Toast
                autoCloseMs={5000}
                closeText={t('common.dismiss')}
                onClose={() => this.setState({ showSuccessMessage: false })}
              >
                <Text bold>{t('forms.household.successTitle')}</Text>
                <Text>{t('forms.household.successText')}</Text>
              </Toast>
            )}
            <DynamicFormContainer
              formId={`${profileType}-household-form`}
              prefillValues={this.props.profile}
              textSubmit={t('forms.household.btnSubmit')}
              textCancel={t('common.backToAccount')}
              onSubmit={this.onSubmit}
              onCancel={this.redirectToDashboard}
            />
          </HouseholdPage>
        </div>
      );
    }

    if (
      CONFIG.FEATURE[featureName].USE_EXTERNAL &&
      CONFIG.FEATURE[featureName].EXTERNAL_URL
    ) {
      window.location = CONFIG.FEATURE[featureName].EXTERNAL_URL;
    }

    return <Redirect to={Routes.DASHBOARD} />;
  }
}

HouseholdContainer.propTypes = {
  isLoading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  profileFetched: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    isLoading:
      state.userInterface.loading[LOADING.PROFILE_GET] ||
      state.userInterface.loading[LOADING.PROFILE_UPDATE],
    profile: state.profile,
    profileFetched: !!state.profile.profileType
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProfile,
      updateProfile,
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdContainer);
