import { ReduxStore } from 'Redux';
import {
  getSpecificDateStringInUnifiedFormat,
  getUnifiedDateStringInSpecificFormat
} from 'Utils/date/dateUtils';

import autocorrectors from '../autocorrect/autocorrectors';

const isString = value => {
  return typeof value === 'string';
};

const dateFormatSpecificToUnified = (value, { fromFormat }) => {
  if (!isString(value) || !fromFormat) {
    return value;
  }
  return getSpecificDateStringInUnifiedFormat(value, fromFormat) || value;
};

const dateFormatUnifiedToSpecific = (value, { toFormat }) => {
  if (!isString(value) || !toFormat) {
    return value;
  }
  return getUnifiedDateStringInSpecificFormat(value, toFormat) || value;
};

const empty = () => '';

const specificValueMapping = (value, { fromValue, toValue }) => {
  if (!isString(value) || value !== fromValue) {
    return value;
  }
  return toValue;
};

const specificValueMappingIfProfileFieldValueInList = (
  value,
  { fromValue, toValue, profileFieldName, profileFieldValueList = [] }
) => {
  if (!isString(value) || value !== fromValue) {
    return value;
  }
  const { profile } = ReduxStore.getState();
  if (
    profile[profileFieldName] &&
    profileFieldValueList.includes(profile[profileFieldName])
  ) {
    return toValue;
  }
  return value;
};

const setFieldValue = (value, { fieldValue }) => {
  return fieldValue;
};

const toNumericZipCode = value => {
  if (/[a-z]+/i.test(value)) {
    return value;
  }
  return autocorrectors.toNumeric(value);
};

export default {
  dateFormatSpecificToUnified,
  dateFormatUnifiedToSpecific,
  empty,
  specificValueMapping,
  specificValueMappingIfProfileFieldValueInList,
  toNumeric: autocorrectors.toNumeric,
  matchAndInsert: autocorrectors.matchAndInsert,
  setFieldValue,
  toNumericZipCode
};
