// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__col-no-v-spacing{padding-top:0 !important;padding-bottom:0 !important}.profile__col-no-h-spacing{padding-left:0 !important;padding-right:0 !important}.profile__col-center{margin-right:auto;margin-left:auto}.profile__col-same-height-children{flex-direction:unset}.profile__col-standard-margin-bottom{margin-bottom:2.5rem}", "",{"version":3,"sources":["webpack://./src/components/_common/Grid/Col.scss"],"names":[],"mappings":"AACE,2BACE,wBAAA,CACA,2BAAA,CAEF,2BACE,yBAAA,CACA,0BAAA,CAGF,qBACE,iBAAA,CACA,gBAAA,CAGF,mCACE,oBAAA,CAGF,qCACE,oBAAA","sourcesContent":[".col {\n  &-no-v-spacing {\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n  }\n  &-no-h-spacing {\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n  }\n\n  &-center {\n    margin-right: auto;\n    margin-left: auto;\n  }\n\n  &-same-height-children {\n    flex-direction: unset;\n  }\n\n  &-standard-margin-bottom {\n    margin-bottom: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col-no-v-spacing": "profile__col-no-v-spacing",
	"col-no-h-spacing": "profile__col-no-h-spacing",
	"col-center": "profile__col-center",
	"col-same-height-children": "profile__col-same-height-children",
	"col-standard-margin-bottom": "profile__col-standard-margin-bottom"
};
export default ___CSS_LOADER_EXPORT___;
