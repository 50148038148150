import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SSRIcon from '@ingka/ssr-icon';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right';
import { t } from 'Utils/localization/i18next';
import styles from './AddressAutocomplete.scss';

export const LoqateEverythingLocationOption = props => (
  <div
    id={`option-${props.index}`}
    className={classNames(styles['address-autocomplete-option'], {
      [styles.selected]: props.selected
    })}
    onKeyDown={props.onKeyDown}
    onClick={props.onClick}
  >
    {props.option.label}
    {!props.option.isCapturable && (
      <div className={styles['address-autocomplete-count-wrap']}>
        {props.option.recordCount !== undefined && (
          <span className={styles['address-autocomplete-count-text']}>{`${
            props.option.recordCount
          } ${t('forms.addressResults')}`}</span>
        )}
        <SSRIcon
          colour="grey-300"
          paths={arrowRight}
          className={styles['address-autocomplete-count-icon']}
        />
      </div>
    )}
  </div>
);

LoqateEverythingLocationOption.propTypes = {
  selected: PropTypes.bool,
  option: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};
