import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Routes from 'Routes/profileRoutes';
import { Grid, Row, Col, Link } from 'Common';
import Loading, { LoadingBall } from '@ingka/loading';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { getPreviousUrl } from 'Utils/url/urlUtils';
import { withRouter } from 'react-router';
/* import List from '@ingka/list'; */
import SSRIcon from '@ingka/ssr-icon';
import CommercialMessage from '@ingka/commercial-message';
// import person from '@ingka/ssr-icon/paths/person';
import gear from '@ingka/ssr-icon/paths/gear';
import projects from '@ingka/ssr-icon/paths/laptop-pencil';
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left';
import arrowRightFromBase from '@ingka/ssr-icon/paths/arrow-right-from-base';
import styles from './DashboardBody.scss';

class DashboardBody extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      backToProjects: false
    };
  }

  setProjectPage = () => {
    const urlSplit = window.location.pathname.split('/');
    const backToProjects =
      window.location.pathname.includes('/projects/') &&
      urlSplit.length === 8 &&
      urlSplit[6] !== '';
    this.setState({ backToProjects });
  };

  componentDidMount() {
    this.setProjectPage();
    document.body.addEventListener(
      'click',
      () => {
        requestAnimationFrame(() => {
          this.setProjectPage();
        });
      },
      true
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <Loading
          text={t(`loaders.dashboard_${Math.ceil(Math.random() * 3)}`)}
          prefix={CONFIG.APP.STYLES_PREFIX}
        >
          <LoadingBall prefix={CONFIG.APP.STYLES_PREFIX} color={'primary'} />
        </Loading>
      );
    }
    const previousUrl = getPreviousUrl();
    const linkLabel = previousUrl.includes('loyalty-hub')
      ? t('common.backToIKEAFamily')
      : t('common.backToPrevious');
    return (
      <div className={styles['content-container']}>
        <Grid
          className={
            styles[
              !CONFIG.FEATURE.LEFT_MENU_ENABLED
                ? 'dashboard-body'
                : 'dashboard-body-left-menu'
            ]
          }
        >
          {!CONFIG.FEATURE.LEFT_MENU_ENABLED && (
            <Row center>
              <Col xs={1} sm={12} md={12} lg={3}></Col>
              {previousUrl && !this.state.backToProjects && (
                <Col xs={12} sm={9} md={9} lg={9}>
                  <Link className={styles['back-link']} url={previousUrl}>
                    <SSRIcon colour="black" paths={arrowLeft} />
                    {linkLabel}
                  </Link>
                </Col>
              )}
              {this.state.backToProjects && (
                <Col xs={12} sm={9} md={9} lg={9}>
                  <Link
                    className={styles['back-link']}
                    to={'/dashboard/projects/#projects'}
                  >
                    <SSRIcon colour="black" paths={arrowLeft} />
                    {t('common.backToProjects')}
                  </Link>
                </Col>
              )}
            </Row>
          )}
          <Row center className={styles['dashboard-body-content']}>
            <Col
              xs={1}
              sm={12}
              md={12}
              lg={3}
              className={
                styles[
                  CONFIG.FEATURE.LEFT_MENU_ENABLED
                    ? 'col-left-menu'
                    : 'col-side-menu'
                ]
              }
            >
              {CONFIG.FEATURE.LEFT_MENU_ENABLED ? (
                <section className={styles['col-side-menu-left']}>
                  <div id="wlo-navigation"></div>
                </section>
              ) : (
                <section className={styles['side-menu']}>
                  {CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS
                    .ACCOUNT_SETTINGS && (
                    <h3
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/dashboard/`
                        });
                      }}
                      className={
                        window.location.pathname.endsWith('/dashboard/')
                          ? styles['side-menu-list-item-selected']
                          : styles['side-menu-list-item']
                      }
                    >
                      <SSRIcon colour="black" paths={gear} />
                      {t('dashboard.side_menu_tabs.account_settings_header')}
                    </h3>
                  )}
                  {/* Will be used in future */}
                  {CONFIG.FEATURE.ENABLE_PROJECTS_MENU &&
                    CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS
                      .CUSTOMER_PROJECTS && (
                      <h3
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/dashboard/projects/`
                          });
                        }}
                        className={
                          window.location.pathname.includes(
                            '/dashboard/projects/'
                          )
                            ? styles['side-menu-list-item-selected']
                            : styles['side-menu-list-item']
                        }
                      >
                        <div className={styles['side-menu-new']}>
                          <SSRIcon colour="black" paths={projects} />
                          {t('dashboard.side_menu_tabs.projects_header')}
                          {CONFIG.FEATURE.ENABLE_PROJECTS_MENU_NEW && (
                            <CommercialMessage
                              className={styles['side-menu-new-label']}
                              variant="family"
                              subtle="false"
                              message={t('dashboard.side_menu_tabs.new')}
                            ></CommercialMessage>
                          )}
                        </div>
                      </h3>
                    )}
                  {CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS.LOGOUT && (
                    <h3
                      className={styles['side-menu-list-item']}
                      id="logout-link"
                      onClick={() => {
                        this.props.history.push(Routes.LOGOUT);
                      }}
                    >
                      <SSRIcon colour="black" paths={arrowRightFromBase} />
                      {t('dashboard.side_menu_tabs.logout_header')}
                    </h3>
                  )}
                </section>
              )}
            </Col>
            <Col
              xs={12}
              sm={9}
              md={9}
              lg={9}
              className={
                styles[
                  CONFIG.FEATURE.LEFT_MENU_ENABLED
                    ? 'col-content-pane-left'
                    : 'col-content-pane'
                ]
              }
            >
              {window.location.href?.toLowerCase().includes('/projects') &&
              !CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS
                ?.CUSTOMER_PROJECTS ? (
                <Redirect to={Routes.DASHBOARD} />
              ) : (
                this.props.children
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

DashboardBody.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

const mapStateToProps = state => {
  return {
    ...state,
    isLoading: state.userInterface.loading[LOADING.PROFILE_GET]
  };
};

const DashboardBodyWithConnect = connect(
  mapStateToProps,
  null
)(DashboardBody);

export default withRouter(DashboardBodyWithConnect);
