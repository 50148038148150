import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Col.scss';
import './style.scss';

export const Col = props => {
  const className = classNames({
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col-xl-${props.xl}`]: props.xl > 0,
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col-lg-${props.lg}`]: props.lg > 0,
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col-md-${props.md}`]: props.md > 0,
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col-sm-${props.sm}`]: props.sm > 0,
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col-xs-${props.xs}`]: props.xs > 0,
    [`${CONFIG.APP.STYLES_PREFIX}ikea-col`]:
      !props.xl && !props.lg && !props.md && !props.sm,
    [styles['col-center']]: props.center,
    [styles['col-same-height-children']]: props.sameHeightChildren,
    [styles['col-no-v-spacing']]: !props.spacing,
    [styles['col-no-h-spacing']]: props.nohspacing,
    [props.className]: props.className
  });

  return <div className={className}>{props.children}</div>;
};

Col.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  center: PropTypes.bool,
  spacing: PropTypes.bool,
  nohspacing: PropTypes.bool,
  sameHeightChildren: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
