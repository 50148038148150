import React from 'react';
import PropTypes from 'prop-types';
import DeliveryAddress from 'Components/Dashboard/ShippingBillingAddresses/DeliveryAddress';
import { Divider } from 'Common';

class DeliveryAddresses extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addressBeingEdited: undefined
    };
  }

  setAddressBeingEdited = addressId => {
    this.props.clearFormAction(this.props.editForm);
    this.setState({ addressBeingEdited: addressId });
  };

  render() {
    return (
      this.props.deliveryAddresses.length > 0 &&
      this.props.deliveryAddresses.map((deliveryAddress, index) => (
        <div key={deliveryAddress.addressId}>
          <DeliveryAddress
            deliveryAddress={deliveryAddress}
            key={deliveryAddress.addressId}
            setAddressBeingEdited={this.setAddressBeingEdited}
            addressBeingEdited={this.state.addressBeingEdited}
            onUpdate={this.props.onUpdate}
            onDelete={this.props.onDelete}
            rows={this.props.rows}
            secondRows={this.props.secondRows}
            name={this.props.name}
            contactTypes={this.props.contactTypes}
            icon={this.props.icon}
            editForm={this.props.editForm}
            maskRules={this.props.maskRules}
            title={this.props.title}
          />
          {index !== this.props.deliveryAddresses.length - 1 ? (
            <Divider dashed />
          ) : (
            ''
          )}
        </div>
      ))
    );
  }
}

DeliveryAddresses.propTypes = {
  deliveryAddresses: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  secondRows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  name: PropTypes.arrayOf(PropTypes.string),
  contactTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  editForm: PropTypes.string,
  maskRules: PropTypes.object,
  title: PropTypes.string,
  clearFormAction: PropTypes.func.isRequired
};

export default DeliveryAddresses;
