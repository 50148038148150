import { string, bool, func, oneOfType, element } from 'prop-types';
import React, { Component, createRef } from 'react';
import Button from '@ingka/button';
import classNames from 'classnames';
import visibilityHide from '@ingka/ssr-icon/paths/visibility-hide';
import visibilityShow from '@ingka/ssr-icon/paths/visibility-show';
import SSRIcon from '@ingka/ssr-icon';
import InputField from '@ingka/input-field';

// Copied version of Ikeas InputField, in order to use it as
// a controlled input component instead of uncontrolled.
// (we use "value" instead of "defaultValue")

const propTypes = {
  id: string.isRequired,
  /** Text on the Input field label */
  label: oneOfType([string, element]),
  /** Text on the Input field placeholder */
  placeholder: oneOfType([string, element]),
  /** Text for required */
  req: oneOfType([string, element]),
  type: string.isRequired,
  /** Boolean if the input is disabled */
  disabled: bool,
  icon: string,
  onChange: func,
  iconClickDescription: oneOfType([string, element]),
  iconPosition: string,
  iconOnClick: func,
  onBlur: func,
  value: string,
  describedById: string,
  prefix: string,
  hidePasswordText: oneOfType([string, element]),
  showPasswordText: oneOfType([string, element]),
  ssrIcon: func,
  className: string
};

class IkeaInputFieldControlled extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
      labelled: props.value && props.value !== ''
    };

    if (createRef) {
      this.input = createRef();
    }
  }

  render() {
    const {
      className,
      ssrIcon,
      hidePasswordText,
      showPasswordText,
      prefix,
      id,
      describedById,
      value,
      iconPosition,
      icon,
      iconOnClick,
      iconClickDescription,
      label,
      type,
      placeholder,
      disabled,
      onBlur,
      req,
      ...other
    } = this.props;
    const trailing = type === 'password' || iconPosition === 'trailing';

    const classes = classNames(
      `${prefix}input-wrap`,
      iconPosition === 'leading' ? `${prefix}input-wrap--leading-icon` : null,
      trailing ? `${prefix}input-wrap--trailing-icon` : null,
      this.state.labelled ? `${prefix}input-wrap--labelled` : null,
      className
    );

    return (
      <div className={classes}>
        <InputField
          ref={el => {
            this.input = el;
          }}
          type={this.state.passwordVisible ? 'text' : type}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          {...other}
          onChange={this.onChange.bind(this)}
          onBlur={onBlur}
          value={value}
          aria-describedby={describedById}
          label={label}
          req={req}
          prefix={prefix}
        />
        {type === 'password' && (
          <Button
            htmlType="button"
            prefix={prefix}
            ssrIcon={
              this.state.passwordVisible ? visibilityHide : visibilityShow
            }
            onClick={this.togglePasswordVisibility.bind(this)}
            type="plain"
            text={
              this.state.passwordVisible ? hidePasswordText : showPasswordText
            }
            disabled={disabled}
          />
        )}
        {iconPosition === 'trailing' && (icon || ssrIcon) && (
          <Button
            htmlType="button"
            prefix={prefix}
            icon={icon}
            ssrIcon={ssrIcon}
            onClick={iconOnClick}
            type="plain"
            text={iconClickDescription}
            disabled={disabled}
          />
        )}
        {iconPosition === 'leading' && icon && (
          <SSRIcon prefix={prefix} icon={icon} />
        )}
        {iconPosition === 'leading' && !icon && ssrIcon && (
          <SSRIcon prefix={prefix} paths={ssrIcon} />
        )}
      </div>
    );
  }

  togglePasswordVisibility(e) {
    e.preventDefault();
    this.setState({
      passwordVisible: !this.state.passwordVisible
    });
  }

  onChange(e) {
    // Setting state on a react component that is using value instead of
    // default value will make the input cursor move to end of value.
    // this.setState({
    //   labelled: e.target.value !== '' ? true : false
    // });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }
}

IkeaInputFieldControlled.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  req: '',
  disabled: false,
  prefix: '',
  hidePasswordText: 'Hide password',
  showPasswordText: 'Show password'
};

IkeaInputFieldControlled.propTypes = propTypes;

export default IkeaInputFieldControlled;
