import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, Link, FlexPanel, Divider, Toast } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getRedirectURLForUpdateEmail } from 'Redux/profile/profileThunks';
import styles from './DashboardSectionReadContainer.scss';

class DashboardEmailSectionReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showErrorMessage: false };
  }

  onEdit = async () => {
    const redirectURL = await this.props.getRedirectURLForUpdateEmail();
    if (redirectURL) {
      window.location = redirectURL;
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showErrorMessage && (
          <Toast
            autoCloseMs={5000}
            closeText={t('common.dismiss')}
            onClose={() => this.setState({ showErrorMessage: false })}
          >
            <Text bold>{t('forms.errors.title')}</Text>
            <Text>- {t('forms.errors.generic')}</Text>
          </Toast>
        )}
        <div className={styles['dashboard-section-read']}>
          <FlexPanel spread className={styles['dashboard-section-read-title']}>
            <Text bold colour="grey-900">
              {t(this.props.title)}
            </Text>
            <Link
              bold
              underline
              colour="grey-900"
              id="user-login-ciam-edit-link"
              onClick={this.onEdit}
            >
              {t('common.edit')}
            </Link>
          </FlexPanel>
          {this.props.children}
        </div>
        {!this.props.isLastElement && <Divider dashboard />}
      </React.Fragment>
    );
  }
}

DashboardEmailSectionReadContainer.propTypes = {
  title: PropTypes.string,
  isLastElement: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // From Redux/Thunks
  getRedirectURLForUpdateEmail: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getRedirectURLForUpdateEmail }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(DashboardEmailSectionReadContainer);
