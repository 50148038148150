import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import { getFieldValuesByNames } from 'Redux/forms/formsHelper';
import IkeaCheckboxControlled from '../_form_ikea_copies/IkeaCheckboxControlled';

class DoubleConsentCheckbox extends React.PureComponent {
  componentDidMount() {
    if (
      this.props.values[this.props.name] !==
      this.props.values[this.props.secondName]
    ) {
      this.setSecondValue(this.props.values[this.props.name]);
    }
  }

  onChange = e => {
    this.setSecondValue(e.target.checked);
    this.props.onChange(e);
  };

  setSecondValue = value => {
    this.props.onChange({
      target: {
        name: this.props.secondName,
        value
      }
    });
  };

  render() {
    const elementText = (
      <div>
        {t(this.props.labelBeforeLinks)}
        <Link underline url={this.props.firstLink} target="_blank">
          {t(this.props.labelFirstLink)}
        </Link>
        {t(this.props.labelBetweenLinks)}
        <Link underline url={this.props.secondLink} target="_blank">
          {t(this.props.labelSecondLink)}
        </Link>
        {t(this.props.labelAfterLinks)}
      </div>
    );

    return (
      <IkeaCheckboxControlled
        id={`${this.props.formId}-${this.props.name}`}
        name={this.props.name}
        checked={this.props.values[this.props.name]}
        text={elementText}
        prefix={CONFIG.APP.STYLES_PREFIX}
        describedById={`${this.props.formId}-${this.props.name}-error`}
        onChange={this.onChange}
      />
    );
  }
}

DoubleConsentCheckbox.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string.isRequired,
  secondName: PropTypes.string.isRequired,
  labelFirstLink: PropTypes.string,
  labelSecondLink: PropTypes.string,
  labelBeforeLinks: PropTypes.string,
  labelBetweenLinks: PropTypes.string,
  labelAfterLinks: PropTypes.string,
  firstLink: PropTypes.string,
  secondLink: PropTypes.string,
  onChange: PropTypes.func,
  // From Redux
  values: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    values: getFieldValuesByNames(state, ownProps.formId, [
      ownProps.name,
      ownProps.secondName
    ])
  };
};

export default connect(
  mapStateToProps,
  null
)(DoubleConsentCheckbox);
