import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';

const DashboardFieldReadWithTranslatedTitleContainer = props => (
  <FlexPanel>
    <Text>
      {props.profile.title &&
        `${t(`forms.namePrefix_${props.profile.title}`)} `}
      {props.names.reduce((concatenated, name) => {
        const value = `${props.profile[name]} ` || '';
        return `${concatenated}${value}`;
      }, '')}
    </Text>
  </FlexPanel>
);

DashboardFieldReadWithTranslatedTitleContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  profile: PropTypes.object
};

DashboardFieldReadWithTranslatedTitleContainer.defaultProps = {
  names: []
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(DashboardFieldReadWithTranslatedTitleContainer);
