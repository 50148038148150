export default class CaptchaApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.CAPTCHA.HOST;
  }

  /**
   * Calls the CaptchaToken service to verify the client token
   * @param  {string} captchaToken
   */
  verifyToken(token) {
    const requestBody = {
      captcha: token
    };
    return fetch(`${this.apiUrl}/captchatoken/verifyToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return [];
      })
      .then(json => {
        return json;
      })
      .catch(e => {
        return [];
      });
  }
}
