import React from 'react';
import PropTypes from 'prop-types';
import { SectionComponents } from './components';

const DynamicFormSection = props => {
  const { component, children, ...rest } = props;
  const Component = SectionComponents[component];

  if (!Component) {
    return children;
  }

  return <Component {...rest}>{children}</Component>;
};

DynamicFormSection.propTypes = {
  component: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default DynamicFormSection;
