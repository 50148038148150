export default class CIAMApiClientMock {
  getRedirectURLForUpdateEmail(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'CHANGE_EMAIL');
  }

  getRedirectURLForDeleteProfile(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'DELETE_USER');
  }

  getRedirectURLForUpdatePassword(partyUid, bearerToken) {
    return this.getRedirectURL(partyUid, bearerToken, 'CHANGE_PASSWORD');
  }

  getRedirectURL(partyUid, bearerToken, flow) {
    if (!partyUid || !bearerToken) {
      throw Error();
    }

    return new Promise(resolve => {
      const mockRedirectUrl = `https://www.ikea.com/${flow}_MOCK_URL`;
      resolve(mockRedirectUrl);
    });
  }
}
