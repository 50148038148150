import React from 'react';
import PropTypes from 'prop-types';
import IkeaInputField from '@ingka/input-field';
import { InlineText, SSRIcons } from 'Common';
import { t } from 'Utils/localization/i18next';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

// IkeaInputField does not allow using both value and defaultValue props.
// This component should be used only with _defaultValue_. The value is
// controlled by the IkeaInputField and can not be updated/changed from
// outside. For IkeaInputField with value prop, use InputFieldControlled.

const InputField = props => {
  const id = props.formId ? `${props.formId}-${props.name}` : props.id;

  const onPaste = e => {
    if (props.noPaste) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <IkeaInputField
        id={id}
        name={props.name}
        defaultValue={props.defaultValue}
        type={props.type}
        maxLength={props.maxLength}
        describedById={`${id}-error`}
        label={
          props.showOptional
            ? `${t(props.label)} ${t('forms.optional')}`
            : t(props.label)
        }
        placeholder={t(props.placeholder)}
        ssrIcon={props.ssrIcon}
        iconPosition={props.iconPosition}
        autoComplete={props.autoComplete}
        prefix={CONFIG.APP.STYLES_PREFIX}
        className={props.className}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        onPaste={onPaste}
      />
      {props.extraTextMsg && (
        <InlineText
          msg={t(props.extraTextMsg)}
          ssrIcon={SSRIcons[props.extraTextIcon]}
        />
      )}
    </React.Fragment>
  );
};

InputField.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  extraTextMsg: PropTypes.string,
  extraTextIcon: PropTypes.string,
  maxLength: PropTypes.number,
  showOptional: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  ssrIcon: PropTypes.func,
  iconPosition: PropTypes.oneOf(['leading', 'trailing']),
  iconOnClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  noPaste: PropTypes.bool
};

InputField.defaultProps = {
  showOptional: false
};

export default InputField;
