import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFormFieldMessagesAction } from 'Redux/forms/formsActions';
import {
  getFieldValue,
  getFieldMessages,
  getFormBackendErrors
} from 'Redux/forms/formsHelper';
import { PasswordErrorKeys } from 'Utils/validation/password';
import { Row, Col } from 'Common';
import validate from 'Utils/validation/validate';
import FormField from 'BaseForm/FormField';
import PasswordInfo from 'ExtendedForm/PasswordInfo';
import InputFieldHandler from 'BaseForm/InputFieldHandler';

class PasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // If "showPasswordError" is true the input field will
      // have a red bottom border with or without an error message.
      showPasswordError: false,
      // If "showPasswordInfo" is true a list of password rules will
      // be displayed to the user to help them enter a valid password.
      showPasswordInfo: false
    };
  }

  componentDidMount() {
    this.setInitialValidationMessages();
  }

  componentDidUpdate(prevProps) {
    if (
      this.isMandatoryRulesFulfilled(prevProps.messages) &&
      !this.isMandatoryRulesFulfilled(this.props.messages)
    ) {
      // This scenario happens when user submits the form without ever
      // touching the password field. Validation of all password rules,
      // including mandatory rules, is performed by the "DynamicForm"
      // when user click "submit".
      this.setState({ showPasswordError: true });
    }
  }

  setInitialValidationMessages = async () => {
    // Errors for empty password field should only be displayed to user
    // if they come back to the form after an unsuccessful form submit
    // (usually the password field is cleared when a form submit fails).
    const shouldValidateMandatoryRule = this.props.backendErrors.length > 0;
    await this.setPasswordValidationInRedux(
      this.props.value,
      shouldValidateMandatoryRule
    );
    if (!this.isMandatoryRulesFulfilled(this.props.messages)) {
      this.setState({ showPasswordError: true });
    }
  };

  onChange = async e => {
    e.preventDefault();
    this.props.onChange(e);
    await this.setPasswordValidationInRedux(e.target.value);
    if (
      this.state.showPasswordError &&
      this.isMandatoryRulesFulfilled(this.props.messages) &&
      this.isPasswordInfoRulesFulfilled(this.props.messages)
    ) {
      this.setState({ showPasswordError: false });
    }
  };

  onFocus = () => {
    this.setState({ showPasswordInfo: true });
  };

  onBlur = async () => {
    await this.setPasswordValidationInRedux(this.props.value);

    const isPasswordInfoRulesFulfilled = this.isPasswordInfoRulesFulfilled(
      this.props.messages
    );
    const isMandatoryRulesFulfilled = this.isMandatoryRulesFulfilled(
      this.props.messages
    );

    this.setState({
      showPasswordInfo:
        !isPasswordInfoRulesFulfilled && isMandatoryRulesFulfilled,
      showPasswordError:
        !isPasswordInfoRulesFulfilled || !isMandatoryRulesFulfilled
    });
  };

  setPasswordValidationInRedux = async (
    value,
    includeMandatoryRules = true
  ) => {
    const { componentProps, validationRules } = this.props.nestedFields[0];
    const passwordRules = includeMandatoryRules
      ? validationRules
      : validationRules.filter(rule => !rule.name.startsWith('mandatory'));

    const messages = validate(
      this.props.formId,
      value,
      componentProps.label,
      passwordRules,
      true
    );
    await this.props.setFormFieldMessagesAction(
      this.props.formId,
      componentProps.name,
      messages
    );
  };

  isPasswordInfoRulesFulfilled = messages => {
    return !messages.some(message => PasswordErrorKeys.includes(message));
  };

  isMandatoryRulesFulfilled = messages => {
    return !messages.some(message => !PasswordErrorKeys.includes(message));
  };

  render() {
    const field = this.props.nestedFields[0];
    const { name, label, maxLength } = field.componentProps;

    return (
      <Row>
        <Col sm={field.colWidth}>
          <FormField
            validation={{
              id: `${this.props.formId}-${name}-error`,
              msg: this.props.messages.find(
                message => !PasswordErrorKeys.includes(message)
              ),
              type: 'error'
            }}
            shouldValidate={this.state.showPasswordError}
          >
            <InputFieldHandler
              formId={this.props.formId}
              name={name}
              value={this.props.value}
              type="password"
              maxLength={maxLength}
              label={label}
              showOptional={!this.props.checkMandatory(name)}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </FormField>
          {this.state.showPasswordInfo && (
            <PasswordInfo
              id={`${this.props.formId}-${name}-error`}
              invalidLength={this.props.messages.includes(PasswordErrorKeys[0])}
              invalidIdenticalChars={this.props.messages.includes(
                PasswordErrorKeys[1]
              )}
              invalidLowercase={this.props.messages.includes(
                PasswordErrorKeys[2]
              )}
              invalidUppercase={this.props.messages.includes(
                PasswordErrorKeys[3]
              )}
              invalidNumberOrSpecialChar={this.props.messages.includes(
                PasswordErrorKeys[4]
              )}
            />
          )}
        </Col>
      </Row>
    );
  }
}

PasswordContainer.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkMandatory: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // From Redux
  value: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
  backendErrors: PropTypes.arrayOf(PropTypes.string),
  setFormFieldMessagesAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const fieldName = ownProps.nestedFields[0].componentProps.name;
  return {
    value: getFieldValue(state, ownProps.formId, fieldName),
    messages: getFieldMessages(state, ownProps.formId, fieldName),
    backendErrors: getFormBackendErrors(state, ownProps.formId)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormFieldMessagesAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordContainer);
