import { ReduxStore } from 'Redux';
import { ShoppingService } from 'Services/ServiceFactory';
import {
  setActiveOrdersCountAction,
  setShoppingListCountAction
} from './commerceActions';

export const getOrderHistoryCount = () => {
  return async dispatch => {
    dispatch(setActiveOrdersCountAction(-1));
  };
};

export const getShoppingListCount = () => {
  const { userId } = ReduxStore.getState().profile;

  return async dispatch => {
    try {
      const shoppingListCount = await ShoppingService.getNumOfShoppingLists(
        userId
      );
      if (shoppingListCount) {
        dispatch(setShoppingListCountAction(shoppingListCount));
      }
    } catch (e) {
      dispatch(setShoppingListCountAction(-1));
    }
  };
};
