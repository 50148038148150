import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getFieldValue } from 'Redux/forms/formsHelper';
import Checkbox from 'BaseForm/Checkbox';

class IkeaCatalogueCheckboxContainer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.allowDirectMail !== this.props.allowDirectMail) {
      // Revalidate this field (its "validationRules" has
      // a dependency towards the "allowDirectMail" field).
      this.props.onBlur({
        target: {
          name: this.props.name,
          value: this.props.value
        }
      });
    }
  }

  render() {
    return <Checkbox {...this.props} />;
  }
}

IkeaCatalogueCheckboxContainer.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  // From Redux
  value: PropTypes.bool,
  allowDirectMail: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    value: getFieldValue(state, ownProps.formId, ownProps.name),
    allowDirectMail: getFieldValue(state, ownProps.formId, 'allowDirectMail')
  };
};

export default connect(
  mapStateToProps,
  null
)(IkeaCatalogueCheckboxContainer);
