import { ACTIONS } from './userInterfaceConstants';

const initialState = {
  loading: {
    // key: bool (e.g. 'login': true)
  }
};

const userInterface = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.key]: action.loading
        }
      };

    default:
      return state;
  }
};

export default userInterface;

// Actions should be mapped in the same order
// as they are defined in the actions file.
