// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__first-text-style{color:#007cc1;font-weight:700}.profile__second-text-style{color:#484848;font-weight:700}", "",{"version":3,"sources":["webpack://./src/components/_common/Links/TextWithLink.scss"],"names":[],"mappings":"AAEA,2BACE,aAAA,CACA,eAAA,CAEF,4BACE,aAAA,CACA,eAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.first-text-style {\n  color: #007CC1;\n  font-weight: 700;\n}\n.second-text-style {\n  color: #484848;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"first-text-style": "profile__first-text-style",
	"second-text-style": "profile__second-text-style"
};
export default ___CSS_LOADER_EXPORT___;
