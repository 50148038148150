import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from 'Utils/localization/i18next';
import {
  Button,
  Grid,
  Row,
  Col,
  PageHeader,
  Text,
  WithoutHeaderAndFooter
} from 'Common';
import Routes from 'Routes/profileRoutes';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import styles from './ResetPasswordConfirmation.scss';

const ResetPasswordConfirmation = props => {
  const { pathname } = props.location;
  const isBasicResetPassword = new RegExp(
    Routes.RESET_PASSWORD_CONFIRMATION_BASIC
  ).test(pathname);
  if (isBasicResetPassword) {
    const from = getQueryStringValue(
      `${props.location.search}${props.location.hash}`,
      Routes.QUERY_PARAMS.FROM
    );
    return (
      <WithoutHeaderAndFooter>
        <Grid className={styles['reset-password-confirmation']}>
          <Row>
            <Col
              sm={5}
              lg={5}
              xl={4}
              className={styles['reset-password-confirmation-left']}
            >
              <PageHeader center={false} margin={false}>
                {t('password.confirmationPageTitle')}
              </PageHeader>
              <Text marginBottom="m" marginTop="m">
                {t('password.confirmationPageText')}{' '}
              </Text>
              <Button
                marginTop="l"
                type="primary"
                text={t('common.backToLogin')}
                onClick={() => {
                  window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN_BASIC}?from=${from}`;
                }}
              />
            </Col>
            <Col sm={1} lg={2} xl={3} />
            <Col sm={6} lg={5} xl={5} />
          </Row>
        </Grid>
      </WithoutHeaderAndFooter>
    );
  }
  return (
    <Grid className={styles['reset-password-confirmation']}>
      <Row>
        <Col
          sm={5}
          lg={5}
          xl={4}
          className={styles['reset-password-confirmation-left']}
        >
          <PageHeader center={false} margin={false}>
            {t('password.confirmationPageTitle')}
          </PageHeader>
          <Text marginBottom="m" marginTop="m">
            {t('password.confirmationPageText')}{' '}
          </Text>
          <Button
            marginTop="l"
            type="primary"
            text={t('common.backToLogin')}
            onClick={() => {
              window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`;
            }}
          />
        </Col>
        <Col sm={1} lg={2} xl={3} />
        <Col sm={6} lg={5} xl={5} />
      </Row>
    </Grid>
  );
};

ResetPasswordConfirmation.propTypes = {
  location: PropTypes.object
};

export default ResetPasswordConfirmation;
