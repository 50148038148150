import { ACTIONS, FORM_KEY_SUFFIX } from './formsConstants';

// Can contain three objects for each active form:
// {formId}-values containing all field values
// {formId}-messages containing field validation messages
// {formId}-errors containing backend validation messages
const initialState = {};

export default function forms(state = initialState, action) {
  let formKey;
  let valuesKey;
  let messagesKey;
  let errorKey;

  switch (action.type) {
    case ACTIONS.SET_FORM_FIELD_VALUE:
      // Set value for a specific form field.
      // {formId}-values[fieldName] = fieldValue
      formKey = `${action.formId}${FORM_KEY_SUFFIX.VALUES}`;
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          [action.fieldName]: action.fieldValue
        }
      };

    case ACTIONS.SET_FORM_FIELD_MESSAGES:
      // Set validation messages for a specific form field.
      // {formId}-messages[fieldName] = fieldMessages
      formKey = `${action.formId}${FORM_KEY_SUFFIX.MESSAGES}`;
      return {
        ...state,
        [formKey]: {
          ...state[formKey],
          [action.fieldName]: action.fieldMessages
        }
      };

    case ACTIONS.SET_FORM_VALUES:
      // Set values for several fields in a form.
      // {formId}-values = {
      //   firstName: 'Paola,
      //   lastName: 'Spring'
      // }
      formKey = `${action.formId}${FORM_KEY_SUFFIX.VALUES}`;
      return {
        ...state,
        [formKey]: {
          ...action.values
        }
      };

    case ACTIONS.SET_FORM_ERRORS:
      formKey = `${action.formId}${FORM_KEY_SUFFIX.ERRORS}`;
      return {
        ...state,
        [formKey]: action.errors
      };

    case ACTIONS.SET_FORM_DISABLE_SUBMIT:
      formKey = `${action.formId}${FORM_KEY_SUFFIX.SUBMIT}`;
      return {
        ...state,
        [formKey]: action.disableSubmit
      };

    case ACTIONS.CLEAR_FORM:
      valuesKey = `${action.formId}${FORM_KEY_SUFFIX.VALUES}`;
      messagesKey = `${action.formId}${FORM_KEY_SUFFIX.MESSAGES}`;
      errorKey = `${action.formId}${FORM_KEY_SUFFIX.ERRORS}`;
      return {
        ...state,
        [valuesKey]: undefined,
        [messagesKey]: undefined,
        [errorKey]: undefined
      };

    case ACTIONS.CLEAR_FORM_MESSAGES:
      messagesKey = `${action.formId}${FORM_KEY_SUFFIX.MESSAGES}`;
      return {
        ...state,
        [messagesKey]: {}
      };

    default:
      return state;
  }
}

// Actions should be mapped in the same order
// as they are defined in the actions file.
