import maskers from './maskers';
import { phone } from './phoneMasker';
import { zipCode } from './zipCodeMasker';

const mask = (value, rules) => {
  if (!value || !rules || !rules.length) {
    return value;
  }

  const allMaskers = { ...maskers, phone, zipCode };

  return rules.reduce((newValue, rule) => {
    const { name, ...args } = rule;
    const maskedValue = allMaskers[name]
      ? allMaskers[name](newValue, args)
      : newValue;
    return maskedValue;
  }, value);
};

export default mask;
