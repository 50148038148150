/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from 'Redux/auth/authThunks';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import { Loading } from 'Common';
import Routes from 'Routes/profileRoutes';

class Login extends Component {
  async componentDidMount() {
    // Get query strings that might contain authorization code "code" and state "state".
    const { search, hash } = this.props.location;

    const icw = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.ICW
    );

    // "from" is a query string parameter that can be used
    // to redirect the user back after the login.
    // The fragment identifier "hash" is required to support URLs with "hash" like:
    // https://events.ikea.com/gb/en/events/#/store/croydon/
    const from = getQueryStringValue(
      `${search}${hash}`,
      Routes.QUERY_PARAMS.FROM
    );

    // "bveventid" is a query string parameter that is
    // used to identify a product page.
    const bveventid = getQueryStringValue(
      `${search}`,
      Routes.QUERY_PARAMS.BV_EVENT_ID
    );
    const auth0ValidQueryParam = !/(?=.*code|.*error=|.*icw=)/.test(search)
      ? ''
      : search;

    this.props.login(
      auth0ValidQueryParam,
      bveventid,
      Routes.DASHBOARD,
      null,
      from,
      undefined,
      icw,
      undefined
    );
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Loading isLoading text="" />;
  }
}

Login.propTypes = {
  location: PropTypes.object,
  login: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(Login);
