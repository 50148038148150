import {
  transformFields,
  transformValues,
  transformOptions
} from './LoqateAddressyTransformers';

/**
 * Loqate address data provider that can be used for address suggestions.
 *
 * Sample selection of commonly used Loqate address fields:
 * "Street", "PostalCode", "City", "Province", "ProvinceCode",
 * "District", "SecondaryStreet", "BuildingNumber", "AdminAreaName"
 *
 * Read more about the Loqate "addressy" API:
 * https://www.loqate.com/resources/support/apis/capture/
 *
 */

export default class LoqateAddressyApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.LOQATE_ADDRESSY.HOST;
    this.apiKey = CONFIG.API.LOQATE_ADDRESSY.KEY;
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
  }

  /**
   * Calls the API ("find") with the provided query string.
   *
   * @param  {String} searchQuery the search string
   * @param  {String} containerId id of the container we are searching within
   * @param  {String} maxResults max results to show
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async complete(
    searchQuery,
    containerId = '',
    maxResults,
    successCallback,
    failureCallback
  ) {
    const postData = `&Key=${encodeURIComponent(
      this.apiKey
    )}&Countries=${encodeURIComponent(
      this.countryCode
    )}&Language=${encodeURIComponent(
      this.languageCode
    )}&Text=${encodeURIComponent(searchQuery)}&Limit=${encodeURIComponent(
      maxResults
    )}&IsMiddleWare=${encodeURIComponent(false)}&Container=${encodeURIComponent(
      containerId
    )}`;

    const result = await fetch(
      `${this.apiUrl}/Capture/Interactive/Find/v1.10/json3.ws`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: postData
      }
    );

    if (!result.ok) {
      failureCallback();
      return;
    }
    const json = await result.json();
    const transformed = transformOptions(json);

    if (!transformed) {
      failureCallback();
      return;
    }
    successCallback(transformed);
  }

  /**
   * Calls the API ("retrieve") with the id of an address
   * suggestion provided from a previous complete/"find" call.
   *
   * @param  {String} searchQuery  the search string
   * @param  {String} captureId id of the result we want to capture
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async capture(searchQuery, captureId, successCallback, failureCallback) {
    const postData = `&Key=${encodeURIComponent(
      this.apiKey
    )}&Countries=${encodeURIComponent(
      this.countryCode
    )}&Language=${encodeURIComponent(
      this.languageCode
    )}&Id=${encodeURIComponent(captureId)}&Text=${encodeURIComponent(
      searchQuery
    )}`;

    const result = await fetch(
      `${this.apiUrl}/Capture/Interactive/Retrieve/v1.00/json3.ws`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: postData
      }
    );

    if (!result.ok) {
      failureCallback();
      return;
    }
    const json = await result.json();
    const transformed1 = transformFields(json, this.addressFieldMap);
    const transformed2 = transformValues(transformed1, this.addressValueMap);

    if (!transformed2) {
      failureCallback();
      return;
    }
    successCallback(transformed2);
  }
}
