import {
  transformFields,
  transformValues
} from './GoogleMapsGeocoderTransformers';

/**
 * Google Maps address data provider that can be used for filtering address
 * results on a specific field, e.g. zipCodes.
 *
 * Sample selection of commonly used Google address fields:
 * "postal_code", "postal_town", "street_number", "route", "political",
 * "locality", "sublocality", "sublocality_level_1", "administrative_area_level_1"
 *
 * Available Google fields to filter on:
 * "country", "postalCode", "locality", "administrativeArea", "route"
 *
 * Read more about the Maps JavaScript API:
 * https://developers.google.com/maps/documentation/javascript/reference/geocoder
 *
 */

export default class GoogleMapsGeocoderClient {
  constructor() {
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressFieldValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_VALUE_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
    this.addressFilterMap = Object.keys(
      CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP
    ).reduce((filterMap, fieldName) => {
      if (CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP[fieldName]) {
        filterMap[fieldName] =
          CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP[fieldName];
      }
      return filterMap;
    }, {});
    this.geocoderService = new google.maps.Geocoder();
  }

  filter(searchData, successCallback, failureCallback) {
    const componentRestrictions = Object.keys(this.addressFilterMap).reduce(
      (restrictions, fieldName) => {
        const googleFieldName = this.addressFilterMap[fieldName];
        const fieldValue = searchData[fieldName];
        restrictions[googleFieldName] = fieldValue;
        return restrictions;
      },
      { country: this.countryCode }
    );
    const request = {
      componentRestrictions
    };

    this.geocoderService.geocode(request, (result, status) => {
      if (status === 'OK') {
        const data = transformFields(
          result,
          this.addressFieldMap,
          this.addressFieldValueMap
        );
        successCallback(transformValues(data, this.addressValueMap));
      } else {
        failureCallback();
      }
    });
  }
}
