import { ReduxStore } from 'Redux';
import analytics from 'Utils/analytics/analyticsWrapper';
import { ProfileService, SalutationService } from 'Services/ServiceFactory';
import { setFormErrorsAction, clearFormAction } from 'Redux/forms/formsActions';
import { setProfileAction } from 'Redux/profile/profileActions';
import {
  mapBackendException,
  sendAnalyticsErrors
} from 'Utils/errors/errorUtils';
import { loadingAction } from 'Redux/userInterface/userInterfaceActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import {
  setDeliveryAddressesAction,
  deleteDeliveryAddressAction,
  setDeliveryAddressesFailedAction
} from './addressesActions';
import { getProfile } from '../profile/profileThunks';

export const getDeliveryAddresses = () => {
  return async dispatch => {
    try {
      await dispatch(getProfile());
    } catch (e) {
      dispatch(setDeliveryAddressesFailedAction());
    }
  };
};

export const createDeliveryAddress = (data, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_CREATE, true));
      const { partyUid } = ReduxStore.getState().auth;
      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const firstNamePhonetic = await SalutationService.getSalutationForFirstName(
          data.firstName,
          data.lastName,
          data.genderCode
        );
        // eslint-disable-next-line no-param-reassign
        data.firstNamePhonetic = firstNamePhonetic;
      }
      await ProfileService.createDeliveryAddress(data);
      const { userId } = ReduxStore.getState().profile;
      const profileResp = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      dispatch(setProfileAction(profileResp));
      dispatch(setDeliveryAddressesAction(profileResp.deliveryAddresses));
      dispatch(clearFormAction(formId));
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_CREATE, false));
    }
  };
};

export const updateDeliveryAddress = (data, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_UPDATE, true));
      const { partyUid } = ReduxStore.getState().auth;
      if (CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE) {
        const firstNamePhonetic = await SalutationService.getSalutationForFirstName(
          data.firstName,
          data.lastName,
          data.genderCode
        );
        // eslint-disable-next-line no-param-reassign
        data.firstNamePhonetic = firstNamePhonetic;
      }
      await ProfileService.updateDeliveryAddress(data);
      const { userId } = ReduxStore.getState().profile;
      const profileResp = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      dispatch(setProfileAction(profileResp));
      dispatch(setDeliveryAddressesAction(profileResp.deliveryAddresses));
      dispatch(clearFormAction(formId));
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_UPDATE, false));
    }
  };
};

export const upsertBillingAddress = (address, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PRIMARY_ADDRESS_UPDATE, true));
      const { partyUid } = ReduxStore.getState().auth;
      const { userId } = ReduxStore.getState().profile;
      await ProfileService.upsertBillingAddress(address, formId);
      const profileResp = await ProfileService.getProfile(
        userId,
        undefined,
        partyUid
      );
      dispatch(setProfileAction(profileResp));
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PRIMARY_ADDRESS_UPDATE, false));
    }
  };
};

export const deleteDeliveryAddress = (addressId, formId) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_DELETE, true));
      const { partyUid } = ReduxStore.getState().auth;

      await ProfileService.deleteDeliveryAddress(partyUid, addressId);
      dispatch(deleteDeliveryAddressAction(addressId));
      await analytics.userProfileFormUpdate({
        status: 'completed',
        form: formId
      });
      return true;
    } catch (e) {
      await sendAnalyticsErrors(e, formId);
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.DELIVERY_ADDRESS_DELETE, false));
    }
  };
};
