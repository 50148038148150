import {
  transformFields,
  transformValues,
  transformOptions
} from './LoqateEverythingLocationTransformers';

/**
 * Loqate address data provider that can be used for address suggestions.
 *
 * Sample selection of commonly used Loqate address fields:
 * "Address1", "Address2", "Address3", "DeliveryAddress", "Locality",
 * "PostalCode", "PostalCodePrimary", "AdministrativeArea"
 *
 * Read more about the Loqate "everything location" API:
 * https://www.loqate.com/resources/support/everything-location/
 *
 */

export default class LoqateEverythingLocationApiClient {
  constructor() {
    this.apiUrl = CONFIG.API.LOQATE_EVERYTHING_LOCATION.HOST;
    this.apiKey = CONFIG.API.LOQATE_EVERYTHING_LOCATION.KEY;
    this.countryCode = CONFIG.COUNTRY;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
  }

  /**
   * Calls the API ("complete") with the provided query string.
   *
   * @param  {String} searchQuery the search string
   * @param  {String} containerId id of the container we are searching within
   * @param  {String} maxResults max results to show
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async complete(
    searchQuery,
    containerId = '',
    maxResults,
    successCallback,
    failureCallback
  ) {
    const postData = {
      country: this.countryCode,
      lqtkey: this.apiKey,
      query: searchQuery,
      container: containerId,
      options: {
        MaxResults: maxResults
      },
      origin: 'auto'
    };

    const result = await fetch(`${this.apiUrl}/address/complete?newcomplete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(postData)
    });

    if (!result.ok) {
      failureCallback();
      return;
    }
    const json = await result.json();
    const transformed = transformOptions(json);

    if (!transformed) {
      failureCallback();
      return;
    }
    successCallback(transformed);
  }

  /**
   * Calls the API ("capture") with the id of an address
   * suggestion provided from a previous complete/"complete" call.
   *
   * @param  {String} searchQuery  the search string
   * @param  {String} captureId id of the result we want to capture
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  async capture(searchQuery, captureId, successCallback, failureCallback) {
    const postData = {
      country: this.countryCode,
      lqtkey: this.apiKey,
      query: searchQuery,
      id: captureId
    };

    const result = await fetch(`${this.apiUrl}/address/capture?newcomplete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(postData)
    });

    if (!result.ok) {
      failureCallback();
      return;
    }
    const json = await result.json();
    const transformed1 = transformFields(json, this.addressFieldMap);
    const transformed2 = transformValues(transformed1, this.addressValueMap);

    if (!transformed2) {
      failureCallback();
      return;
    }
    successCallback(transformed2);
  }
}
