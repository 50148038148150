import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './AddressSummary.scss';

const AddressSummary = props => (
  <div className={styles['address-summary']}>
    <div className={styles['address-summary-text']}>
      {CONFIG.ADDRESS_AUTOCOMPLETE.SUMMARY_CONTENT.map((addressRow, index) => (
        <div key={`address-autocomplete-row-${index}`}>
          {addressRow.map(fieldName => (
            <React.Fragment key={`address-autocomplete-${fieldName}`}>
              {props[fieldName] && `${props[fieldName]} `}
            </React.Fragment>
          ))}
        </div>
      ))}
      <Link
        bold
        underline
        className={styles['address-summary-edit']}
        onClick={props.onEdit}
      >
        {t('common.edit')}
      </Link>
    </div>
  </div>
);

AddressSummary.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  zipCode: PropTypes.string,
  cityName: PropTypes.string,
  province: PropTypes.string,
  onEdit: PropTypes.func.isRequired
};

export default AddressSummary;
