import validators from './validators';

const validate = (
  formId,
  value,
  label,
  rules,
  includeAll,
  setFormFieldMessages
) => {
  let allMessages = [];

  if (!rules || !rules.length) {
    return allMessages;
  }

  for (let i = 0; i < rules.length; i += 1) {
    const { name, ...args } = rules[i];

    if (validators[name]) {
      const messages =
        validators[name](value, label, args, formId, setFormFieldMessages) ||
        [];
      allMessages = [...allMessages, ...messages];
    }
    if (!includeAll && allMessages.length > 0) {
      return allMessages;
    }
  }
  return allMessages;
};

export default validate;
