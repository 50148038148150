import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import {
  getFieldValuesByNames,
  getFieldMessagesByNames
} from 'Redux/forms/formsHelper';
import FormField from 'BaseForm/FormField';
import CheckboxControlled from 'BaseForm/CheckboxControlled';
import styles from './CheckboxArrayContainer.scss';

class CheckboxArrayContainer extends React.PureComponent {
  createEvent = (name, checked) => {
    return {
      target: {
        name,
        checked,
        type: 'checkbox'
      }
    };
  };

  onChange = fieldName => e => {
    const checkboxEvent = this.createEvent(fieldName, e.target.checked);
    this.props.onChange(checkboxEvent);
  };

  render() {
    const contactMethodFields = this.props.nestedFields;
    return (
      <div className={styles['checkbox-array-checkboxes']}>
        {contactMethodFields.map(field => (
          <FormField
            key={`contact-method-${field.name}`}
            validation={{
              id: `${this.props.formId}-${field.name}-error`,
              msg: this.props.messages[field.name],
              type: `${CONFIG.APP.STYLES_PREFIX}error`
            }}
            shouldValidate={this.props.messages[field.name] !== undefined}
          >
            <CheckboxControlled
              formId={this.props.formId}
              name={field.name}
              label={field.label}
              value={this.props.values[field.name]}
              onChange={this.onChange(field.name)}
              onBlur={this.props.onBlur}
              lessBottomMargin
            />
          </FormField>
        ))}
      </div>
    );
  }
}

CheckboxArrayContainer.propTypes = {
  formId: PropTypes.string.isRequired,
  nestedFields: PropTypes.arrayOf(PropTypes.object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  // From Redux
  values: PropTypes.object,
  messages: PropTypes.object,
  profile: PropTypes.object
};

CheckboxArrayContainer.defaultProps = {
  values: {},
  messages: {}
};

const mapStateToProps = (state, ownProps) => {
  const fieldNames =
    ownProps.nestedFields &&
    ownProps.nestedFields.map(field => field.componentProps.name);
  return {
    values: getFieldValuesByNames(state, ownProps.formId, fieldNames),
    messages: getFieldMessagesByNames(state, ownProps.formId, fieldNames),
    nestedFields: state.profile[ownProps.name].map(field => {
      return {
        name: field.name,
        label: field.label
      };
    })
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxArrayContainer);
