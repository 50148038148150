import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFormAction } from 'Redux/forms/formsActions';
import { Link, Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import DynamicFormContainer from 'ExtendedForm/DynamicForm/DynamicFormContainer';

class DashboardAddDeliveryAddress extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isAdding: false };
    this.myRef = React.createRef();
  }

  onAdd = () => {
    this.setState({ isAdding: true });
    this.scrollToTop();
  };

  onClose = () => {
    this.setState({ isAdding: false }, () => {
      this.props.clearFormAction(this.props.addForm);
      this.scrollToCenter();
    });
  };

  onCreate = data => {
    const successCallback = () => {
      this.setState({ isAdding: false });
      this.scrollToCenter();
    };
    const failureCallback = () => {
      this.scrollToCenter();
    };
    this.props.onCreate(data, successCallback, failureCallback);
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  scrollToTop = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    return this.state.isAdding ? (
      <div ref={this.setRef}>
        {this.props.titleAdd && (
          <Text bold colour="grey-900">
            {t(this.props.titleAdd)}
          </Text>
        )}
        <DynamicFormContainer
          formId={this.props.addForm}
          loadingSubmit={this.props.isLoading}
          textSubmit={t('forms.addressSave')}
          textCancel={t('common.cancel')}
          onCancel={this.onClose}
          onSubmit={this.onCreate}
        />
      </div>
    ) : (
      <div ref={this.setRef}>
        {!this.state.isAdding &&
          this.props.deliveryAddressesCount === 0 &&
          !this.props.deliveryAddressesFailed && (
            <Text marginBottom="s">
              {t('dashboard.address.deliveryMissing')}
            </Text>
          )}
        {this.props.deliveryAddressesCount < 10 && (
          <Link bold id={`${this.props.addForm}-link`} onClick={this.onAdd}>
            {t('dashboard.address.deliveryAdd')}
          </Link>
        )}
      </div>
    );
  }
}

DashboardAddDeliveryAddress.propTypes = {
  titleAdd: PropTypes.string,
  deliveryAddressesFailed: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  addForm: PropTypes.string.isRequired,
  deliveryAddressesCount: PropTypes.number,
  // From Redux/Thunks
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.DELIVERY_ADDRESS_CREATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAddDeliveryAddress);
