import { ACTIONS } from './profileConstants';

export const setProfileAction = profile => {
  return {
    type: ACTIONS.SET_PROFILE,
    profile
  };
};

export const setUserIdAction = userId => {
  return {
    type: ACTIONS.SET_USER_ID,
    userId
  };
};

export const setCoworkerLinkingStatusAction = coworkerLinkingStatus => {
  return {
    type: ACTIONS.SET_COWORKER_LINKING_STATUS,
    coworkerLinkingStatus
  };
};

export const clearProfileAction = () => {
  return {
    type: ACTIONS.CLEAR_PROFILE
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
