import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { i18nextInit } from 'Utils/localization/i18next';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import Login from 'Components/Login/Login';
import LoginInternalContainer from 'Components/Login/LoginInternalContainer';
import Logout from 'Components/Logout/Logout';
import SignupUpgradeContainer from 'Components/Signup/SignupUpgradeContainer';
import DashboardContainer from 'Components/Dashboard/DashboardContainer';
import HouseholdContainer from 'Components/Household/HouseholdContainer';
import ResetPasswordContainer from 'Components/Password/ResetPasswordContainer';
import ResetPasswordConfirmation from 'Components/Password/ResetPasswordConfirmation';
import UpdatePasswordContainer from 'Components/Password/UpdatePasswordContainer';
import DeletedProfileContainer from 'Components/DeletedProfile/DeletedProfileContainer';
import FamilyMicrositeIframe from 'Components/DashboardFamily/FamilyMicrositeIframe';
import CustomerProject from 'Components/Dashboard/CustomerProject/CustomerProject';
import Routes from 'Routes/profileRoutes';
import DashboardVerificationOtpContainer from 'Components/Dashboard/DashboardVerificationOtpContainer';
import DashboardEditMobileExternalContainer from 'Components/Dashboard/DashboardEditMobileExternalContainer';
import SignupContainer from 'Components/Signup/SignupContainer';
import ErrorPage from 'Components/ErrorPage/ErrorPage';
import '../styles/common.scss';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    const mode = getQueryStringValue(
      this.props.location.search,
      Routes.QUERY_PARAMS.TRANSLATE
    );
    i18nextInit(mode);
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <Switch>
        <Route
          path={Routes.LOGIN}
          component={
            CONFIG.FEATURE.INTERNAL_LOGIN ? LoginInternalContainer : Login
          }
        />
        <Route
          path={Routes.RESET_PASSWORD_CONFIRMATION}
          component={ResetPasswordConfirmation}
        />
        <Route
          path={Routes.RESET_UPDATE_PASSWORD}
          component={UpdatePasswordContainer}
        />
        <Route
          path={Routes.RESET_PASSWORD}
          component={ResetPasswordContainer}
        />
        <Route path={Routes.LOGOUT} component={Logout} />
        <Route path={Routes.UPGRADE} component={SignupUpgradeContainer} />
        <Route path={Routes.HOUSEHOLD} component={HouseholdContainer} />
        <Route path={Routes.DASHBOARD} component={DashboardContainer} />
        {CONFIG.FEATURE.REGULAR_DASHBOARD.SIDE_MENU_TABS?.CUSTOMER_PROJECTS && (
          <Route path={Routes.CUSPRO} component={CustomerProject} />
        )}
        <Route
          path={Routes.DELETED_PROFILE}
          component={DeletedProfileContainer}
        />
        {CONFIG.URL.FAMILY_MICROSITE_IFRAME && (
          <Route
            path={Routes.FAMILY_MICROSITE_IFRAME}
            component={FamilyMicrositeIframe}
          />
        )}
        <Route path={Routes.SIGNUP} component={SignupContainer} />
        <Route path={Routes.ERROR} component={ErrorPage} />
        <Route
          path={Routes.SEND_OTP}
          component={DashboardVerificationOtpContainer}
        />
        <Route
          path={Routes.EDIT_MOBILE}
          component={DashboardEditMobileExternalContainer}
        />
        <Redirect from={Routes.ROOT_CONTEXT} to={Routes.LOGIN} />
      </Switch>
    );
  }
}

App.propTypes = {
  location: PropTypes.object
};

const mapStateToProps = () => {
  return {};
};

const AppWithConnect = connect(
  mapStateToProps,
  null
)(App);

export default withRouter(AppWithConnect);
