import React from 'react';
import PropTypes from 'prop-types';
import validate from 'Utils/validation/validate';
import autocorrect from 'Utils/autocorrect/autocorrect';
import { AddressAutocompleteService } from 'Services/ServiceFactory';
import Address from 'ExtendedForm/Address/Address';
import { InlineAlert } from 'Common';
import { t } from 'Utils/localization/i18next';

const queryMinLength = CONFIG.ADDRESS_AUTOCOMPLETE.QUERY_THRESHOLD || 1;
const filterFieldNames =
  CONFIG.FEATURE.ENABLE_ADDRESS_AUTOCOMPLETE &&
  Object.keys(CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP).filter(
    fieldName => CONFIG.ADDRESS_AUTOCOMPLETE.FILTER_MAP[fieldName] !== null
  );

class AddressWithFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterMessage: null
    };
  }

  onValuesChange = values => {
    if (values) {
      Object.keys(values).forEach(name => {
        this.props.onChange({
          target: {
            name,
            value: values[name]
          }
        });

        if (this.props.messages[name] && values[name]) {
          // Generally, we wait and let the "DynamicForm" validate the fields
          // when user submits the full form. However, in order to get rid of
          // any existing error messages as the user enters valid data, we do
          // validation here if the field had an error message before autofill.
          this.props.onBlur({
            target: {
              name,
              value: values[name]
            }
          });
        }
        this.setFocusToFirstEmptyMandatoryField();
      });
    }
  };

  setFocusToFirstEmptyMandatoryField = () => {
    const { values, formId, checkMandatory } = this.props;
    const fieldName = Object.keys(values).find(
      name => (!values[name] || !values[name].length) && checkMandatory(name)
    );
    const element =
      document.getElementById(`${formId}-${fieldName}`) ||
      document.getElementById(`${formId}-address1`);
    if (element) {
      element.focus();
    }
  };

  onFilterSuccess = values => {
    this.onValuesChange(values);
    if (this.state.filterMessage) {
      this.setState({ filterMessage: null });
    }
  };

  onFilterFailure = triggerFieldName => {
    const filterMessage = t('forms.errors.addressFilterSearch', {
      field: t(`forms.${triggerFieldName}`)
    });
    this.setState({ filterMessage });
    // Clear all fields except for the one triggering filter/autocomplete.
    const newValues = this.props.nestedFields.reduce((values, field) => {
      // eslint-disable-next-line no-param-reassign
      values[field.componentProps.name] = '';
      return values;
    }, {});
    this.onValuesChange({
      ...newValues,
      [triggerFieldName]: this.props.values[triggerFieldName]
    });
  };

  complete = triggerFieldName => {
    const successCallback = result => this.onFilterSuccess(result);
    const failureCallback = () => this.onFilterFailure(triggerFieldName);
    AddressAutocompleteService.filter(
      this.props.values,
      successCallback,
      failureCallback
    );
  };

  isFieldValueValid = (name, value) => {
    const { nestedFields, formId } = this.props;
    const { autocorrectRules, validationRules } =
      nestedFields.find(field => field.componentProps.name === name) || {};
    const autocorrected = autocorrect(formId, value, autocorrectRules);
    const messages = validate(formId, autocorrected, null, validationRules);
    return messages.length === 0;
  };

  onFieldBlur = e => {
    const { name } = e.target;
    const value = this.props.values[name] || '';

    if (
      !this.props.disableAutocomplete &&
      value.length >= queryMinLength &&
      filterFieldNames.includes(name) &&
      this.isFieldValueValid(name, value)
    ) {
      this.complete(name);
    }
    this.props.onBlur(e);
  };

  render() {
    return (
      <React.Fragment>
        <div id="map" />
        {this.state.filterMessage && (
          <InlineAlert
            icon="alert"
            text={this.state.filterMessage}
            marginTop="s"
            marginBottom="m"
          />
        )}
        <Address
          formId={this.props.formId}
          nestedFields={this.props.nestedFields}
          values={this.props.values}
          messages={this.props.messages}
          checkMandatory={this.props.checkMandatory}
          onBlur={this.onFieldBlur}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

AddressWithFilter.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  checkMandatory: PropTypes.func.isRequired,
  disableAutocomplete: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddressWithFilter;
