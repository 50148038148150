import { Loading } from 'Common';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout } from 'Redux/auth/authThunks';
import { t } from 'Utils/localization/i18next';
import { isValidIKEAUrl, getQueryStringValue } from 'Utils/url/urlUtils';

class Logout extends React.Component {
  componentDidMount() {
    const url = window.location.search;
    const param = getQueryStringValue(url, 'from');
    const redirectLoggedOutTo = param
      ? isValidIKEAUrl(param)
        ? param
        : `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/`
      : `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/`;
    this.props.logout(redirectLoggedOutTo);
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Loading isLoading text={t('loaders.logout')} />;
  }
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(Logout));
