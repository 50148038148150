import { ACTIONS } from './userInterfaceConstants';

export const loadingAction = (key, loading) => {
  return {
    type: ACTIONS.SET_LOADING,
    key,
    loading
  };
};

// Follow the same naming pattern while creating new action.
// Keep this comment at end of file.
//
// export const verbSomethingAction = (...params) => {
//   return {
//     type: ACTIONS.VERB_SOMETHING
//   };
// };
