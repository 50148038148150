// Actions constants should be mapped in the same order
// as they are defined in the actions file.
export const ACTIONS = {
  SET_LOADING: 'set_loading'
};

export const LOADING = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  PROFILE_GET: 'profile_get',
  PROFILE_CREATE: 'profile_create',
  PROFILE_UPDATE: 'profile_update',
  PROFILE_DELETE: 'profile_delete',
  PROFILE_UPGRADE: 'profile_upgrade',
  PRIMARY_ADDRESS_UPDATE: 'primary_address_update',
  DELIVERY_ADDRESS_GET: 'delivery_address_get',
  DELIVERY_ADDRESS_CREATE: 'delivery_address_create',
  DELIVERY_ADDRESS_UPDATE: 'delivery_address_update',
  DELIVERY_ADDRESS_DELETE: 'delivery_address_delete',
  PAYMENTS_GET: 'payments_get'
};
