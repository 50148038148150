import React from 'react';
import PropTypes from 'prop-types';
import { t, exists } from 'Utils/localization/i18next';
import IkeaRadioButtonGroup from '@ingka/radio-button-group';
import {
  Text,
  TextWithLink,
  TextWithModal,
  SSRIcon,
  SSRIcons,
  Icon,
  Link
} from 'Common';
import styles from './ProfileTypeRadioButtonGroup.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';

const ProfileTypeRadioButtonGroup = props => {
  const getRadioButtonLabelComponent = option => {
    switch (option.labelComponent) {
      case 'Text':
        return <Text>{t(option.label)}</Text>;
      case 'TextWithLink':
        return (
          <TextWithLink
            labelBeforeLink={`${t(option.labelBeforeLink)} `}
            labelLink={t(option.labelLink)}
            labelAfterLink={
              exists(option.labelAfterLink) && t(option.labelAfterLink)
            }
            link={t(option.link)}
            linkTarget="_blank"
          />
        );
      case 'TextWithModal':
        return (
          <TextWithModal
            id={`${option.value}-modal-link`}
            modalType="alert"
            textBeforeLink={option.textBeforeLink}
            textLink={option.textLink}
            textAfterLink={option.textAfterLink}
            textLinkLineBreak={option.textLinkLineBreak}
            modalTitle={option.modalTitle}
            modalText1={option.modalText1}
            modalText2={option.modalText2}
            modalLink={option.modalLink}
            modalLinkTextBefore={option.modalLinkTextBefore}
            modalLinkText={option.modalLinkText}
            modalLinkTextAfter={option.modalLinkTextAfter}
            modalButtonRedirectLink={option.modalButtonRedirectLink}
            modalButtonRedirectText={option.modalButtonRedirectText}
            modalButtonCloseText={option.modalButtonCloseText}
            onTextClick={() =>
              props.onChange({
                target: {
                  name: props.name,
                  type: 'radio',
                  value: option.value
                }
              })
            }
          >
            <div className={styles['profile-type-modal-sections-wrap']}>
              {option.modalSections
                .filter(section => exists(section.title))
                .map((section, index) => (
                  <div
                    key={`modal-section-${index}`}
                    className={styles['profile-type-modal-section']}
                  >
                    {SSRIcons[section.icon] ? (
                      <SSRIcon icon={section.icon} size="200" />
                    ) : (
                      <Icon size="md">{section.icon}</Icon>
                    )}
                    <div>
                      <Text bold>{t(section.title)}</Text>
                      {exists(section.text) && <Text>{t(section.text)}</Text>}
                      {exists(section.link) && (
                        <Link
                          underline
                          onClick={() => window.open(t(section.link), '_blank')}
                        >
                          {t(section.linkText)}
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </TextWithModal>
        );
      default:
        return t(option.label);
    }
  };

  return (
    <IkeaRadioButtonGroup
      id={`${props.formId}-${props.name}`}
      name={t(props.label)}
      list={props.options.map((option, index) => {
        return {
          id: `${props.formId}-${props.name}-${index}`,
          name: props.name,
          text: getRadioButtonLabelComponent(option),
          value: option.value,
          disabled: option.disabled,
          checked: option.value === props.value
        };
      })}
      prefix={CONFIG.APP.STYLES_PREFIX}
      describedById={`${props.formId}-${props.name}-error`}
      className={styles['profile-type-radio-btn-group']}
      onBlur={props.onBlur}
      onChange={props.onChange}
    />
  );
};

ProfileTypeRadioButtonGroup.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  showOptional: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export default ProfileTypeRadioButtonGroup;
