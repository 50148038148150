import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tooltip.scss';

export const Tooltip = props =>
  props.isOpen ? (
    <div className={classNames(styles.tooltip, props.className)} tabIndex={0}>
      <small className={styles['tooltip-content']}>{props.text}</small>
      {props.children}
    </div>
  ) : (
    <div className={classNames(styles.tooltip, props.className)}>
      {props.children}
    </div>
  );

Tooltip.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

Tooltip.defaultProps = {
  isOpen: false
};
