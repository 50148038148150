// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__link-underline{text-decoration:underline !important}.profile__link-bold{font-weight:bold !important}.profile__link-colour-brand-blue{color:rgb(var(--colour-static-ikea-brand-blue, 0, 88, 163)) !important}.profile__link-colour-feedback-error{color:rgb(var(--colour-semantic-negative, 224, 7, 81)) !important}.profile__link-colour-grey-500{color:rgb(var(--colour-neutral-5, 146, 146, 146)) !important}.profile__link-colour-grey-700{color:rgb(var(--colour-neutral-6, 72, 72, 72)) !important}.profile__link-colour-grey-900{color:rgb(var(--colour-neutral-7, 17, 17, 17)) !important}", "",{"version":3,"sources":["webpack://./src/components/_common/Links/Link.scss"],"names":[],"mappings":"AAEA,yBACE,oCAAA,CAGF,oBACE,2BAAA,CAIA,iCACE,sEAAA,CAGF,qCACE,iEAAA,CAGF,+BACE,4DAAA,CAGF,+BACE,yDAAA,CAGF,+BACE,yDAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.link-underline {\n  text-decoration: underline !important;\n}\n\n.link-bold {\n  font-weight: bold !important;\n}\n\n.link-colour {\n  &-brand-blue {\n    color: $colour-static-ikea-brand-blue !important;\n  }\n\n  &-feedback-error {\n    color: $colour-semantic-negative !important;\n  }\n\n  &-grey-500 {\n    color: $colour-neutral-5 !important;\n  }\n\n  &-grey-700 {\n    color: $colour-neutral-6 !important;\n  }\n\n  &-grey-900 {\n    color: $colour-neutral-7 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-underline": "profile__link-underline",
	"link-bold": "profile__link-bold",
	"link-colour-brand-blue": "profile__link-colour-brand-blue",
	"link-colour-feedback-error": "profile__link-colour-feedback-error",
	"link-colour-grey-500": "profile__link-colour-grey-500",
	"link-colour-grey-700": "profile__link-colour-grey-700",
	"link-colour-grey-900": "profile__link-colour-grey-900"
};
export default ___CSS_LOADER_EXPORT___;
