import { ACTIONS } from './paymentConstants';

const initialState = {
  isPaymentFetched: false,
  payments: []
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_PAYMENTS:
      return {
        ...state,
        isPaymentFetched: true,
        payments: action.payments
      };
    case ACTIONS.DELETE_PAYMENT:
      return {
        ...state,
        payments: action.payments
      };
    case ACTIONS.CLEAR_PAYMENTS:
    default:
      return state;
  }
};

export default payments;

// Actions should be mapped in the same order
// as they are defined in the actions file.
