// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__dashboard-header-regular{margin:0rem 0}.profile__dashboard-header-family{margin:0rem 0;display:flex;justify-content:space-between}.profile__dashboard-header-title{margin-bottom:1.5rem}@media only screen and (max-width: 48rem){.profile__dashboard-header-general{margin:0 0}.profile__dashboard-header-family{margin:0 0;flex-direction:column;align-items:center}.profile__dashboard-header-text-wrap{align-self:flex-start}}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/DashboardHeaderContainer.scss"],"names":[],"mappings":"AACE,mCACE,aAAA,CAEF,kCACE,aAAA,CACA,YAAA,CACA,6BAAA,CAEF,iCACE,oBAAA,CAIJ,0CAEI,mCACE,UAAA,CAEF,kCACE,UAAA,CACA,qBAAA,CACA,kBAAA,CAEF,qCACE,qBAAA,CAAA","sourcesContent":[".dashboard-header {\n  &-regular {\n    margin: 0rem 0;\n  }\n  &-family {\n    margin: 0rem 0;\n    display: flex;\n    justify-content: space-between;\n  }\n  &-title {\n    margin-bottom: 1.5rem;\n  }\n}\n\n@media only screen and (max-width: 48rem) {\n  .dashboard-header {\n    &-general {\n      margin: 0 0;\n    }\n    &-family {\n      margin: 0 0;\n      flex-direction: column;\n      align-items: center;\n    }\n    &-text-wrap {\n      align-self: flex-start;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard-header-regular": "profile__dashboard-header-regular",
	"dashboard-header-family": "profile__dashboard-header-family",
	"dashboard-header-title": "profile__dashboard-header-title",
	"dashboard-header-general": "profile__dashboard-header-general",
	"dashboard-header-text-wrap": "profile__dashboard-header-text-wrap"
};
export default ___CSS_LOADER_EXPORT___;
