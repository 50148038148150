import { Response } from '../helper/models';

export class LoginResponse extends Response {
  constructor(httpResponse) {
    super();
    this.originalResponse = httpResponse || {};
    this.authenticated = false;
  }
}

export class ChallengeResponse extends Response {
  constructor(httpResponse) {
    super();
    this.originalResponse = httpResponse || {};
  }
}

export class ChallengeRef {
  constructor(challengeId, secretToken) {
    this.challengeId = challengeId;
    this.secretToken = secretToken;
  }
}
