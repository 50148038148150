import { PROFILE_TYPES } from 'Redux/profile/profileConstants';

export function isMember(loyaltyProgram, membershipType) {
  if (
    loyaltyProgram &&
    loyaltyProgram.loyaltyCardList &&
    loyaltyProgram.loyaltyCardList.length &&
    loyaltyProgram.loyaltyCardList[0].cardType === membershipType
  ) {
    return true;
  }
  return false;
}

export function getUserType(loyaltyProgram) {
  if (isMember(loyaltyProgram, 'FAMILY')) {
    return PROFILE_TYPES.FAMILY;
  } else if (isMember(loyaltyProgram, 'BUSINESS')) {
    return PROFILE_TYPES.BUSINESS;
  }
  return PROFILE_TYPES.REGULAR;
}
