import { Response, ResponseError } from '../helper/models';
import {
  reasonCodeToErrorCode,
  errorMessageToErrorCode
} from '../helper/errorUtils';
import * as family from './transformFamily';
import * as regular from './transformRegular';
import { isMember } from './profileUtils';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import * as stringUtils from 'Utils/string/stringUtils';
import { IRW_RESIDENCE_TYPES, OW_RESIDENCE_TYPES } from './residenceTypes';

export function registerProfileRequest(data) {
  if (!data) {
    return;
  }
  switch (data.profileType) {
    case PROFILE_TYPES.REGULAR:
      return regular.registerProfileRequest(data);
    case PROFILE_TYPES.FAMILY:
      return family.registerProfileRequest(data);
  }
}

export function registerProfileResponse(data) {
  const result = new Response();
  if (Array.isArray(data)) {
    data.forEach(error => {
      result.errors.push(
        new ResponseError(
          ...(error.errorCode === '10010'
            ? errorMessageToErrorCode(error.errorMessage)
            : reasonCodeToErrorCode(error.errorCode)),
          transformErrorAttributeList(error.errorAttributeList)
        )
      );
    });
  }
  return result;
}

export function updateProfileRequest(currentProfile, updatedProfile) {
  if (!updatedProfile) {
    return;
  }
  switch (updatedProfile.profileType) {
    case PROFILE_TYPES.REGULAR:
      return regular.updateProfileRequest(currentProfile, updatedProfile);
    case PROFILE_TYPES.FAMILY:
      return family.updateProfileRequest(currentProfile, updatedProfile);
  }
}

export function updateProfileResponse(data) {
  const result = new Response();
  if (Array.isArray(data)) {
    result.errors = data.map(error => {
      return new ResponseError(
        ...(error.errorCode === '10010'
          ? errorMessageToErrorCode(error.errorMessage)
          : reasonCodeToErrorCode(error.errorCode)),
        transformErrorAttributeList(error.errorAttributeList)
      );
    });
  }
  return result;
}

export function updateProfileCMSResponse(data) {
  const result = new Response();
  if (Array.isArray(data['Errors'])) {
    result.errors = data['Errors'].map(error => {
      return new ResponseError(
        ...reasonCodeToErrorCode(error.errorType, error.errorMessage),
        transformErrorAttributeList(error.errorAttributeList)
      );
    });
  }
  return result;
}

function transformErrorAttributeList(data) {
  // In order to display field specific validation messages
  // for server side error messages, the value of the error
  // message needs to match the field name.
  if (!data) {
    return;
  }
  const newData = data.map(item => {
    if (item.value === 'city') {
      item.value = 'cityName';
    }
    return item;
  });
  return newData;
}

export function getProfileResponse(data) {
  let profile = getRegularProfileData(data);

  if (isMember(data.loyaltyProgram, 'FAMILY')) {
    return {
      ...profile,
      ...family.getProfileResponse(data)
    };
  }
  return profile;
}

function getRegularProfileData(data) {
  const deliveryAddresses = data.privateMaintCustomer.contactMethodList.filter(
    item =>
      item.contactMethodType === 'ADDRESS' &&
      item.contactMethodRoleList[0].contactMethodRoleId === 'DELIVERY_DEST' &&
      item.address
  );
  return {
    title: cleanNotSet(data.privateMaintCustomer.namePrefix),
    firstName: cleanNotSet(data.privateMaintCustomer.firstName),
    lastName: cleanNotSet(data.privateMaintCustomer.lastName),
    lastName2: cleanNotSet(data.privateMaintCustomer.secondLastName),
    firstNamePhonetic: cleanNotSet(data.privateMaintCustomer.firstNamePhonetic),
    lastNamePhonetic: cleanNotSet(data.privateMaintCustomer.lastNamePhonetic),
    preferredLanguage: cleanNotSet(data.privateMaintCustomer.languageCode),
    countryCode: cleanNotSet(data.privateMaintCustomer.countryCode),
    preferredStore: cleanNotSet(data.preferredBuCode),
    genderCode: cleanNotSet(data.privateMaintCustomer.genderCode),
    noOfChildren: cleanNotSet(data.noOfChildren),
    maritalStatus: cleanNotSet(data.maritalStatusCode),
    educationLevel: cleanNotSet(data.educationLevel),
    // customerid is icmpartyuid
    customerId: data.customerRef.customerId,
    socialSecurityNumber: data.socialSecurityNo,
    personalIdType: data.customerIdentifier?.personalIdType,
    personalId: data.customerIdentifier?.personalId,
    personalIdSource: data.customerIdentifier?.personalIdSource,
    documentId: data.customerIdentifier?.documentId,
    birthDate:
      data.privateMaintCustomer.birthDate &&
      stringUtils.reverseString(
        data.privateMaintCustomer.birthDate.replace('Z', ''),
        '-'
      ),
    noOfRooms: data.residenceInfo && data.residenceInfo.noOfRooms,
    coExistenceInfo: data.residenceInfo && data.residenceInfo.coExistenceInfo,
    homeOwnership:
      data.residenceInfo && data.residenceInfo.homeOwnershipIndicator,
    holidayHomeOwnership:
      data.residenceInfo && data.residenceInfo.holidayHomeOwnership,
    residenceType:
      data.residenceInfo &&
      data.residenceInfo.residenceType &&
      getOWResidenceType(data.residenceInfo.residenceType),
    outdoorType:
      data.residenceInfo &&
      data.residenceInfo.residenceType &&
      getOWOutdoorType(data.residenceInfo.residenceType),
    livingSpace: data.residenceInfo && data.residenceInfo.livingSpaceUnit,
    residenceId: data.residenceInfo && data.residenceInfo.id,
    allowSMS:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowSms === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowSms == '0'
        ? true
        : false,
    allowSharingToSocialMedia:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowSharingToSocialMedia === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowSharingToSocialMedia == '0'
        ? true
        : false,
    allowFindPotentialCustomers:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowFindPotentialCustomers === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowFindPotentialCustomers == '0'
        ? true
        : false,
    allowAppPushNotification:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowAppPushNotification === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowAppPushNotification == '0'
        ? true
        : false,
    allowEmail:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowPersonalEmailCode === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowPersonalEmailCode == '0'
        ? true
        : false,
    allowCollectBuyInfo:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowCollectBuyInfoCode === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowCollectBuyInfoCode == '0'
        ? true
        : false,
    allowDirectMail:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowDirectMailCode === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowDirectMailCode == '0'
        ? true
        : false,
    allowPhone:
      !data.crmCustomerIntegrity ||
      data.crmCustomerIntegrity.notAllowPhoneContactCode === undefined
        ? undefined
        : data.crmCustomerIntegrity.notAllowPhoneContactCode == '0'
        ? true
        : false,
    participateInMemberPanel: isParticipateInMemberPanel(
      data.crmInterestAreaGroupList
    ),
    subscribeToIKEACatalogue: isSubscribeToIKEACatalogue(
      data.crmInterestAreaGroupList
    ),
    email: cleanNotSet(extractContactInfo('EMAIL', data).contactMethodData),
    mobile: cleanNotSet(extractContactInfo('MOBILE', data).contactMethodData),
    landline: cleanNotSet(extractContactInfo('PHONE', data).contactMethodData),
    profileType: PROFILE_TYPES.REGULAR,
    deliveryAddresses:
      deliveryAddresses && deliveryAddresses.length > 0
        ? getDeliveryAddressListData(data)
        : [],
    ...extractAddressInfo(data),
    ...extractChildInfo(data),
    interestCodes: getInterestCodes(data.crmInterestAreaGroupList),
    phoneVerified: data.privateMaintCustomer.phoneVerificationStatus == '1'
  };
}

function cleanNotSet(value) {
  return value === 'NOTSET' || value === 'NOT SET' ? undefined : value;
}

function extractAddressAndHouseNumber(address1) {
  const addressMatch = address1?.match(/^[^0-9]+/);
  const addressWithoutHouseNumber =
    addressMatch && addressMatch.length > 0 && addressMatch[0];
  const houseNumber = address1?.split(addressWithoutHouseNumber)[1];
  return {
    addressWithoutHouseNumber: addressWithoutHouseNumber?.trim(),
    houseNumber
  };
}

function isParticipateInMemberPanel(interestAreaGroups) {
  if (interestAreaGroups && interestAreaGroups.length > 0) {
    if (
      interestAreaGroups[0].crmInterestAreaList.find(
        interestArea => interestArea.interestAreaCode === '60'
      )
    ) {
      return true;
    }
  }
  return false;
}

function isSubscribeToIKEACatalogue(interestAreaGroups) {
  if (interestAreaGroups && interestAreaGroups.length > 0) {
    if (
      interestAreaGroups[0].crmInterestAreaList.find(
        interestArea => interestArea.interestAreaCode === '90'
      )
    ) {
      return true;
    }
  }
  return false;
}

function extractContactInfo(fieldName, profileData) {
  const cmItems = profileData.privateMaintCustomer.contactMethodList.filter(
    item => item.contactMethodType === fieldName
  );
  if (cmItems.length) {
    return cmItems[0];
  }
  return {};
}

/**
 * Flattens the address object and the formattedAddress object inside
 * Will need to be refactored once we start to support multiple addresses
 *
 * @param {*} profileData
 */
function extractAddressInfo(profileData) {
  const contactMethods = profileData.privateMaintCustomer.contactMethodList.filter(
    item => item.contactMethodType === 'ADDRESS'
  );

  let addressData = {};
  for (let i = 0; i < contactMethods.length; i++) {
    const { address, contactMethodRoleList } = contactMethods[i];

    if (contactMethodRoleList[0].contactMethodRoleId === 'INVOICE_DEST') {
      addressData.addressId = cleanNotSet(address.addressId);
      addressData.addressCountryCode = cleanNotSet(address.countryCode);
      addressData.cityName = cleanNotSet(address.cityName);
      addressData.zipCode = cleanNotSet(address.zipCode);
      addressData.province = cleanNotSet(
        address.provinceCode ? address.provinceCode : address.streetName
      );
      if (address.formattedAddress) {
        const extractHouseNumber =
          CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER &&
          CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER_COUNTRIES?.includes(
            addressData.addressCountryCode
          );
        const addressAndHouseNumber = extractAddressAndHouseNumber(
          cleanNotSet(address.formattedAddress.address1)
        );
        addressData.address1 = extractHouseNumber
          ? addressAndHouseNumber.addressWithoutHouseNumber
          : cleanNotSet(address.formattedAddress.address1);
        addressData.houseNumber = extractHouseNumber
          ? addressAndHouseNumber.houseNumber
          : undefined;
        addressData.address2 = cleanNotSet(address.formattedAddress.address2);
        addressData.address3 = cleanNotSet(address.formattedAddress.address3);
      }
    }
  }
  return addressData;
}

function extractChildInfo(profileData) {
  const { crmCustomerChildList = [] } = profileData;
  crmCustomerChildList.sort((o1, o2) =>
    new Date(o1.birthDate) < new Date(o2.birthDate) ? -1 : 1
  );

  const childData = crmCustomerChildList.reduce((transformed, child, index) => {
    Object.keys(child).forEach(key => {
      const transformedKey = `child-${key}-${index}`;
      transformed[transformedKey] =
        key === 'birthDate'
          ? stringUtils.reverseString(child.birthDate.replace('Z', ''), '-')
          : child[key];
      transformed[transformedKey] = cleanNotSet(transformed[transformedKey]);
    });
    return transformed;
  }, {});
  return childData;
}

export function getDeliveryAddressListData(data) {
  if (!data) {
    return [];
  }
  if (CONFIG.FEATURE.ENABLE_ICM_CMS) {
    const deliveryAddresses = data.privateMaintCustomer.contactMethodList.filter(
      item =>
        item.contactMethodType === 'ADDRESS' &&
        item.contactMethodRoleList &&
        item.contactMethodRoleList[0].contactMethodRoleId === 'DELIVERY_DEST' &&
        item.address
    );
    return deliveryAddresses.reduce((transformed, address) => {
      transformed.push(getDeliveryAddressData(address));
      return transformed;
    }, []);
  } else {
    return data.reduce((transformed, address) => {
      transformed.push(getIRWDeliveryAddressData(address));
      return transformed;
    }, []);
  }
}

export function getIRWDeliveryAddressData(data) {
  const transformed = {
    addressId: cleanNotSet(data.addressId),
    firstName: cleanNotSet(data.firstName),
    lastName: cleanNotSet(data.lastName),
    firstNamePhonetic: cleanNotSet(data.firstNamePhonetic),
    lastNamePhonetic: cleanNotSet(data.lastNamePhonetic),
    mobile:
      cleanNotSet(data.mobile) && cleanNotSet(data.mobile.contactMethodData),
    landline:
      cleanNotSet(data.phone) && cleanNotSet(data.phone.contactMethodData),
    zipCode: cleanNotSet(data.zipCode),
    cityName: cleanNotSet(data.cityName),
    province: cleanNotSet(data.provinceCode),
    addressCountryCode: cleanNotSet(data.countryCode)
  };

  if (data.formattedAddress) {
    const extractHouseNumber = CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER;
    const addressAndHouseNumber = extractAddressAndHouseNumber(
      cleanNotSet(data.formattedAddress.address1)
    );
    transformed.address1 = extractHouseNumber
      ? addressAndHouseNumber.addressWithoutHouseNumber
      : cleanNotSet(data.formattedAddress.address1);
    transformed.houseNumber = extractHouseNumber
      ? addressAndHouseNumber.houseNumber
      : undefined;
    transformed.address2 = cleanNotSet(data.formattedAddress.address2);
    transformed.address3 = cleanNotSet(data.formattedAddress.address3);
  }
  return transformed;
}

export function getDeliveryAddressData(data) {
  const transformed = {
    addressId: cleanNotSet(data.address.addressId),
    firstName: cleanNotSet(data.address.firstName),
    lastName: cleanNotSet(data.address.lastName),
    firstNamePhonetic: cleanNotSet(data.address.firstNamePhonetic),
    lastNamePhonetic: cleanNotSet(data.address.lastNamePhonetic),
    mobile: cleanNotSet(data.address.mobile),
    landline: cleanNotSet(data.address.phone),
    zipCode: cleanNotSet(data.address.zipCode),
    cityName: cleanNotSet(data.address.cityName),
    province: cleanNotSet(data.address.provinceCode),
    addressCountryCode: cleanNotSet(data.address.countryCode)
  };

  if (data.address.formattedAddress) {
    const extractHouseNumber = CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER;
    const addressAndHouseNumber = extractAddressAndHouseNumber(
      cleanNotSet(data.address.formattedAddress.address1)
    );
    transformed.address1 = extractHouseNumber
      ? addressAndHouseNumber.addressWithoutHouseNumber
      : cleanNotSet(data.address.formattedAddress.address1);
    transformed.houseNumber = extractHouseNumber
      ? addressAndHouseNumber.houseNumber
      : undefined;
    transformed.address2 = cleanNotSet(data.address.formattedAddress.address2);
    transformed.address3 = cleanNotSet(data.address.formattedAddress.address3);
  }
  return transformed;
}

export function createDeliveryAddressRequest(data) {
  const extractHouseNumber = CONFIG.FEATURE.EXTRACT_HOUSE_NUMBER;
  return {
    deliveryAddress: {
      firstName: data.firstName,
      lastName: data.lastName,
      firstNamePhonetic: data.firstNamePhonetic,
      lastNamePhonetic: data.lastNamePhonetic,
      mobile: {
        contactMethodType: 'MOBILE',
        contactMethodData: data.mobile
      },
      phone: {
        contactMethodType: 'PHONE',
        contactMethodData: data.landline
      },
      formattedAddress: {
        address1: extractHouseNumber
          ? data.address1.trim().concat(` ${data.houseNumber}`)
          : data.address1,
        address2: data.address2,
        address3: data.address3
      },
      zipCode: data.zipCode,
      cityName: data.cityName,
      provinceCode: data.province,
      countryCode: data.addressCountryCode || CONFIG.COUNTRY.toUpperCase()
    },
    agreeToIKEAPolicy: true
  };
}

export function createDeliveryAddressResponse(data) {
  const result = new Response();
  result.errors = data.reduce((acc, error) => {
    return acc.concat([
      new ResponseError(
        ...(error.errorCode === '10010'
          ? errorMessageToErrorCode(error.errorMessage)
          : reasonCodeToErrorCode(error.errorCode))
      )
    ]);
  }, []);
  return result;
}

export function getIRWResidenceType(residenceType, outdoorType) {
  let irwResidenceType = residenceType;
  if (outdoorType) {
    irwResidenceType = IRW_RESIDENCE_TYPES[`${residenceType}_${outdoorType}`];
  }
  return irwResidenceType;
}

function getOWResidenceType(residenceType) {
  const OwResidenceType = OW_RESIDENCE_TYPES[residenceType];
  return OwResidenceType ? OwResidenceType.residenceType : residenceType;
}

function getOWOutdoorType(residenceType) {
  const OwResidenceType = OW_RESIDENCE_TYPES[residenceType];
  return OwResidenceType ? OwResidenceType.outdoorType : residenceType;
}

function getInterestCodes(interestAreaGroups) {
  let interestCodes = [];
  if (interestAreaGroups && interestAreaGroups.length > 0) {
    if (interestAreaGroups[0].interestAreaGroupType === 'InterestCodes') {
      interestAreaGroups[0].crmInterestAreaList.forEach(interestArea => {
        if (
          interestArea.interestAreaCode !== '60' &&
          interestArea.interestAreaCode !== '90'
        ) {
          interestCodes.push(interestArea.interestAreaCode);
        }
      });
    }
  }
  return interestCodes;
}
