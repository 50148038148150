import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Link, Text, SSRIcon, Toast } from 'Common';

const DashboardVerificationMessage = props => {
  return (
    <Toast id="verificationMessage" closeText={t(props.dismissLink)}>
      <div>
        <Text bold>{t(props.title)}</Text>
        <Text marginTop="xs" marginBottom="xs">
          {t(props.body)}
        </Text>
        {props.verifyLink && props.verifyLinkTo && (
          <Link bold to={props.verifyLinkTo}>
            {t(props.verifyLink)}
          </Link>
        )}
        {props.verifyLink &&
          props.internalLink &&
          document.getElementById(props.internalLink) && (
            <Link
              bold
              onClick={() =>
                document.getElementById(props.internalLink).click()
              }
            >
              {t(props.verifyLink)}
            </Link>
          )}
        {props.verifyLink && props.verifyLinkAction && (
          <Link bold onClick={props.verifyLinkAction}>
            {t(props.verifyLink)}
          </Link>
        )}
        {props.openContactSectionLink && (
          <Link
            bold
            onClick={() => {
              const tabButton = document.getElementById('tab-button-account');
              const formId = CONFIG.FEATURE.ENABLE_CIAM_API
                ? 'personal-form'
                : 'contact-form';
              const editButton = document.getElementById(
                `${props.profileType}-dashboard-account-${formId}-link`
              );
              tabButton.click();
              editButton.click();
              tabButton.scrollIntoView();
            }}
          >
            {t(props.openContactSectionLink)}
          </Link>
        )}
        {props.emailSent && (
          <Text id="emailSent" bold>
            <SSRIcon icon="check" colour="white" />
            {t(props.emailSent)}
          </Text>
        )}
      </div>
    </Toast>
  );
};

DashboardVerificationMessage.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  emailSent: PropTypes.string,
  verifyLink: PropTypes.string,
  verifyLinkTo: PropTypes.string,
  internalLink: PropTypes.string,
  dismissLink: PropTypes.string,
  verifyLinkAction: PropTypes.func,
  openContactSectionLink: PropTypes.string,
  profileType: PropTypes.string
};

export default DashboardVerificationMessage;
