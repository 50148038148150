import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SSRIcon, Divider } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './CountryCodeDropdown.scss';

class CountryCodeDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideClick, false);
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  onOptionClick = option => {
    this.setState({ showDropdown: false });
    this.props.onChange(option);
  };

  onOutsideClick = e => {
    if (!this.myRef.contains(e.target)) {
      this.setState({ showDropdown: false });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const { showDropdown } = this.state;
    const { options, topOptions, value } = this.props;

    const isCountryFixed = options.length === 1;
    const selectedOption = options.find(option => option.value === value);
    const dropdownIcon = showDropdown ? 'chevronUpSmall' : 'chevronDownSmall';

    return (
      <div className={styles['country-code-dropdown']} ref={this.setRef}>
        <div
          className={classNames(
            CONFIG.FEATURE.RIGHT_TO_LEFT
              ? styles['country-code-dropdown-selected-rtl']
              : styles['country-code-dropdown-selected'],
            {
              [styles['country-code-dropdown-selected-fixed']]: isCountryFixed
            }
          )}
          onClick={!isCountryFixed ? this.toggleDropdown : null}
        >
          {`${selectedOption.name} (${selectedOption.code})`}
          {!isCountryFixed && <SSRIcon icon={dropdownIcon} />}
        </div>
        {showDropdown && (
          <div className={styles['country-code-dropdown-options-wrap']}>
            {topOptions.map(option => (
              <CountryOption
                key={`option-${option.name}`}
                option={option}
                onClick={() => this.onOptionClick(option)}
              />
            ))}
            {topOptions.length > 0 && (
              <Divider className={styles['country-code-dropdown-divider']} />
            )}
            {(this.props.sortOptions
              ? options.sort((o1, o2) =>
                  t(`countries.${o1.name}`).localeCompare(
                    t(`countries.${o2.name}`)
                  )
                )
              : options
            ).map(option => (
              <CountryOption
                key={`option-${option.name}`}
                option={option}
                onClick={() => this.onOptionClick(option)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

CountryCodeDropdown.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  // Array of { name: "", value: "" } objects
  options: PropTypes.arrayOf(PropTypes.object),
  topOptions: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  sortOptions: PropTypes.bool
};

CountryCodeDropdown.defaultProps = {
  options: [],
  topOptions: [],
  sortOptions: true
};

const CountryOption = ({ option, onClick }) => {
  const optionText = CONFIG.FEATURE.RIGHT_TO_LEFT
    ? `(${option.code.replace(/(\+)(.*)/, '$2$1')}) ${t(
        `countries.${option.name}`
      )}`
    : `${t(`countries.${option.name}`)} (${option.code})`;
  return (
    <div className={styles['country-code-dropdown-option']} onClick={onClick}>
      {`${optionText}`}
    </div>
  );
};

CountryOption.propTypes = {
  option: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default CountryCodeDropdown;
