import {
  transformFields,
  transformOptions,
  transformValues
} from './GoogleMapsAutocompleteTransformers';

/**
 * Google Maps address data provider that can be used for address suggestions.
 *
 * Sample selection of commonly used Google address fields:
 * "postal_code", "postal_town", "street_number", "route", "political",
 * "locality", "sublocality", "sublocality_level_1", "administrative_area_level_1"
 *
 * Read more about the Maps JavaScript API:
 * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
 * https://developers.google.com/maps/documentation/javascript/places
 *
 */

export default class GoogleMapsAutocompleteClient {
  constructor() {
    this.countryCode = CONFIG.COUNTRY;
    this.languageCode = CONFIG.LANGUAGE;
    this.addressFieldMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_MAP;
    this.addressFieldValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.FIELD_VALUE_MAP;
    this.addressValueMap = CONFIG.ADDRESS_AUTOCOMPLETE.VALUE_MAP;
    this.autocompleteService =
      CONFIG.FEATURE.ENABLE_ADDRESS_AUTOCOMPLETE &&
      new google.maps.places.AutocompleteService();
  }

  /**
   * Calls the API ("AutocompleteService") with the provided query string.
   *
   * "containerId" and "maxResults" parameters are not used by this service,
   * but kept in order keep interface consistent with other address services.
   *
   * @param  {String} searchQuery the search string
   * @param  {String} containerId not used by this service
   * @param  {String} maxResults not used by this service
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  complete(
    searchQuery,
    containerId,
    maxResults,
    successCallback,
    failureCallback
  ) {
    if (!this.sessionToken) {
      this.createSessionToken();
    }
    const request = {
      input: searchQuery,
      types: ['address'],
      componentRestrictions: { country: this.countryCode },
      sessionToken: this.sessionToken
    };

    this.autocompleteService.getPlacePredictions(request, (result, status) => {
      const { PlacesServiceStatus } = google.maps.places;
      if (status === PlacesServiceStatus.OK) {
        successCallback(transformOptions(result));
      } else if (status === PlacesServiceStatus.ZERO_RESULTS) {
        successCallback([]);
      } else {
        failureCallback();
      }
    });
  }

  /**
   * Calls the API ("PlacesService") with the id of an address suggestion
   * provided from a previous complete/"AutocompleteService" call.
   *
   * The "searchQuery" parameter is not used by this service, but kept in
   * order keep interface consistent with other address services.
   *
   * @param  {String} searchQuery not used by this service
   * @param  {String} captureId id of the result we want to capture
   * @param  {func}   successCallback function called after successful request
   * @param  {func}   failureCallback function called after unsuccessful request
   */
  capture(searchQuery, captureId, successCallback, failureCallback) {
    if (!this.placesService) {
      this.placesService = new google.maps.places.PlacesService(
        document.getElementById('map')
      );
    }

    const request = {
      placeId: captureId,
      fields: ['address_component'],
      sessionToken: this.sessionToken
    };

    this.placesService.getDetails(request, (result, status) => {
      const { PlacesServiceStatus } = google.maps.places;
      if (status === PlacesServiceStatus.OK) {
        const data = transformFields(
          result,
          this.addressFieldMap,
          this.addressFieldValueMap
        );
        successCallback(transformValues(data, this.addressValueMap));
      } else if (status === PlacesServiceStatus.ZERO_RESULTS) {
        successCallback({});
      } else {
        failureCallback();
      }
      this.deleteSessionToken();
    });
  }

  createSessionToken = () => {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  };

  deleteSessionToken = () => {
    this.sessionToken = null;
  };
}
