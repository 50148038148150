import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { t } from 'Utils/localization/i18next';
import analytics from 'Utils/analytics/analyticsWrapper';
import { getRedirectURLForDeleteProfile } from 'Redux/profile/profileThunks';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { Text, H2, Link, FlexPanel, Button } from 'Common';
import SSRIcon from '@ingka/ssr-icon';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import styles from './DeleteProfileContainer.scss';

class DeleteProfileCIAMContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showErrorMessage: false };
  }

  onDeleteClick = async () => {
    const redirectURL = await this.props.getRedirectURLForDeleteProfile();
    if (redirectURL) {
      analytics.userProfileFormUpdate({
        status: 'started',
        form: `${this.props.profileType}-dashboard-delete-account`
      });
      window.location = redirectURL;
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  render() {
    return (
      <div className={styles['dashboard-section-read']}>
        <FlexPanel
          spread
          className={styles['dashboard-section-read-delete-title']}
        >
          <H2 tagName="h2" headingSize="m" margin={false}>
            {t('dashboard.profile-details.deleteAccount.deleteTitle')}
          </H2>
          <Button
            className={styles['edit-link']}
            small
            type="tertiary"
            onClick={this.onDeleteClick}
          >
            <SSRIcon
              className={styles['trash-can-icon']}
              colour="black"
              paths={trashCan}
            />
            {t('dashboard.profile-details.deleteAccount.deleteLink')}
          </Button>
          <Link
            bold
            colour="grey-900"
            className={styles['edit-link-mobile']}
            onClick={this.onDeleteClick}
          >
            <SSRIcon
              className={styles['trash-can-icon']}
              colour="black"
              paths={trashCan}
            />
          </Link>
        </FlexPanel>
        <FlexPanel>
          <Text className={styles['delete-profile-text']}>
            {this.props.profileType === PROFILE_TYPES.FAMILY
              ? t('dashboard.profile-details.deleteAccount.deleteText')
              : t('dashboard.settings.deleteBodyRegular')}
          </Text>
        </FlexPanel>
      </div>
    );
  }
}

DeleteProfileCIAMContainer.propTypes = {
  profileType: PropTypes.string,
  getRedirectURLForDeleteProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profileType: state.profile.profileType
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getRedirectURLForDeleteProfile
    },
    dispatch
  );
};

const DeleteProfileWithConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProfileCIAMContainer);

export default withRouter(DeleteProfileWithConnect);
