import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlexPanel, Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './CommunicationPreferences.scss';

const ResidenceTypeReadContainer = props => {
  const ResidenceTypeFunc = param => {
    switch (param) {
      case '1':
        return 'forms.household.residenceType_1';
      case '2':
        return 'forms.household.residenceType_2';
      case '4':
        return 'forms.household.residenceType_4';
      case '5':
        return 'forms.household.residenceType_flat';
      case '22':
        return 'forms.household.residenceType_22';
    }
    return '';
  };
  const HolidayHomeOwnership = param => {
    switch (param) {
      case '0':
        return 'forms.household.holidayHomeOwnership_0';
      case '1':
        return 'forms.household.holidayHomeOwnership_1';
    }
    return '';
  };

  const HomeOwnership = param => {
    switch (param) {
      case '0':
        return 'forms.household.homeOwnership_0';
      case '1':
        return 'forms.household.homeOwnership_1';
    }
    return '';
  };

  const LivingSpace = param => {
    switch (param) {
      case '39':
        return 'forms.household.livingSpace_40';
      case '79':
        return 'forms.household.livingSpace_80';
      case '119':
        return 'forms.household.livingSpace_120';
      case '159':
        return 'forms.household.livingSpace_160';
      case '300':
        return 'forms.household.livingSpace_300';
      case '40':
        return 'forms.household.livingSpace_40';
      case '80':
        return 'forms.household.livingSpace_80';
      case '120':
        return 'forms.household.livingSpace_120';
      case '160':
        return 'forms.household.livingSpace_160';
    }
    return '';
  };

  const OutdoorType = param => {
    switch (param) {
      case 'GARDEN':
        return 'forms.household.residenceType_garden';
      case 'BALCONY':
        return 'forms.household.residenceType_balcony';
      case 'TERRACE':
        return 'forms.household.residenceType_terrace';
      case 'NO_OUTDOOR':
        return 'forms.household.residenceType_noOutdoor';
    }
    return '';
  };

  return (
    <div className={styles['residence-type-container']}>
      {(props.profile.residenceType && props.profile.residenceType !== '0') ||
      (props.profile.noOfRooms && props.profile.noOfRooms !== '0') ||
      (props.profile.outdoorType && props.profile.outdoorType !== '0') ||
      props.profile.homeOwnership ||
      props.profile.holidayHomeOwnership ||
      (props.profile.livingSpace && props.profile.livingSpace !== '0') ? (
        <Text marginBottom="s" className={styles['common-specifier-title']}>
          {t(`dashboard.communications.home-interest-section.home`)}
        </Text>
      ) : (
        ''
      )}
      {!props.hideResidenceType &&
        props.profile.residenceType !== '0' &&
        props.profile.residenceType && (
          <>
            <div className={styles['sub-category-header']}>
              <p>
                {t(
                  `dashboard.communications.home-interest-section.residenceType`
                )}
              </p>
            </div>
            <FlexPanel className={styles['residence-type-container-value']}>
              <Text className={styles['sub-category-value']}>
                {t(`${ResidenceTypeFunc(props.profile.residenceType)}`)}
              </Text>
            </FlexPanel>
          </>
        )}
      {!props.hideNoOfRooms &&
        props.profile.noOfRooms &&
        props.profile.noOfRooms !== '0' && (
          <>
            <div className={styles['sub-category-header']}>
              <p>{t(`forms.household.noOfRooms`)}</p>
            </div>
            <FlexPanel className={styles['residence-type-container-value']}>
              <Text className={styles['sub-category-value']}>
                {props.profile.noOfRooms >= 0
                  ? props.profile.noOfRooms
                  : t(`forms.household.empty`)}
              </Text>
            </FlexPanel>
          </>
        )}

      {!props.hideOutdoorType &&
        props.profile.outdoorType &&
        props.profile.outdoorType !== '0' && (
          <>
            <div className={styles['sub-category-header']}>
              <p>{t(`forms.outdoorType`)}</p>
            </div>
            <FlexPanel className={styles['residence-type-container-value']}>
              <Text className={styles['sub-category-value']}>
                {t(`${OutdoorType(props.profile.outdoorType)}`)}
              </Text>
            </FlexPanel>
          </>
        )}

      {!props.hideHomeOwnership && props.profile.homeOwnership && (
        <>
          <div className={styles['sub-category-header']}>
            <p>{t(`forms.household.homeOwnership`)}</p>
          </div>
          <FlexPanel className={styles['residence-type-container-value']}>
            <Text className={styles['sub-category-value']}>
              {t(`${HomeOwnership(props.profile.homeOwnership)}`)}
            </Text>
          </FlexPanel>
        </>
      )}
      {!props.hideHolidayHomeOwnership && props.profile.holidayHomeOwnership && (
        <>
          <div className={styles['sub-category-header']}>
            <p>{t(`forms.household.holidayHomeOwnership`)}</p>
          </div>
          <FlexPanel className={styles['residence-type-container-value']}>
            <Text className={styles['sub-category-value']}>
              {t(`${HolidayHomeOwnership(props.profile.holidayHomeOwnership)}`)}
            </Text>
          </FlexPanel>
        </>
      )}
      {!props.hideLivingSpace &&
        props.profile.livingSpace &&
        props.profile.livingSpace !== '0' && (
          <div>
            <div className={styles['sub-category-header']}>
              <p>{t(`forms.livingSpace`)}</p>
            </div>
            <FlexPanel className={styles['residence-type-container-value']}>
              <Text className={styles['sub-category-value']}>
                {t(`${LivingSpace(props.profile.livingSpace)}`)}
              </Text>
            </FlexPanel>
          </div>
        )}
    </div>
  );
};

ResidenceTypeReadContainer.propTypes = {
  profile: PropTypes.object,
  residenceType: PropTypes.string,
  noOfRooms: PropTypes.string,
  holidayHomeOwnership: PropTypes.string,
  livingSpace: PropTypes.string,
  hideHolidayHomeOwnership: PropTypes.bool,
  hideLivingSpace: PropTypes.bool,
  hideResidenceType: PropTypes.bool,
  hideNoOfRooms: PropTypes.bool,
  hideHomeOwnership: PropTypes.bool,
  hideOutdoorType: PropTypes.bool
};

ResidenceTypeReadContainer.defaultProps = {
  hideHolidayHomeOwnership: false,
  hideOutdoorType: false,
  hideLivingSpace: false,
  hideResidenceType: false,
  hideNoOfRooms: false,
  hideHomeOwnership: false,
  name: ''
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(ResidenceTypeReadContainer);
