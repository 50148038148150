import React from 'react';
import PropTypes from 'prop-types';
import InputFieldHandler from 'BaseForm/InputFieldHandler';

const ZipCodeInput = props => (
  <InputFieldHandler
    formId={props.formId}
    name={props.name}
    value={props.value}
    label={props.label}
    placeholder={props.placeholder}
    maxLength={20}
    disabled={props.disabled}
    showOptional={props.showOptional}
    onChange={props.onChange}
    onBlur={props.onBlur}
    uncontrolledProps={props.uncontrolled}
  />
);

ZipCodeInput.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showOptional: PropTypes.bool,
  uncontrolled: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

ZipCodeInput.defaultProps = {
  uncontrolled: false
};

export default ZipCodeInput;
