import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { t } from 'Utils/localization/i18next';
import { getQueryStringValue } from 'Utils/url/urlUtils';
import { setPageTitle } from 'Utils/document/documentUtils';
import { hasValidSession, login } from 'Redux/auth/authThunks';
import Routes from 'Routes/profileRoutes';

class SignupUpgradeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.upgradeFormId = 'regular-upgrade-form';
    this.state = {
      redirectToDashboard: false
    };
    // "from" is a query string parameter that can be
    // used to redirect the user back after the upgrade
    // The fragment identifier "hash" is required to support URLs with "hash" like:
    // https://events.ikea.com/gb/en/events/#/store/croydon/
    this.from = getQueryStringValue(
      `${this.props.location.search}${this.props.location.hash}`,
      Routes.QUERY_PARAMS.FROM
    );
  }

  componentDidMount() {
    setPageTitle(t(['metaData.upgradePageTitle', 'metaData.defaultPageTitle']));
    this.init();
  }

  init = async () => {
    const isLoggedIn = await this.props.hasValidSession();

    if (!isLoggedIn) {
      await this.props.login(
        null,
        null,
        null,
        null,
        `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.UPGRADE}`
      );
    } else {
      this.setState({ redirectToDashboard: true });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      this.state.redirectToDashboard && (
        <Redirect
          to={{
            pathname: Routes.DASHBOARD,
            state: { showUpgrade: true, redirectToAfterUpgrade: this.from }
          }}
        />
      )
    );
  }
}

SignupUpgradeContainer.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  hasValidSession: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      hasValidSession,
      login
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(SignupUpgradeContainer);
