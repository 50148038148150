// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__quantity-field-label{display:inline-block;padding:1rem .5rem .5rem 0}.profile__quantity-field-label svg{margin-left:.3rem;margin-right:-0.3rem;cursor:pointer}.profile__quantity-field select{appearance:none !important;-webkit-appearance:none !important;-moz-appearance:none !important}", "",{"version":3,"sources":["webpack://./src/components/_form_base/QuantityField.scss"],"names":[],"mappings":"AAGE,+BACE,oBAAA,CACA,0BAAA,CAEA,mCACE,iBAAA,CACA,oBAAA,CACA,cAAA,CAGJ,gCACE,0BAAA,CACA,kCAAA,CACA,+BAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.quantity-field {\n  &-label {\n    display: inline-block;\n    padding: $space-100 $space-50 $space-50 0; \n\n    svg {\n      margin-left: 0.3rem;\n      margin-right: -0.3rem;\n      cursor: pointer;\n    }\n  }\n  select {\n    appearance: none !important;\n    -webkit-appearance: none !important;\n    -moz-appearance: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quantity-field-label": "profile__quantity-field-label",
	"quantity-field": "profile__quantity-field"
};
export default ___CSS_LOADER_EXPORT___;
