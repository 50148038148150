import { getNumOfActiveOrders } from './transformers';
import { readAuthTokenCookie } from 'Utils/auth/authUtils';

export default class OrderHistoryApiClient {
  constructor() {
    const { COUNTRY, LANGUAGE, API } = CONFIG;
    this.apiHost = API.ORDER.HOST;
    this.headers = {
      'accept-language': `${LANGUAGE}-${COUNTRY}`
    };
  }

  getNumOfActiveOrders() {
    const authTokenCookie = readAuthTokenCookie();
    return fetch(`${this.apiHost}/orders`, {
      method: 'GET',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },
      credentials: 'include'
    })
      .then(result => {
        if (!result.ok) {
          const err = new Error(
            'HTTP error with status code: ' + result.status
          );
          err.httpStatus = result.status;
          throw err;
        }
        return result.json();
      })
      .then(json => {
        if (json.list) {
          return getNumOfActiveOrders(json.list);
        }
        const err = new Error('Unexpected order history response');
        err.httpStatus = 200;
        throw err;
      })
      .catch(() => {
        throw Error();
      });
  }
}
