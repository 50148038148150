import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'Common';
import styles from './LinkBack.scss';

export const LinkBack = props => (
  <Link
    to={props.to}
    url={props.url}
    className={styles['link-back']}
    center={props.center}
  >
    {props.children}
  </Link>
);

LinkBack.propTypes = {
  to: PropTypes.string,
  url: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};
