import DashboardFieldReadContainer from 'Components/Dashboard/DashboardFieldReadContainer';
import DashboardFieldArrayReadContainer from 'Components/Dashboard/DashboardFieldArrayReadContainer';
import DashboardFieldReadWithTranslatedTitleContainer from 'Components/Dashboard/DashboardFieldReadWithTranslatedTitleContainer';
import DashboardSectionReadContainer from 'Components/Dashboard/DashboardSectionReadContainer';
import DashboardPreferencesSectionReadContainer from 'Components/Dashboard/DashboardPreferencesSectionReadContainer';
import DashboardEmailSectionReadContainer from 'Components/Dashboard/DashboardEmailSectionReadContainer';
import DashboardMobileSectionReadContainer from 'Components/Dashboard/DashboardMobileSectionReadContainer';
import DashboardPasswordSectionReadContainer from 'Components/Dashboard/DashboardPasswordSectionReadContainer';
import DashboardChecklistReadContainer from 'Components/Dashboard/DashboardChecklistReadContainer';
import DashboardTranslatedFieldReadContainer from 'Components/Dashboard/DashboardTranslatedFieldReadContainer';
import DashboardBooleanFieldReadContainer from 'Components/Dashboard/DashboardBooleanFieldReadContainer';
import DashboardPrimaryAddressFieldReadContainer from 'Components/Dashboard/Address/DashboardPrimaryAddressFieldReadContainer';
import DashboardDeliveryAddressFieldReadContainer from 'Components/Dashboard/Address/DashboardDeliveryAddressFieldReadContainer';
import DashboardPrimaryAddressSectionReadContainer from 'Components/Dashboard/Address/DashboardPrimaryAddressSectionReadContainer';
import DashboardDeliveryAddressSectionReadContainer from 'Components/Dashboard/Address/DashboardDeliveryAddressSectionReadContainer';
import DashboardMobileFieldReadContainer from 'Components/Dashboard/DashboardMobileFieldReadContainer';
import DashboardEmailFieldReadContainer from 'Components/Dashboard/DashboardEmailFieldReadContainer';
import DashboardCatalogExternalRead from 'Components/Dashboard/DashboardCatalogExternalRead';
import DashboardFieldWithTranslationReadContainer from 'Components/Dashboard/DashboardFieldWithTranslationReadContainer';
import DashboardFieldsWithVisibilityConditionsContainer from 'Components/Dashboard/DashboardFieldsWithVisibilityConditionsContainer';
import DashboardFieldsWithCommonPrefixReadContainer from 'Components/Dashboard/DashboardFieldsWithCommonPrefixReadContainer';
import DashboardCheckboxReadContainer from 'Components/Dashboard/DashboardCheckboxReadContainer';
import DeleteAccountContainer from 'Components/Dashboard/LoginSecurity/DeleteAccountContainer';
import EmailSectionReadContainer from 'Components/Dashboard/ProfileDetails/EmailSectionReadContainer';
import PasswordSectionReadContainer from 'Components/Dashboard/LoginSecurity/PasswordSectionReadContainer';
import MobileSectionReadContainer from 'Components/Dashboard/ProfileDetails/MobileSectionReadContainer';
import PersonalInformationSectionReadContainer from 'Components/Dashboard/ProfileDetails/PersonalInformationSectionReadContainer';
import PersonalInformationFieldReadContainer from 'Components/Dashboard/ProfileDetails/PersonalInformationFieldReadContainer';
import TaxInformationFieldReadContainer from 'Components/Dashboard/ProfileDetails/TaxInformationFieldReadContainer';
import SectionReadContainer from 'Components/Dashboard/ProfileDetails/SectionReadContainer';
import MobileFieldReadContainer from 'Components/Dashboard/ProfileDetails/MobileFieldReadContainer';
import EmailFieldReadContainer from 'Components/Dashboard/ProfileDetails/EmailFieldReadContainer';
import DeliveryAddressFieldReadContainer from 'Components/Dashboard/ShippingBillingAddresses/DeliveryAddressFieldReadContainer';
import DeliveryAddressSectionReadContainer from 'Components/Dashboard/ShippingBillingAddresses/DeliveryAddressSectionReadContainer';
import PrimaryAddressFieldReadContainer from 'Components/Dashboard/ShippingBillingAddresses/PrimaryAddressFieldReadContainer';
import PrimaryAddressSectionReadContainer from 'Components/Dashboard/ShippingBillingAddresses/PrimaryAddressSectionReadContainer';
import PreferredStoreSectionReadContainer from 'Components/Dashboard/CommunicationPreferences/PreferredStoreSectionReadContainer';
import DeliveryAddressFieldReadContainerAddButton from 'Components/Dashboard/ShippingBillingAddresses/DeliveryAddressFieldReadContainerAddButton';
import DeliveryAddressSectionReadContainerButton from 'Components/Dashboard/ShippingBillingAddresses/DeliveryAddressSectionReadContainerButton';
import NewsLetterSubscriptionSectionReadContainer from 'Components/Dashboard/CommunicationPreferences/NewsletterSubscriptionSectionReadContainer';
import HomeInterestSectionReadContainer from 'Components/Dashboard/CommunicationPreferences/HomeInterestSectionReadContainer';
import NewsletterFieldReadContainer from 'Components/Dashboard/CommunicationPreferences/NewsletterFieldReadContainer';
import UnsubscribeSectionContainer from 'Components/Dashboard/CommunicationPreferences/UnsubscribeSectionContainer';
import HouseHoldInformationReadContainer from 'Components/Dashboard/CommunicationPreferences/HouseHoldInformationReadContainer';
import HomeInterestFieldReadContainer from '../../Dashboard/CommunicationPreferences/HomeInterestFieldReadContainer';
import LandlineSectionReadContainer from '../../Dashboard/ProfileDetails/LandlineSectionReadContainer';
import ResidenceTypeReadContainer from '../../Dashboard/CommunicationPreferences/ResidenceTypeReadContainer';
import PaidMediaConsentReadContainer from '../../Dashboard/CommunicationPreferences/PaidMediaConsentReadContainer';
import AttractNewCustomerSectionReadContainer from '../../Dashboard/CommunicationPreferences/AttractNewCustomerSectionReadContainer';
import AttractNewCustomerFieldReadContainer from '../../Dashboard/CommunicationPreferences/AttractNewCustomerFieldReadContainer';

export const SectionComponents = {
  DashboardSectionRead: DashboardSectionReadContainer,
  DashboardPreferencesSectionRead: DashboardPreferencesSectionReadContainer,
  DashboardPrimaryAddressSectionRead: DashboardPrimaryAddressSectionReadContainer,
  DashboardDeliveryAddressSectionRead: DashboardDeliveryAddressSectionReadContainer,
  DashboardEmailSectionRead: DashboardEmailSectionReadContainer,
  DashboardMobileSectionRead: DashboardMobileSectionReadContainer,
  DashboardPasswordSectionRead: DashboardPasswordSectionReadContainer,
  DeleteAccount: DeleteAccountContainer,
  EmailSectionRead: EmailSectionReadContainer,
  PersonalInformationSectionRead: PersonalInformationSectionReadContainer,
  PasswordSectionRead: PasswordSectionReadContainer,
  MobileSectionRead: MobileSectionReadContainer,
  SectionRead: SectionReadContainer,
  DeliveryAddressSectionRead: DeliveryAddressSectionReadContainer,
  DeliveryAddressSectionReadContainer: DeliveryAddressSectionReadContainerButton,
  PrimaryAddressSectionRead: PrimaryAddressSectionReadContainer,
  PreferredStoreSectionRead: PreferredStoreSectionReadContainer,
  NewsLetterSubscriptionSectionRead: NewsLetterSubscriptionSectionReadContainer,
  HomeInterestSectionRead: HomeInterestSectionReadContainer,
  NewsletterFieldRead: NewsletterFieldReadContainer,
  UnsubscribeSection: UnsubscribeSectionContainer,
  LandlineSectionRead: LandlineSectionReadContainer,
  PaidMediaConsentRead: PaidMediaConsentReadContainer,
  AttractNewCustomerSectionRead: AttractNewCustomerSectionReadContainer,
  AttractNewCustomerFieldRead: AttractNewCustomerFieldReadContainer
};

export const FieldComponents = {
  DashboardFieldRead: DashboardFieldReadContainer,
  DashboardFieldArrayRead: DashboardFieldArrayReadContainer,
  DashboardFieldReadWithTranslatedTitle: DashboardFieldReadWithTranslatedTitleContainer,
  DashboardChecklistRead: DashboardChecklistReadContainer,
  DashboardTranslatedFieldRead: DashboardTranslatedFieldReadContainer,
  DashboardBooleanFieldRead: DashboardBooleanFieldReadContainer,
  DashboardPrimaryAddressFieldRead: DashboardPrimaryAddressFieldReadContainer,
  DashboardDeliveryAddressFieldRead: DashboardDeliveryAddressFieldReadContainer,
  DashboardMobileFieldRead: DashboardMobileFieldReadContainer,
  DashboardEmailFieldRead: DashboardEmailFieldReadContainer,
  DashboardCatalogExternalRead,
  PersonalInformationFieldRead: PersonalInformationFieldReadContainer,
  TaxInformationFieldRead: TaxInformationFieldReadContainer,
  DashboardFieldWithTranslationRead: DashboardFieldWithTranslationReadContainer,
  HouseHoldInformationRead: HouseHoldInformationReadContainer,
  HomeInterestFieldRead: HomeInterestFieldReadContainer,
  DashboardFieldsWithVisibilityConditions: DashboardFieldsWithVisibilityConditionsContainer,
  DashboardFieldsWithCommonPrefixRead: DashboardFieldsWithCommonPrefixReadContainer,
  DashboardCheckboxRead: DashboardCheckboxReadContainer,
  MobileFieldRead: MobileFieldReadContainer,
  EmailFieldRead: EmailFieldReadContainer,
  DeliveryAddressFieldRead: DeliveryAddressFieldReadContainer,
  DeliveryAddressFieldReadContainerAdd: DeliveryAddressFieldReadContainerAddButton,
  PrimaryAddressFieldRead: PrimaryAddressFieldReadContainer,
  ResidenceTypeRead: ResidenceTypeReadContainer
};

export const Components = {
  ...SectionComponents,
  ...FieldComponents
};
