import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, H2, Link, FlexPanel, Toast, Button } from 'Common';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import { updateHouseHoldInfo } from 'Redux/profile/profileThunks';
import { clearFormAction } from 'Redux/forms/formsActions';
import { t } from 'Utils/localization/i18next';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { isEditModeEnabled } from 'Utils/url/urlUtils';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import SSRIcon from '@ingka/ssr-icon';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './CommunicationPreferences.scss';

class HomeInterestSectionReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: isEditModeEnabled(window.location.search, 'home') || false,
      showSuccessMessage: false
    };

    this.myRef = React.createRef();
  }

  onEdit = () => {
    this.setState({ isEditing: true, showSuccessMessage: false });
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onSubmit = async data => {
    const updateAction = this.props.updateHouseHoldInfo;
    const newProfile = { ...this.props.profile, ...data };
    const success = await updateAction(
      trimAllObjPropertiesSkipPasswords(newProfile),
      this.props.editForm,
      true
    );

    if (success) {
      this.setState({
        isEditing: false,
        showSuccessMessage: true
      });
    }
    this.scrollToCenter();
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.hideComponent && (
          <div ref={this.setRef} className={styles['section-read']}>
            {this.state.showSuccessMessage && (
              <Toast autoCloseMs={5000} closeText={t('common.dismiss')}>
                <Text bold>
                  {t('dashboard.confirmation.communicationSectionUpdateTitle')}
                </Text>
                <Text>
                  {t('dashboard.confirmation.communicationSectionUpdateText')}
                </Text>
              </Toast>
            )}
            <FlexPanel spread className={styles['section-read-title']}>
              <H2 tagName="h2" headingSize="m" margin={false}>
                {t(`${this.props.title}`)}
              </H2>
              {this.props.editForm && (
                <Button
                  className={styles['edit-link']}
                  small
                  type="tertiary"
                  id={`${this.props.editForm}-link`}
                  onClick={this.onEdit}
                >
                  <SSRIcon colour="black" paths={pencil} />
                  {t('common.edit')}
                </Button>
              )}
              {this.props.editForm && (
                <Link
                  bold
                  colour="grey-900"
                  id={`${this.props.editForm}-link`}
                  className={styles['edit-link-mobile']}
                  onClick={this.onEdit}
                >
                  <SSRIcon colour="black" paths={pencil} />
                </Link>
              )}
            </FlexPanel>
            <Text
              className={styles['communication-preferences-description']}
              marginBottom="s"
            >
              {t(`${this.props.description}`)}
            </Text>
            {this.state.isEditing && (
              <DynamicFormContainerModal
                formId={this.props.editForm}
                prefillValues={this.props.profile}
                loadingSubmit={this.props.isLoading}
                textSubmit={t('common.save')}
                textCancel={t('common.cancel')}
                onCancel={this.onClose}
                onSubmit={this.onSubmit}
                title={t(this.props.title)}
              />
            )}
            {this.props.children}
          </div>
        )}
      </React.Fragment>
    );
  }
}

HomeInterestSectionReadContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  editForm: PropTypes.string,
  isLastElement: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  hideComponent: PropTypes.bool,
  // From Redux/Thunks
  profile: PropTypes.object,
  isLoading: PropTypes.bool,
  updateHouseHoldInfo: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired
};

HomeInterestSectionReadContainer.defaultProps = {
  hideComponent: false
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateHouseHoldInfo,
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeInterestSectionReadContainer);
