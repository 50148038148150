import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Text, H2, Link, FlexPanel, Toast, Button } from 'Common';
import DynamicFormContainerModal from 'ExtendedForm/DynamicForm/DynamicFormContainerModal';
import {
  updateProfile,
  updateUserLogin,
  updateProfileSettings,
  updateCustomerPersonalInfo
} from 'Redux/profile/profileThunks';
import { setNewPassword } from 'Redux/auth/authThunks';
import { clearFormAction } from 'Redux/forms/formsActions';
import { t } from 'Utils/localization/i18next';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import { isEditModeEnabled } from 'Utils/url/urlUtils';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import SSRIcon from '@ingka/ssr-icon';
import pencil from '@ingka/ssr-icon/paths/pencil';
import styles from './SectionReadContainer.scss';

class PersonalInformationSectionReadContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: isEditModeEnabled(window.location.search, 'personal') || false,
      showSuccessMessage: false,
      showMemberPanelMessage: false,
      newMemberPanelState: false
    };

    this.myRef = React.createRef();
    this.updateActions = {
      personal: this.props.updateCustomerPersonalInfo,
      contact: this.props.updateUserLogin,
      credentials: this.props.setNewPassword,
      communication: this.props.updateProfileSettings
    };
    this.updateMessages = {
      personal: {
        title: 'dashboard.confirmation.personalSectionUpdateTitle',
        text: 'dashboard.confirmation.personalSectionUpdateText'
      },
      contact: {
        title: 'dashboard.confirmation.contactSectionUpdateTitle',
        text: 'dashboard.confirmation.contactSectionUpdateText'
      },
      credentials: {
        title: 'dashboard.confirmation.passwordSectionUpdateTitle',
        text: 'dashboard.confirmation.passwordSectionUpdateText'
      },
      communication: {
        title: 'dashboard.confirmation.communicationSectionUpdateTitle',
        text: 'dashboard.confirmation.communicationSectionUpdateText'
      },
      communicationMemberPanelOptIn: {
        title: 'dashboard.confirmation.communicationMemberPanelOptInTitle',
        text: 'dashboard.confirmation.communicationMemberPanelOptInText'
      },
      communicationMemberPanelOptOut: {
        title: 'dashboard.confirmation.communicationMemberPanelOptOutTitle',
        text: 'dashboard.confirmation.communicationMemberPanelOptOutText'
      }
    };
  }

  onEdit = () => {
    this.setState({ isEditing: true, showSuccessMessage: false });
  };

  onClose = () => {
    this.setState({ isEditing: false }, () => {
      this.props.clearFormAction(this.props.editForm);
      this.scrollToCenter();
    });
  };

  onSubmit = async data => {
    const updateAction =
      this.updateActions[this.props.type] || this.props.updateProfile;

    const memberPanelOldState = this.props.profile.participateInMemberPanel;

    const newProfile = { ...this.props.profile, ...data };
    const success = await updateAction(
      trimAllObjPropertiesSkipPasswords(newProfile),
      this.props.editForm,
      null
    );

    const memberPanelNewState = newProfile.participateInMemberPanel;
    const memberPanelChanged = memberPanelOldState !== memberPanelNewState;

    if (success) {
      this.setState({
        isEditing: false,
        showSuccessMessage: true,
        showMemberPanelMessage: memberPanelChanged,
        newMemberPanelState: memberPanelNewState
      });
    }
    this.scrollToCenter();
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const OPT_IN = 'communicationMemberPanelOptIn';
    const OPT_OUT = 'communicationMemberPanelOptOut';

    return (
      <>
        <div ref={this.setRef} className={styles['dashboard-section-read']}>
          {this.state.showSuccessMessage &&
            !this.state.showMemberPanelMessage &&
            this.updateMessages[this.props.type] && (
              <Toast autoCloseMs={5000} closeText={t('common.dismiss')}>
                <Text bold>
                  {t(this.updateMessages[this.props.type].title)}
                </Text>
                <Text>{t(this.updateMessages[this.props.type].text)}</Text>
              </Toast>
            )}
          {this.state.showSuccessMessage &&
            this.state.showMemberPanelMessage &&
            this.updateMessages[this.props.type] && (
              <Toast autoCloseMs={20000} closeText={t('common.dismiss')}>
                <Text bold>
                  {!this.state.newMemberPanelState
                    ? t(this.updateMessages[OPT_OUT].title)
                    : t(this.updateMessages[OPT_IN].title)}
                </Text>
                <Text>
                  {!this.state.newMemberPanelState
                    ? t(this.updateMessages[OPT_OUT].text)
                    : t(this.updateMessages[OPT_IN].text)}
                </Text>
              </Toast>
            )}
          <FlexPanel spread className={styles['dashboard-section-read-title']}>
            {this.props.title && (
              <H2 tagName="h2" headingSize="m" margin={false}>
                {t(this.props.title)}
              </H2>
            )}
            {this.props.editForm && (
              <Button
                className={styles['edit-link']}
                small
                type="tertiary"
                id={`${this.props.editForm}-link`}
                onClick={this.onEdit}
              >
                <SSRIcon colour="black" paths={pencil} />
                {t('common.edit')}
              </Button>
            )}
            {this.props.editForm && (
              <Link
                bold
                colour="grey-900"
                id={`${this.props.editForm}-link`}
                className={styles['edit-link-mobile']}
                onClick={this.onEdit}
              >
                <SSRIcon colour="black" paths={pencil} />
              </Link>
            )}
          </FlexPanel>
          {this.state.isEditing && (
            <DynamicFormContainerModal
              formId={this.props.editForm}
              prefillValues={this.props.profile}
              loadingSubmit={this.props.isLoading}
              textSubmit={t('common.save')}
              textCancel={t('common.cancel')}
              onCancel={this.onClose}
              onSubmit={this.onSubmit}
              title={this.props.title}
            />
          )}
          {this.props.children}
        </div>
      </>
    );
  }
}

PersonalInformationSectionReadContainer.propTypes = {
  title: PropTypes.string,
  editForm: PropTypes.string,
  type: PropTypes.oneOf([
    'personal',
    'contact',
    'credentials',
    'communication'
  ]),
  isLastElement: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  // From Redux/Thunks
  profile: PropTypes.object,
  userLogin: PropTypes.string,
  isLoading: PropTypes.bool,
  updateProfile: PropTypes.func.isRequired,
  updateCustomerPersonalInfo: PropTypes.func.isRequired,
  updateUserLogin: PropTypes.func.isRequired,
  updateProfileSettings: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    userLogin: state.auth.userLogin,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE]
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateProfile,
      updateUserLogin,
      updateProfileSettings,
      updateCustomerPersonalInfo,
      setNewPassword,
      clearFormAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformationSectionReadContainer);
