export default {
  notNumeric: /[^\d]/g,
  notAlphanumeric: /[^\da-zA-Z]/g,
  notAlphanumericAndSpace: /[^\da-zA-Z ]/g,
  date: {
    'DD-MM-YYYY': /^(\d{2})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2,4})$/,
    'DD.MM.YYYY': /^(\d{2})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2,4})$/,
    'MM-DD-YYYY': /^(\d{2})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2,4})$/,
    'YYYY-MM-DD': /^(\d{4})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2})$/,
    'YYYY.MM.DD': /^(\d{4})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2})$/,
    'YYYY.MM.DD.': /^(\d{4})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}$/,
    'MM-YYYY': /^(\d{0})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2,4})$/,
    'MM.YYYY': /^(\d{0})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}(\d{2,4})$/,
    'YYYY-MM': /^(\d{0})[/\-.\s]{0,1}(\d{4})[/\-.\s]{0,1}(\d{2})$/,
    'YYYY.MM.': /^(\d{0})[/\-.\s]{0,1}(\d{4})[/\-.\s]{0,1}(\d{2})[/\-.\s]{0,1}$/
  }
};
