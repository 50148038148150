import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IkeaHyperLink from '@ingka/hyperlink';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Link.scss';
import '@ingka/hyperlink/style.scss';

export const Link = props => {
  const className = classNames(`${CONFIG.APP.STYLES_PREFIX}link`, {
    [styles['link-underline']]: props.underline,
    [styles['link-bold']]: props.bold,
    [styles[`link-colour-${props.colour}`]]: props.colour !== undefined,
    [props.className]: props.className
  });

  // If the to prop is provided we assume that the
  // user wants to use react router Link component
  if (props.to) {
    return (
      <RouterLink
        id={props.id}
        className={className}
        style={props.style}
        to={props.to}
      >
        {props.children}
      </RouterLink>
    );
  }
  return (
    <IkeaHyperLink
      id={props.id}
      url={props.url}
      target={props.target}
      className={className}
      prefix={CONFIG.APP.STYLES_PREFIX}
      style={props.style}
      tabIndex={props.tabIndex}
      rel="noopener noreferrer"
      onBlur={e => {
        if (props.onBlur) {
          e.preventDefault();
          props.onBlur(e);
        }
      }}
      onClick={e => {
        if (props.onClick) {
          e.preventDefault();
          props.onClick(e);
        }
      }}
      onKeyDown={e => {
        if (props.onKeyDown) {
          e.preventDefault();
          props.onKeyDown(e);
        }
      }}
    >
      {props.children}
    </IkeaHyperLink>
  );
};

Link.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  underline: PropTypes.bool,
  bold: PropTypes.bool,
  colour: PropTypes.oneOf([
    'grey-500',
    'grey-700',
    'grey-900',
    'brand-blue',
    'feedback-error'
  ])
};
