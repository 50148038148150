import { readAuthTokenCookie } from 'Utils/auth/authUtils';
export default class PaymentsApiClientMock {
  constructor() {
    const { COUNTRY, API } = CONFIG;
    this.apiUrl = `${API.PAYMENT.HOST}/${COUNTRY}`;
    this.authTokenCookie = readAuthTokenCookie();
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authTokenCookie}`
    };

    this.payments = [
      {
        id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
        isPreferred: true,
        psp: 'ACI',
        paymentBrandName: 'MASTERCARD',
        optionCategory: 'CREDITCARD',
        paymentCardDetails: {
          last4Digits: '0010',
          expiryMonth: '12',
          expiryYear: '2018'
        }
      },
      {
        id: 'd290f1ee-6c54-4b01-90e6-d701748f0852',
        psp: 'ACI',
        paymentBrandName: 'VISA',
        optionCategory: 'CREDITCARD',
        paymentCardDetails: {
          last4Digits: '0010',
          expiryMonth: '12',
          expiryYear: '2021'
        }
      },
      {
        id: 'd290f1ee-6c54-4b01-90e6-d701748f0853',
        psp: 'ACI',
        paymentBrandName: 'MASTERCARD',
        optionCategory: 'CREDITCARD',
        paymentCardDetails: {
          last4Digits: '0010',
          expiryMonth: '12',
          expiryYear: '2022'
        }
      },
      {
        id: 'd290f1ee-6c54-4b01-90e6-d701748f0854',
        psp: 'ACI',
        paymentBrandName: 'VISA',
        optionCategory: 'CREDITCARD',
        paymentCardDetails: {
          last4Digits: '0010',
          expiryMonth: '12',
          expiryYear: '2026'
        }
      }
    ];
  }

  getPayments = userId => {
    console.log('Payments test API getPayments called with', userId);
    return new Promise((resolve, reject) => {
      resolve(this.payments);
    });
  };

  removePayment = paymentId => {
    console.log('Payments test API removePayment called with', paymentId);
    return new Promise((resolve, reject) => {
      this.payments = this.payments.filter(payment => payment.id !== paymentId);
      resolve(this.payments);
    });
  };

  setAsDefaultPayment = paymentId => {
    console.log('Payments test API setAsDefaultPayment called with', paymentId);
    return new Promise((resolve, reject) => {
      resolve(this.payments);
    });
  };
}
