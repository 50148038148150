import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';
import styles from './SectionReadContainer.scss';

const TaxInformationFieldReadContainer = props => {
  return (
    <FlexPanel>
      <Text>
        <div className={styles['tax-information-container']}>
          <div>
            <Text
              bold
              marginBottom="s"
              className={styles['common-specifier-title']}
            >
              {t(`dashboard.profile-details.tax-information-section.title`)}
            </Text>
            <div key={1} className={styles['horizontal-container-stacked']}>
              <div className={styles['common-specifier']}>
                {!props.profile.personalId &&
                !props.profile.documentId &&
                CONFIG.FEATURE.ENABLE_PERSONAL_ID_TYPE_LABEL
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalIdType`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {!props.profile.personalId &&
                !props.profile.documentId &&
                CONFIG.FEATURE.ENABLE_PERSONAL_ID_TYPE_LABEL
                  ? `-`
                  : ''}
              </div>
              <div className={styles['common-specifier']}>
                {!props.profile.personalId && !props.profile.documentId
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalId`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {!props.profile.personalId && !props.profile.documentId
                  ? `-`
                  : ''}
              </div>

              <div className={styles['common-specifier']}>
                {props.profile.personalId &&
                CONFIG.FEATURE.ENABLE_PERSONAL_ID_TYPE_LABEL
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalIdType`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {props.profile.personalId &&
                CONFIG.FEATURE.ENABLE_PERSONAL_ID_TYPE_LABEL
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalId-${props.profile.personalIdType}`
                    )} `
                  : ''}
              </div>

              <div className={styles['common-specifier']}>
                {props.profile.personalId
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalId`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {props.profile.personalId ? `${props.profile.personalId}` : ''}
              </div>

              <div className={styles['common-specifier']}>
                {props.profile.documentId
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalIdType`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {props.profile.documentId
                  ? `${props.profile.personalIdType}`
                  : ''}
              </div>

              <div className={styles['common-specifier']}>
                {props.profile.documentId
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.personalIdSource`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {props.profile.documentId
                  ? `${t(
                      `countries.${props.profile.personalIdSource?.substring(
                        0,
                        props.profile.personalIdSource?.indexOf('_GOV')
                      )}`
                    )} `
                  : ''}
              </div>

              <div className={styles['common-specifier']}>
                {props.profile.documentId
                  ? `${t(
                      `dashboard.profile-details.tax-information-section.documentId`
                    )} `
                  : ''}
              </div>
              <div className={styles['tax-information-value']}>
                {props.profile.documentId ? `${props.profile.documentId}` : ''}
              </div>
            </div>
          </div>
        </div>
      </Text>
    </FlexPanel>
  );
};

TaxInformationFieldReadContainer.propTypes = {
  personalId: PropTypes.string,
  personalIdType: PropTypes.string,
  personalIdSource: PropTypes.string,
  documentId: PropTypes.string,
  profile: PropTypes.object,
  names: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

TaxInformationFieldReadContainer.defaultProps = {
  personalId: '',
  personalIdType: '',
  personalIdSource: '',
  documentId: '',
  names: []
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    personalId: state.profile.personalId,
    personalIdType: state.profile.personalIdType,
    personalIdSource: state.profile.personalIdSource,
    documentId: state.profile.documentId
  };
};

export default connect(
  mapStateToProps,
  null
)(TaxInformationFieldReadContainer);
