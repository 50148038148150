import React from 'react';
import PropTypes from 'prop-types';
import IkeaLoading from '@ingka/loading';
import classNames from 'classnames';
import { Grid, Row, Col } from 'Common';
import ScrollToTop from 'Common/ScrollToTop';
import styles from './Loading.scss';
import '@ingka/loading/style.scss';

export class Loading extends React.PureComponent {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    const elements = document.getElementsByClassName(
      `${CONFIG.APP.STYLES_PREFIX}loading__text`
    );
    if (elements && elements.length) {
      elements[0].classList.add(`${CONFIG.APP.STYLES_PREFIX}h3`);
    }
  }

  render() {
    return (
      <ScrollToTop>
        <Grid>
          <Row>
            <Col
              className={classNames(
                styles['loading-wrap'],
                this.props.className
              )}
            >
              <IkeaLoading
                prefix={CONFIG.APP.STYLES_PREFIX}
                text={this.props.text}
              />
            </Col>
          </Row>
        </Grid>
      </ScrollToTop>
    );
  }
}

Loading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};
