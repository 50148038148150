export function transformFields(
  response,
  addressFieldMap,
  addressFieldValueMap
) {
  if (!response || !response.length || !response[0]['address_components']) {
    return;
  }

  const addressComponents = response[0]['address_components'];
  return Object.keys(addressFieldMap).reduce((data, fieldName) => {
    const googleFieldNames =
      typeof addressFieldMap[fieldName] === 'string'
        ? [addressFieldMap[fieldName]]
        : addressFieldMap[fieldName];

    if (!googleFieldNames) {
      data[fieldName] = undefined;
      return data;
    }

    let nextSeparator = '';

    data[fieldName] = googleFieldNames.reduce(
      (concatenatedValue, googleFieldName) => {
        // Look for a separator config between address text, separator starts with { and ends with }
        // Example: {/} between apartment name and apartment number should look like apartment name/apartment number
        const separatorMatch = googleFieldName.match(/^\{(.*)\}$/);

        if (separatorMatch) {
          nextSeparator = separatorMatch[1];
          return concatenatedValue;
        }

        const addressComponent =
          addressComponents.find(component =>
            component.types.includes(googleFieldName)
          ) || {};

        const value =
          (addressFieldValueMap &&
            addressComponent[addressFieldValueMap[fieldName]]) ||
          addressComponent['long_name'] ||
          addressComponent['short_name'] ||
          '';
        const separator = concatenatedValue ? nextSeparator : '';

        const newConcatenatedValue = value
          ? `${concatenatedValue}${separator}${value}`
          : concatenatedValue;

        if (addressFieldMap[`${fieldName}Default`]) {
          const defaultFieldName = `${fieldName}Default`;
          if (!newConcatenatedValue) {
            const defaultAddressComponent =
              addressComponents.find(component =>
                component.types.includes(addressFieldMap[defaultFieldName])
              ) || {};

            const defaultValue =
              (addressFieldValueMap &&
                defaultAddressComponent[
                  addressFieldValueMap[defaultFieldName]
                ]) ||
              defaultAddressComponent['long_name'] ||
              defaultAddressComponent['short_name'] ||
              '';
            return defaultValue;
          }
        }
        nextSeparator = '';
        return newConcatenatedValue;
      },
      ''
    );
    return data;
  }, {});
}

export function transformValues(data, addressValueMap) {
  if (!addressValueMap) {
    return data;
  }
  const fieldsToMap = Object.keys(addressValueMap).filter(
    fieldName => addressValueMap[fieldName] !== null
  );
  for (let i = 0; i < fieldsToMap.length; i++) {
    const fieldName = fieldsToMap[i];
    const prevValue = data[fieldName];
    data[fieldName] = (() => {
      for (let mappedValue of addressValueMap[fieldName]) {
        if (mappedValue.includes(prevValue)) {
          return mappedValue.split('=')[1];
        }
      }
    })();
  }
  // Certain zip code for India (ex 494001  )do not return either city name or province , in such cases we default these fields (hidden and mandatory in the form )  to NOT SET

  if (!data.cityName && CONFIG.COUNTRY.toUpperCase() === 'IN')
    data.cityName = 'NOT SET';

  if (!data.province && CONFIG.COUNTRY.toUpperCase() === 'IN')
    data.province = 'NOT SET';

  return data;
}
