/* eslint-disable prefer-destructuring */
import regex from './regex';

const isString = value => {
  return typeof value === 'string';
};

const capitalizeFirstChar = value => {
  if (!isString(value)) {
    return value;
  }
  return `${value[0].toUpperCase()}${value.substring(1)}`;
};

/**
 * Logic to convert year from a two digit to four digit
 * @param year : year passed in two digit format
 * Maximum sign up age is 116
 * Calculate year which was 116 years ago from current year.
 * Split it in two parts A (first 2 digits) and B(last 2 digits)
 * if year is less than or equal to B, then prefix A+1 to year otherwise prefix A to year
 * For example, in 2019 year 01 will be converted to 2001, 02 will be converted to 1902
 * */
const convertYearToFourDigits = year => {
  const currentYear = new Date().getFullYear();
  const eighteenYears = currentYear - 116;
  const years = eighteenYears.toString().match(/^(\d{2})(\d{2})$/);
  let yearInFourDigits;
  if (year <= years[2]) {
    yearInFourDigits = `${Number(years[1]) + 1}${year}`;
  } else {
    yearInFourDigits = `${years[1]}${year}`;
  }
  return yearInFourDigits;
};

const toLowercase = value => {
  if (!isString(value)) {
    return value;
  }
  return value.toLowercase();
};

const toUppercase = value => {
  if (!isString(value)) {
    return value;
  }
  return value.toUpperCase();
};

const toNumeric = value => {
  if (!isString(value)) {
    return value;
  }
  return value.replace(regex.notNumeric, '');
};

const toAlphanumeric = value => {
  if (!isString(value)) {
    return value;
  }
  return value.replace(regex.notAlphanumeric, '');
};

const toAlphanumericWithSpace = value => {
  if (!isString(value)) {
    return value;
  }
  return value.replace(regex.notAlphanumericAndSpace, '');
};

const convertFullWidthToHalfWidth = value => {
  return value.replace(/[！-～]/g, replace =>
    String.fromCharCode(replace.charCodeAt(0) - 0xfee0)
  );
};

const removeSpaces = value => {
  if (!isString(value)) {
    return value;
  }
  return value.replace(/\s/g, '');
};

const matchAndInsert = (value, { match, insert, modifier }) => {
  if (!isString(value) || match === undefined || insert === undefined) {
    return value;
  }
  const result = value.match(new RegExp(match, modifier));
  return result
    ? result
        .splice(1)
        .filter(Boolean)
        .join(insert)
    : value;
};

const matchAndReplace = (value, { match, replace, modifier }) => {
  if (!isString(value) || match === undefined || replace === undefined) {
    return value;
  }
  const regexp = new RegExp(match, modifier);
  return value.replace(regexp, replace);
};

const name = value => {
  if (!isString(value) || !value.includes('.')) {
    return value;
  }
  return value
    .split(' ')
    .map(_value => _value.replaceAll('.', '. '))
    .join('');
};

const date = (value, { format = 'DD-MM-YYYY', delimiter = '-' }) => {
  if (!isString(value)) {
    return value;
  }

  const newValue = value
    .replace(regex.notNumeric, '')
    .match(regex.date[format]);

  if (!newValue || newValue.length <= 3) {
    return value;
  }

  let DD;
  let MM;
  let YYYY;

  switch (format) {
    case 'DD-MM-YYYY':
      DD = newValue[1];
      MM = newValue[2];
      YYYY =
        newValue[3].length === 2
          ? convertYearToFourDigits(newValue[3])
          : newValue[3];

      return `${DD}${DD ? delimiter || '-' : ''}${MM}${
        MM ? delimiter || '-' : ''
      }${YYYY}`;

    case 'DD.MM.YYYY':
      DD = newValue[1];
      MM = newValue[2];
      YYYY =
        newValue[3].length === 2
          ? convertYearToFourDigits(newValue[3])
          : newValue[3];

      return `${DD}${DD ? delimiter || '.' : ''}${MM}${
        MM ? delimiter || '.' : ''
      }${YYYY}`;

    case 'MM-DD-YYYY':
      DD = newValue[2];
      MM = newValue[1];
      YYYY =
        newValue[3].length === 2
          ? convertYearToFourDigits(newValue[3])
          : newValue[3];
      return `${MM}${MM ? delimiter || '-' : ''}${DD}${
        DD ? delimiter || '-' : ''
      }${YYYY}`;

    case 'YYYY-MM-DD':
      DD = newValue[3];
      MM = newValue[2];
      YYYY = newValue[1];

      return `${YYYY}${YYYY ? delimiter || '-' : ''}${MM}${
        MM ? delimiter || '-' : ''
      }${DD}`;

    case 'YYYY.MM.DD':
      DD = newValue[3];
      MM = newValue[2];
      YYYY = newValue[1];

      return `${YYYY}${YYYY ? delimiter || '.' : ''}${MM}${
        MM ? delimiter || '.' : ''
      }${DD}`;

    case 'YYYY.MM.DD.':
      DD = newValue[3];
      MM = newValue[2];
      YYYY = newValue[1];

      return `${YYYY}${YYYY ? delimiter || '.' : ''}${MM}${
        MM ? delimiter || '.' : ''
      }${DD}${DD ? delimiter || '.' : ''}`;

    case 'MM-YYYY':
      MM = newValue[2];
      YYYY = newValue[3];
      return `${MM}${MM ? delimiter || '-' : ''}${YYYY}`;

    case 'MM.YYYY':
      MM = newValue[2];
      YYYY = newValue[3];
      return `${MM}${MM ? delimiter || '.' : ''}${YYYY}`;

    case 'YYYY.MM.':
      MM = newValue[3];
      YYYY = newValue[2];
      return `${YYYY}${YYYY ? delimiter || '.' : ''}${MM}${
        MM ? delimiter || '.' : ''
      }`;

    case 'YYYY-MM':
      MM = newValue[3];
      YYYY = newValue[2];
      return `${YYYY}${YYYY ? delimiter || '-' : ''}${MM}`;

    default:
      return value;
  }
};

export default {
  capitalizeFirstChar,
  toLowercase,
  toUppercase,
  toNumeric,
  toAlphanumeric,
  toAlphanumericWithSpace,
  convertFullWidthToHalfWidth,
  removeSpaces,
  matchAndInsert,
  matchAndReplace,
  date,
  name
};
