import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { Grid, Row, Col, Loading, Text, Button, PageHeader } from 'Common';
import Image from '@ingka/image';
import { setPageTitle } from 'Utils/document/documentUtils';
import image from './DELETED_PROFILE.jpg';
import styles from './DeletedProfileContainer.scss';

class DeletedProfileContainer extends React.PureComponent {
  render() {
    setPageTitle(
      t(['metaData.deletedProfilePageTitle', 'metaData.defaultPageTitle'])
    );
    if (this.props.isLoading) {
      return <Loading isLoading text={t('loaders.delete')} />;
    }
    return (
      <Grid className={styles['deleted-profile']}>
        <Row>
          <Col lg={4}>
            <PageHeader>{t('delete.confirmation.title')}</PageHeader>
            <Text marginTop="m">
              {this.props.profileType === PROFILE_TYPES.FAMILY
                ? t('delete.confirmation.textFamily')
                : t('delete.confirmation.textRegular')}
            </Text>
            <Text marginTop="m" marginBottom="m">
              {t('delete.confirmation.textGeneral')}
            </Text>
            <Button
              marginTop="l"
              type="primary"
              text={t('common.browseProducts')}
              onClick={() => {
                window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}`;
              }}
            />
          </Col>
          <Col lg={8}>
            <Image src={image} alt={t('delete.boxTitle')} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

DeletedProfileContainer.propTypes = {
  isLoading: PropTypes.bool,
  profileType: PropTypes.string,
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {
    isLoading: state.userInterface.loading[LOADING.PROFILE_DELETE],
    profileType: state.profile.profileType
  };
};

export default connect(
  mapStateToProps,
  null
)(DeletedProfileContainer);
