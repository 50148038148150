import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'Utils/localization/i18next';
import { InlineText, SSRIcons, Tooltip } from 'Common';
import IkeaInputFieldControlled from '../_form_ikea_copies/IkeaInputFieldControlled';
import IkeaInputFieldUncontrolled from '../_form_ikea_copies/IkeaInputFieldUncontrolled';
import styles from './InputFieldHandler.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/svg-icon/style.rtl.scss';
import '@ingka/button/style.rtl.scss';
import '@ingka/forms/style.rtl.scss';
// IkeaInputField does not allow using both value and defaultValue props.
// This component should be used only with _value_. For IkeaInputField with
// defaultValue prop, use InputField.

class InputFieldHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    };
    // Using myRef is a workaround for controlling what label style is set in
    // IkeaInputField, which only checks/toggles this style (from small to big
    // and vice versa) in componentDidMount (depending on defaultValue) and in
    // onChange (depending on e.target.value). If we e.g. autofill some fields
    // with values from the Redux store, the label style would not align.
    this.myRef = React.createRef();
    this.typeRegex = this.props.typeRegex && new RegExp(this.props.typeRegex);
  }

  componentDidMount() {
    if (this.props.value.length) {
      this.myRef.setState({ labelled: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.value.length && this.props.value.length) {
      this.myRef.setState({ labelled: true });
    }
    if (prevProps.value.length && !this.props.value.length) {
      this.myRef.setState({ labelled: false });
    }
  }

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  onChange = e => {
    if (!this.typeRegex || this.typeRegex.test(e.target.value)) {
      this.props.onChange(e);
    }
  };

  render() {
    const id = this.props.formId
      ? `${this.props.formId}-${this.props.name}`
      : this.props.id;

    return (
      <Tooltip
        isOpen={this.state.showTooltip}
        text={t(this.props.tooltip)}
        className={classNames(
          CONFIG.FEATURE.RIGHT_TO_LEFT
            ? styles['input-field-rtl']
            : styles['input-field'],
          this.props.className
        )}
      >
        {this.props.uncontrolled || this.props.uncontrolledProps ? (
          <IkeaInputFieldUncontrolled
            id={id}
            ref={this.setRef}
            name={this.props.name}
            value={this.props.value}
            type={this.props.type}
            describedById={`${id}-error`}
            label={
              this.props.showOptional
                ? `${t(this.props.label)} ${t('forms.optional')}`
                : t(this.props.label)
            }
            maxLength={this.props.maxLength}
            placeholder={t(this.props.placeholder)}
            autoComplete={this.props.autoComplete}
            ssrIcon={
              this.props.ssrIcon
                ? this.props.ssrIcon
                : this.props.tooltip &&
                  SSRIcons[this.props.tooltipIcon || 'tooltip']
            }
            iconPosition={this.props.iconPosition}
            iconClickDescription={
              this.props.tooltip && this.state.showTooltip
                ? `Hide ${this.props.name} information`
                : this.props.tooltip && !this.state.showTooltip
                ? `Show ${this.props.name} information`
                : this.props.iconClickDescription
            }
            iconOnClick={() => {
              if (this.props.tooltip) {
                this.setState({ showTooltip: !this.state.showTooltip });
              }
              if (this.props.iconOnClick) {
                this.props.iconOnClick();
              }
            }}
            onBlur={e => {
              if (this.props.tooltip && this.state.showTooltip) {
                this.setState({ showTooltip: false });
              }
              if (this.props.onBlur) {
                this.props.onBlur(e);
              }
            }}
            onChange={this.onChange}
            onFocus={this.props.onFocus}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            prefix={CONFIG.APP.STYLES_PREFIX}
            disabled={this.props.disabled}
          />
        ) : (
          <IkeaInputFieldControlled
            id={id}
            ref={this.setRef}
            name={this.props.name}
            value={this.props.value}
            type={this.props.type}
            describedById={`${id}-error`}
            label={
              this.props.showOptional
                ? `${t(this.props.label)} ${t('forms.optional')}`
                : t(this.props.label)
            }
            maxLength={this.props.maxLength}
            placeholder={t(this.props.placeholder)}
            autoComplete={this.props.autoComplete}
            ssrIcon={
              this.props.ssrIcon
                ? this.props.ssrIcon
                : this.props.tooltip &&
                  SSRIcons[this.props.tooltipIcon || 'tooltip']
            }
            iconPosition={this.props.iconPosition}
            iconClickDescription={
              this.props.tooltip && this.state.showTooltip
                ? `Hide ${this.props.name} information`
                : this.props.tooltip && !this.state.showTooltip
                ? `Show ${this.props.name} information`
                : this.props.iconClickDescription
            }
            iconOnClick={() => {
              if (this.props.tooltip) {
                this.setState({ showTooltip: !this.state.showTooltip });
              }
              if (this.props.iconOnClick) {
                this.props.iconOnClick();
              }
            }}
            onBlur={e => {
              if (this.props.tooltip && this.state.showTooltip) {
                this.setState({ showTooltip: false });
              }
              if (this.props.onBlur) {
                this.props.onBlur(e);
              }
            }}
            onChange={this.onChange}
            onFocus={this.props.onFocus}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            prefix={CONFIG.APP.STYLES_PREFIX}
            disabled={this.props.disabled}
          />
        )}
        {this.props.extraTextMsg && (
          <InlineText
            msg={t(this.props.extraTextMsg)}
            ssrIcon={SSRIcons[this.props.extraTextIcon]}
          />
        )}
      </Tooltip>
    );
  }
}

InputFieldHandler.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.string,
  uncontrolled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  typeRegex: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  extraTextMsg: PropTypes.string,
  extraTextIcon: PropTypes.string,
  maxLength: PropTypes.number,
  showOptional: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  ssrIcon: PropTypes.func,
  iconPosition: PropTypes.oneOf(['leading', 'trailing']),
  iconClickDescription: PropTypes.string,
  iconOnClick: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipIcon: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  uncontrolledProps: PropTypes.bool
};

InputFieldHandler.defaultProps = {
  value: '',
  uncontrolled: false,
  showOptional: false,
  iconPosition: 'trailing',
  uncontrolledProps: false
};

export default InputFieldHandler;
