import { push } from 'connected-react-router';
import JwtDecode from 'jwt-decode';
import { ReduxStore } from 'Redux';
import {
  clearAuthAction,
  setLoginSuccessAction,
  setPartyUidAction,
  setUserLoginAction,
  setUserPasswordAction
} from 'Redux/auth/authActions';
import { setFormErrorsAction, clearFormAction } from 'Redux/forms/formsActions';
import { loadingAction } from 'Redux/userInterface/userInterfaceActions';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import {
  clearProfileAction,
  setUserIdAction,
  setCoworkerLinkingStatusAction
} from 'Redux/profile/profileActions';
import {
  AuthService,
  Auth0Service,
  BazaarvoiceUASService,
  DMPService,
  EventService
} from 'Services/ServiceFactory';
import {
  setEmailVerifiedAction,
  setLoginIdentifierAction
} from 'Redux/verification/verificationActions';
import analytics from 'Utils/analytics/analyticsWrapper';
import {
  directToBVContainer,
  getBVEventIdFromQueryStrings,
  removeBVLoginTokenCookie,
  writeBVLoginTokenCookie
} from 'Utils/bazaarvoice/bazaarvoiceUtils';
import {
  writeDMPUserIdCookies,
  removeDMPUserIdCookies
} from 'Utils/dmp/DMPUtils';
import { isUTCDateInPast } from 'Utils/date/dateUtils';
import { mapBackendException } from 'Utils/errors/errorUtils';
import { events } from 'Utils/pubsub';
import { isValidIKEAUrl } from 'Utils/url/urlUtils';
import Routes from 'Routes/profileRoutes';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import {
  readAuthTokenCookie,
  deleteAuthTokenCookie,
  deleteSessionCookie,
  getRedirectUrlFromQueryStrings,
  getCoworkerLinkingStatusFromQueryStrings,
  appendAuthTokenToUrl,
  readGuestTokenCookie,
  writeSessionCookie,
  readWindowAuthTokens,
  isPopup
} from 'Utils/auth/authUtils';

const redirectToUrl = async url => {
  if (isPopup()) {
    await analytics.ciePagePopup('login_form', 'login_page_popup_success');
    const currentWindow = window.self;
    currentWindow.opener.location.assign(url);
    currentWindow.opener = window.self;
    currentWindow.close();
  } else {
    window.location = url;
  }
};

export const getSession = async () => {
  if (CONFIG.FEATURE.INTERNAL_LOGIN && !CONFIG.FEATURE.ENABLE_ICM_CMS) {
    const irwSession = await AuthService.getIRWProfileSession(false);
    let sessionResp = { authenticated: false };
    if (irwSession.userType === 'R') {
      sessionResp = {
        authenticated: true,
        user: {
          userId: irwSession.userId,
          userLogin: irwSession.emailAddress,
          userName: irwSession.userName,
          profileType: {
            MINIMUM: 'REGULAR',
            FAMILY: 'FAMILY',
            BUSINESS: 'BUSINESS'
          }[irwSession.profileType]
        }
      };
    }
    return sessionResp;
  }
  const authResp = await AuthService.isAuthenticated();
  if (!(authResp && authResp.authenticated)) {
    throw new Error();
  }
  return authResp;
};

const externalLogin = (
  loginQueryStrings,
  bvEventId,
  internalRedirect,
  externalRedirect,
  from = '',
  icw
) => {
  return async dispatch => {
    try {
      let sessionResp;

      // Case 1:
      // User is redirected back from CIAM hosted login page with access
      // token and id token after user has provided valid credentials.
      if (loginQueryStrings) {
        const authResp = await Auth0Service.handleAuthentication(
          loginQueryStrings
        );

        if (!authResp) {
          window.location = `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`;
          return;
        }

        if (CONFIG.FEATURE.ENABLE_LOGIN_EVENT) {
          await EventService.sendLoginEvent(
            authResp.access_token,
            JwtDecode(readGuestTokenCookie()).sub
          );
        }

        // The below call is necessary to get the WCS cookies required by some other services to work like the "order history" and "shopping lists".
        // This call should be called in the "login" after the CIAM authentication and not scattered in the app.
        // The call should be removed once the "CFB1.3" solution is rolled out.
        if (!CONFIG.FEATURE.ENABLE_ICM_CMS) {
          await AuthService.getIRWProfileSession(true);
        }

        sessionResp = sessionResp || (await getSession());

        if (
          window.ikea.cookieConsent &&
          window.ikea.cookieConsent.hasConsent(3)
        ) {
          const firstNameInitial = sessionResp.user.firstName
            ? sessionResp.user.firstName.charAt(0).toUpperCase()
            : undefined;
          const lastNameInitial = sessionResp.user.lastName
            ? sessionResp.user.lastName.charAt(0).toUpperCase()
            : undefined;
          const nameInitials =
            firstNameInitial && lastNameInitial
              ? `${firstNameInitial}${lastNameInitial}`
              : '';
          await writeSessionCookie(
            JSON.stringify({
              firstName: sessionResp.user.firstName,
              nameInitials,
              preferredStore: sessionResp.user.preferredStore,
              zipCode: sessionResp.user.zipCode
            })
          );
          if (
            window.ikea.pubsub &&
            typeof window.ikea.pubsub.publish === 'function'
          ) {
            await window.ikea.pubsub.publish(events.USER_INFO_AVAILABLE);
          }
        }

        if (CONFIG.FEATURE.ENABLE_BAZAARVOICE) {
          const bvLoginToken = await BazaarvoiceUASService.getBVLoginToken(
            sessionResp.user.userId,
            sessionResp.user.userLogin
          );
          writeBVLoginTokenCookie(bvLoginToken, authResp.expires_in * 1000);
          const bvEventIdFromQueryStrings = getBVEventIdFromQueryStrings(
            loginQueryStrings
          );
          if (bvEventIdFromQueryStrings) {
            directToBVContainer(bvLoginToken, bvEventIdFromQueryStrings);
            return;
          }
        }

        if (CONFIG.FEATURE.ENABLE_DMP_USER_COOKIES) {
          const dmpCookieContent = await DMPService.getHashUserCookieContent(
            sessionResp.user.partyUid,
            sessionResp.user.userLogin
          );
          writeDMPUserIdCookies(dmpCookieContent, authResp.expires_in * 1000);
        }

        const redirectUrlFromQueryStrings = getRedirectUrlFromQueryStrings(
          loginQueryStrings
        );
        if (redirectUrlFromQueryStrings) {
          redirectToUrl(appendAuthTokenToUrl(redirectUrlFromQueryStrings));
          return;
        }
        // Don't redirect to loyalty-hub in case of coworker link
        if (!getCoworkerLinkingStatusFromQueryStrings(loginQueryStrings)) {
          if (CONFIG.FEATURE.ENABLE_IKEA_FAMILY_HUB) {
            if (sessionResp.user.profileType === PROFILE_TYPES.FAMILY) {
              redirectToUrl(CONFIG.URL.IKEA_FAMILY_HUB);
              return;
            }
          }
          if (CONFIG.FEATURE.ENABLE_IKEA_REGULAR_HUB) {
            if (sessionResp.user.profileType === PROFILE_TYPES.REGULAR) {
              redirectToUrl(CONFIG.URL.IKEA_FAMILY_HUB);
              return;
            }
          }
        }
      }
      const authToken =
        (await readWindowAuthTokens()) || (await readAuthTokenCookie());
      const authTokenExist =
        authToken && !isUTCDateInPast(JwtDecode(authToken).exp);
      const validSession = authTokenExist;

      // Case 2:
      // User does not have a valid session (token) and will be redirected
      // to CIAM hosted login page.
      if (!validSession) {
        const redirectTo = isValidIKEAUrl(from) ? from : undefined;
        Auth0Service.login(
          redirectTo,
          CONFIG.FEATURE.ENABLE_BAZAARVOICE ? bvEventId : undefined,
          icw
        );
        return;
      }

      // Case 3:
      // User has a valid session (token).
      sessionResp = sessionResp || (await getSession());
      // The below call is necessary to get the WCS cookies required by some other services to work like the "order history" and "shopping lists".
      // This call should be called in the "login" after the CIAM authentication and not scattered in the app.
      // The call should be removed once the "CFB1.3" solution is rolled out.
      if (!loginQueryStrings && !CONFIG.FEATURE.ENABLE_ICM_CMS) {
        await AuthService.getIRWProfileSession(false);
      }

      if (!sessionResp.authenticated) {
        dispatch(push(Routes.LOGIN));
        return;
      }
      // User is logged in and authenticated.
      await dispatch(setLoginSuccessAction(sessionResp.user.userLogin));
      await dispatch(setUserIdAction(sessionResp.user.userId));
      const coworkerLinkingStatus = sessionResp.user.employeeId
        ? icw || getCoworkerLinkingStatusFromQueryStrings(loginQueryStrings)
        : undefined;
      await dispatch(setCoworkerLinkingStatusAction(coworkerLinkingStatus));
      await dispatch(setPartyUidAction(sessionResp.user.partyUid));
      if (CONFIG.FEATURE.ENABLE_EMAIL_VERIFICATION) {
        await dispatch(setEmailVerifiedAction(sessionResp.user.emailVerified));
      }
      if (CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION) {
        await dispatch(setLoginIdentifierAction(sessionResp.user.identifier));
      }
      if (
        window.ikea &&
        window.ikea.pubsub &&
        typeof window.ikea.pubsub.publish === 'function'
      ) {
        window.ikea.pubsub.publish(events.LOGIN);
      }

      const feature =
        CONFIG.FEATURE[
          `${sessionResp.user.profileType.toUpperCase()}_DASHBOARD`
        ];

      if (!feature.USE_APPLICATION && feature.USE_EXTERNAL) {
        redirectToUrl(feature.EXTERNAL_URL);
        return;
      }

      if (internalRedirect) {
        if (isValidIKEAUrl(from)) {
          const redirectUrl = appendAuthTokenToUrl(from);
          redirectToUrl(redirectUrl);
          return;
        }
        // Don't redirect to loyalty-hub in case of coworker link
        if (!coworkerLinkingStatus) {
          if (CONFIG.FEATURE.ENABLE_IKEA_FAMILY_HUB) {
            if (sessionResp.user.profileType === PROFILE_TYPES.FAMILY) {
              redirectToUrl(CONFIG.URL.IKEA_FAMILY_HUB);
              return;
            }
          }
          if (CONFIG.FEATURE.ENABLE_IKEA_REGULAR_HUB) {
            if (sessionResp.user.profileType === PROFILE_TYPES.REGULAR) {
              redirectToUrl(CONFIG.URL.IKEA_FAMILY_HUB);
              return;
            }
          }
        }
        // eslint-disable-next-line no-unused-expressions
        isPopup()
          ? redirectToUrl(
              `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${internalRedirect}`
            )
          : dispatch(push(internalRedirect));
      }
      if (externalRedirect) {
        redirectToUrl(externalRedirect);
        return;
      }
    } catch (e) {
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.profileGet'
        })
      );
    }
  };
};

const internalLogin = (
  loginRequest,
  internalRedirect,
  externalRedirect,
  from = '',
  formId,
  isBasicLogin
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.LOGIN, true));

      let sessionResp;

      // Case 1:
      // User has entered credentials on internal login page
      if (loginRequest) {
        const authResp = await AuthService.login(loginRequest);
        const { passwordExpired } = authResp;
        if (passwordExpired) {
          // Password is expired, saving user id and password in state
          await dispatch(setUserLoginAction(loginRequest.username));
          await dispatch(setUserPasswordAction(loginRequest.password));
          const redirectTo = isValidIKEAUrl(from) ? from : undefined;
          // Redirect to update password page
          dispatch(
            push({
              pathname: Routes.RESET_UPDATE_PASSWORD,
              ...(from && { search: `?from=${redirectTo}` })
            })
          );
          return;
        }

        sessionResp = sessionResp || (await getSession());

        analytics.userLoggedInInternal(
          sessionResp.user.profileType,
          sessionResp.user.userId
        );
        if (
          window.ikea.cookieConsent &&
          window.ikea.cookieConsent.hasConsent(3) &&
          sessionResp.user.userName
        ) {
          const match = sessionResp.user.userName.match(/^.*(?= )/);
          const firstName = match && match.length && match[0];
          if (firstName) {
            await writeSessionCookie(
              JSON.stringify({
                firstName
              })
            );
          }
        }
      }

      sessionResp = sessionResp || (await getSession());

      const hasValidSession = sessionResp.authenticated;

      // Case 2:
      // User does not have a valid session
      if (!hasValidSession) {
        const redirectTo = isValidIKEAUrl(from) ? from : undefined;
        dispatch(
          push({
            pathname: isBasicLogin ? Routes.LOGIN_BASIC : Routes.LOGIN,
            showInternalLoginPage: true,
            ...(from && { search: `?from=${redirectTo}` })
          })
        );
        return;
      }

      // User is logged in and authenticated.
      await dispatch(setLoginSuccessAction(sessionResp.user.userLogin));
      await dispatch(setUserIdAction(sessionResp.user.userId));

      if (
        window.ikea &&
        window.ikea.pubsub &&
        typeof window.ikea.pubsub.publish === 'function'
      ) {
        window.ikea.pubsub.publish(events.LOGIN);
      }

      // Redirect to from
      if (isValidIKEAUrl(from)) {
        window.location = from;
        return;
      }

      const feature =
        CONFIG.FEATURE[
          `${sessionResp.user.profileType.toUpperCase()}_DASHBOARD`
        ];

      if (!feature.USE_APPLICATION && feature.USE_EXTERNAL) {
        window.location = feature.EXTERNAL_URL;
        return;
      }

      if (internalRedirect) {
        if (formId) {
          dispatch(clearFormAction(formId));
        }
        dispatch(push(internalRedirect));
      }
      if (externalRedirect) {
        window.location = externalRedirect;
        return;
      }
    } catch (e) {
      if (formId) {
        dispatch(clearFormAction(formId));
        dispatch(setFormErrorsAction(formId, mapBackendException(e)));
        return;
      }
      dispatch(
        push({
          pathname: Routes.ERROR,
          link: Routes.LOGIN,
          linkText: 'common.backToLogin',
          errorText: 'error.profileGet'
        })
      );
    } finally {
      dispatch(loadingAction(LOADING.LOGIN, false));
    }
  };
};

export const login = (
  loginQueryStrings,
  bvEventId,
  internalRedirect,
  externalRedirect,
  from = window.location.href.includes('profile/dashboard')
    ? window.location.href
    : '',
  formId,
  icw,
  isBasicLogin
) => {
  return CONFIG.FEATURE.INTERNAL_LOGIN
    ? internalLogin(
        loginQueryStrings,
        internalRedirect,
        externalRedirect,
        from,
        formId,
        isBasicLogin
      )
    : externalLogin(
        loginQueryStrings,
        bvEventId,
        internalRedirect,
        externalRedirect,
        from,
        icw
      );
};

export const hasValidSession = () => {
  return async () => {
    const authResp = await AuthService.isAuthenticated();
    return authResp.authenticated;
  };
};

export const resetPassword = (
  { username, sendPassMethod },
  formId,
  from,
  isBasic
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      const data = {
        username: username.toLowerCase(),
        sendPassMethod: sendPassMethod.toUpperCase()
      };
      await AuthService.resetPassword(data);
      return true;
    } finally {
      // ignore error for security so that someone trying to reset password of
      // unknown users doesn't know which emails are registered and which are not
      dispatch(clearFormAction(formId));
      dispatch(
        push({
          pathname: isBasic
            ? Routes.RESET_PASSWORD_CONFIRMATION_BASIC
            : Routes.RESET_PASSWORD_CONFIRMATION,
          ...(from && { search: `?from=${from}` })
        })
      );
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

export const setNewPassword = (
  { currentPassword, newPassword, newPasswordRetype },
  formId,
  from
) => {
  return async dispatch => {
    try {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, true));
      const { userId } = ReduxStore.getState().profile;
      const { userLogin } = ReduxStore.getState().auth;
      const data = {
        userId,
        currentPassword,
        newPassword,
        newPasswordRetype,
        ...(formId === 'reset-password-form' && { userLogin })
      };

      await AuthService.setNewPassword(data);

      if (formId === 'reset-password-form') {
        await dispatch(
          login(
            { username: userLogin, password: newPassword },
            null,
            Routes.DASHBOARD,
            null,
            from,
            formId
          )
        );
      }
      dispatch(clearFormAction(formId));
      return true;
    } catch (e) {
      dispatch(setFormErrorsAction(formId, mapBackendException(e)));
      return false;
    } finally {
      dispatch(loadingAction(LOADING.PROFILE_UPDATE, false));
    }
  };
};

/**
 * @param {string} redirectLoggedOutTo (optional) URL to redirect logged out user
 */
export const logout = (redirectLoggedOutTo, forceLogout) => {
  const authTokenCookie = readAuthTokenCookie();
  return async dispatch => {
    if (authTokenCookie || CONFIG.FEATURE.INTERNAL_LOGIN || forceLogout) {
      if (
        CONFIG.FEATURE.ENABLE_ICM_CMS &&
        !CONFIG.API.IRW.DISABLE_SESSION_CONTEXT
      ) {
        await AuthService.logout(); // 1- logout from IRW
      }

      if (
        window.ikea &&
        window.ikea.pubsub &&
        typeof window.ikea.pubsub.publish === 'function'
      ) {
        window.ikea.pubsub.publish(events.LOGOUT);
      }

      removeBVLoginTokenCookie();
      removeDMPUserIdCookies();

      dispatch(clearAuthAction());
      dispatch(clearProfileAction());

      await deleteAuthTokenCookie();
      await deleteSessionCookie();
      if (!CONFIG.FEATURE.INTERNAL_LOGIN) {
        // 2- logout from auth0
        Auth0Service.logout(redirectLoggedOutTo, authTokenCookie);
      } else {
        window.location.href = redirectLoggedOutTo;
      }
    } else {
      window.location.href = redirectLoggedOutTo;
    }
  };
};
