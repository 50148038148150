const { PasswordPolicy, charsets } = require('password-sheriff');

export const validateNumbers = new PasswordPolicy({
  contains: { expressions: [charsets.numbers] }
});
export const validateSpecialCharacters = new PasswordPolicy({
  contains: { expressions: [charsets.specialCharacters] }
});
export const validateUpperCase = new PasswordPolicy({
  contains: { expressions: [charsets.upperCase] }
});
export const validateLowerCase = new PasswordPolicy({
  contains: { expressions: [charsets.lowerCase] }
});
export const validateIdenticalChars = new PasswordPolicy({
  identicalChars: { max: 2 }
});
export const validateMinLength = new PasswordPolicy({
  length: { minLength: 8 }
});
export const validateMaxLength = value => {
  return value.length <= 20;
};
export const PasswordErrorKeys = [
  'invalidLength',
  'invalidIdenticalChars',
  'invalidLowercase',
  'invalidUppercase',
  'invalidNumberOrSpecialChar'
];
