import JwtDecode from 'jwt-decode';
import { isUTCDateInPast } from 'Utils/date/dateUtils';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import {
  isValidIKEAUrl,
  isTokenRequired,
  appendQueryParamToUrl,
  getQueryStringValue
} from 'Utils/url/urlUtils';
import {
  writeCookie,
  deleteCookie,
  readCookie
} from 'Utils/cookies/cookiesUtils';
import Routes from 'Routes/profileRoutes';

const AUTH_TOKEN_COOKIE_NAME = 'idp_reguser';
const GUEST_TOKEN_COOKIE_NAME = 'guest';
const SESSION_COOKIE_NAME = 'ikea_session';

// TODO: reading the cookies should happen through the "client-token-service". Please check: https://git.build.ingka.ikea.com/one-web-profiles/client-token-service
export const readAuthTokenCookie = () => {
  return readCookie(AUTH_TOKEN_COOKIE_NAME);
};

export const readGuestTokenCookie = () => {
  return readCookie(GUEST_TOKEN_COOKIE_NAME);
};

export const readWindowAuthTokens = () => {
  return new Promise(resolve => {
    if (window.ikea && window.ikea.authentication) {
      window.ikea.authentication.getTokens((error, token) => {
        if (token && token.auth) {
          resolve(token.auth.value);
        } else {
          resolve(null);
        }
      });
    } else {
      resolve(readAuthTokenCookie());
    }
  });
};

export const writeAuthTokenCookie = accessToken => {
  writeCookie(
    AUTH_TOKEN_COOKIE_NAME,
    accessToken,
    undefined,
    `/${CONFIG.COUNTRY}`
  );
};

export const writeSessionCookie = userSession => {
  writeCookie(
    SESSION_COOKIE_NAME,
    window.btoa(unescape(encodeURIComponent(userSession))),
    undefined,
    `/${CONFIG.COUNTRY}`
  );
};

export const deleteAuthTokenCookie = () => {
  deleteCookie(AUTH_TOKEN_COOKIE_NAME, `/${CONFIG.COUNTRY}`);
};

export const deleteSessionCookie = () => {
  deleteCookie(SESSION_COOKIE_NAME, `/${CONFIG.COUNTRY}`);
};

export const deleteRefreshToken = () => {
  localStorage.removeItem(`refreshToken_${CONFIG.COUNTRY.toLowerCase()}`);
};

export const validateIdToken = idToken => {
  const decodedToken = JwtDecode(idToken);
  if (
    decodedToken &&
    !isUTCDateInPast(decodedToken.exp) &&
    decodedToken.aud === CONFIG.API.AUTH0.CLIENT_ID &&
    decodedToken.iss.includes(CONFIG.API.AUTH0.DOMAIN)
  ) {
    return true;
  }
  return false;
};

// Temporary validation until CIAM fix aud mismatch between hlp and autoLogin
const validateAutoLoginIdToken = idToken => {
  const decodedToken = JwtDecode(idToken);
  if (
    decodedToken &&
    !isUTCDateInPast(decodedToken.exp) &&
    decodedToken.iss.includes(CONFIG.API.AUTH0.DOMAIN)
  ) {
    return true;
  }
  return false;
};

export const storeAutoLoginToken = tokenArray => {
  try {
    // 1- Get and validate the id token
    const idToken = tokenArray.find(token => token.tokenType === 'ID_TOKEN')
      .tokenValue;
    const isValidIdToken = validateAutoLoginIdToken(idToken);
    if (!isValidIdToken) {
      return false;
    }
    // 2- Get and decode the access token
    const accessToken = tokenArray.find(
      token => token.tokenType === 'ACCESS_TOKEN'
    ).tokenValue;
    // 3- Write the user token cookie
    writeAuthTokenCookie(accessToken);
    return true;
  } catch (e) {
    return false;
  }
};

export const getDecodedAuthToken = authToken => {
  const decodedToken = authToken && JwtDecode(authToken);
  if (decodedToken) {
    const customerType = decodedToken['https://accounts.ikea.com/customerType'];
    const profileType =
      customerType === 'business'
        ? PROFILE_TYPES.BUSINESS
        : decodedToken['https://accounts.ikea.com/loyaltyPrograms'].some(
            program => program === 'IKEA_FAMILY'
          )
        ? PROFILE_TYPES.FAMILY
        : PROFILE_TYPES.REGULAR;
    return {
      exp: decodedToken.exp,
      userId: decodedToken['https://accounts.ikea.com/memberId'],
      partyUid: decodedToken['https://accounts.ikea.com/partyUId'],
      profileType
    };
  }
  return {};
};

export const getRedirectUrlFromQueryStrings = queryStrings => {
  const params = decodeURIComponent(queryStrings).match(
    /state="redirectTo=([^"]*)/
  );
  return params && params.length > 1 ? params[1] : null;
};

export const getCoworkerLinkingStatusFromQueryStrings = queryStrings => {
  const params = decodeURIComponent(queryStrings).match(/icw=([^&]*)/);
  return params && params.length > 1 ? params[1] : null;
};

export const getCodeFromQueryStrings = queryStrings => {
  const params = decodeURIComponent(queryStrings).match(/code=([^&]*)/);
  return params && params.length > 1 ? params[1] : null;
};

// This method is to check the OTP verified status true from the Auth0 URL.
export const getOtpVerifiedStatus = searchOrHash => {
  const params = decodeURIComponent(searchOrHash).match(/verified=true/);
  return params && params.length > 0;
};

// Will only append token query param if "url" is a valid
// IKEA URL and listed as an exact URL without wild cards.
export const appendAuthTokenToUrl = url => {
  const token = readAuthTokenCookie();
  return isValidIKEAUrl(url, true) && isTokenRequired(url) && token
    ? appendQueryParamToUrl(url, Routes.QUERY_PARAMS.TOKEN, token)
    : url;
};

export const getUtmCampaignParams = url => {
  const campaignParamsKeys = [
    Routes.QUERY_PARAMS.UTM_SOURCE,
    Routes.QUERY_PARAMS.UTM_MEDIUM,
    Routes.QUERY_PARAMS.UTM_CAMPAIGN
  ];
  const from = url.match(/from=(.*)/);
  const fromParams =
    from && from.length > 1 ? new URL(decodeURIComponent(from[1])).search : '';
  const campaignValues = campaignParamsKeys.reduce((values, key) => {
    return values.concat(getQueryStringValue(fromParams || url, key) || '');
  }, []);
  return campaignValues.join('|');
};

export const getItmCampaignParams = url => {
  const campaignParamsKeys = [
    Routes.QUERY_PARAMS.ITM_CAMPAIGN,
    Routes.QUERY_PARAMS.ITM_ELEMENT,
    Routes.QUERY_PARAMS.ITM_CONTENT
  ];
  const from = url.match(/from=(.*)/);
  const fromParams =
    from && from.length > 1 ? new URL(decodeURIComponent(from[1])).search : '';
  const campaignValues = campaignParamsKeys.reduce((values, key) => {
    return values.concat(getQueryStringValue(fromParams || url, key) || '');
  }, []);
  return campaignValues.join('|');
};

export const isRememberMeSelected = idToken => {
  const decodedToken = JwtDecode(idToken);
  return decodedToken && decodedToken['https://accounts.ikea.com/isRemembered'];
};

export const isInsideIframe = () => {
  return (
    window.location !== window.parent.location ||
    window.self !== window.top ||
    window.frameElement
  );
};

export const isPopup = () => {
  return window.opener && window.opener !== window;
};
