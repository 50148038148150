// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__dashboard-delivery-address{display:flex;justify-content:flex-start}.profile__dashboard-delivery-address-edit-close{position:absolute;right:0;z-index:2}.profile__dashboard-delivery-address-edit-wrap{width:100%;margin-bottom:2rem}.profile__dashboard-delivery-address-read-wrap{display:flex;justify-content:flex-start}.profile__dashboard-delivery-address-read-field{flex-direction:column;line-height:1.5;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/Address/DashboardDeliveryAddress.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,0BAAA,CAEA,gDACE,iBAAA,CACA,OAAA,CACA,SAAA,CAGF,+CACE,UAAA,CACA,kBAAA,CAGF,+CACE,YAAA,CACA,0BAAA,CAGF,gDACE,qBAAA,CACA,eAAA,CACA,WAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.dashboard-delivery-address {\n  display: flex;\n  justify-content: flex-start;\n\n  &-edit-close {\n    position: absolute;\n    right: 0;\n    z-index: 2;\n  }\n\n  &-edit-wrap {\n    width: 100%;\n    margin-bottom: 2rem;\n  }\n\n  &-read-wrap {\n    display: flex;\n    justify-content: flex-start;\n  }\n\n  &-read-field {\n    flex-direction: column;\n    line-height: 1.5;\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard-delivery-address": "profile__dashboard-delivery-address",
	"dashboard-delivery-address-edit-close": "profile__dashboard-delivery-address-edit-close",
	"dashboard-delivery-address-edit-wrap": "profile__dashboard-delivery-address-edit-wrap",
	"dashboard-delivery-address-read-wrap": "profile__dashboard-delivery-address-read-wrap",
	"dashboard-delivery-address-read-field": "profile__dashboard-delivery-address-read-field"
};
export default ___CSS_LOADER_EXPORT___;
