import maskers from './maskers';

const { matchAndInsert, removeSpaces } = maskers;

export default {
  AT: [
    value => removeSpaces(value),
    value => matchAndInsert(value, { match: /(\+43)(\d*)/, insert: ' ' })
  ],
  CZ: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+420)(\d{3})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  AU: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+61)(\d{3})(\d{3})(\d{3})/,
        insert: ' '
      })
  ],
  AO: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+244)(\d{3})(\d{3})(\d{*})/,
        insert: ' '
      })
  ],
  BR: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+55)(\d{2})(9\d{4})(\d*)/,
        insert: ' '
      })
  ],
  CH: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+41)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  CV: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+238)(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  DK: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+45)(\d{2})(\d{2})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  FO: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+298)(\d{2})(\d{2})(\d{2})/,
        insert: ' '
      })
  ],
  FR: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+33)(\d{1})(\d{2})(\d{2})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  GB: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+44)(\d{4})(\d{3})(\d*)/, insert: ' ' })
  ],
  GL: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+299)(\d{2})(\d{2})(\d{2})/,
        insert: ' '
      })
  ],
  GW: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+245)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  HR: [value => removeSpaces(value)],
  HU: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+36)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  IE: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+353)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  IN: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+91)(\d{2})(\d{4})(\d*)/,
        insert: ' '
      })
  ],
  JO: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+962)(\d)(\d{4})(\d*)/,
        insert: ' '
      })
  ],
  KW: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+965)(\d*)/,
        insert: ' '
      })
  ],
  KR: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+82)(\d{2})(\d{4})(\d*)/,
        insert: ' '
      })
  ],
  MA: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+212)(\d)(\d{2})(\d{2})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  MX: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+52)(\d{3})(\d{3})(\d*)/, insert: '-' })
  ],
  MZ: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match:
          value.length > 12 ? /(\+258)(\d{3})(\d*)/ : /(\+258)(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  NL: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+31)(\d{1})(\d*)/,
        insert: ' '
      })
  ],
  NO: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+47)(\d{3})(\d{2})(\d*)/, insert: ' ' })
  ],
  PH: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+63\d)(\d{3})(\d*)/, insert: ' ' })
  ],
  PL: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+48)(\d{3})(\d{3})(\d*)/, insert: ' ' })
  ],
  RU: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+7)(\d{3})(\d{3})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  'RU/KZ': [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+7)(\d{3})(\d{3})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  SAF: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+27)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  SI: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+386)(\d{2})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  SK: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+421)(\d{3})(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  ST: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+239)(\d{3})(\d*)/,
        insert: ' '
      })
  ],
  UA: [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, {
        match: /(\+380)(\d{2})(\d{3})(\d{2})(\d*)/,
        insert: ' '
      })
  ],
  'USA/CA': [
    value => removeSpaces(value),
    value =>
      matchAndInsert(value, { match: /(\+1)(\d{3})(\d{3})(\d*)/, insert: '-' })
  ]
};

// There can be one or more masking rules for each key defined in
// "defaultCountryCodeMap" in countryCodes.js
