import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { t } from 'Utils/localization/i18next';
import { Grid, Row, Col, Button, Text, PageHeader } from 'Common';
import Image from '@ingka/image';
import Routes from 'Routes/profileRoutes';
import { logout } from 'Redux/auth/authThunks';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import image from './ERROR.jpg';
import styles from './ErrorPage.scss';

const ErrorPage = props => {
  return (
    <Grid className={styles['error-page']}>
      <Row>
        <Col sm={10} lg={5}>
          <PageHeader className={styles['error-page__title']}>
            {t('error.title')}
          </PageHeader>
          <Text className={styles['error-page__message']} marginTop="m">
            {t(props.location.errorText) || t('error.genericText')}
          </Text>
          <Button
            marginTop="l"
            type="primary"
            text={t(props.location.linkText || 'common.backToLogin')}
            onClick={() =>
              props.logout(
                `${window.location.origin}/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/profile${Routes.LOGIN}`,
                true
              )
            }
          />
        </Col>
        <Col sm={10} lg={7}>
          <Image
            className={styles['error-page__image']}
            src={image}
            alt={t('error.title')}
          />
        </Col>
      </Row>
    </Grid>
  );
};

ErrorPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  logout: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
};

const ErrorPageWithConnect = connect(
  null,
  mapDispatchToProps
)(ErrorPage);

export default withRouter(ErrorPageWithConnect);
