import analytics from 'Utils/analytics/analytics';
import {
  ANALYTICS_USER_ACTIONS,
  ANALYTICS_ACTIONS_LABELS,
  ANALYTICS_FORM_MAPPING,
  ANALYTICS_FIELD_MAPPING
} from 'Utils/analytics/analyticsConstants';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
import { readAuthTokenCookie, getDecodedAuthToken } from 'Utils/auth/authUtils';
import JwtDecode from 'jwt-decode';

const userDisplayedSignupPage = profileType => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS[
        `SIGNUP_DISPLAY_${profileType.toUpperCase()}_PROFILE`
      ],
      ANALYTICS_ACTIONS_LABELS.SUCCESS,
      null
    );
  }
};

const userLoggedIn = authResp => {
  if (/production$/.test(CONFIG.ENV)) {
    // Ugly cookie consent check for France, this should be removed when the cookie consent is available for all markets
    if (
      /FR/i.test(CONFIG.COUNTRY) &&
      !window.ikea.cookieConsent.hasConsent(4)
    ) {
      return;
    }

    const idToken = authResp.id_token || authResp.idToken;
    const decodedIdToekn = JwtDecode(idToken);
    const customerType =
      decodedIdToekn['https://accounts.ikea.com/customerType'];
    const profileType =
      customerType === 'business'
        ? PROFILE_TYPES.BUSINESS
        : decodedIdToekn['https://accounts.ikea.com/loyaltyPrograms'].some(
            program => program === 'IKEA_FAMILY'
          )
        ? PROFILE_TYPES.FAMILY
        : PROFILE_TYPES.REGULAR;
    const customerId = decodedIdToekn['https://accounts.ikea.com/partyUId'];
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS[`LOGIN_${profileType.toUpperCase()}_PROFILE`],
      ANALYTICS_ACTIONS_LABELS.SUCCESS,
      customerId
    );
  }
};

const userLoggedInInternal = (profileType, customerId) => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS[`LOGIN_${profileType.toUpperCase()}_PROFILE`],
      ANALYTICS_ACTIONS_LABELS.SUCCESS,
      customerId
    );
  }
};

const userCompletedSignup = (profileType, noDelay) => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS[
        `SIGNUP_COMPLETED_${profileType.toUpperCase()}_PROFILE`
      ],
      ANALYTICS_ACTIONS_LABELS.SUCCESS,
      null,
      noDelay
    );
  }
};

const userUpdatedProfile = profileType => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS.UPDATE_PROFILE,
      ANALYTICS_ACTIONS_LABELS[`${profileType.toUpperCase()}_PROFILE_UPDATE`],
      null
    );
  }
};

const userUpgradedProfile = () => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS.UPDATE_PROFILE,
      ANALYTICS_ACTIONS_LABELS.REGULAR_PROFILE_UPGRADE,
      null
    );
  }
};

const userUpdatedUserLogin = profileType => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS.UPDATE_PROFILE,
      ANALYTICS_ACTIONS_LABELS[
        `${profileType.toUpperCase()}_PROFILE_USER_LOGIN_UPDATE`
      ],
      null
    );
  }
};

const userDisplayedDashboard = () => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendPageViewAnalyticsEvent();
  }
};

const userDeletedProfile = profileType => {
  if (CONFIG.ENV === 'production') {
    analytics.sendUserActionAnalyticsEvent(
      ANALYTICS_USER_ACTIONS.DELETE_PROFILE,
      ANALYTICS_ACTIONS_LABELS[`${profileType.toUpperCase()}_PROFILE_DELETE`],
      null
    );
  }
};

const userProfileFormUpdate = ({ status, form }) => {
  if (/production$/.test(CONFIG.ENV)) {
    const { partyUid, profileType } = getDecodedAuthToken(
      readAuthTokenCookie()
    );
    const action = ANALYTICS_FORM_MAPPING[form];
    const label = form.replaceAll(/-/g, '_').concat(`_${status}`);
    analytics.sendProfileActionAnalyticsEvent({
      label,
      partyUid,
      action,
      profileType
    });
  }
};

const userProfileFieldUpdate = ({ value, field }) => {
  if (/production$/.test(CONFIG.ENV)) {
    const { partyUid, profileType } = getDecodedAuthToken(
      readAuthTokenCookie()
    );
    const action = ANALYTICS_FIELD_MAPPING[field];
    const label = field.replaceAll(/-/g, '_').concat(`_${value}`);
    analytics.sendProfileActionAnalyticsEvent({
      label,
      partyUid,
      action,
      profileType
    });
  }
};

const userProfileUpdateError = ({ status, form, errorKey, errorMessage }) => {
  if (/production$/.test(CONFIG.ENV)) {
    const { partyUid, profileType } = getDecodedAuthToken(
      readAuthTokenCookie()
    );
    const action = ANALYTICS_FORM_MAPPING[form];
    const label = form.replaceAll(/-/g, '_').concat(`_${status}`);
    analytics.sendProfileErrorActionAnalyticsEvent({
      label,
      partyUid,
      action,
      profileType,
      errorKey,
      errorMessage
    });
  }
};

const ciePagePopup = (action, label) => {
  if (/production$/.test(CONFIG.ENV)) {
    analytics.sendUserActionAnalyticsEvent(action, label, null, true);
  }
};

export default {
  userDisplayedSignupPage,
  userLoggedIn,
  userLoggedInInternal,
  userCompletedSignup,
  userUpdatedProfile,
  userUpgradedProfile,
  userUpdatedUserLogin,
  userDisplayedDashboard,
  userDeletedProfile,
  userProfileFormUpdate,
  userProfileUpdateError,
  userProfileFieldUpdate,
  ciePagePopup
};
