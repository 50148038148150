// Actions constants should be mapped in the same order
// as they are defined in the actions file.
export const ACTIONS = {
  SET_OTP_STATUS: 'set_otp_status',
  SET_EMAIL_STATUS: 'set_email_status',
  SET_ACCOUNT_LINKED_STATUS: 'set_account_linked_status',
  SET_OTP_SENT_ERROR: 'set_otp_sent_error',
  SET_EMAIL_VERIFIED_STATUS: 'set_email_verified_status',
  SET_LOGIN_IDENTIFIER: 'set_login_identifier'
};
