import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { history, ReduxStore } from 'Redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import App from 'Components/App';
import ScrollToTop from 'Common/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import '@ingka/base/style.scss';

document.body.addEventListener('keyup', e => {
  if (e.which === 9) {
    document
      .getElementById('profile-frontend')
      .classList.remove('profile__no-focus-outline');
  }
});

ReactDOM.render(
  <Provider store={ReduxStore}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('profile-frontend')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
