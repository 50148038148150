import { getNumOfActiveOrders } from './transformers';
import { readAuthTokenCookie } from 'Utils/auth/authUtils';

export default class OWShoppingListApiClientMock {
  constructor() {
    this.apiHost = CONFIG.API.SHOPPING_LIST.HOST;
  }

  getNumOfShoppingLists() {
    console.log(
      'OWShoppingListApiClient mock API getNumOfShoppingLists called'
    );
    return new Promise(resolve => {
      const mockNumber = '3';
      resolve(mockNumber);
    });
  }
}
