import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { ADDRESS_SERVICE_PROVIDERS } from 'Services/address';
import { AddressAutocompleteService } from 'Services/ServiceFactory';
import { Link, Text, SSRIcons } from 'Common';
import InputFieldHandler from 'BaseForm/InputFieldHandler';
import { LoqateEverythingLocationOption } from './LoqateEverythingLocationOption';
import { LoqateAddressyOption } from './LoqateAddressyOption';
import { GoogleMapsOption } from './GoogleMapsOption';
import { GoogleMapsLogo } from './GoogleMapsLogo';
import { AddressVendorMessage } from './AddressVendorMessage';
import Address from '../Address';
import AddressSummary from './AddressSummary';
import styles from './AddressAutocomplete.scss';

const AUTOCOMPLETE_OPTION_COMPONENTS = {
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_EVERYTHING_LOCATION]: LoqateEverythingLocationOption,
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_ADDRESSY]: LoqateAddressyOption,
  [ADDRESS_SERVICE_PROVIDERS.LOQATE_INTERNAL]: LoqateAddressyOption,
  [ADDRESS_SERVICE_PROVIDERS.GOOGLE_MAPS_AUTOCOMPLETE]: GoogleMapsOption,
  default: LoqateEverythingLocationOption
};

class AddressAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      displayHint: false,
      displayLoader: false,
      displayFields: false,
      activeIndex: -1,
      searchOptions: []
    };

    this.queryMinLength = CONFIG.ADDRESS_AUTOCOMPLETE.QUERY_THRESHOLD || 5;
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.disableAutocomplete) {
      document.addEventListener('mousedown', this.onOutsideClick, false);
    }
  }

  componentWillUnmount() {
    if (!this.props.disableAutocomplete) {
      document.removeEventListener('mousedown', this.onOutsideClick, false);
    }
  }

  onOutsideClick = e => {
    if (this.myRef && !this.myRef.contains(e.target)) {
      this.clearAndSetState({ value: this.state.value });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  onQueryChange = e => {
    const { value } = e.target;
    this.setState({ value, activeIndex: -1 });
  };

  onQueryKeyDown = e => {
    if (e.keyCode === 13) {
      // Enter
      e.preventDefault();
      this.handleEnterClick();
    }
    this.handleNavigation(e);
  };

  onQueryKeyUp = e => {
    if (e.ctrlKey) {
      return;
    }

    const regex = /[a-zA-Z0-9]/;
    const char = String.fromCharCode(e.keyCode);
    const isAlphanumeric = regex.test(char);

    // Autocomplete if key is alphanumeric or backspace
    if (isAlphanumeric || e.keyCode === 8) {
      this.complete();
    }
  };

  onOptionClick = index => {
    if (!this.isValidIndex(index)) {
      return;
    }

    const option = this.state.searchOptions[index];
    const action = option.isCapturable ? this.capture : this.complete;
    this.setState({ value: option.label, activeIndex: index }, () => action());
  };

  onOptionKeyDown = e => {
    if (e.keyCode === 13) {
      // Enter
      this.onOptionClick(this.state.activeIndex);
    } else {
      this.handleNavigation(e);
    }
  };

  handleNavigation = e => {
    const { activeIndex } = this.state;
    let newActiveIndex;

    switch (e.keyCode) {
      // Tab
      case 9:
        newActiveIndex = e.shiftKey ? activeIndex - 1 : activeIndex + 1;
        if (this.isValidIndex(newActiveIndex)) {
          this.setState({ activeIndex: newActiveIndex });
        } else {
          // Options list removed when user tabs out of range
          this.clearAndSetState({ value: this.state.value });
        }
        break;

      // Up
      case 38:
        e.preventDefault();
        newActiveIndex = activeIndex - 1;
        if (this.isValidIndex(newActiveIndex) || newActiveIndex === -1) {
          this.setState({ activeIndex: newActiveIndex });
        }
        break;

      // Down
      case 40:
        e.preventDefault();
        newActiveIndex = activeIndex + 1;
        if (this.isValidIndex(newActiveIndex)) {
          this.setState({ activeIndex: newActiveIndex });
        }
        break;
    }
  };

  handleEnterClick = () => {
    const { activeIndex } = this.state;

    if (activeIndex === -1) {
      this.complete();
    }
    if (this.isValidIndex(activeIndex)) {
      this.onOptionClick(activeIndex);
    }
  };

  complete = () => {
    const { value, activeIndex, searchOptions } = this.state;

    // If user selected an option in the
    // list this is the id of that option
    const containerId =
      this.isValidIndex(activeIndex) && searchOptions[activeIndex].id;

    if (!containerId && !value.length) {
      this.clearAndSetState();
      return;
    }
    if (!containerId && value.length < this.queryMinLength) {
      this.clearAndSetState({ value, displayHint: true });
      return;
    }

    this.clearAndSetState({ value, displayLoader: true });

    const successCallback = result =>
      this.setState({ displayLoader: false, searchOptions: result });
    const failureCallback = () => this.clearAndSetState({ value });

    AddressAutocompleteService.complete(
      value,
      containerId,
      10,
      successCallback,
      failureCallback
    );
  };

  capture = () => {
    const { value, activeIndex, searchOptions } = this.state;
    const captureId = searchOptions[activeIndex].id;

    const successCallback = result => {
      this.onComplete(result);
      this.clearAndSetState({
        value: searchOptions[activeIndex].label
      });
    };
    const failureCallback = () => this.clearAndSetState({ value });

    AddressAutocompleteService.capture(
      value,
      captureId,
      successCallback,
      failureCallback
    );
  };

  onComplete = values => {
    Object.keys(values).forEach(name => {
      this.props.onChange({
        target: {
          name,
          value: values[name]
        }
      });
      this.props.onBlur({
        target: {
          name,
          value: values[name]
        }
      });
    });
  };

  clearAndSetState = data => {
    this.setState({
      value: '',
      displayHint: false,
      displayLoader: false,
      activeIndex: -1,
      searchOptions: [],
      ...data
    });
  };

  displayFields = () => {
    this.setState({ displayFields: true });
  };

  isValidIndex = index => {
    return index > -1 && index < this.state.searchOptions.length;
  };

  isMessagesVisible = () => {
    const { messages } = this.props;
    return Object.keys(messages).some(
      name => messages[name] && messages[name].length > 0
    );
  };

  isAddressEmpty = () => {
    const { values } = this.props;
    return !Object.keys(values).some(
      name => values[name] !== undefined && values[name] !== ''
    );
  };

  render() {
    if (!this.props.nestedFields || !this.props.nestedFields.length) {
      return null;
    }

    const isAddressEmpty = this.isAddressEmpty();
    const isMessagesVisible = this.isMessagesVisible();
    const displayForm = this.state.displayFields || isMessagesVisible;
    const displaySummary =
      !displayForm && !isAddressEmpty && !isMessagesVisible;
    const displayEnterManually = !displayForm && isAddressEmpty;

    const addressForm = (
      <Address
        formId={this.props.formId}
        nestedFields={this.props.nestedFields}
        values={this.props.values}
        messages={this.props.messages}
        checkMandatory={this.props.checkMandatory}
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
      />
    );

    if (this.props.disableAutocomplete) {
      return addressForm;
    }

    return (
      <div ref={this.setRef} className={styles['address-autocomplete']}>
        <div id="map" />
        <div className={styles['address-autocomplete-query-input-wrap']}>
          <InputFieldHandler
            formId={this.props.formId}
            name="autocomplete"
            value={this.state.value}
            label={t('forms.addressPlaceholder')}
            maxLength={200}
            autoComplete="x"
            ssrIcon={SSRIcons.search}
            iconPosition="leading"
            onChange={this.onQueryChange}
            onKeyUp={this.onQueryKeyUp}
            onKeyDown={this.onQueryKeyDown}
            required
          />
        </div>
        <div className={styles['address-autocomplete-result-wrap']}>
          {!this.state.displayLoader && this.state.searchOptions.length > 0 && (
            <div className={styles['address-autocomplete-options']}>
              {this.state.searchOptions.map((option, index) => {
                const OptionComponent =
                  AUTOCOMPLETE_OPTION_COMPONENTS[
                    CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER
                  ] || AUTOCOMPLETE_OPTION_COMPONENTS.default;
                return (
                  <OptionComponent
                    key={`option-${index}`}
                    option={option}
                    index={index}
                    selected={this.state.activeIndex === index}
                    onKeyDown={this.onOptionKeyDown}
                    onClick={() => this.onOptionClick(index)}
                  />
                );
              })}
              {CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER &&
                CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER.startsWith(
                  'GOOGLE'
                ) && <GoogleMapsLogo />}
              {CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER &&
                CONFIG.ADDRESS_AUTOCOMPLETE.SERVICE_PROVIDER.startsWith(
                  'DAWA'
                ) && <AddressVendorMessage />}
            </div>
          )}
          {displayForm && addressForm}
          {displaySummary && (
            <AddressSummary
              {...this.props.values}
              onEdit={this.displayFields}
            />
          )}
          {displayEnterManually && (
            <Link
              id="expand-address-form-link"
              className={styles['address-autocomplete-enter-manually']}
              onClick={this.displayFields}
            >
              {t('forms.manualAddressPromptMsg')}
            </Link>
          )}
          {this.state.displayHint && (
            <AutocompleteHint>{t('forms.addressHint')}</AutocompleteHint>
          )}
          {this.state.displayLoader && (
            <AutocompleteHint>{t('forms.addressLoading')}</AutocompleteHint>
          )}
        </div>
      </div>
    );
  }
}

AddressAutocomplete.propTypes = {
  formId: PropTypes.string,
  nestedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  checkMandatory: PropTypes.func.isRequired,
  disableAutocomplete: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const AutocompleteHint = props => (
  <div className={styles['autocomplete-hint']}>
    <Text className={styles['autocomplete-hint-text']}>{props.children}</Text>
  </div>
);

AutocompleteHint.propTypes = {
  children: PropTypes.string
};

export default AddressAutocomplete;
