import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStores } from 'Redux/stores/storesThunks';
import DropdownControlled from 'BaseForm/DropdownControlled';

class PreferredStoreDropdownOnSubmit extends React.PureComponent {
  componentDidMount() {
    this.props.getStores();
  }

  render() {
    return (
      <DropdownControlled
        formId={this.props.formId}
        name={this.props.name}
        label={this.props.label}
        showOptional={this.props.showOptional}
        value={this.props.value}
        removeHintText={this.props.removeHintText}
        options={this.props.stores
          .map(store => {
            return {
              name: store.name,
              value: store.number
            };
          })
          .filter(store => !this.props.hideKeys.includes(store.value))}
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
      />
    );
  }
}

PreferredStoreDropdownOnSubmit.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  showOptional: PropTypes.bool,
  removeHintText: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  // From Redux/Thunks
  getStores: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.object)
};

PreferredStoreDropdownOnSubmit.defaultProps = {
  hideKeys: []
};

const mapStateToProps = state => {
  return {
    stores: state.stores.stores
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStores
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferredStoreDropdownOnSubmit);
