import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import DropdownControlled from 'BaseForm/DropdownControlled';

const PreferredCommunicationFrequency = props => (
  <DropdownControlled
    formId={props.formId}
    name={props.name}
    label={props.label}
    showOptional={props.showOptional}
    value={props.value}
    options={props.communicationFrequencies.map(frequency => {
      return {
        name: t(`forms.preferredFrequency_${frequency}`),
        value: frequency
      };
    })}
    onBlur={props.onBlur}
    onChange={props.onChange}
  />
);

PreferredCommunicationFrequency.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  communicationFrequencies: PropTypes.arrayOf(PropTypes.string),
  showOptional: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

PreferredCommunicationFrequency.defaultProps = {
  communicationFrequencies: ['weekly', 'twiceWeekly', 'monthly', 'twiceMonthly']
};

export default PreferredCommunicationFrequency;
