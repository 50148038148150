import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Icon.scss';
import icons from './icons';

export const Icon = props =>
  icons[props.children] ? (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
      className={classNames(styles.icon, styles[`icon-${props.size}`], {
        [props.className]: props.className
      })}
    >
      {!props.hideTitle && <title>{props.children}</title>}
      <g className={props.rotate && styles['icon-rotate']}>
        {icons[props.children]}
      </g>
    </svg>
  ) : (
    ''
  );

Icon.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  rotate: PropTypes.bool,
  hideTitle: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.string.isRequired
};

Icon.defaultProps = {
  size: 'sm'
};
