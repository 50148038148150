import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel } from 'Common';
import mask from 'Utils/mask/mask';
import styles from './SectionReadContainer.scss';

const EmailFieldReadContainer = props => {
  const placeholder =
    !props.profile[props.names[0]] &&
    t(`dashboard.placeholders.${props.names[0]}`);
  const maskedValue = props.profile[props.names[0]]
    ? `${mask(props.profile[props.names[0]], props.maskRules)}`
    : '';

  return (
    <FlexPanel spread>
      {placeholder ? (
        <Text colour="grey-500">{placeholder}</Text>
      ) : (
        <div>
          <p className={styles['common-specifier']}>
            {t(`dashboard.communications.email-section.title`)}
          </p>
          <div>
            <Text className={styles['common-value']}>{maskedValue}</Text>
          </div>
        </div>
      )}
    </FlexPanel>
  );
};

EmailFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  profile: PropTypes.object,
  emailVerified: PropTypes.bool,
  maskRules: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    emailVerified: state.verification.emailVerifiedStatus
  };
};

export default connect(
  mapStateToProps,
  null
)(EmailFieldReadContainer);
