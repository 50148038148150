import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import styles from './Row.scss';
import './style.scss';

export const Row = props => (
  <div
    className={classNames(`${CONFIG.APP.STYLES_PREFIX}ikea-row`, {
      [styles['row-center']]: props.center,
      [props.className]: props.className
    })}
    style={props.style}
  >
    {props.children}
  </div>
);

Row.propTypes = {
  className: PropTypes.string,
  center: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
