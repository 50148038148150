import { ssnRegex } from 'Utils/validation/regex/ssnRegex';
import autocorrectors from 'Utils/autocorrect/autocorrectors';
import transformers from 'Utils/transform/transformers';
import { t } from 'Utils/localization/i18next';

const isString = value => {
  return typeof value === 'string';
};

/**
 * Transforms value from format 'DD-MM-YYYY' to a more readable string. When
 * using default arguments, '09-04-1991' will be transformed to '09 Apr 1991'.
 *
 * Use "mask" parameter to modify the order of the transformed content. "mask"
 * takes precedence over "delimiter"; make sure to include delimiters in mask.
 *
 * "yearFormat", "monthFormat" and "dayFormat" are Intl formats used to specify
 * format of year, month and day respectively. Available values are listed here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
const birthDate = (
  value,
  {
    delimiter = ' ',
    mask = '',
    yearFormat = 'numeric',
    monthFormat = 'short',
    dayFormat = '2-digit',
    disableAutoLocalization,
    disableDayAutoLocalization
  }
) => {
  if (!isString(value)) {
    return value;
  }
  const formattedDate = value.match(/^(\d{2})-(\d{2})-(\d{2,4})$/);
  if (!formattedDate || formattedDate.length < 4) {
    return value;
  }

  const date = new Date(
    `${formattedDate[3]}-${formattedDate[2]}-${formattedDate[1]}T00:00:00`
  );

  const day =
    disableAutoLocalization || disableDayAutoLocalization
      ? formattedDate[1]
      : new Intl.DateTimeFormat(
          `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
          { day: dayFormat }
        ).format(date);

  const month = disableAutoLocalization
    ? formattedDate[2]
    : new Intl.DateTimeFormat(
        `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
        { month: monthFormat }
      ).format(date);

  const year = disableAutoLocalization
    ? formattedDate[3]
    : new Intl.DateTimeFormat(
        `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
        { year: yearFormat }
      ).format(date);

  if (mask) {
    return mask
      .replace('day', day)
      .replace('month', month)
      .replace('year', year);
  }
  return `${day}${delimiter}${month}${delimiter}${year}`;
};

const birthDateWithTranslatedMonth = (
  value,
  { mask, monthFormat = 'short' }
) => {
  if (!isString(value) || !mask) {
    return value;
  }
  const formattedDate = value.match(/^(\d{2})-(\d{2})-(\d{2,4})$/);
  if (!formattedDate || formattedDate.length < 4) {
    return value;
  }
  const day = formattedDate[1];
  const month = formattedDate[2];
  const year = formattedDate[3];

  return mask
    .replace('day', day)
    .replace('month', t(`date.month.${monthFormat}.${month.replace(/^0/, '')}`))
    .replace('year', year);
};

const swedishSSN = value => {
  if (!isString(value) || !ssnRegex.SE.test(value)) {
    return value;
  }
  return value.replace(/(.*)(?:\d{4})$/, '$1-XXXX');
};

export default {
  birthDate,
  birthDateWithTranslatedMonth,
  swedishSSN,
  matchAndInsert: autocorrectors.matchAndInsert,
  matchAndReplace: autocorrectors.matchAndReplace,
  removeSpaces: autocorrectors.removeSpaces,
  toNumeric: autocorrectors.toNumeric,
  dateFormatSpecificToUnified: transformers.dateFormatSpecificToUnified,
  dateFormatUnifiedToSpecific: transformers.dateFormatUnifiedToSpecific,
  specificValueMappingIfProfileFieldValueInList:
    transformers.specificValueMappingIfProfileFieldValueInList
};
