import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { PageHeader, Text } from 'Common';
import { connect } from 'react-redux';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import styles from './DashboardSectionForm.scss';

class UpgradeSectionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isEditing: false,
      showSuccessMessage: false
    };
  }

  render() {
    const displayName =
      CONFIG.FEATURE.ENABLE_SALUTATION_SERVICE &&
      this.props.profile.firstNamePhonetic
        ? this.props.profile.firstNamePhonetic
        : this.props.profile.firstName;
    return (
      <React.Fragment>
        <div className={styles['dashboard-section-form']}>
          {this.props.title && (
            <PageHeader marginBottom="m">
              {t('forms.signup.pageTitleUpgrade')}
            </PageHeader>
          )}
          {this.props.text && (
            <div>
              <Text marginBottom="m">
                {t('forms.signup.upgradeHello', {
                  firstName: displayName
                })}{' '}
                {t('forms.signup.upgradeText')}
              </Text>
            </div>
          )}

          {this.props.text2 && (
            <Text marginBottom="m">{t(this.props.text2)}</Text>
          )}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

UpgradeSectionForm.propTypes = {
  profile: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    profileType: state.profile.profileType,
    profileFetched: !!state.profile.profileType,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPGRADE]
  };
};

export default connect(mapStateToProps)(UpgradeSectionForm);
