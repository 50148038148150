import { ErrorTypes } from './ErrorTypes';
import { ErrorCodes } from './ErrorCodes';

export const errorMessageToErrorCode = errorMessage => {
  switch (errorMessage) {
    case 'ws_createNormalUser.email1.error.email':
    case 'ws_createFamilyUser.email1.error.email':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_INVALID_EMAIL_DOMAIN];
    case 'ws_updateNormalUser.zipCode.error.zipCode':
    case 'ws_updateFamilyUser.zipCode.error.zipCode':
    case 'ws_createNormalUser.zipCode.error.zipCode':
    case 'ws_createFamilyUser.zipCode.error.zipCode':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_INVALID_ZIPCODE];
    default:
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_VALIDATION_FAILED];
  }
};

// Reason codes from IRW API v2, mapped to the application generic erors
// These codes are for profile creation only. See IRW docs.
export const reasonCodeToErrorCode = (reasonCode, errorMessage) => {
  switch (reasonCode) {
    case '10010':
      // This error code is also used for post/put delivery address
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_VALIDATION_FAILED];
    case '10020':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_ALREADY_LOGGED_IN];
    case '10030':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_ALREADY_EXISTS];
    case '10130':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_ALREADY_EXISTS];
    case '10040':
      // This error code is used for post delivery address
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_DELIVERY_ADDRESS_QUANTITY_LIMIT
      ];
    case '10050':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_FAMILY_NOT_ENABLED];
    case '10060':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_BUSINESS_NOT_ENABLED];
    case '10090':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_MISSING_PARAMETERS];
    case '10091':
      return [ErrorTypes.ACCOUNT, ErrorCodes.PASSWORD_TOO_WEAK];
    case '10092':
      return [ErrorTypes.ACCOUNT, ErrorCodes.PASSWORD_TOO_COMMON];
    case '10093':
      return [ErrorTypes.ACCOUNT, ErrorCodes.PASSWORD_CONTAINS_USER_INFO];
    case '10150':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_SSN_ALREADY_USED];
    case 'UNIQUENESS_FAILURE':
      return [ErrorTypes.ACCOUNT, ErrorCodes.ACCOUNT_SSN_ALREADY_USED];

    // Error code for Update login id operation
    case '50010':
      return [ErrorTypes.ACCOUNT, ErrorCodes.EMPTY_PAYLOAD];
    case '50020':
      return [ErrorTypes.ACCOUNT, ErrorCodes.INVALID_PAYLOAD];
    case '50030':
      return [ErrorTypes.ACCOUNT, ErrorCodes.LOGONID_ALREADY_EXIST];
    case '50031':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.LOGONID_ALREADY_EXIST_ON_MOBILE_UPDATE
      ];
    case '50040':
      return [ErrorTypes.ACCOUNT, ErrorCodes.NOT_AUTHORIZED];
    case '50050':
      return [ErrorTypes.ACCOUNT, ErrorCodes.LOGONID_TYPE_NOT_SUPPORTED];

    case '20010':
      return [
        ErrorTypes.VERIFICATION,
        ErrorCodes.VERIFICATION_UNVERIFIED_MOBILE
      ];
    case '20011':
      return [
        ErrorTypes.VERIFICATION,
        ErrorCodes.VERIFICATION_UNVERIFIED_EMAIL
      ];
    case '20015':
      return [ErrorTypes.VERIFICATION, ErrorCodes.VERIFICATION_FAILED];
    case '20016':
      return [
        ErrorTypes.VERIFICATION,
        ErrorCodes.VERIFICATION_FAILED_CONTEXT_NOT_FOUND
      ];
    case '20017':
      return [ErrorTypes.VERIFICATION, ErrorCodes.VERIFICATION_COOLDOWN];
    case 'VALIDATION:minAge':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_INVALID_CHILD_BIRTH_DATE,
        errorMessage
      ];
    case 'VALIDATION:regEx':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_VALIDATION_FAILED,
        errorMessage
      ];
    case 'VALIDATION:missingField':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_MISSING_PARAMETERS,
        errorMessage
      ];
    case 'VALIDATION:maxLength':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_VALIDATION_FAILED,
        errorMessage
      ];
    case 'VALIDATION':
      return [
        ErrorTypes.ACCOUNT,
        ErrorCodes.ACCOUNT_VALIDATION_FAILED,
        errorMessage
      ];
    default:
      return [ErrorTypes.UNKNOWN, ErrorCodes.UNKNOWN, reasonCode];
  }
};
