// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile__reset-password-confirmation{min-height:50vh;padding-top:6rem !important;padding-bottom:6rem !important}@media(max-width: 576px){.profile__reset-password-confirmation{padding-top:2rem !important;padding-bottom:2rem !important}.profile__reset-password-confirmation-left{padding-right:6rem}}", "",{"version":3,"sources":["webpack://./src/components/Password/ResetPasswordConfirmation.scss"],"names":[],"mappings":"AAEA,sCACE,eAAA,CACA,2BAAA,CACA,8BAAA,CAGF,yBACE,sCACE,2BAAA,CACA,8BAAA,CAEA,2CACE,kBAAA,CAAA","sourcesContent":["@import '@ingka/variables/style.scss';\n\n.reset-password-confirmation {\n  min-height: 50vh;\n  padding-top: 6rem !important;\n  padding-bottom: 6rem !important;\n}\n\n@media (max-width: 576px) {\n  .reset-password-confirmation {\n    padding-top: 2rem !important;\n    padding-bottom: 2rem !important;\n\n    &-left {\n      padding-right: 6rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-password-confirmation": "profile__reset-password-confirmation",
	"reset-password-confirmation-left": "profile__reset-password-confirmation-left"
};
export default ___CSS_LOADER_EXPORT___;
