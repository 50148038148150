import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from 'Common';
import { t } from 'Utils/localization/i18next';

export const LegalText = ({ props }) => {
  return (
    <Text marginTop="s">
      {t(props.legalTextBeforeLink)}{' '}
      <Link url={props.firstLink} target="_blank">
        {t(props.firstLinkLabel)}
      </Link>{' '}
      {t(props.secondLabel)}{' '}
      <Link url={props.secondLink} target="_blank">
        {t(props.secondLinkLabel)}
      </Link>{' '}
      {t(props.thirdLabel)}{' '}
      <Link url={props.thirdLink} target="_blank">
        {t(props.thirdLinkLabel)}
      </Link>
      {'.'}
    </Text>
  );
};

LegalText.propTypes = {
  id: PropTypes.string,
  legalTextBeforeLink: PropTypes.string,
  firstLink: PropTypes.string,
  firstLinkLabel: PropTypes.string,
  secondLabel: PropTypes.string,
  secondLink: PropTypes.string,
  secondLinkLabel: PropTypes.string,
  thirdLabel: PropTypes.string,
  thirdLink: PropTypes.string,
  thirdLinkLabel: PropTypes.string,
  // Props passed down to Text component
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  center: PropTypes.bool,
  smaller: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  colour: PropTypes.oneOf([
    'grey-500',
    'grey-700',
    'brand-blue',
    'feedback-error'
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  props: PropTypes.arrayOf(PropTypes.string)
};
