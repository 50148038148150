import { ACTIONS } from './paymentConstants';

export const setPayments = payments => {
  return {
    type: ACTIONS.SET_PAYMENTS,
    payments
  };
};

export const deletePayment = () => {
  return {
    type: ACTIONS.DELETE_PAYMENT
  };
};

export const setDefaultPayment = () => {
  return {
    type: ACTIONS.SET_DEFAULT
  };
};

export const clearPaymentsAction = () => {
  return {
    type: ACTIONS.CLEAR_PAYMENTS
  };
};
